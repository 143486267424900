import { FetchResult, gql } from 'apollo-boost';
import { IRewardConfig } from '../../../models/reward-config';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query RewardConfig($uid: String!) {
        reward_config(uid: $uid) {
            uid,
            name,
            description,
            maxWeeklyEarn: max_weekly_earn,

            rewards {
                uid,
                name,
                description,
            }
        }
    }
`;

export async function getRewardConfig(rewardConfigUid: string): Promise<IRewardConfig> {
    const result: FetchResult<{ reward_config: IRewardConfig }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid: rewardConfigUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.reward_config;
}
