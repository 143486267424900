import { FetchResult, gql } from 'apollo-boost';
import { IInitiatingEvent } from '../../../models/initiating-event';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation InitiatingEventSave($initiatingEvent: InitiatingEventInput!){
        initiating_event_save (initiating_event_data: $initiatingEvent) {
            uid
        }
    }
`;

export async function initiatingEventSave(initiatingEvent: Partial<IInitiatingEvent>): Promise<string> {
    const result: FetchResult<{ initiating_event_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { initiatingEvent },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.initiating_event_save.uid;
}
