import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IListResult } from '../../../models/list-result';
import { IUserInvite } from '../../../models/user-invite';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

function getQuery(isForGroup: boolean) {

    return gql`
        query UserInvitesGetPaged($page: Int, $searchText: String, $orderBy: String, $groupUid: String, $pageSize: Int) {
            user_invites_get_paged(page: $page, search_text: $searchText, order_by: $orderBy, group_uid: $groupUid, page_size: $pageSize) {
                count,
                page,
                pageSize: page_size,

                data {
                    uid,
                    inviterEmail: inviter_email,
                    inviterUid: inviter_uid,
                    inviteeFirstName: invitee_first_name,
                    inviteeLastName: invitee_last_name,
                    inviteeEmail: invitee_email
                    status,
                    expirationDate: expiration_date,
                    expired,
                    lastSent: last_sent_at_string
                }
            }
        }
    `;
}

export async function getUserInvitesPaged(params: {
    page: number,
    searchText?: string,
    orderBy?: string[],
    groupUid?: string,
    pageSize?: number,
}): Promise<IListResult<IUserInvite>> {
    const result: FetchResult<{ user_invites_get_paged: IListResult<IUserInvite> }> = await graphqlClient.query({
        query: getQuery(!!params.groupUid),
        variables: {
            page: params.page,
            searchText: params.searchText,
            orderBy: params.orderBy?.join(';'),
            groupUid: params.groupUid,
            pageSize: params.pageSize || 20,
        },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.user_invites_get_paged, ['__typename']);
}
