import { FetchResult, gql } from 'apollo-boost';
import { IUserPlan } from '../../../models/user-plan';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query UserPlan($uid: String!) {
        user_plan(uid: $uid) {
            uid,
            name,
            type,
            accessType: access_type,
            freeTrial: active_trial,
            trialLength: trial_length,
            trialEndDate: trial_end,
            trialForRoles: trial_for_roles,
            finalUnitIndex: final_unit_index,
            description,
            oneOff: one_off,
            priceId: price_id,
            free,
            enabled,
            default,
            sector,
            maxLicenseCount: max_license_count,
            adminPermissions: admin_permissions,
            learnersAllowed: learners_allowed,
            maxGroupsPerEducator: max_groups_per_educator,
            certifications: included_certifications {
                uid,
                name,
                description,
                achievementType: achievement_type,
                iconFileName: icon_file_name,
                index,
                available,
            }
        }
    }
`;

export async function getUserPlan(userPlanUid: string): Promise<IUserPlan> {
    const result: FetchResult<{ user_plan: IUserPlan }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid: userPlanUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_plan;
}
