import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';
import * as React from 'react';
import { logout } from '../../../api/actions/logout-action';
import { AppConfig } from '../../../config/app-config';
import { IUser } from '../../../models/user';

import styles from './current-user.module.scss';


export function CurrentUser({ user }: { user?: IUser }) {
    const logOut = async () => {
        await logout();
        Cookies.remove(AppConfig.tokenName);
        document.location.href = '/login';
    };

    return (<div className={styles.container}>
        {`${user?.firstName} ${user?.lastName}`}
        <button onClick={logOut} className={styles.user}>
            <FontAwesomeIcon icon={faUser} />
        </button>
    </div>);
}
