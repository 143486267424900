import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowDown, faArrowLeft, faArrowUp, faLock, faPlus, faUnlock, faUpload, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { curriculumDelete } from '../../../api/actions/curriculum/curriculum-delete';
import { curriculumDisable } from '../../../api/actions/curriculum/curriculum-disable';
import { curriculumEnable } from '../../../api/actions/curriculum/curriculum-enable';
import { curriculumPublish } from '../../../api/actions/curriculum/curriculum-publish';
import { getCurriculum } from '../../../api/actions/curriculum/curriculum-get';
import { curriculumSave } from '../../../api/actions/curriculum/curriculum-save';
import { unitDisable } from '../../../api/actions/unit/unit-disable';
import { unitEnable } from '../../../api/actions/unit/unit-enable';
import { unitSetIndex } from '../../../api/actions/unit/unit-set-index';
import { getAbsoluteClientUrl } from '../../../config/app-config';
import { Block } from '../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { SelectField } from '../../../components/shared/select/select';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { Tabs } from '../../../components/shared/tabs/tabs';
import { basePerformError } from '../../../helpers/error-helpers';
import { joinPath, getParentPath } from '../../../helpers/path-helpers';
import { ICurriculum } from '../../../models/curriculum';
import { IUnit } from '../../../models/unit';
import { IDictionary } from '../../../types/dictionary';
import { CurriculumAchievementsList } from '../../achievements/curriculum-achievement-list';
import { ContentAgeRating } from '../../../models/narrative';
import { CurrentUserContext } from '../../../components/user/current-user-manager';
import { CheckBoxWrapper } from '../../../components/shared/checkbox/checkbox-wrapper';
import styles from './curriculum-editor.module.scss';


enum FormFields {
    name = 'name',
    description = 'description',
    basicUnitCount = 'basicUnitCount',
    minAgeRating = 'minAgeRating',
    default = 'default'
}

interface IForm {
    [FormFields.name]: string,
    [FormFields.description]?: string,
    [FormFields.basicUnitCount]?: number | null,
    [FormFields.minAgeRating]?: number | null,
    [FormFields.default]?: boolean,
}

export function CurriculumEditor(props: RouteComponentProps<{uid: string}>) {

    const [loading, setLoading] = useState(true);
    const [curriculum, setCurriculum] = useState<ICurriculum>();
    const [entityForDelete, setEntityForDelete] = useState<ICurriculum>();

    const currentUserContext = React.useContext(CurrentUserContext);

    const concurrentEditing = useMemo(() => curriculum?.edited && !!curriculum?.lastEditedBy && !!currentUserContext?.currentUser && (curriculum?.lastEditedBy.uid !== currentUserContext?.currentUser?.uid), [curriculum, currentUserContext]);

    const loadData = React.useCallback(async (uid?: string) => {
        setLoading(true);
        try {
            if(uid || props.match.params.uid !== '_') {
                const cuid = uid || props.match.params.uid;
                const cur = await getCurriculum(cuid);
                setCurriculum(cur);
                if (cuid && cuid !== cur.uid) {
                  //update the URL if we got a draft curriculum
                  const parent_path = getParentPath(props.match.url, 1)
                  props.history.push(joinPath(parent_path, cur.uid));
                }
            }
            else {
                setCurriculum({} as ICurriculum);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
        setLoading(false);
    }, [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData().catch(() => {/** */});
    }, []);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newCurriculum: Partial<ICurriculum> = {
                uid: curriculum?.uid,
                name: values.name,
                description: values.description,
                basicUnitCount: values.basicUnitCount || undefined,
                minAgeRating: values.minAgeRating,
                default: values.default
            };
            const uid = await curriculumSave(newCurriculum as ICurriculum);
            if(!curriculum?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            }
            await loadData(uid);
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const enableCurriculum = async () => {
        if(curriculum?.uid) {
            try {
                await curriculumEnable(curriculum.uid);
                await loadData(curriculum.uid);
                setCurriculum({...curriculum, available: true });
                toast.success('Item has been successfully enabled');
            }
            catch(err) {
                basePerformError(err, props.history);
            }
        }
    };

    const disableCurriculum = async () => {
        if(curriculum?.uid) {
            try {
                await curriculumDisable(curriculum.uid);
                setCurriculum({...curriculum, available: false });
                toast.success('Item has been successfully saved');
            }
            catch(err) {
                basePerformError(err, props.history);
            }
        }
    };

    const publishCurriculum = async () => {
        if(curriculum?.uid) {
            try {
                await curriculumPublish(curriculum.uid);
                setCurriculum({...curriculum, edited: false });
                toast.success('Item has been successfully published');
            }
            catch(err) {
                basePerformError(err, props.history);
            }
        }
    };

    const previewCurriculum = async () => {
      if(curriculum?.uid && (curriculum?.edited || !curriculum?.carbonCopy)) {
        //show the current curriculum if we have edits (and this is a draft), or if this the only copy
        const cpath = getAbsoluteClientUrl('curriculum', curriculum?.uid);
        window.open(cpath)
      } else if(curriculum?.uid && curriculum?.carbonCopy && !curriculum?.edited) {
        //show the carbon copy (i.e. original version) if we have one and if there are no edits
        const cpath = getAbsoluteClientUrl('curriculum', curriculum?.carbonCopy);
        window.open(cpath)
      }
    }

    const enableUnit = async (uid: string) => {
        try {
            await unitEnable(uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const disableUnit = async (uid: string) => {
        try {
            await unitDisable(uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const setIndex = async (uid: string, index: number) => {
        try {
            await unitSetIndex(uid, index);
            loadData().catch(() => {/** */});
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().trim().required('Please enter Name').min(3).max(50),
            description: Yup.string().trim().min(3).max(255),
            basicUnitCount: Yup.number().label('Basic Unit Count').integer().nullable(),
            minAgeRating: Yup.number().label('Age Rating').required(),
        });
    };

    const renderForm = ({ errors, touched }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                {concurrentEditing && (
                  <div className={styles.alert}>
                      This curriculum is currently being edited by another user ({curriculum?.lastEditedBy?.email}).
                      Editing some parts of the curriculum may be disabled.
                  </div>
                )}

                <Form noValidate>

                    { curriculum && curriculum.uid && (
                        <div className="form-buttons">
                            { curriculum.uid && !curriculum.available && (
                                <LinkButton disabled={concurrentEditing} onClick={enableCurriculum}><FontAwesomeIcon icon={faUnlock} /> Enable</LinkButton>
                            )}
                            { curriculum.uid && curriculum.available && (
                                <LinkButton onClick={disableCurriculum}><FontAwesomeIcon icon={faLock} /> Disable</LinkButton>
                            )}
                            { curriculum.uid && curriculum.edited && (
                                <LinkButton onClick={publishCurriculum}><FontAwesomeIcon icon={faUpload} /> Publish</LinkButton>
                            )}
                            { curriculum.uid && (
                                <LinkButton onClick={previewCurriculum}><FontAwesomeIcon icon={faEye} />{curriculum.edited || !curriculum.available ? 'Preview' : 'View Live'} </LinkButton>
                            )}

                            { curriculum.uid && (
                                <LinkButton disabled={concurrentEditing} onClick={() => setEntityForDelete(curriculum)} className="red">
                                    <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                </LinkButton>
                            )}
                        </div>
                    )}
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name
                            </div>
                            <Field type="text" name={FormFields.name} />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field component="textarea" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Age Rating:
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.minAgeRating}
                                emptyTitle=""
                                data={Object.keys(ContentAgeRating).map(rating => ({ uid: parseInt(rating), name: ContentAgeRating[parseInt(rating)]}))}
                            />
                        </label>
                        <div className="errors">{errors.minAgeRating}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Default for Age Group">
                                <Field type="checkbox" name={FormFields.default} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.default && errors.default}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Basic Unit Count
                            </div>
                            <Field type="number" name={FormFields.basicUnitCount} />
                        </label>
                        <div className="errors">{touched.basicUnitCount && errors.basicUnitCount}</div>
                    </div>
                    <div className="form-buttons">
                        <Button onClick={() => props.history.push('/curriculums')} className="gray">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                        ? (<Loader />)
                        : (<Button type="submit" disabled={concurrentEditing}><span>{curriculum?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return (<>
        <Tabs
            data={[
                {
                    title: 'Edit',
                    content: (<>
                        <Block className={styles.editor}>
                            { loading
                              ? (<Loader />)
                              : ''
                            }
                            { curriculum && (<>
                                <AddBreadcrumbsItem
                                    title={curriculum && curriculum.uid ? `Edit: ${curriculum.name}` : 'Add Curriculum'}
                                    url={props.match.url}
                                />
                                <Formik
                                        initialValues={{
                                            name: curriculum.name || '',
                                            description: (curriculum && curriculum.description) || '',
                                            basicUnitCount: curriculum?.basicUnitCount || null,
                                            minAgeRating: curriculum.minAgeRating || 0,
                                            default: curriculum.default || false
                                        }}
                                        validationSchema={getValidationSchema}
                                        onSubmit={handleSubmit}
                                >
                                {renderForm}
                                </Formik>

                                <div>

                                </div>
                            </>)}
                        </Block>
                    </>)
                },
                {
                    title: <>Units: <strong>{ curriculum?.units?.length || 0 }</strong></>,
                    hidden: !curriculum?.uid,
                    content: (<>
                        <div className="list-title">
                            <div>Units: <strong>{ curriculum?.units?.length || 0 }</strong></div>
                            <div>
                                <Button disabled={concurrentEditing} className="orange" onClick={() => { props.history.push(joinPath('/', props.match.url, 'units/_')); }}>
                                    <FontAwesomeIcon icon={faPlus} /> <span>Add Unit</span>
                                </Button>
                            </div>
                        </div>
                        { curriculum?.units && !!curriculum.units.length && (
                            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Unit</th>
                                        <th>Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        curriculum.units.sort((u1, u2) => u1.index - u2.index)
                                        .map((u: IUnit, i: number) => (
                                            <tr key={u.uid}>
                                                <td>
                                                    <div className={styles.markContainer}>
                                                        <div className={`${styles.mark} ${u.available ? '' : styles.disabled}`}>&nbsp;</div>
                                                        <div className={styles.title}>
                                                            {u.index}
                                                            <LinkButton
                                                                onClick={() => setIndex(u.uid, i + 2)}
                                                                disabled={i >= (curriculum.units?.length || 0) - 1 || concurrentEditing}
                                                            >
                                                                <FontAwesomeIcon icon={faArrowDown} />
                                                            </LinkButton>
                                                            <LinkButton
                                                                onClick={() => setIndex(u.uid, i)}
                                                                disabled={i < 1 || concurrentEditing}
                                                            >
                                                                <FontAwesomeIcon icon={faArrowUp} />
                                                            </LinkButton>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {u.name + (u.edited ? '*' : '')}
                                                    <div className="list-item-comment">{u.description}</div>
                                                </td>
                                                <td>
                                                    {u.type}
                                                </td>
                                                <td>
                                                    <LinkButton onClick={() => props.history.push(joinPath('/', props.match.url, 'units', u.uid)) }>
                                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                                    </LinkButton><br />
                                                    { !u.available && (<>
                                                        <LinkButton disabled={concurrentEditing} onClick={() => enableUnit(u.uid)}>
                                                            <FontAwesomeIcon icon={faUnlock} /> Enable
                                                        </LinkButton><br />
                                                    </>)}
                                                    { u.available && (<>
                                                        <LinkButton onClick={() => disableUnit(u.uid)}>
                                                            <FontAwesomeIcon icon={faLock} /> Disable
                                                        </LinkButton><br />
                                                    </>)}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        )}
                    </>)
                },
                {

                    title: <>Achievements</>,
                    hidden: !curriculum?.uid,
                    content: (<>
                        {curriculum && (
                            <CurriculumAchievementsList
                                curriculum={curriculum}
                                reloadData={loadData}
                                editable={!concurrentEditing}
                            />
                        )}
                    </>),
                },
            ]}
        />


        { curriculum && curriculum.uid && entityForDelete &&  (<>
            <DeleteQuestionPopup
                title="Delete Curriculum"
                question={<>Are you sure want to delete curriculum: <strong>{entityForDelete.name}</strong>?</>}
                onClose={() => setEntityForDelete(undefined)}
                onDelete={async () => await curriculumDelete(entityForDelete.uid)}
                onFinish={() => { props.history.push('/curriculums'); }}
            />
        </>)}
    </>);
}
