import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IExternalReference } from '../../../models/external-reference';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetExternalReference($uid: String!){
        external_reference(uid: $uid) {
            uid,
            serviceName: service_name,
            externalServiceUid: external_service_uid,
            externalServiceUrl: external_service_url,
            infoPageUid: info_page_uid,
            timeOffset: time_offset,
            preferredSource: preferred_source,
            paidPlansOnly: paid_plans_only,

            narrative {
                uid,
            },

            episode {
                uid,
            },
        }
    }
`;

export async function getExternalReference(uid: string): Promise<IExternalReference> {
    const result: FetchResult<{ external_reference: IExternalReference }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.external_reference, ['__typename']);
}
