import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { emailDisable } from '../../../api/actions/email/email-disable';
import { emailEnable } from '../../../api/actions/email/email-enable';
import { getAllEmails } from '../../../api/actions/email/emails-get-all';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { CurrentUserContext } from '../../../components/user/current-user-manager';
import { basePerformError } from '../../../helpers/error-helpers';
import { joinPath } from '../../../helpers/path-helpers';
import { IEmail } from '../../../models/email';
import styles from './email-list.module.scss';


export function EmailList(props: RouteComponentProps) {

    const [loading, setLoading] = useState(true);
    const [emails, setEmails] = useState<IEmail[]>([]);

    const currentUserContext = React.useContext(CurrentUserContext);

    const loadData = React.useCallback(async () => {
        if(!currentUserContext?.currentUser) return;
        setLoading(true);
        try {
            const es = await getAllEmails();
            setEmails( es );
        }
        catch(err) {
            basePerformError(err, props.history);
        }

        setLoading(false);
    }, [currentUserContext?.currentUser, props.history]);

    useEffect(() => {
        loadData();
    }, [loadData, currentUserContext?.currentUser]);

    const renderButtons = () => (
        <div className={styles.buttons}>
            <Button className="orange" to={joinPath(props.match.url, '_')}>Add Email</Button>
        </div>
    );

    const disableEmail = async (uid: string) => {
        try {
            await emailDisable(uid);
            loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const enableEmail = async (uid: string) => {
        try {
            await emailEnable(uid);
            loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    return loading ? (<Loader />) :(
        <>
            {renderButtons()}

            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Send Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        emails.sort((c1, c2) => c1.name > c2.name ? 1 : (c1.name < c2.name ? -1 : 0) ).map((c => (
                            <tr key={c.uid}>
                                <td>
                                    <div className={styles.markContainer}>
                                        <div className={`${styles.mark} ${c.enabled ? '' : styles.disabled}`}>&nbsp;</div>
                                        <div className={styles.title}>
                                            {c.name}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {c.type}
                                </td>
                                <td>
                                    {c.daysAfterSignup ? c.daysAfterSignup + " days after signup" : ""}
                                </td>
                                <td>
                                    <LinkButton to={joinPath(props.match.url, c.uid)}>
                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                    </LinkButton><br />
                                    { !c.enabled && (<>
                                        <LinkButton className={styles.enableBtn} onClick={() => enableEmail(c.uid)}>
                                            <FontAwesomeIcon icon={faUnlock} /> Enable
                                        </LinkButton><br />
                                    </>)}
                                    { c.enabled && (<>
                                        <LinkButton onClick={() => disableEmail(c.uid)}>
                                            <FontAwesomeIcon icon={faLock} /> Disable
                                        </LinkButton><br />
                                    </>)}
                                </td>
                            </tr>
                        )))
                    }
                </tbody>
            </table>

            {renderButtons()}
        </>
    );
}
