import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { unitSectionSave } from '../../../api/actions/unit-section/unit-section-save';
import { Button } from '../../../components/shared/button/button';
import { Loader } from '../../../components/shared/loader/loader';
import { removeFields } from '../../../helpers/common-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import { ISkill, buildSkillsTree } from '../../../models/skill';
import { IUnitSection, IUnitSectionExerciseParam, UnitSectionTerminologyValues } from '../../../models/unit-section';
import { IDictionary } from '../../../types/dictionary';
import styles from './unit-section-exercise-params-editor.module.scss';
import { SelectField } from '../../../components/shared/select/select';

interface IProps extends RouteComponentProps {
    unitSection: IUnitSection,
    entity: IUnitSectionExerciseParam,
    skills?: ISkill[],
    onClose: () => void,
    reload(): void,
}

enum FormFields {
    type = 'type',
    skill = 'skill',
    terminology = 'terminology',
}

interface IForm {
    [FormFields.type]: string,
    [FormFields.skill]: string,
    [FormFields.terminology]: string,
}

function UnitSectionExerciseParamEditor(props: IProps) {

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const isNew = !props.entity.type;
            props.entity.type = values.type;
            props.entity.skill = values.skill;
            props.entity.terminology = values.terminology;
            if(!props.unitSection.exerciseParams) props.unitSection.exerciseParams = [];
            
            if(isNew) props.unitSection.exerciseParams.push(props.entity);

            const newUnitSection = removeFields(props.unitSection, ['__typename', 'unit', 'learningChunks', 'edited']);
            await unitSectionSave(newUnitSection);

            toast.success('Item has been successfully saved');
            setSubmitting(false);
            setLoading(false);
            props.reload();
            props.onClose();
        }
        catch (err) {
            props.reload();
            basePerformError(err, props.history);
            setSubmitting(false);
            setLoading(false);
        }
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            type: Yup.string().trim().required(),
            terminology: Yup.string().trim().required(),
            skill: Yup.string().trim().required().test(
                'not-exists',
                'Selected combination of parameters already exists',
                function(value) {
                    return !props.unitSection.exerciseParams || !props.unitSection.exerciseParams.some(
                        (p: IUnitSectionExerciseParam) => p.type === this.parent.type && p.skill === value && p !== props.entity,
                    );
                },
            ),
        });
    };

    const renderForm = ({ errors, touched, values, setFieldValue }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form
                    noValidate={true}
                >
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Type
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.type}
                                emptyTitle=""
                                data={['mult_choice', 'user_choice', 'open_response']}
                            />
                        </label>
                        <div className="errors">{touched.type && errors.type}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Terminology
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.terminology}
                                emptyTitle=""
                                data={[UnitSectionTerminologyValues.lay, UnitSectionTerminologyValues.model]}
                            />
                        </label>
                        <div className="errors">{touched.terminology && errors.terminology}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Skill
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.skill}
                                emptyTitle="None"
                                data={props.skills && buildSkillsTree(props.skills)}
                                isActive={(el: ISkill) => !el.children}
                            />
                        </label>
                        <div className="errors">{touched.skill && errors.skill}</div>
                    </div>

                    <div className="form-buttons">
                        <Button onClick={props.onClose} className="gray small">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit" className="small"><span>{props.entity.type ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !props.entity ? null : (
        <Formik
            initialValues={{
                type: (props.entity && props.entity.type) || '',
                skill: (props.entity && props.entity.skill) || '',
                terminology: (props.entity && props.entity.terminology) || '',
            }}
            validationSchema={getValidationSchema}
            onSubmit={handleSubmit}
        >
            {renderForm}
        </Formik>
    );
}

const UnitSectionExerciseParamEditorWithRouter = withRouter(UnitSectionExerciseParamEditor);
export { UnitSectionExerciseParamEditorWithRouter as UnitSectionExerciseParamEditor };
