import { FetchResult, gql } from 'apollo-boost';
import { IEpisode } from '../../../models/episode';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation EpisodeSave($episode: EpisodeInput!, $signatureFile: Upload, $catalogFile: Upload){
        episode_save (episode_data: $episode, signatureFile: $signatureFile, catalogFile: $catalogFile) {
            uid
        }
    }
`;

export async function episodeSave(episode: Partial<IEpisode>, signatureFile?: File, catalogFile?: File): Promise<string> {
    const result: FetchResult<{ episode_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { episode: {
            uid: episode.uid,
            name: episode.name,
            season: episode.season,
            number_in_season: episode.numberInSeason,
            description: episode.description,
            duration: episode.duration,
            url: episode.url,
            narrative: episode.narrative,
            coming_soon: episode.comingSoon,
            status: episode.status,
        }, signatureFile, catalogFile },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.episode_save.uid;
}
