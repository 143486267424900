import { FetchResult, gql } from 'apollo-boost';
import { ICurriculumAchievement } from '../../../models/curriculum-achievement';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation CurriculumAchievementSave($curriculumAchievement: CurriculumAchievementInput!){
        curriculum_achievement_save (input_data: $curriculumAchievement) {
            uid
        }
    }
`;

export async function curriculumAchievementSave(curriculumAchievement: Partial<ICurriculumAchievement>): Promise<string> {
    const result: FetchResult<{ curriculum_achievement_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { curriculumAchievement: {
            uid: curriculumAchievement.uid,
            trigger_type: curriculumAchievement.triggerType,
            trigger_value: curriculumAchievement.triggerValue,
            required_completed_unit: curriculumAchievement.requiredCompletedUnit,
            unit_trigger: curriculumAchievement.unitTrigger,
            pd_hours: curriculumAchievement.pdHours,
            achievement: curriculumAchievement.achievement,
            curriculum: curriculumAchievement.curriculum,
        }},
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.curriculum_achievement_save?.uid;
}
