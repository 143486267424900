import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const MUTATION = gql`
    mutation S3SignUploadRequet($path: String!, $fileName: String!){
        sign_upload_request (path: $path, file_name: $fileName) {
            data
        }
    }
`;

interface ISignedRequest {
    url: string;
    fields: any;
}

export async function s3SignUploadRequet(path: string, fileName: string): Promise<ISignedRequest> {
    const result: FetchResult<{ sign_upload_request: { data: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { path, fileName },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return JSON.parse(result.data.sign_upload_request.data);
}
