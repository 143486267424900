import React, { createContext, useCallback, useMemo, useRef, useState } from 'react';
import { Checkbox } from './checkbox';

export interface ICheckboxGroupContextProps {
    state: boolean | null | undefined,
    registerCheckbox(state?: boolean | null): string,
    unregisterCheckbox(uid?: string): void,
    onChangeCallback(uid?: string, chkState?: boolean | null): void,
}

interface IElementProps extends IProps {
    checkboxGroupContextValue?: ICheckboxGroupContextProps,
    children: any,
}

interface ICheckbox {
    uid: string,
    state?: boolean | null,
}

export const CheckboxGroupContext = createContext<ICheckboxGroupContextProps | undefined>(undefined);

interface IProps {
    title: any,
    children: any,
}


export function CheckboxGroup(props: IProps) {

    return (
        <CheckboxGroupContext.Consumer>
            {(value) => <CheckboxGroupElement {...props} checkboxGroupContextValue={value} >{props.children}</CheckboxGroupElement>}
        </CheckboxGroupContext.Consumer>
    );
}

function CheckboxGroupElement(props: IElementProps) {

    const [state, setState] = useState<boolean | null | undefined>();
    const checkboxes = useRef<ICheckbox[]>([]);

    const onChangeCallback = useCallback((uid?: string, chkState?: boolean | null) => {
        let allChecked = true;
        let allUnchecked = true;
        checkboxes.current?.forEach(chk => {
            if(chk.uid === uid) {
                chk.state = chkState;
            }

            if(chk.state === undefined) {
                // empty group
            }
            else if(chk.state === true) {
                allUnchecked = false;
            }
            else if(chk.state === false) {
                allChecked = false;
            }
            else {
                allChecked = false;
                allUnchecked = false;
            }
        })

        const newState = allUnchecked && allChecked ? undefined : (!allUnchecked && !allChecked ? null : (allUnchecked ? false : true));
        if(state !== newState) {
            setState(newState);
        }
    }, [checkboxes]);


    const registerCheckbox = useCallback((state?: boolean | null) => {
        let newUid = `${(new Date()).getTime()}-${Math.round(Math.random() * 1000000)}`;
        while(checkboxes.current?.some(chk => chk.uid === newUid)) {
            newUid = `${(new Date()).getTime()}-${Math.round(Math.random() * 1000000)}`;
        }

        checkboxes.current.push({ uid: newUid, state });
        onChangeCallback();
        return newUid;
    }, [checkboxes, onChangeCallback])


    const unregisterCheckbox = useCallback((uid?: string) => {
        checkboxes.current = checkboxes.current.filter(chk => chk.uid !== uid);
        onChangeCallback();
    }, [checkboxes, onChangeCallback]);


    const onChange = (state: boolean) => {
        const newState = state === true ? true : false;
        checkboxes.current.forEach(chk => { chk.state = newState; });
        setState(newState);
    }


    const contextValue = useMemo(
        () => ({ state, registerCheckbox, unregisterCheckbox, onChangeCallback }),
        [onChangeCallback, registerCheckbox, unregisterCheckbox, state],
    );


    const rootCheckboxRenderer = (className?: string) => (
        <Checkbox
            value={true}
            checked={state}
            onChange={onChange}
            className={className}
        >{props.title}</Checkbox>
    );

    const containerRenderer = (content: any) => (
        <CheckboxGroupContext.Provider value={contextValue}>
            {content}
        </CheckboxGroupContext.Provider>
    )

    return (<>
            {typeof props.children === "function"
                ? props.children(rootCheckboxRenderer, containerRenderer)
                : (<>
                    {rootCheckboxRenderer()}
                    {containerRenderer(props.children)}
                </>)
            }
    </>);
}