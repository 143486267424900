import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../helpers/common-helpers';
import { IEmotionCategory } from '../../models/emotion-category';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
{
    emotion_categories {
        uid,
        label,
        theme,
        definition,
        color,
        adjective,
        valence,
        parent,
        minAgeRating: min_age_rating,
        imgFileName: img_file_name,
        actionTendencies: action_tendencies {
            uid,
            label,
            imgFileName: img_file_name
        }
    }
}
`;

export async function getAllEmotionCategories(): Promise<IEmotionCategory[]> {
    const result: FetchResult<{ emotion_categories: IEmotionCategory[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.emotion_categories, ['__typename']);
}
