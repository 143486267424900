import React, { useState, useEffect } from 'react';
import { Field, Formik, Form, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router-dom';
import { EmotionActionTendencyList } from './emotion-action-tendency-list'
import { EmotionRegulationStrategyList } from './emotion-regulation-strategy-list'
import { getEmotionCategory } from '../../../api/actions/emotion-categories/emotion-category-get';
import { Button } from '../../../components/shared/button/button';
import { emotionCategorySave } from '../../../api/actions/emotion-categories/emotion-category-save';
import { deleteEmotionCategoryImage } from '../../../api/actions/emotion-categories/emotion_category_delete_img';
import { getEmotionCategoryFilePath } from '../../../config/app-config';
import { getRegulationStrategyDefaults } from '../../../api/actions/regulation-strategy/regulation-strategy-defaults-get';
import { UploaderField } from '../../../components/shared/uploader/uploader';
import { IEmotionCategoryForm } from '../../../models/emotion-category';
import {IActionTendency} from '../../../models/action-tendency';
import {IRegulationStrategy} from '../../../models/regulation-strategy';
import { ContentAgeRating } from '../../../models/narrative';
import { UploaderImagePreview } from '../../../components/shared/uploader/uploader-image-preview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import styles from './emotion-categories-list.module.scss';
import { toast } from 'react-toastify';

interface RouteState {
  emotionCategory?: IEmotionCategoryForm;
  parents?: string[];
  allActionTendencies?: IActionTendency[];
  emotionTendencies?:IActionTendency[];
  allRegulationStrategies?: IRegulationStrategy[];
  emotionStrategies?: IRegulationStrategy[];
}

const initialFormState: IEmotionCategoryForm = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  parent: null,
  label: '',
  definition: '',
  image: undefined,
  valence: '',
  color: '',
  adjective: '',
  minAgeRating: 0,
};

export function EmotionCategoriesEdit(
  // eslint-disable-next-line @typescript-eslint/ban-types
  props: RouteComponentProps<{}, any, RouteState>
) {
  const passedEmotionParentsList = props.location.state?.parents;
  const passedEmotionCategory = props.location.state?.emotionCategory;
  const passedAllReguationStrategies = props.location.state?.allRegulationStrategies;
  const passedEmotionRegStrategies = props.location.state?.emotionStrategies;
  const [emotionCategory, setEmotionCategory] = useState<IEmotionCategoryForm>(
    passedEmotionCategory || initialFormState
  );
  const [emotionActionTendencies, setEmotionActionTendencies] = useState<IActionTendency[] | undefined>(props.location.state?.emotionTendencies);
  const [wrongActionTendencies, setWrongActionTendencies] = useState<IActionTendency[] | undefined>();
  const [emotionRegulationStrategies, setEmotionRegulationStrategies] = useState<IRegulationStrategy[] | undefined>();
  const [allRegulationStrategies, setAllRegulationStrategies] = useState<IRegulationStrategy[] | undefined>(); 


  useEffect(() => {
    if (passedEmotionCategory) {
      setEmotionCategory(passedEmotionCategory);
    }

    if (!passedAllReguationStrategies) {
      loadRegStrategies();
    } else {
      setAllRegulationStrategies(passedAllReguationStrategies);      
    }

    if(!passedEmotionRegStrategies) {
      reloadEmotionData();
    }
    else {
      setEmotionRegulationStrategies(passedEmotionRegStrategies);
    }

  }, [passedEmotionCategory, passedAllReguationStrategies, passedEmotionRegStrategies]);


  const loadRegStrategies = async () => {
    const rss = await getRegulationStrategyDefaults();
    //console.log("fetching default regulation strategies: " + rss);
    setAllRegulationStrategies(rss);      
  }

  const reloadEmotionData = async () => {
    //console.log("reloading emotion action tendencies");
    if(!emotionCategory || !emotionCategory?.uid) return;

    try {
      const category = await getEmotionCategory(emotionCategory?.uid)
      if (category) {
        setEmotionActionTendencies(category.actionTendencies);
        setEmotionRegulationStrategies(category.regulationStrategies);
        setWrongActionTendencies(category.wrongActionTendencies);
      }
    } catch (error) {
      console.error('Error reloading emotion category:', error);
    }
  }

  const handleDeleteImage = async (uid: string) => {
    try {
      const res = await deleteEmotionCategoryImage(uid);

      if (res.success) {
        // Update the emotionCategory state to reflect the image deletion
        setEmotionCategory((prevState) => ({
          ...prevState,
          imgFileName: null,
        }));
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const handleSave = async (values: IEmotionCategoryForm) => {
    if (values.parent) {
      console.log("save; parent exists");
      const parentString = formatString(values);
      if (parentString.length > 0)  {
        console.log("saving parent array");
        values.parent = [parentString];
      }
      else {
        console.log("saving parent as null");
        values.parent = null;
      }
    }else console.log("save; parent blank");
    
    try {
      const { success, error_msg } = await emotionCategorySave(
        values,
        values?.image?.file
      );
      if (success) {
        toast.success('Item has been successfully saved');
        props.history.push('/libraries/emotion-categories');
      } else {
        toast.error(`Error: ${error_msg}`);
      }
    } catch (error) {
      console.error('Error saving emotion category:', error);
    }
  };

  const formatString = (ec: IEmotionCategoryForm) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (ec.parent) return ec.parent.replace(/[^a-zA-Z0-9]/g, '');
    return ''; // null
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className={styles.formContainer}>
      <Formik initialValues={emotionCategory} onSubmit={handleSave}>
        {(formikProps: FormikProps<IEmotionCategoryForm>) => {
          const { errors, touched } = formikProps;
          return (
            <Form>
              <div className="form-item">
                <label>
                  <div className="form-label">UID: {emotionCategory.uid}</div>
                </label>
              </div>

              <div className="form-item">
                <label>
                  <div className="form-label">Parent</div>
                  <Field component="select" name="parent">
                    {emotionCategory.parent === null ? (
                      <option value="" label="Select parent" />
                    ) : (
                      <option
                        value={emotionCategory.parent}
                        label={capitalizeFirstLetter(
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          JSON.parse(emotionCategory.parent)[0]
                        )}
                      />
                    )}
                    <option/>
                    {passedEmotionParentsList?.map((emotion, index) => (
                      <option
                        key={index}
                        value={emotion.toLowerCase()}
                        label={emotion}
                      />
                    ))}
                  </Field>
                </label>
                <div className="errors">{touched.parent && errors.parent}</div>
              </div>

              <div className="form-item">
                <label>
                  <div className="form-label required">Label</div>
                  <Field type="text" name="label" />
                </label>
                <div className="errors">{touched.label && errors.label}</div>
              </div>

              <div className="form-item">
                <label>
                  <div className="form-label">Definition</div>
                  <Field component="textarea" name="definition" />
                </label>
                <div className="errors">
                  {touched.definition && errors.definition}
                </div>
              </div>

              <div className="form-item">
                <label>
                  <div className="form-label required">Color</div>
                  <Field type="text" name="color" />
                </label>
                <div className="errors">{touched.color && errors.color}</div>
              </div>

              <div className="form-item">
                <label>
                  <div className="form-label">Adjective</div>
                  <Field type="text" name="adjective" />
                </label>
                <div className="errors">
                  {touched.adjective && errors.adjective}
                </div>
              </div>

              <div className="form-item">
                <label>
                  <div className="form-label required">Valence</div>
                  <Field component="select" name="valence">
                    <option value="" label="Select valence" />
                    <option value="unpleasant" label="Unpleasant" />
                    <option value="pleasant" label="Pleasant" />
                  </Field>
                </label>
                <div className="errors">
                  {touched.valence && errors.valence}
                </div>
              </div>
              
                <div className="form-item">
                  <label>
                    <div className="form-label">Image</div>
                    <Field
                      component={UploaderField}
                      name="image"
                      acceptFileTypes=".png,.jpg,.jpeg"
                      path={getEmotionCategoryFilePath(
                        emotionCategory?.uid || ''
                      )}
                      previewComponent={UploaderImagePreview}
                    />
                  </label>
                  <div className="errors">{touched.image && errors.image}</div>
                </div>
              

              {emotionCategory.imgFileName && (
                <>
                  <div onClick={() => emotionCategory?.uid ? handleDeleteImage(emotionCategory?.uid) : null}>
                    Delete this image <FontAwesomeIcon icon={faTrashAlt} />
                  </div>
                  <img
                    className={styles.emotionImage}
                    src={emotionCategory?.uid ? `${getEmotionCategoryFilePath(
                      emotionCategory?.uid, emotionCategory.imgFileName)}` : ''}
                    alt={emotionCategory.imgFileName}
                  />
                  <div>
                    {emotionCategory.imgFileName}
                  </div>
                </>
              )}
              <div className="form-item">
                <label>
                  <div className="form-label">Content Age Rating</div>
                  <Field component="select" name="minAgeRating">
                    {emotionCategory.minAgeRating === null ? (
                      <option value="" label="Select Age Rating" />
                    ) : (
                      <option
                        value={emotionCategory.minAgeRating}
                        label={ContentAgeRating[emotionCategory.minAgeRating]}
                      />
                    )}

                    {Object.entries(ContentAgeRating).map(([value, label]) => (
                      <option key={value} value={value} label={label} />
                    ))}
                  </Field>
                </label>
                <div className="errors">
                  {touched.minAgeRating && errors.minAgeRating}
                </div>
              </div>

              <div className="form-buttons">
                <Button
                  onClick={() =>
                    props.history.push('/libraries/emotion-categories')
                  }
                  className="gray"
                >
                  <span>Back</span>
                </Button>
                <Button type="submit">
                  <span>{emotionCategory.label ? 'Save' : 'Create'}</span>
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>

      {emotionCategory.uid && (
        <EmotionActionTendencyList 
          emotionUid={emotionCategory.uid} 
          emotionTendencies={emotionActionTendencies} 
          allTendencies={props.location.state?.allActionTendencies} 
          reloadData={reloadEmotionData}
          wrongTendencies={false} />
        )}


      {emotionCategory.uid && (
        <EmotionActionTendencyList 
          emotionUid={emotionCategory.uid} 
          emotionTendencies={wrongActionTendencies} 
          allTendencies={props.location.state?.allActionTendencies} 
          reloadData={reloadEmotionData}
          wrongTendencies={true} />
        )}

      {emotionCategory.uid && (
        <EmotionRegulationStrategyList 
          emotionUid={emotionCategory.uid} 
          emotionStrategies={emotionRegulationStrategies} 
          allStrategies={allRegulationStrategies} 
          reloadData={reloadEmotionData} />
        )}

    </div>
  );
}
