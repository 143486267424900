import { faSortDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { BaseTypes } from '../../../types';
import styles from './select.module.scss';

interface IProps<T, P extends { children?: React.ReactNode }> {
    name?: string,
    value?: T,
    disabled?: any,
    emptyTitle?: string,
    component: any,
    params?: P,
    form?: FormikProps<any>,
    className?: string,
    titleExtractor?(el: T): any,
    onChange?(event: { target: { name?: string, value?: T }, currentTarget: { name?: string, value?: T }}): void,
    onBlur?: (value: any) => void,
}

export function CustomSelect<T, P extends { children?: React.ReactNode }>(props: IProps<T, P>) {
    const [ opened, setOpened ] = React.useState<boolean>(false);
    const titleExtractor = props.titleExtractor || ((x: any) => x && typeof x === BaseTypes.object ? x.name : x);

    const onChange = (value?: T) => {
        setOpened(false);

        const target = {
            name: props.name,
            value,
        };

        const val = {
            target,
            currentTarget: target,
        };

        if(props.onBlur) {
            props.onBlur(val);
        }
        if(props.onChange) {
            props.onChange(val);
        }
    };

    const Component = props.component;

    if(!props.params) return null;

    const {children, ...params} = props.params;

    return (
        <div className={`selectField ${styles.selectField} ${props.className} ${props.disabled && styles.disabled}`}>
            <div onClick={() => !props.disabled && setOpened(true)} className={styles.fieldSubContainer}>
                <div className={`${styles.valueContainer} ${props.value ? '' : styles.empty}`}>
                    { (props.value && titleExtractor(props.value)) || props.emptyTitle }
                    &nbsp;
                </div>
                <FontAwesomeIcon icon={faSortDown} />
            </div>
            { !props.disabled && props.value && (
                <div className={styles.clearButton} onClick={() => onChange(undefined)}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            )}
            { opened && (<>
                <div className={styles.locker} onClick={() => setOpened(false)}></div>
                <div className={styles.options}>
                    <Component {...params as any} onChange={onChange} value={props.value} >{children}</Component>
                </div>
            </>)}
        </div>
    );

}


export function CustomSelectField<T, P extends { children?: React.ReactNode }>(
    props: FieldProps & Partial<IProps<T, P>> & { internalComponent: any }
): React.ReactElement {
    const {field, internalComponent, form, ...params} = props;

    React.useEffect(() => {
        if(form.isSubmitting) {
            form.setFieldTouched(field.name, true)
        }
    }, [field.name, form.isSubmitting]);
    
    return (
        <CustomSelect<T, P>
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            component={internalComponent}
            form={form}
            {...params}
        />
    );
}