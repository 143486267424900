import { getEntityInfo } from '../api/actions/task/get-entity-info';
import { AppConfig } from '../config/app-config';
import { EntityTypes } from '../models/enums/entity-types';
import { isNotNullOrEmpty } from './common-helpers';

export async function getRelativeLinkToEntity(entityType: EntityTypes, entityUid: string) {
    const entityInfo = await getEntityInfo(entityType, entityUid);
    if(!entityInfo.parentEntities?.length) entityInfo.parentEntities = [];
    const parentEntities = [
        { entityType: entityInfo.entityType, entityUid: entityInfo.entityUid },
        ...entityInfo.parentEntities,
    ]
    
    let url = '/';
    const narrative = parentEntities.find(ei => ei.entityType === EntityTypes.narrative);
    if(narrative) url = `/narratives/${narrative.entityUid}`;

    const episode = parentEntities.find(ei => ei.entityType === EntityTypes.episode);
    if(episode) url += `/episodes/${episode.entityUid}`;

    const scene = parentEntities.find(ei => ei.entityType === EntityTypes.scene);
    if(scene) url += `/scenes/${scene.entityUid}`;

    const emotionEpisode = parentEntities.find(ei => ei.entityType === EntityTypes.emotionEpisode);
    if(emotionEpisode) url += `/emotion-episodes/${emotionEpisode.entityUid}`;

    const participant = parentEntities.find(ei => ei.entityType === EntityTypes.participant);
    if(participant) url += `/participant/${participant.entityUid}`;

    return url;
}


export function isDataUrl(url: string) {
    return url && url.indexOf('data:') === 0;
}

export function isAbsoluteUrl(url?: string) {
    return url && /^https?:\/\//.test(url);
}


export function getParentPath(path: string, stepsUp: number) {
    const el = path.split('/');

    return el.slice(0, -1 * stepsUp).join('/') || "/";
}


export const joinPath = (...pathParts: Array<string | null | undefined>): string => {
    if(!pathParts.length) return '';

    return pathParts.filter(isNotNullOrEmpty).map(
        (part: string, i: number) => !i ? part.replace(/(\/$)/g, '') : part.replace(/(^\/)|(\/$)/g, ''),
    ).join('/');
};

export function rootPath(path: string): string {
    return joinPath(AppConfig.path, path);
}

export function imagesPath(path: string): string {
    return joinPath(AppConfig.path, 'images', path);
}

export function getFullFileName(f: string) {
    if(!f) return ''
    const parts = f.split(/(\/|\\)/);
    return parts[parts.length - 1];
}

export function getBasicFileName(f: string) {
    return f.replace(/--[0-9]+\./ig, '.');
}

export function getFileName(fullFileName: string): string {
    fullFileName = getFullFileName(fullFileName);
    return fullFileName.replace(/\.[^.]*$/ig, '');
}

export function getFileExt(fullFileName: string): string {
    const parts = fullFileName.split('.');
    return parts[parts.length - 1];
}
