import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const DELETE_REGULATION_STRATEGY_IMG_MUTATION = gql`
    mutation DeleteRegulationStrategyImage($uid: String!) {
      regulation_strategy_delete_img(uid: $uid) {
        success
      }
    }
`;


export async function deleteRegulationStrategyImage(uid: string): Promise<{ success: boolean }> {
  const result: FetchResult<{ regulation_strategy_delete_img: { success: boolean } }> = await graphqlClient.mutate({
      mutation: DELETE_REGULATION_STRATEGY_IMG_MUTATION,
      variables: { uid },
      errorPolicy: 'all',
  });

  if (!result.data || (result.errors && result.errors.length)) {
      throw new MultiError(result.errors || ['Unknown error']);
  }

  return {
    success: result.data.regulation_strategy_delete_img.success,
  };
}