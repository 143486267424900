import { FetchResult, gql } from 'apollo-boost';
import { IRewardConfigItem } from '../../../models/reward-config-item';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation RewardConfigItemSave($rewardConfigItem: RewardConfigItemInput!){
        reward_config_item_save (input_data: $rewardConfigItem) {
            uid
        }
    }
`;

export async function rewardConfigItemSave(rewardConfigItem: Partial<IRewardConfigItem>): Promise<string> {
    const result: FetchResult<{ reward_config_item_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { rewardConfigItem: {
            uid: rewardConfigItem.uid,
            name: rewardConfigItem.name,
            description: rewardConfigItem.description,
            pip_option: rewardConfigItem.pipOption,
            achievement: rewardConfigItem.achievement,
            reward_config: rewardConfigItem.rewardConfig,
        } },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.reward_config_item_save?.uid;
}
