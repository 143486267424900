import { FetchResult, gql } from 'apollo-boost';
import { IExercise } from '../../../models/exercise';
import { BaseTypes } from '../../../types';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query {
        custom_exercises {
            uid,
            heading,
            question,
            correctResponse: correct_response,
            fileName: file_name,
            type,
            responses {
                uid,
                text,
                parent,
                answersCount: answers_count,
                feedback,
            },

            skill {
                uid,
                name,
            },

            concept {
                uid,
                name,
            },

            learningChunk: learning_chunk {
                uid,
                index,
                type,

                unitSection: unit_section {
                    uid,
                    name,

                    unit {
                        uid,
                        name,

                        curriculum {
                            uid,
                            name
                        }
                    }
                }
            }
        }
    }
`;

export async function getAllCustomExercises(): Promise<IExercise[]> {
    const result: FetchResult<{ custom_exercises: IExercise[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    result.data.custom_exercises?.forEach(e => {
        if(typeof e.responses === BaseTypes.string) {
            e.responses = JSON.parse(e.responses as any);
        }
    });

    return result.data.custom_exercises;
}
