import { FetchResult, gql } from 'apollo-boost';
import { IAchievement } from '../../../models/achievement';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query Achievements($achievement_type: String) {
        achievements (achievement_type: $achievement_type) {
            uid,
            name,
            description,
            achievementType: achievement_type,
            iconFileName: icon_file_name,
            index,
            available,
        }
    }
`;

export async function getAllAchievements(achievement_type: string | undefined | null): Promise<IAchievement[]> {
    const result: FetchResult<{ achievements: IAchievement[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { achievement_type: achievement_type },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.achievements;
}
