import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IEmotionEpisode } from '../../../models/emotion-episode';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetAdjacentEmotionEpisodes($episodeStart: Int!, $narrativeUid: String!){
        emotion_episodes_adjacent_to_episode(episode_start: $episodeStart, narrative_uid: $narrativeUid) {
            uid,
            description,
            start,
            end,
            status,
            absoluteStart: absolute_start,
            available,
        }
    }
`;

export async function getAdjacentEmotionEpisodes(episodeStart: number | undefined, narrativeUid: string): Promise<IEmotionEpisode[]> {
    const result: FetchResult<{ emotion_episodes_adjacent_to_episode: IEmotionEpisode[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { episodeStart, narrativeUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.emotion_episodes_adjacent_to_episode, ['__typename']);
}
