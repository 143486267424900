import { FetchResult, gql } from 'apollo-boost';
import { IEmail } from '../../../models/email';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation EmailSave($email: EmailInput!){
        email_save (email_data: $email) {
            uid
        }
    }
`;

export async function emailSave(email: Partial<IEmail>): Promise<string> {
    const result: FetchResult<{ email_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { email },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.email_save.uid;
}
