import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { INarrative } from '../../../models/narrative';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetNarrative($uid: String!){
        narrative(uid: $uid) {
            uid,
            title,
            description,
            mediaType: media_type,
            platformType: platform_type,
            platforms: platforms,
            fileName: file_name,
            contentUrl: content_url,
            available,
            enabledAt: enabled_at,
            minAgeRating: min_age_rating,
            comingSoon: coming_soon,
            status,
            duration,
            audioSignatureFileName: audio_signature_file_name,
            audioCatalogFileName: audio_catalog_file_name,
            soundtrackFileName: soundtrack_file_name,

            topics {
                uid,
                name,
                description,
            },

            externalReferences: external_references {
                uid,
                serviceName: service_name,
                externalServiceUid: external_service_uid,
                externalServiceUrl: external_service_url,
                preferredSource: preferred_source,
                timeOffset: time_offset,
                paidPlansOnly: paid_plans_only
            }

            episodes {
                uid,
                name,
                season,
                numberInSeason: number_in_season,
                description,
                url,
                available,
                status,
                tasksAvailableForUserCount: tasks_available_for_user_count,
            },

            characters {
                uid,
                name,
                fileName: file_name,
            },

            scenes {
                uid,
                description,
                start: start,
                end: end,
                fileName: file_name,
                fileUrl: file_url,
                available,
                emotionEpisodeTypes: emotion_episode_types,

                emotionEpisodes: emotion_episodes {
                    uid,
                    description,
                    start,
                    end,
                    available,
                },

                episode {
                    uid,
                    name,
                    season,
                    numberInSeason: number_in_season
                }
            }

            endSurveyUid: end_survey_ref
        }
    }
`;

export async function getNarrative(uid: string): Promise<INarrative> {
    const result: FetchResult<{ narrative: INarrative }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.narrative, ['__typename']);
}
