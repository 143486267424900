import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUsersPaged } from '../../../../api/actions/user/users-get-paged';
import { Button } from '../../../../components/shared/button/button';
import { Grid } from '../../../../components/shared/grid/grid';
import { Popup } from '../../../../components/shared/popup/popup';
import { SearchField } from '../../../../components/shared/search-field/search-field';
import { basePerformError } from '../../../../helpers/error-helpers';
import { getHIPAANameReplacementString, getHIPAAEmailReplacementString } from '../../../../helpers/hipaa-helpers';
import { IUser } from '../../../../models/user';
import { IUserGroup } from '../../../../models/user-group';
import { UserGroupCreateInviteComponent } from '../../user-groups/parts/user-group-create-invite-component';
import styles from './users-list-component.module.scss';

interface IProps {
    userGroup?: IUserGroup,
    updateFlag?: any,
    onUserAddClick?(): void,
    actionsRenderer?(user: IUser, requestUpdate: () => void): React.ReactElement | null | undefined,
}

export function UsersListComponent(props: IProps) {
    const history = useHistory();

    const [searchText, setSearchText] = useState<string | undefined>();
    const [inviteFormVisible, setInviteFormVisible] = useState(false);

    const loadData = React.useCallback(async (page: number = 1) => {
        //for now always order users by most recently created
        const orderBy = ['-created_at'];
        try {
            return await getUsersPaged({
                page,
                searchText,
                groupUid: props.userGroup?.uid,
                orderBy: orderBy
            });
        }
        catch(err) {
            basePerformError(err, history);
        }
    }, [history, props.userGroup?.uid, searchText]);

    const isHIPAAUser = (user: IUser) => {
      return (props.userGroup?.HIPAAEnforced && (!user?.userPermissionsForGroup || user.userPermissionsForGroup?.length === 0));
    }

    const gridContainerRenderer = React.useCallback(
        (content, pager) => (<>
            <div className={styles.pager}>
                {pager}
                {props.userGroup && (<Button onClick={() => setInviteFormVisible(true)}>Invite User</Button>)}
                {props.onUserAddClick && (<Button className="orange" onClick={props.onUserAddClick}>Add User</Button>)}
            </div>
            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <tbody>
                    <tr>
                        <th>#</th>
                        <th>Email</th>
                        <th>First</th>
                        <th>Last</th>
                        <th>Role</th>
                        <th>Full Access</th>
                        <th>Extension</th>
                        <th>Level</th>
                        <th>Last On / Unit</th>
                        <th>
                            {props.userGroup ? 'Permissions' : 'Disabled'}
                        </th>
                        <th>Actions</th>
                    </tr>
                    {content}
                </tbody>
            </table>
            <div className={styles.pager}>{pager}</div>
        </>),
        [],
    );

    const gridItemRenderer = React.useCallback(
        (user: IUser, i: number, count: number, reloadData: () => void) => (
            <tr key={user.uid}>
                <td>
                    <div className={styles.titleContainer}>
                        <div className={`${styles.mark} ${user.disabled ? styles.disabled : ''}`}>&nbsp;</div>
                        <div className={styles.title}>
                            {i + 1}
                        </div>
                    </div>
                </td>
                <td>{isHIPAAUser(user) ? getHIPAAEmailReplacementString(user.email) : user.email + (props.userGroup?.owner?.uid === user.uid ? ' (OWNER)' : '')}</td>
                <td>{isHIPAAUser(user) ? getHIPAANameReplacementString(user.firstName) : user.firstName}</td>
                <td>{isHIPAAUser(user) ? getHIPAANameReplacementString(user.lastName) : user.lastName}</td>
                <td>{user.role?.toUpperCase()}</td>
                <td>{user.hasFullAccess ? 'YES' : ''}</td>
                <td>{user.extensionInstalled ? 'YES' : ''}</td>
                <td>{user.currentLevel}</td>
                <td>{user.lastEngagement} {user.lastEngagement && user.lastUnit ? '/' : ''} {user.lastUnit}</td>
                <td>
                    {!props.userGroup
                        ? (user.disabled ? 'Disabled' : '')
                        : (user.userPermissionsForGroup?.join(', '))
                    }
                </td>
                <td>
                    {props.actionsRenderer?.(user, reloadData)}
                </td>
            </tr>
        ),
        [],
    );

    return (<>
        <div>Total Users: {props.userGroup?.userCount}</div>

        <SearchField onChange={setSearchText} />

        <Grid<IUser>
            getPageData={loadData}
            containerRenderer={gridContainerRenderer}
            itemRenderer={gridItemRenderer}
        />

        <div className={styles.buttonsContainer} >
            <div></div>
            {props.userGroup && (<Button onClick={() => setInviteFormVisible(true)}>Invite User</Button>)}
            {props.onUserAddClick && (<Button className="orange" onClick={props.onUserAddClick}>Add User</Button>)}
        </div>

        {inviteFormVisible && props.userGroup && (
            <Popup
                title="Send Invite"
                onClose={() => setInviteFormVisible(false)}
            >
                <UserGroupCreateInviteComponent userGroup={props.userGroup} onSuccess={() => setInviteFormVisible(false)} />
            </Popup>
        )}
    </>)
}
