import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getAllTopics } from '../../../api/actions/topic/topics-get-all';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { compareText } from '../../../helpers/array-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import { ITopic } from '../../../models/topic';
import styles from './topics-list.module.scss';


export function TopicsList(props: RouteComponentProps) {
    const [topics, setTopics] = useState<ITopic[]>([]);

    const loadData = React.useCallback(async () => {
        try {
            setTopics((await getAllTopics())?.sort((t1, t2) => compareText(t1, t2, (t) => t.name)));
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history]);

    useEffect(() => {
        loadData();
    }, [loadData]);


    return !topics ? null : (
        <>
            <AddBreadcrumbsItem
                title="Topics"
                url={'/libraries/topics'}
            />

            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Motivations</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {topics && topics.map((topic, i) => (
                        <tr key={topic.name}>
                            <td>{i+1}</td>
                            <td>{topic.name}</td>
                            <td>{topic.motivations ? topic.motivations.join(', ') : ""}</td>
                            <td>{topic.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}
