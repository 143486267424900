import { FetchResult, gql } from 'apollo-boost';
import { IRewardConfig } from '../../../models/reward-config';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation RewardConfigSave($rewardConfig: RewardConfigInput!){
        reward_config_save (input_data: $rewardConfig) {
            uid
        }
    }
`;

export async function rewardConfigSave(rewardConfig: Partial<IRewardConfig>): Promise<string> {
    const result: FetchResult<{ reward_config_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { rewardConfig: {
            uid: rewardConfig.uid,
            name: rewardConfig.name,
            description: rewardConfig.description,
            max_weekly_earn: rewardConfig.maxWeeklyEarn
        } },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.reward_config_save?.uid;
}
