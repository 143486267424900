import { FetchResult, gql } from 'apollo-boost';
import { IExercise } from '../../../models/exercise';
import { BaseTypes } from '../../../types';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetExercisesForEpisodeAndExerciseParams($episodeUid: String!, $exerciseType: String!, $skill: String!, $terminology: String!, $age: Int) {
        exercises_for_episode_and_exercise_params(episode_uid: $episodeUid, exercise_type: $exerciseType, skill: $skill, terminology: $terminology, age: $age) {
            uid,
            heading,
            initialPrompt: initial_prompt,
            question,
            correctResponse: correct_response,
            correctResponseArray: correct_response_array,
            fileName: file_name,
            type,
            responses {
                uid,
                text,
                parent,
                answersCount: answers_count,
                feedback,
                feedbackArray: feedback_array,
                postInterstitialFeedback: post_interstitial_feedback
            },
        }
    }
`;

export async function getExercisesGetFiltered(episodeUid: string, exerciseType: string, skill: string, terminology: string, age: number): Promise<IExercise[]> {
    const result: FetchResult<{ exercises_for_episode_and_exercise_params: IExercise[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { episodeUid, exerciseType, skill, terminology, age },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    result.data.exercises_for_episode_and_exercise_params?.forEach(e => {
        if(typeof e.responses === BaseTypes.string) {
            e.responses = JSON.parse(e.responses as any);
        }
    });

    return result.data.exercises_for_episode_and_exercise_params;
}
