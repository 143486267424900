import { FetchResult, gql } from 'apollo-boost';
import { IActionTendency } from '../../../models/action-tendency';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
  {
      action_tendencies {
          uid,
          label,
          imgFileName: img_file_name,
      }
  }
`;

export async function getAllActionTendencies(): Promise<IActionTendency[]> {
    const result: FetchResult<{ action_tendencies: IActionTendency[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if (!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.action_tendencies;
}
