import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { getAllCurriculums } from '../../../../api/actions/curriculum/curriculums-get-all';
import { addCurriculumToUserGroup } from '../../../../api/actions/user-group/user-group-curriculum-add';
import { removeCurriculumFromUserGroup } from '../../../../api/actions/user-group/user-group-curriculum-remove';
import { Button } from '../../../../components/shared/button/button';
import { LinkButton } from '../../../../components/shared/link-button/link-button';
import { Popup } from '../../../../components/shared/popup/popup';
import { compareText } from '../../../../helpers/array-helpers';
import { ICurriculum } from '../../../../models/curriculum';
import { IUserGroup } from '../../../../models/user-group';
import styles from './user-group-curriculums-list.module.scss';


interface IProps {
    userGroup?: IUserGroup,
    reloadUserGroup(): void,
}

export function UserGroupCurriculumsList(props: IProps) {

    const [allCurriculums, setAllCurriculums] = React.useState<ICurriculum[]>();
    const [curriculumSelectorPopupVisible, setCurriculumSelectorPopupVisible] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            setAllCurriculums(await getAllCurriculums());
        })().catch(() => {/** */});
    }, []);

    const addCurriculum = React.useCallback((curriculumUid: string) => {
        (async () => {
            if(!props.userGroup?.uid) return;
            await addCurriculumToUserGroup(props.userGroup.uid, curriculumUid);
            props.reloadUserGroup();
        })().catch(() => {/** */});
    }, [props]);

    const removeCurriculum = React.useCallback((curriculumUid: string) => {
        (async () => {
            if(!props.userGroup?.uid) return;
            await removeCurriculumFromUserGroup(props.userGroup.uid, curriculumUid);
            props.reloadUserGroup();
        })().catch(() => {/** */});
    }, [props]);

    return (
        <>
            <div className="list-title">
                <div>Curricula:
                    <strong>{ (props.userGroup?.curriculums?.length) || 0 }</strong>
                </div>
                <div>
                    <Button
                        className="orange"
                        onClick={() => setCurriculumSelectorPopupVisible(true) }
                    >
                        <FontAwesomeIcon icon={faPlus} /> <span>Add Curriculum</span>
                    </Button>
                </div>
            </div>
            { !!props.userGroup?.curriculums?.length && (
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Age</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.userGroup?.curriculums?.sort((c1, c2) => compareText(c1, c2, (c) => c.name)).map(((curriculum, i) => (
                                <tr key={curriculum.uid}>
                                    <td>
                                        <div className={styles.markContainer}>
                                            <div className={`${styles.mark} ${curriculum.available ? '' : styles.disabled}`}>&nbsp;</div>
                                            <div className={styles.title}>
                                                {i + 1}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {curriculum.name}
                                    </td>
                                    <td>
                                        {curriculum.description}
                                    </td>
                                    <td>
                                        {curriculum.minAgeRating}
                                    </td>
                                    <td>
                                        <LinkButton onClick={() => removeCurriculum(curriculum.uid)}>
                                            <FontAwesomeIcon icon={faEdit} /> Delete
                                        </LinkButton>
                                    </td>
                                </tr>
                            )))
                        }
                    </tbody>
                </table>
            )}




            { curriculumSelectorPopupVisible && (
                <Popup
                    title="Add Curriculum"
                    onClose={() => setCurriculumSelectorPopupVisible(false)}
                    buttons={[
                        {
                            title: <><FontAwesomeIcon icon={faTimes} /> <span>Close</span></>,
                            className: 'gray',
                            onClick: () => setCurriculumSelectorPopupVisible(false),
                        },
                    ]}
                >
                    <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allCurriculums?.sort((c1, c2) => compareText(c1, c2, (c) => c.name)).map(((curriculum, i) => (
                                    <tr key={curriculum.uid}>
                                        <td>
                                            <div className={styles.markContainer}>
                                                <div className={`${styles.mark} ${curriculum.available ? '' : styles.disabled}`}>&nbsp;</div>
                                                <div className={styles.title}>
                                                    { i + 1 }
                                                </div>
                                            </div>
                                        </td>
                                        <td>{curriculum.name}</td>
                                        <td>
                                            {curriculum.description}
                                        </td>
                                        <td>
                                            { props.userGroup?.curriculums?.every(c => c.uid !== curriculum.uid) && (
                                                <LinkButton onClick={() => addCurriculum(curriculum.uid)}>
                                                    <FontAwesomeIcon icon={faPlus} /> Add
                                                </LinkButton>
                                            )}
                                        </td>
                                    </tr>
                                )))
                            }
                        </tbody>
                    </table>

                </Popup>
            )}
        </>
    );
}
