import { FetchResult, gql } from 'apollo-boost';
import { IAnswer } from '../../../models/answer';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query Answer($uid: String!) {
        response(uid: $uid) {
            uid,
            answer,
            createdAt: created_at,
            flagStatus: flag_status,
            exerciseType: exercise_type,
            createdAt: created_at,
            upvoteCount: upvote_count,
            downvoteCount: downvote_count,

            user {
                uid,
                email,
                role
            }

            skill {
                uid,
                name,
                description
            },

            flags {
              uid,
              type,
              status,
              createdAt: created_at,
              updatedAt: updated_at

              creator: user {
                uid,
                email,
                role,
                firstName: first_name,
                lastName: last_name,
              }
            }

            participant {
                character {
                    uid
                    name,
                },
                emotionEpisode: emotion_episode {
                    uid,
                    description,

                    scene {
                        uid,
                        description,
                        narrative {
                            uid
                            title,
                            mediaType: media_type,
                        },
                        episode {
                            uid,
                            name,
                            season,
                            numberInSeason: number_in_season
                        }

                    }

                },
            }
        }
    }
`;

export async function getAnswer(uid: string): Promise<IAnswer> {
    const result: FetchResult<{ response: IAnswer }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.response;
}

