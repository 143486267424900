import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { getAllSkills } from '../../../api/actions/skills-get-all';
import { unitSectionSave } from '../../../api/actions/unit-section/unit-section-save';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { Popup } from '../../../components/shared/popup/popup';
import { removeFields } from '../../../helpers/common-helpers';
import { ISkill } from '../../../models/skill';
import { IUnitSection, IUnitSectionExerciseParam } from '../../../models/unit-section';
import { UnitSectionExerciseParamEditor } from './unit-section-exercise-params-editor';

interface IProps {
    unitSection: IUnitSection;
    reload(): void;
}

export function UnitSectionExerciseParamsList(props: IProps) {

    const [skills, setSkills] = React.useState<ISkill[]>();
    const [editableEntity, setEditableEntity] = React.useState<IUnitSectionExerciseParam>();
    const [entityForDelete, setEntityForDelete] = React.useState<IUnitSectionExerciseParam>();

    React.useEffect(() => {
        (async () => {
            setSkills(await getAllSkills());
        })();
    }, []);

    return (<>
        <div className="list-title">
            <div>Exercise Parameters:
                <strong> { (props.unitSection.exerciseParams && props.unitSection.exerciseParams.length) || 0 }</strong>
            </div>
            <div>
                <Button className="orange" onClick={() => setEditableEntity({} as IUnitSectionExerciseParam)}>
                    <FontAwesomeIcon icon={faPlus} /> <span>Add Exercise Parameters</span>
                </Button>
            </div>
        </div>
        { props.unitSection.exerciseParams && props.unitSection.exerciseParams.length && (
            <table cellPadding="0" cellSpacing="0" className="list">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Type</th>
                        <th>Skill</th>
                        <th>Terminology</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.unitSection.exerciseParams.map((param: IUnitSectionExerciseParam, i: number) => (
                            <tr key={param.type + param.skill}>
                                <td>
                                    {i + 1}
                                </td>
                                <td>
                                    {param.type}
                                </td>
                                <td>
                                    {
                                        (skills && skills.find((skill: ISkill) => skill.uid === param.skill)?.name)
                                        || param.skill
                                    }
                                </td>
                                <td>
                                  {param.terminology}
                                </td>
                                <td>
                                    <LinkButton
                                        onClick={() => setEditableEntity(param) }
                                    >
                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                    </LinkButton><br />
                                    <LinkButton className="red" onClick={() => setEntityForDelete(param)}>
                                        <FontAwesomeIcon icon={faTrash} /> Delete
                                    </LinkButton><br />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )}

        { editableEntity && (
            <Popup
                title={editableEntity.type ? 'Edit Exercise Params' : 'Add Exercise Params'}
                modal={true}
            >
                <UnitSectionExerciseParamEditor
                    unitSection={props.unitSection}
                    entity={editableEntity}
                    skills={skills}
                    onClose={() => setEditableEntity(undefined)}
                    reload={props.reload}
                />
            </Popup>
        )}

        { entityForDelete && (
            <DeleteQuestionPopup
                title="Delete Exercise Params item"
                question={<>Are you sure want to delete exercise params item?</>}
                onClose={() => setEntityForDelete(undefined)}
                onDelete={async () => {
                    const params = props.unitSection.exerciseParams?.filter((ex: IUnitSectionExerciseParam) => ex !== entityForDelete);
                    return await unitSectionSave({
                        ...removeFields(props.unitSection, ['__typename', 'unit', 'learningChunks', 'available', 'edited']),
                        exerciseParams: params?.length ? params : undefined,
                    });
                }}
                onFinish={() => props.reload() }
            />
        )}
    </>);
}
