
export interface IConcept {
    uid: string,
    name: string,
    description: string,
    parent?: string,
    index: number,
    icon?: string,

    children?: IConcept[],
}

export function buildConceptsTree(concepts: IConcept[]) {
    return concepts.filter(c => !c.parent).map(c => ({ ...c, ...(getChildren(c, concepts) ? { children: getChildren(c, concepts)} : {})}));
}

function getChildren(concept: IConcept, concepts: IConcept[]) {
    const children: IConcept[] = concepts.filter(sc => sc.parent === concept.uid)
        .map(c => ({ ...c, ...(getChildren(c, concepts) ? { children: getChildren(c, concepts)} : {})} as IConcept));
    return children?.length ? children : undefined;
}
