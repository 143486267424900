import { FetchResult, gql } from 'apollo-boost';
import { ILearningChunk } from '../../../models/learning-chunk';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';
import { BaseTypes } from '../../../types';

const QUERY = gql`
    query LearningChunk($learningChunkUid: String!) {
        learning_chunk(learning_chunk_uid: $learningChunkUid) {
            uid,
            index,
            type,
            content,
            available,
            fileNames: file_names,
            edited,

            exercise {
                uid,
                heading,
                question,
                correctResponse: correct_response,
                fileName: file_name,
                type,
                edited,
                responses {
                    uid,
                    text,
                    parent,
                    answersCount: answers_count,
                    feedback,
                },

                skill {
                    uid,
                },

                concept {
                    uid,
                },
            },

            unitSection: unit_section {
                uid,
                name,
            }
        }
    }
`;

export async function getLearningChunk(learningChunkUid: string): Promise<ILearningChunk> {
    const result: FetchResult<{ learning_chunk: ILearningChunk }> = await graphqlClient.query({
        query: QUERY,
        variables: { learningChunkUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    if(result.data.learning_chunk.exercise && typeof result.data.learning_chunk.exercise.responses === BaseTypes.string) {
        result.data.learning_chunk.exercise.responses = JSON.parse(result.data.learning_chunk.exercise.responses as any);
    }

    return result.data.learning_chunk;
}
