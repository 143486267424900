import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userGroupDisable } from '../../../../api/actions/user-group/user-group-disable';
import { userGroupEnable } from '../../../../api/actions/user-group/user-group-enable';
import { getUserGroupsPaged } from '../../../../api/actions/user-group/user-groups-get-paged';
import { Button } from '../../../../components/shared/button/button';
import { Grid } from '../../../../components/shared/grid/grid';
import { LinkButton } from '../../../../components/shared/link-button/link-button';
import { SearchField } from '../../../../components/shared/search-field/search-field';
import { basePerformError } from '../../../../helpers/error-helpers';
import { joinPath } from '../../../../helpers/path-helpers';
import { IUserGroup } from '../../../../models/user-group';
import styles from './user-groups-list-component.module.scss';


interface IProps {
    pagePath: string,
    parentGroup?: IUserGroup,
}

export function UserGroupsListComponent(props: IProps) {

    const history = useHistory()

    const [searchText, setSearchText] = useState<string | undefined>();
    const [dataUpdatedAt, setDataUpdatedAt] = React.useReducer(() => (new Date()).getTime(), 0);

    const loadData = React.useCallback(async (page: number = 1) => {
        try {
            return await getUserGroupsPaged({page, searchText, onlyRoot: !props.parentGroup?.uid, parentGroupUid: props.parentGroup?.uid });
        }
        catch(err) {
            basePerformError(err, history);
        }
    }, [history, props.parentGroup?.uid, searchText]);


    const enableUserGroup = React.useCallback(async (uid: string) => {
        try {
            await userGroupEnable(uid);
            setDataUpdatedAt();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, history);
        }
    }, [history]);


    const disableUserGroup = React.useCallback(async (uid: string) => {
        try {
            await userGroupDisable(uid);
            setDataUpdatedAt();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, history);
        }
    }, [history]);


    const gridContainerRenderer = React.useCallback(
        (content, pager) => (<>
            <div className={styles.pager}>
                {pager}
                <Button className="orange" to={joinPath(props.pagePath, '_')}>Add User Group</Button>
            </div>
            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <tbody>
                    <tr>
                        <th>#</th>
                        <th>Type</th>
                        <th>Title</th>
                        <th>Invite Code</th>
                        <th>Default</th>
                        <th>External</th>
                        <th>Actions</th>
                    </tr>
                    {content}
                </tbody>
            </table>
            <div className={styles.pager}>{pager}</div>
        </>),
        [props.pagePath],
    );

    const gridItemRenderer = React.useCallback(
        (userGroup, i) => (
            <tr key={userGroup.uid}>
                <td>
                    <div className={styles.titleContainer}>
                        <div className={`${styles.mark} ${!userGroup.enabled ? styles.disabled : ''}`}>&nbsp;</div>
                        <div className={styles.title}>
                            {i + 1}
                        </div>
                    </div>
                </td>
                <td>{userGroup.groupType?.toUpperCase()}</td>
                <td>{userGroup.title}</td>
                <td>{userGroup.inviteCodeExpirationDate && moment(userGroup.inviteCodeExpirationDate) > moment() ? 'YES' : ''}</td>
                <td>{userGroup.default ? 'YES' : ''}</td>
                <td>{userGroup.createdBy?.role === 'user' ? 'YES' : ''}</td>
                <td>
                    <LinkButton to={joinPath(props.pagePath, userGroup.uid)}>
                        <FontAwesomeIcon icon={faEdit} /> Edit
                    </LinkButton><br />
                    {(!props.parentGroup || props.parentGroup.enabled) && (<>
                        { !userGroup.enabled && (<>
                            <LinkButton onClick={() => enableUserGroup(userGroup.uid)}>
                                <FontAwesomeIcon icon={faUnlock} /> Enable
                            </LinkButton><br />
                        </>)}
                        { userGroup.enabled && (<>
                            <LinkButton onClick={() => disableUserGroup(userGroup.uid)} className="orange">
                                <FontAwesomeIcon icon={faLock} /> Disable
                            </LinkButton><br />
                        </>)}
                    </>)}
                </td>
            </tr>
        ),
        [disableUserGroup, enableUserGroup, props.pagePath, props.parentGroup],
    )

    return (<>
        <SearchField onChange={setSearchText} />

        <Grid<IUserGroup>
            getPageData={loadData}
            containerRenderer={gridContainerRenderer}
            itemRenderer={gridItemRenderer}
        />
        <div className={styles.buttonsContainer} >
            <div></div>
            <Button className="orange" to={joinPath(props.pagePath, '_')}>Add User Group</Button>
        </div>
    </>);
}
