import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { VideoServiceEditor } from './video-service-editor';
import { VideoServicesList } from './video-services-list';


export function VideoServicesManagementPage(props: RouteComponentProps) {

    return (<>
        <Route exact
            path={props.match.url} component={VideoServicesList}
        />
        <Route exact
            path={`${props.match.url}/:uid`} component={VideoServiceEditor}
        />
    </>);
}
