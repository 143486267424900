import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faExternalLinkAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import * as React from 'react';
import DateTimePicker from 'react-datetime-picker';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { taskDelete } from '../../../api/actions/task/task-delete';
import { getRelatedCommentsByTask } from '../../../api/actions/task/task-get-related-comments';
import { taskSave } from '../../../api/actions/task/task-save';
import { CommentsList } from '../../../components/comments/comments-list';
import { Button } from '../../../components/shared/button/button';
import { CKEditorField } from '../../../components/shared/ckeditor/ckeditor';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { CustomSelectField } from '../../../components/shared/select/custom-select';
import { SelectField } from '../../../components/shared/select/select';
import { Tabs } from '../../../components/shared/tabs/tabs';
import { useCurrentUser } from '../../../components/user/current-user-manager';
import { basePerformError } from '../../../helpers/error-helpers';
import { getRelativeLinkToEntity } from '../../../helpers/path-helpers';
import { IComment } from '../../../models/comment';
import { EntityTypes } from '../../../models/enums/entity-types';
import { UserRoles } from '../../../models/enums/user-roles-enum';
import { ITask, TaskPriorities, TaskStatuses, TaskStatusesInfo, TaskTypes } from '../../../models/task';
import { IUser } from '../../../models/user';
import { IDictionary } from '../../../types/dictionary';
import { SelectUsersList } from '../../users/users/parts/select-users-list';
import styles from './task-editor.module.scss';

enum FormFields {
    entityType = 'entityType',
    entityName = 'entityName',
    status = 'status',
    taskType = 'taskType',
    name = 'name',
    description = 'description',
    priority = 'priority',
    assignee = 'assignee',
    deadlineAt = 'deadlineAt',
}

interface IForm {
    [FormFields.name]: string,
    [FormFields.description]?: string,
    [FormFields.taskType]: TaskTypes,
    [FormFields.priority]?: TaskPriorities,
    [FormFields.assignee]?: IUser,
    [FormFields.status]?: TaskStatuses,
    [FormFields.deadlineAt]?: string,
}

interface IProps {
    history: any,
    task: ITask,
    updateTask(task: ITask): void,
    reload(): void,
    onClose(): void,
}

export function TaskEditor(props: IProps) {

    const currentUser = useCurrentUser();

    const [loading, setLoading] = React.useState(false);

    const [task, setTask] = React.useState<ITask>();
    const [relatedComments, setRelatedComments] = React.useState<IComment[]>();
    const [deleteVisible, setDeleteVisible] = React.useState(false);

    React.useEffect(() => {
        setTask(props.task);
        if(props.task.uid) {
            getRelatedCommentsByTask(props.task.uid)
            .then((comments) => {
                setRelatedComments(comments);
            })
            .catch(() => { /** */ });
        }
        else {
            setRelatedComments(undefined)
        }
    }, [props.task]);

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().label('Task Title').trim().required().min(3).max(100),
            description: Yup.string().label('Description').trim().max(60000),
            priority: Yup.mixed<TaskPriorities>().label('Priority'),
            taskType: Yup.mixed<TaskTypes>().label('Task Type').required(),
            deadlineAt: Yup.string().label('Deadline Date'),
        });
    };

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newTask: Partial<ITask> = {
                ...task,
                status: values.status,
                author: !task?.uid ? { uid: currentUser?.uid } as IUser : undefined,
                assignee: values.assignee ? { uid: values.assignee.uid } as IUser : undefined,
                priority: values.deadlineAt ? TaskPriorities.high : values.priority,
                deadlineAt: values.deadlineAt ? moment(values.deadlineAt).utc().format() : undefined,
            };
            
            if(currentUser?.uid === task?.author.uid && task?.status === TaskStatuses.newTask) {
                newTask.name = values.name;
                newTask.description = values.description;
                newTask.taskType = values.taskType;
            }

            const uid = await taskSave(newTask);
            if(task && !task.uid) {
                props.updateTask({...task, uid});
            }

            toast.success('Item has been successfully saved');
            props.reload();
        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    }


    const goToEntity = async (isNewWindow = false) => {
        if(!task) return;
        const url = await getRelativeLinkToEntity(task.entityType, task.entityUid);
        if(isNewWindow) {
            window.open(url, "_blank");
        }
        else {
            props.history.push(url, "_blank");
        }
    }

    const renderForm = ({ errors, touched, setFieldValue, values }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div>
                <Form noValidate>
                    <div className="form-buttons">
                        {task?.uid && (
                            <LinkButton
                                onClick={() => goToEntity()}
                                onContextMenu={(ev) => { ev.preventDefault(); goToEntity(true); }}
                            ><FontAwesomeIcon icon={faExternalLinkAlt} /> Go to Entity</LinkButton>
                        )}
                        {currentUser?.role === UserRoles.admin && (
                            <LinkButton onClick={() => setDeleteVisible(true)} className="red"><FontAwesomeIcon icon={faTrash} /> Delete Task</LinkButton>
                        )}
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Task Status
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.status}
                                data={Object.keys(TaskStatuses).map(t => ({ uid: TaskStatuses[t], name: TaskStatusesInfo[TaskStatuses[t]].statusText}))}
                            />
                        </label>
                        <div className="errors">{touched.status && errors.status}</div>
                    </div>
                    
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Entity Type
                            </div>
                            <Field type="text" name={FormFields.entityType} disabled={true} />
                        </label>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Entity Name
                            </div>
                            <Field type="text" name={FormFields.entityName} disabled={true} />
                        </label>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Task Type
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.taskType}
                                data={Object.keys(TaskTypes).map(t => TaskTypes[t])}
                                disabled={task?.status !== TaskStatuses.newTask || task?.author.uid !== currentUser?.uid}
                            />
                        </label>
                        <div className="errors">{touched.taskType && errors.taskType}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Task Title
                            </div>
                            <Field
                                type="text"
                                name={FormFields.name}
                                disabled={task?.status !== TaskStatuses.newTask || task?.author.uid !== currentUser?.uid}
                            />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field
                                component={CKEditorField}
                                name={FormFields.description}
                                toolbar={[
                                    { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', '-', 'Undo', 'Redo' ] },
                                    { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] }, { name: 'tools', items: [ 'ShowBlocks' ] },
                                    { name: 'styles', items: [ 'Styles' ] },
                                    '/',
                                    { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                                    { name: 'basicstyles', items: [ 'FontSize', 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
                                    { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                                    { name: 'insert', items: [ 'Table', 'HorizontalRule', 'SpecialChar' ] },
                                ]}
                                height={300}
                                disabled={task?.status !== TaskStatuses.newTask || task?.author.uid !== currentUser?.uid}
                            />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Priority
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.priority}
                                data={Object.keys(TaskPriorities).map(el => ({ uid: el, name: el.toUpperCase()}))}
                                disabled={task?.status === TaskStatuses.closed}
                            />
                        </label>
                        <div className="errors">{touched.priority && errors.priority}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Deadline Date
                            </div>
                            <DateTimePicker
                                value={values.deadlineAt && moment(values.deadlineAt).toDate()}
                                onChange={(v: any) => {
                                    setFieldValue(FormFields.deadlineAt, moment(v).format('YYYY-MM-DDTHH:mm:ss'));
                                }}
                                clearIcon={null}
                                disableClock={true}
                                showLeadingZeros={true}
                                minDate={moment().add(2, 'days').toDate()}
                                maxDate={moment().add(1, 'year').toDate()}
                                disabled={task?.status === TaskStatuses.closed}
                            />
                        </label>
                        <div className="errors">{errors.deadlineAt}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Assignee
                            </div>
                            <Field
                                component={CustomSelectField}
                                name={FormFields.assignee}
                                internalComponent={SelectUsersList}
                                params={{
                                    roles: [UserRoles.admin, UserRoles.editor],
                                    narrativeUid: currentUser?.role === UserRoles.editor && task?.entityType === EntityTypes.narrative
                                        ? task?.entityUid
                                        : undefined,
                                }}
                                titleExtractor={(user?: IUser) => !user ? '' : `${user?.firstName} ${user?.lastName} [${user?.email}]`}
                                disabled={task?.status === TaskStatuses.closed}
                            />
                        </label>
                        <div className="errors">{touched.assignee && errors.assignee}</div>
                    </div>

                    <div className="form-buttons">
                        <Button onClick={props.onClose} className="gray">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<>
                                {task?.status !== TaskStatuses.closed && (<Button type="submit">Save</Button>)}
                            </>)
                        }
                    </div>
                </Form>
            </div>
        );
    }

    return (<>
        <div>
            { task && (
                <Tabs
                    data={[
                        {
                            title: 'Task Info',
                            content: (<>
                                <br />
                                <Formik
                                    initialValues={{
                                        entityType: task.entityType.toUpperCase(),
                                        entityName: task.entityName,
                                        status: task.status,
                                        name: task.name || '',
                                        description: task.description || '',
                                        priority: task.priority || TaskPriorities.normal,
                                        deadlineAt: task.deadlineAt || '',
                                        taskType: task.taskType,
                                        assignee: task.assignee,
                                    }}
                                    validationSchema={getValidationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {renderForm}
                                </Formik>
                            </>)
                        },
                        ...(!task.uid ? [] : [{
                            title: 'Unresolved Comments',
                            content: (
                                <div className={styles.tabContentMargin}>
                                    <CommentsList readonly={true} displayLinkToEntity={true} comments={relatedComments} />
                                </div>
                            )
                            }]
                        ),
                    ]}
                />
            )}
        </div>

        { deleteVisible && (
            <DeleteQuestionPopup
                title="Delete Task"
                question={<>Are you sure want to delete the Task?</>}
                onClose={() => setDeleteVisible(false)}
                onDelete={async () => task?.uid && (await taskDelete(task.uid))}
                onFinish={() => { props.reload(); props.onClose(); }}
            />
        )}
    </>)
}
