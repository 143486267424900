import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const REMOVE_EMOTION_CATEGORY_ACTION_TENDENCY_MUTATION = gql`
    mutation RemoveEmotionCategoryActionTendency($uid: String!, $at_uid: String!, $wrong: Boolean) {
      emotion_category_remove_action_tendency(uid: $uid, at_uid: $at_uid, wrong: $wrong) {
            success
        }
    }
`;

export async function removeEmotionCategoryActionTendency(uid: string, at_uid: string, wrong: boolean): Promise<{ success: boolean }> {
  const result: FetchResult<{ emotion_category_remove_action_tendency: { success: boolean } }> = await graphqlClient.mutate({
      mutation: REMOVE_EMOTION_CATEGORY_ACTION_TENDENCY_MUTATION,
      variables: { uid, at_uid, wrong },
      errorPolicy: 'all',
  });

  if (!result.data || (result.errors && result.errors.length)) {
      throw new MultiError(result.errors || ['Unknown error']);
  }

  return {
    success: result.data.emotion_category_remove_action_tendency.success,
  };
}
