import { FetchResult, gql } from 'apollo-boost';
import { IEmail } from '../../../models/email';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    {
        emails {
            uid,
            name,
            identifier,
            type,
            enabled,
            daysAfterSignup: days_after_signup
        }
    }
`;

export async function getAllEmails(): Promise<IEmail[]> {
    const result: FetchResult<{ emails: IEmail[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.emails;
}
