import * as React from 'react';
import styles from './page-switcher.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFastBackward, faStepBackward, faStepForward, faFastForward } from '@fortawesome/free-solid-svg-icons';

const PAGE_SWITCHER_SIZE = 10;

interface IProps {
    page: number,
    pageSize: number,
    itemsCount: number,
    onChange: (page: number) => void,
    size?: number,
    className?: string,
}

export function PageSwitcher(props: IProps) {
    const size = props.size || PAGE_SWITCHER_SIZE;

    const pagesCount = React.useMemo(() => Math.ceil(props.itemsCount / props.pageSize), [props]);

    const renderButton = (page: number, content?: any, selected?: boolean, disabled: boolean = false, key?: string | number) => (
        <button key={key || page} disabled={disabled} onClick={() => props.onChange(page)} className={selected ? styles.selected : ''}>
            {content || page.toString()}
        </button>
    );

    return (
        <div className={props.className}>
            { renderButton(
                1,
                <FontAwesomeIcon icon={faFastBackward} />,
                false,
                props.page === 1,
                'first',
            )}
            { renderButton(
                props.page - 1,
                <FontAwesomeIcon icon={faStepBackward} />,
                false,
                props.page === 1,
                'prev',
            )}
            { new Array(pagesCount).fill(1).map((el, index) => {
                 if(index + 1 < props.page && props.page > pagesCount - Math.floor(size / 2) && index + 1 > pagesCount - size + 2) {
                    return renderButton(index + 1, undefined, props.page === index + 1);
                }
                else if(props.page >= Math.floor(size / 2) && index + 1 <= props.page - Math.floor(size / 2) + 1) {
                    if(index === 0) {
                        return renderButton(index + 1);
                    }
                    else if(props.page > size / 2 && index === 1) {
                        return (<span className={styles.less} key={'less'}>...</span>);
                    }
                }
                else if(index + 1 > props.page - Math.floor(size / 2) + 1 && index + 1 < props.page + Math.floor(size / 2) - 1) {
                    return renderButton(index + 1, undefined, props.page === index + 1);
                }
                else if(index + 1 > props.page && props.page < size / 2 && index + 1 < size - 1) {
                    return renderButton(index + 1, undefined, props.page === index + 1);
                }
                else if(props.page < pagesCount && index + 1 > props.page) {
                    if(index + 1 === pagesCount) {
                        return renderButton(index + 1);
                    }
                    else if(props.page < pagesCount - size / 2 + 1 && index + 1 === pagesCount - 1) {
                        return (<span className={styles.greater} key={'greater'}>...</span>);
                    }
                }
                
                return null;
            })}
            { renderButton(
                props.page + 1,
                <FontAwesomeIcon icon={faStepForward} />,
                false,
                props.page >= pagesCount,
                'next',
            )}
            { renderButton(
                pagesCount,
                <FontAwesomeIcon icon={faFastForward} />,
                false,
                props.page >= pagesCount,
                'last',
            )}

            <div className={styles.text}>
                {props.itemsCount && (<>
                    Displaying
                    <strong> {(props.page - 1) * props.pageSize + 1} </strong>
                    to
                    <strong> {props.page * props.pageSize > props.itemsCount
                        ? props.itemsCount
                        : props.page * props.pageSize
                    } </strong> of <strong>{props.itemsCount}</strong>
                </>)}
            </div>

        </div>
    )
}