import { FetchResult, gql } from 'apollo-boost';
import { IExternalReference } from '../../../models/external-reference';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation ExternalReferenceSave($externalReference: ExternalReferenceInput!){
        external_reference_save (external_reference_data: $externalReference) {
            uid
        }
    }
`;

export async function externalReferenceSave(externalReference: Partial<IExternalReference>): Promise<string> {
    const result: FetchResult<{ external_reference_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { externalReference: {
            uid: externalReference.uid,
            service_name: externalReference.serviceName,
            external_service_uid: externalReference.externalServiceUid,
            info_page_uid: externalReference.infoPageUid,
            external_service_url: externalReference.externalServiceUrl,
            preferred_source: externalReference.preferredSource,
            time_offset: externalReference.timeOffset,
            paid_plans_only: externalReference.paidPlansOnly,

            narrative: externalReference.narrative,
            episode: externalReference.episode,
        }},
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.external_reference_save.uid;
}
