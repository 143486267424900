import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowDown, faArrowLeft, faArrowUp, faLock, faPlus, faUnlock, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { learningChunkDisable } from '../../../api/actions/learning-chunk/learning-chunk-disable';
import { learningChunkEnable } from '../../../api/actions/learning-chunk/learning-chunk-enable';
import { learningChunkSetIndex } from '../../../api/actions/learning-chunk/learning-chunk-set-index';
import { getUnit } from '../../../api/actions/unit/unit-get';
import { unitSectionDelete } from '../../../api/actions/unit-section/unit-section-delete';
import { unitSectionDisable } from '../../../api/actions/unit-section/unit-section-disable';
import { unitSectionEnable } from '../../../api/actions/unit-section/unit-section-enable';
import { getUnitSection } from '../../../api/actions/unit-section/unit-section-get';
import { unitSectionSave } from '../../../api/actions/unit-section/unit-section-save';
import { Block } from '../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { getLearningChunkFilePath, LEARNING_CHUNK_FILE_URL_PLACEHOLDER } from '../../../config/app-config';
import { basePerformError } from '../../../helpers/error-helpers';
import { joinPath } from '../../../helpers/path-helpers';
import { escapeRegexpString } from '../../../helpers/regexp-helpers';
import { ILearningChunk } from '../../../models/learning-chunk';
import { IUnit } from '../../../models/unit';
import { IUnitSection, UnitSectionTypes } from '../../../models/unit-section';
import { IDictionary } from '../../../types/dictionary';
import styles from './unit-section-editor.module.scss';
import { UnitSectionExerciseParamsList } from './unit-section-exercise-params-list';
import { SelectField } from '../../../components/shared/select/select';
import { CheckBoxWrapper } from '../../../components/shared/checkbox/checkbox-wrapper';


enum FormFields {
    name = 'name',
    description = 'description',
    index = 'index',
    type = 'type',
    episodeCount = 'episodeCount',
    multPerspectiveCompatible = 'multPerspectiveCompatible',
}

interface IForm {
    [FormFields.name]: string,
    [FormFields.description]?: string,
    [FormFields.index]?: number,
    [FormFields.type]?: UnitSectionTypes,
    [FormFields.episodeCount]?: number,
    [FormFields.multPerspectiveCompatible]?: boolean,
}

export function UnitSectionEditor(props: RouteComponentProps<{uid: string, unitUid: string, curriculumUid: string}>) {

    const [loading, setLoading] = useState(false);
    const [unitSection, setSectionUnit] = useState<IUnitSection>();
    const [unit, setUnit] = useState<IUnit>();
    const [entityForDelete, setEntityForDelete] = useState<IUnitSection>();

    const loadData = React.useCallback(async (uid?: string) => {
        try {
            setUnit(await getUnit(props.match.params.unitUid));
            if(uid || props.match.params.uid !== '_') {
                setSectionUnit(await getUnitSection(uid || props.match.params.uid));
            }
            else {
                setSectionUnit({} as IUnitSection);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.uid, props.match.params.unitUid]);

    useEffect(() => {
        loadData();
    }, [loadData, props.match.params.uid, props.match.params.unitUid]);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newUnitSection: Partial<IUnitSection> = {
                uid: unitSection?.uid,
                name: values.name,
                description: values.description,
                index: values.index,
                type: values.type,
                episodeCount: values.episodeCount,
                exerciseParams: unitSection?.exerciseParams,
                multPerspectiveCompatible: values.multPerspectiveCompatible
            };
            if(!newUnitSection.uid) {
                newUnitSection.unit = { uid: props.match.params.unitUid } as IUnit;
            }
            const uid = await unitSectionSave(newUnitSection);
            if(!unitSection?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            }
            await loadData(uid);
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const enableUnitSection = async () => {
        if(!unitSection?.uid) return;
        try {
            await unitSectionEnable(unitSection.uid);
            setSectionUnit({...unitSection, available: true });
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const disableUnitSection = async () => {
        if(!unitSection?.uid) return;
        try {
            await unitSectionDisable(unitSection.uid);
            setSectionUnit({...unitSection, available: false });
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const enableLearningChunk = async (uid: string) => {
        try {
            await learningChunkEnable(uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const disableLearningChunk = async (uid: string) => {
        try {
            await learningChunkDisable(uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const getPrevUid = () => {
        if(unit && unit.unitSections && unit.unitSections.length) {
            const prev = unit.unitSections.findIndex(us => unitSection?.uid === us.uid) - 1;
            return unit.unitSections[prev]?.uid;
        }
    };
    const getNextUid = () => {
        if(unit && unit.unitSections && unit.unitSections.length) {
            const next = unit.unitSections.findIndex(us => unitSection?.uid === us.uid) + 1;
            return unit.unitSections[next] ? unit.unitSections[next].uid : undefined;
        }
    };

    const setIndex = async (uid: string, index: number) => {
        try {
            await learningChunkSetIndex(uid, index);
            loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().trim().required('Please enter Name').min(3).max(50),
            description: Yup.string().trim().min(3).max(255),
            index: Yup.number().required('Please select Index').min(1),
            type: Yup.mixed<UnitSectionTypes>().required(),
            episodeCount: Yup.number().label('Episode count')
                .when(
                    'type',
                    {
                        is: UnitSectionTypes.practice,
                        then: Yup.number().label('Episode count').required().min(1).max(100),
                    },
                ),
            multPerspectiveCompatible: Yup.boolean().label('Supports Multiple Perspectives'),
        });
    };

    const renderForm = ({ errors, touched, values }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>

                    { unitSection && unitSection.uid && (<>
                        <div className="form-buttons">
                        {getPrevUid()
                            ? (<Button
                                type="reset"
                                onClick={() => {
                                    setSectionUnit(undefined);
                                    props.history.push(joinPath(
                                        '/',
                                        props.match.url.replace(/unit-sections\/[^/]*$/ig, ''),
                                        'unit-sections',
                                        getPrevUid(),
                                    ));
                                }}
                                className="small outline gray"
                            >
                                <FontAwesomeIcon icon={faArrowLeft} /> <span>Prev</span>
                            </Button>)
                            : (<div></div>)
                        }
                        {getNextUid()
                            ? (<Button
                                type="reset"
                                onClick={() => {
                                    setSectionUnit(undefined);
                                    props.history.push(joinPath(
                                        '/',
                                        props.match.url.replace(/unit-sections\/[^/]*$/ig, ''),
                                        'unit-sections',
                                        getNextUid(),
                                    ));
                                }}
                                className="small outline gray"
                            >
                                <span>Next</span> <FontAwesomeIcon icon={faArrowRight} />
                            </Button>)
                            : (<div></div>)
                        }
                        </div>
                        <div className="form-buttons">
                            { unitSection.uid && !unitSection.available && (
                                <LinkButton onClick={enableUnitSection}><FontAwesomeIcon icon={faUnlock} /> Enable</LinkButton>
                            )}
                            { unitSection.uid && unitSection.available && (
                                <LinkButton onClick={disableUnitSection}><FontAwesomeIcon icon={faLock} /> Disable</LinkButton>
                            )}
                            { unitSection.uid && unitSection.edited && (
                                <div>Edited*</div>
                            )}

                            { unitSection.uid && (
                                <LinkButton onClick={() => setEntityForDelete(unitSection)} className="red">
                                    <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                </LinkButton>
                            )}
                        </div>
                    </>)}
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name
                            </div>
                            <Field type="text" name={FormFields.name} />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field component="textarea" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Index
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.index}
                                emptyTitle=""
                                data={[
                                    { uid: 1, name: "First" },
                                    ...(
                                        unit?.unitSections
                                        ? unit.unitSections.map((u: IUnitSection, i: number) => (
                                            u.uid === unitSection?.uid
                                            ? null
                                            : {
                                                uid: !unitSection?.index || unitSection?.index > i ? (i + 2) : (i + 1),
                                                name: `#${!unitSection?.index || unitSection?.index > i ? (i + 2) : (i + 1)} After: ${u.name}`,
                                            }
                                        )).filter(us => !!us)
                                        : []
                                    )
                                ]}
                            />
                        </label>
                        <div className="errors">{touched.index && errors.index}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Type
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.type}
                                disabled={unitSection?.uid}
                                emptyTitle=""
                                data={Object.keys(UnitSectionTypes).map((t: string) => ({ uid: t, name: t.toUpperCase() })) }
                            />
                        </label>
                        <div className="errors">{touched.type && errors.type}</div>
                    </div>

                    { values.type === UnitSectionTypes.practice && (
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Episode count
                                </div>
                                <Field type="number" min={0} max={100} name={FormFields.episodeCount} />
                            </label>
                            <div className="errors">{touched.episodeCount && errors.episodeCount}</div>
                        </div>
                    )}

                    { values.type === UnitSectionTypes.practice && (
                      <div className="form-item">
                          <label>
                              <div className="form-label">
                                  &nbsp;
                                  </div>
                                  <CheckBoxWrapper label="Supports Multiple Perspectives">
                                    <Field type="checkbox" name={FormFields.multPerspectiveCompatible} />
                                  </CheckBoxWrapper>
                          </label>
                          <div className="errors">{touched.multPerspectiveCompatible && errors.multPerspectiveCompatible}</div>
                      </div>
                      )}

                    <div className="form-buttons">
                        <Button
                            onClick={() => props.history.push(joinPath(
                                '/curriculums',
                                props.match.params.curriculumUid,
                                'units',
                                props.match.params.unitUid,
                            ))}
                            className="gray"
                        >
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                        ? (<Loader />)
                        : (<Button type="submit"><span>{unitSection?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return (<>
        <Block className={styles.editor}>
            { unitSection && unit?.curriculum?.uid && (<>
                <AddBreadcrumbsItem
                    title={unit && `Curriculum: ${unit.curriculum.name}`}
                    url={joinPath('/curriculums', unit.curriculum.uid)}
                />
                <AddBreadcrumbsItem
                    title={unit && `Unit: ${unit.name}`}
                    url={joinPath('/curriculums/', unit.curriculum.uid, 'units', unit.uid)}
                />
                <AddBreadcrumbsItem
                    title={unitSection && unitSection.uid ? `Edit Unit Section: ${unitSection.name}` : 'Add Unit Section'}
                />
                <Formik
                        initialValues={{
                            name: unitSection.name || '',
                            description: (unitSection.description) || '',
                            index: (unitSection.index) || undefined,
                            type: (unitSection.type) || undefined,
                            episodeCount: (unitSection.episodeCount) || undefined,
                            multPerspectiveCompatible: (unitSection.multPerspectiveCompatible) || false,
                        }}
                        validationSchema={getValidationSchema}
                        onSubmit={handleSubmit}
                >
                    {renderForm}
                </Formik>

                <div>{/* empty right */}</div>
            </>)}
        </Block>


        { unitSection && unitSection.uid && unitSection.type === UnitSectionTypes.practice && !unitSection.learningChunks && (
            <UnitSectionExerciseParamsList unitSection={unitSection} reload={loadData} />
        )}


        { unitSection && unitSection.uid && !unitSection.exerciseParams && (<>
            <div className="list-title">
                <div>Learning Chunks: <strong>{ (unitSection.learningChunks && unitSection.learningChunks.length) || 0 }</strong></div>
                <div>
                    <Button className="orange" onClick={() => { props.history.push(joinPath('/', props.match.url, 'learning-chunks/_')); }}>
                        <FontAwesomeIcon icon={faPlus} /> <span>Add Learning Chunk</span>
                    </Button>
                </div>
            </div>
            { unitSection.learningChunks && !!unitSection.learningChunks.length && (
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Learning Chunk</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            unitSection.learningChunks.sort((l1, l2) => l1.index - l2.index)
                            .map((l: ILearningChunk, i: number) => (
                                <tr key={l.uid}>
                                    <td>
                                        <div className={styles.markContainer}>
                                            <div className={`${styles.mark} ${l.available ? '' : styles.disabled}`}>&nbsp;</div>
                                            <div className={styles.title}>
                                                {l.index + (l.edited ? '*' : '')}
                                                <LinkButton
                                                    onClick={() => setIndex(l.uid, i + 2)}
                                                    disabled={i >= (unitSection.learningChunks?.length || 0) - 1}
                                                >
                                                    <FontAwesomeIcon icon={faArrowDown} />
                                                </LinkButton>
                                                <LinkButton
                                                    onClick={() => setIndex(l.uid, i)}
                                                    disabled={i < 1}
                                                >
                                                    <FontAwesomeIcon icon={faArrowUp} />
                                                </LinkButton>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        { (!l.type || l.type === UnitSectionTypes.learning) && (
                                            <div className={`cke-content ${styles.contentPreviewContainer}`}>
                                                <div
                                                    className={styles.contentPreview}
                                                    dangerouslySetInnerHTML={{
                                                        __html: l.content
                                                        .replace(/ autoplay\b/ig, '')
                                                        .replace(
                                                            new RegExp(escapeRegexpString(LEARNING_CHUNK_FILE_URL_PLACEHOLDER), 'ig'),
                                                            getLearningChunkFilePath(l.uid),
                                                        ),
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        )}

                                        { l.type === UnitSectionTypes.practice && l.exercise && (
                                            l.exercise.question
                                        )}
                                    </td>
                                    <td>
                                        {l.type && l.type.toUpperCase()}
                                    </td>
                                    <td>
                                        <LinkButton
                                            onClick={() => props.history.push(joinPath('/', props.match.url, 'learning-chunks', l.uid)) }
                                        >
                                            <FontAwesomeIcon icon={faEdit} /> Edit
                                        </LinkButton><br />
                                        { !l.available && (<>
                                            <LinkButton onClick={() => enableLearningChunk(l.uid)}>
                                                <FontAwesomeIcon icon={faUnlock} /> Enable
                                            </LinkButton><br />
                                        </>)}
                                        { l.available && (<>
                                            <LinkButton onClick={() => disableLearningChunk(l.uid)}>
                                                <FontAwesomeIcon icon={faLock} /> Disable
                                            </LinkButton><br />
                                        </>)}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )}
        </>)}


        { entityForDelete && (
            <DeleteQuestionPopup
                title="Delete Unit Section"
                question={<>Are you sure want to delete unit section: <strong>{entityForDelete.name}</strong>?</>}
                onClose={() => setEntityForDelete(undefined)}
                onDelete={async () => await unitSectionDelete(entityForDelete.uid)}
                onFinish={() => { props.history.push(joinPath(
                    '/curriculums',
                    props.match.params.curriculumUid,
                    'units',
                    props.match.params.unitUid,
                )); }}
            />
        )}
    </>);
}
