import { FetchResult, gql } from 'apollo-boost';
import { IComment } from '../../../models/comment';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query commentsGetByEntity($entityType: String!, $entityUid: String!) {
        comments(entity_type: $entityType, entity_uid: $entityUid) {
            uid,
            html,
            resolved,
            parentCommentUid: parent_comment_uid,
            entityUid: entity_uid,
            entityType: entity_type,
            createdAt: created_at,
            updatedAt: updated_at,

            author {
                uid,
                firstName: first_name,
                lastName: last_name,
                email,
                role,
            },

            task {
                uid,
                name,
            },
        }
    }
`;

export async function getCommentsByEntity(entityType: string = '', entityUid: string = ''): Promise<IComment[]> {
    const result: FetchResult<{ comments: IComment[]}> = await graphqlClient.query({
        query: QUERY,
        variables: { entityType, entityUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.comments;
}
