export enum EmotionEpisodeContentTagTypes {
    triggerWarning = 'trigger_warning',
    general_category = 'general_category',
    positive_instance = 'positive_instance',
    uncategorized = 'uncategorized'
}


export interface IEmotionEpisodeContentTag {
    uid: string,
    name: string,
    tagType?: EmotionEpisodeContentTagTypes,
}
