import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserGroupCurriculumRemove($userGroupUid: String!, $curriculumUid: String!){
        user_group_curriculum_remove (user_group_uid: $userGroupUid, curriculum_uid: $curriculumUid) {
            success,
        }
    }
`;

export async function removeCurriculumFromUserGroup(userGroupUid: string, curriculumUid: string): Promise<{ success: boolean }> {
    const result: FetchResult<{ user_group_curriculum_remove: { success: boolean } }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { userGroupUid, curriculumUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_group_curriculum_remove;
}
