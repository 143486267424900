import { FetchResult, gql } from 'apollo-boost';
import { ICurriculum } from '../../../models/curriculum';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetAllCurriculums($groupUid: String) {
        curriculums(group_uid: $groupUid) {
            uid,
            name,
            description,
            available,
            minAgeRating: min_age_rating,
            default
        }
    }
`;

export async function getAllCurriculums(groupUid?: string): Promise<ICurriculum[]> {
    const result: FetchResult<{ curriculums: ICurriculum[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { groupUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.curriculums;
}
