import { FetchResult, gql } from 'apollo-boost';
import { ILearningChunk } from '../../../models/learning-chunk';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation LearningChunkSave($learningChunk: LearningChunkInput!, $files: [Upload], $exerciseFile: Upload){
        learning_chunk_save (learning_chunk_data: $learningChunk, files: $files, exercise_file: $exerciseFile) {
            uid
        }
    }
`;

export async function learningChunkSave(learningChunk: ILearningChunk, files?: File[], exerciseFile?: File): Promise<string> {
    const result: FetchResult<{ learning_chunk_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { learningChunk: {
            ...learningChunk,
            ...(learningChunk.exercise
                ? {
                    exercise: {
                        ...learningChunk.exercise,
                        responses: JSON.stringify(learningChunk.exercise.responses),
                    },
                }
                : {}
            ),
        }, files, exerciseFile },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.learning_chunk_save.uid;
}
