import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getAllNeeds } from '../../../api/actions/need/needs-get-all';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { compareText } from '../../../helpers/array-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import { INeed } from '../../../models/need';
import styles from './needs-list.module.scss';


export function NeedsList(props: RouteComponentProps) {
    const [needs, setNeeds] = useState<INeed[]>([]);

    const loadData = React.useCallback(async () => {
        try {
            setNeeds((await getAllNeeds())?.sort((n1, n2) => compareText(n1, n2, (n) => n.name)));
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history]);

    useEffect(() => {
        loadData();
    }, [loadData]);


    return !needs ? null : (
        <>
            <AddBreadcrumbsItem
                title="Needs"
                url={'/libraries/needs'}
            />

            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {needs && needs.map((need, i) => (
                        <tr key={need.name}>
                            <td>{i+1}</td>
                            <td>{need.name}</td>
                            <td>{need.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}
