import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { curriculumAchievementSave } from '../../api/actions/achievement/curriculum-achievement-save';
import { Button } from '../../components/shared/button/button';
import { Loader } from '../../components/shared/loader/loader';
import { SelectField } from '../../components/shared/select/select';
import { isNotNullOrEmpty } from '../../helpers/common-helpers';
import { basePerformError } from '../../helpers/error-helpers';
import { IAchievement } from '../../models/achievement';
import { ICurriculum } from '../../models/curriculum';
import { ICurriculumAchievement } from '../../models/curriculum-achievement';
import { AchievementTriggerTypes } from '../../models/enums/achievement-trigger-types';
import { IUnit } from '../../models/unit';
import { IDictionary } from '../../types/dictionary';
import styles from './achievement-editor.module.scss';


interface IProps {
    curriculumAchievement: ICurriculumAchievement,
    reloadData(): void,
    onClose(): void,
}

enum FormFields {
    name = 'name',
    description = 'description',
    
    triggerType = 'triggerType',
    triggerValue = 'triggerValue',
    unitTrigger = 'unitTrigger',
    requiredCompletedUnit = 'requiredCompletedUnit',
    pdHours = 'pdHours'
}

interface IForm {
    [FormFields.name]?: string,
    [FormFields.description]?: string,
    
    [FormFields.triggerType]: AchievementTriggerTypes,
    [FormFields.triggerValue]?: number,
    [FormFields.pdHours]?: number,

    [FormFields.unitTrigger]?: string,
    [FormFields.requiredCompletedUnit]?: string,
}

export function CurriculumAchievementEditor(props: IProps) {

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newCurriculumAchievement: Partial<ICurriculumAchievement> = {
                uid: props.curriculumAchievement.uid,
                triggerType: values.triggerType,
                triggerValue: values.triggerType !== AchievementTriggerTypes.unit ? values.triggerValue : undefined,
                pdHours: values.pdHours,
                requiredCompletedUnit: values.triggerType === AchievementTriggerTypes.points
                    && isNotNullOrEmpty(values.requiredCompletedUnit)
                    ? ({ uid: values.requiredCompletedUnit } as IUnit)
                    : undefined,
                unitTrigger: values.triggerType === AchievementTriggerTypes.unit ? ({ uid: values.unitTrigger } as IUnit) : undefined,
                achievement: { uid: props.curriculumAchievement.achievement.uid } as IAchievement,
                curriculum: { uid: props.curriculumAchievement.curriculum.uid } as ICurriculum,
            };
            props.curriculumAchievement.uid = await curriculumAchievementSave(newCurriculumAchievement);
            props.reloadData();
            toast.success('Item has been successfully saved');
        }
        catch (err) {
            basePerformError(err);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().trim().required('Please enter Name').min(3).max(50),
            description: Yup.string().trim().min(3).max(255),
            triggerType: Yup.mixed<AchievementTriggerTypes>().label('Trigger Type').required(),
            pdHours: Yup.number().label('PD Hours'),
            triggerValue: Yup.mixed<number>().label('Trigger Value')
            .test('eg1', 'Trigger Value should be greater or equal than 1',
                function(this: { parent: IForm }, val) {
                    return this.parent.triggerType === AchievementTriggerTypes.unit || parseInt(val) >= 1;
                },
            )
            .test('el100', 'Trigger Value should be less or equal than 100',
                function(this: { parent: IForm }, val) {
                    return this.parent.triggerType === AchievementTriggerTypes.unit
                        || this.parent.triggerType === AchievementTriggerTypes.points
                        || parseInt(val) <= 100;
                },
            )
            .test('el10000', 'Trigger Value should be less or equal than 10000',
                function(this: { parent: IForm }, val) {
                    return this.parent.triggerType === AchievementTriggerTypes.unit
                        || parseInt(val) <= 10000;
                },
            ),
            unitTrigger: Yup.string().label('Unit').when(
                FormFields.triggerType,
                {
                    is: AchievementTriggerTypes.unit,
                    then: Yup.string().required(),
                },
            ),
        });
    };

    const renderForm = ({ errors, touched, values }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name
                            </div>
                            <Field type="text" name="name" disabled={true} />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field component="textarea" name="description" disabled={true} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Trigger Type
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.triggerType}
                                emptyTitle=""
                                data={
                                    Object.keys(AchievementTriggerTypes)
                                    .map((x: string) => ({ uid: AchievementTriggerTypes[x], name: AchievementTriggerTypes[x].toString().toUpperCase() }))
                                }
                            />
                        </label>
                        <div className="errors">{touched.triggerType && errors.triggerType}</div>
                    </div>

                    {values.triggerType !== AchievementTriggerTypes.unit && (<>
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Trigger Value
                                </div>
                                <Field type="number" name={FormFields.triggerValue} min={1} max={10000} />
                            </label>
                            <div className="errors">{touched.triggerValue && errors.triggerValue}</div>
                        </div>

                        <div className="form-item">
                        <label>
                            <div className="form-label">
                                Unit
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.requiredCompletedUnit}
                                emptyTitle=""
                                data={
                                    props.curriculumAchievement.curriculum.units?.sort((u1, u2) => u1.index - u2.index)
                                    .map((unit: IUnit) => ({ uid: unit.uid, name: unit.name }))
                                }
                            />
                        </label>
                        <div className="errors">{touched.requiredCompletedUnit && errors.requiredCompletedUnit}</div>
                        </div>

                    </>)}

                    {values.triggerType === AchievementTriggerTypes.unit && (<>

                        <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Unit
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.unitTrigger}
                                emptyTitle=""
                                data={
                                    props.curriculumAchievement.curriculum.units?.sort((u1, u2) => u1.index - u2.index)
                                    .map((unit: IUnit) => ({ uid: unit.uid, name: unit.name }))
                                }
                            />
                        </label>
                        <div className="errors">{touched.unitTrigger && errors.unitTrigger}</div>
                        </div>

                    </>)}

                    <div className="form-item">
                            <label>
                                <div className="form-label">
                                    PD Hours
                                </div>
                                <Field type="number" name={FormFields.pdHours} min={1} max={10000} />
                            </label>
                            <div className="errors">{touched.pdHours && errors.pdHours}</div>
                    </div>

                    <div className="form-buttons">
                        <Button className="gray" onClick={props.onClose}>
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Close</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>Save</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return (
            
        <Formik
            initialValues={{
                name: props.curriculumAchievement.achievement?.name || '',
                description: props.curriculumAchievement.achievement?.description || '',
                triggerType: props.curriculumAchievement.triggerType || '',
                triggerValue: props.curriculumAchievement.triggerValue || 0,
                requiredCompletedUnit: props.curriculumAchievement.requiredCompletedUnit?.uid || '',
                unitTrigger: props.curriculumAchievement.unitTrigger?.uid || '',
                pdHours: props.curriculumAchievement.pdHours || 0
            }}
            validationSchema={getValidationSchema}
            onSubmit={handleSubmit}
        >
            {renderForm}
        </Formik>
    );
}
