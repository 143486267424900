import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation SurveyNarrativeRemove($surveyUid: String!, $narrativeUid: String!){
        survey_narrative_remove (survey_uid: $surveyUid, narrative_uid: $narrativeUid) {
            success,
        }
    }
`;

export async function removeNarrativeFromSurvey(surveyUid: string, narrativeUid: string): Promise<{ success: boolean }> {
    const result: FetchResult<{ survey_narrative_remove: { success: boolean } }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { surveyUid, narrativeUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.survey_narrative_remove;
}
