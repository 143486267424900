import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserPlanRemoveCertification($uid: String!, $cert_uid: String!){
        user_plan_remove_certification (uid: $uid, cert_uid: $cert_uid) {
            success
        }
    }
`;

export async function userPlanRemoveCertification(uid: string, cert_uid: string): Promise<{ success: boolean }> {
    const result: FetchResult<{ user_plan_remove_certification: { success: boolean }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { uid, cert_uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_plan_remove_certification;
}
