import * as React from 'react';
import styles from './tabs.module.scss';
import { isNotNullOrEmpty } from '../../../helpers/common-helpers';

interface ITabInfo {
    title: string | React.ReactElement | Array<string | React.ReactElement>,
    content?: string | React.ReactElement | Array<string | React.ReactElement> | null,
    uid?: string,
    disabled?: boolean,
    hidden?: boolean,
}

interface IProps {
    data?: ITabInfo[],
    activeTabUid?: string,
    activeTabIndex?: number,
}

export function Tabs(props: IProps) {

    const [activeTabIndex, setActiveTabIndex] = React.useState(props.activeTabIndex || 0);

    React.useEffect(() => {
        if(isNotNullOrEmpty(props.activeTabUid)) {
            const index = props.data?.findIndex(d => isNotNullOrEmpty(d.uid) && d.uid === props.activeTabUid);
            setActiveTabIndex(!index || index === -1 ? 0 : index);
        }
        else if(isNotNullOrEmpty(props.activeTabIndex)) {
            setActiveTabIndex(props.activeTabIndex || 0)
        }
    }, [props.activeTabIndex, props.activeTabUid])// no need props.data

    return !props.data?.length ? null : (
        <div className={styles.tabs}>
            <div className={styles.tabsContainer}>
                <ul>
                    {
                        props.data?.map((tabInfo, i) => tabInfo.hidden ? null : (
                            <li
                                key={`tab-${i}`}
                                className={activeTabIndex === i ? styles.active : ''}
                                onClick={() => !tabInfo.disabled && setActiveTabIndex(i)}
                            >{tabInfo.title}</li>
                        ))
                    }
                </ul>
            </div>
            <div>
                {
                    props.data?.map((tabInfo, i) => tabInfo.hidden ? null : (
                        <div
                            key={`tab-cnt-${i}`}
                            className={`${styles.contentContainer} ${activeTabIndex === i ? styles.active : ''}`}
                        >{tabInfo.content}</div>
                    ))
                }
            </div>
        </div>
    )
}