import { FetchResult, gql } from 'apollo-boost';
import { IEntityInfo } from '../../../models/entity-info';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetEntityInfo($entityType: String, $entityUid: String) {
        entity_info(entity_type: $entityType, entity_uid: $entityUid) {
            entityUid: entity_uid,
            entityType: entity_type,
            
            parentEntities: parent_entities {
                entityUid: entity_uid,
                entityType: entity_type,
            },
        }
    }
`;

export async function getEntityInfo(entityType: string, entityUid: string): Promise<IEntityInfo> {
    const result: FetchResult<{ entity_info: IEntityInfo}> = await graphqlClient.query({
        query: QUERY,
        variables: { entityType, entityUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.entity_info;
}
