import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { PlanEditor } from './plan-editor';
import { PlanList } from './plan-list';


export function PlanManagementPage(props: RouteComponentProps) {

    return (<>
        <Route exact
            path={props.match.url} component={PlanList}
        />
        <Route exact
            path={`${props.match.url}/:uid`} component={PlanEditor}
        />
    </>);
}
