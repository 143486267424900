import { faArrowLeft, faEdit, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { toast } from 'react-toastify';
import { characterDelete } from '../../../../../../api/actions/character/character-delete';
import { Button } from '../../../../../../components/shared/button/button';
import { LinkButton } from '../../../../../../components/shared/link-button/link-button';
import { Popup } from '../../../../../../components/shared/popup/popup';
import { getCharacterFilePath } from '../../../../../../config/app-config';
import { compareText } from '../../../../../../helpers/array-helpers';
import { ICharacter } from '../../../../../../models/character';
import { INarrative } from '../../../../../../models/narrative';
import { CharacterEditor } from '../../../character/character-editor';
import { basePerformError } from '../../../../../../helpers/error-helpers';
import { MultiError } from '../../../../../../types/multi-error';
import styles from '../../narrative-editor.module.scss';

interface IProps {
    narrative: INarrative,
    reloadData(): Promise<void>,
}

export function CharactersManagement(props: IProps) {

    const [characterUid, setCharacterUid] = React.useState<string>();
    const [characterEditorVisible, setCharacterEditorVisible] = React.useState(false);
    const [characterForDelete, setCharacterForDelete] = React.useState<ICharacter>();

    return (<>
        <div className="list-title">
            <div>Characters: <strong>{ (props.narrative.characters && props.narrative.characters.length) || 0 }</strong></div>
            <div>
                <Button className="orange" onClick={() => { setCharacterUid(undefined); setCharacterEditorVisible(true); }}>
                    <FontAwesomeIcon icon={faPlus} /> <span>Add Character</span>
                </Button>
            </div>
        </div>
        { props.narrative.characters && !!props.narrative.characters.length && (
            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <thead>
                    <tr>
                        <th>Character Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.narrative.characters
                        .sort((c1, c2) => compareText(c1, c2, (c) => c.name))
                        .map((c: ICharacter) => (
                            <tr key={c.uid}>
                                <td className={styles.character}>
                                    <img
                                        className={styles.characterPhoto}
                                        src={getCharacterFilePath(c.uid, c.fileName)} alt={c.name}
                                    />
                                    {c.name}
                                </td>
                                <td>
                                    <LinkButton onClick={() => { setCharacterUid(c.uid); setCharacterEditorVisible(true); }}>
                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                    </LinkButton><br />
                                    <LinkButton
                                        className="red"
                                        onClick={() => { setCharacterForDelete(c); }}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                    </LinkButton><br />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )}


{ characterEditorVisible && (
            <Popup
                modal={true}
                title={characterUid ? 'Edit Character' : 'Add Character'}
                onClose={() => {
                    props.reloadData().catch(() => { /** */});
                    setCharacterEditorVisible(false);
                }}
            >
                <CharacterEditor
                    uid={characterUid}
                    narrativeUid={props.narrative.uid}
                    onClose={() => { setCharacterEditorVisible(false); }}
                    reload={props.reloadData}
                />
            </Popup>
        )}

        { characterForDelete && (
            <Popup
                modal={true}
                className="red"
                title="Delete Character"
                onClose={() => setCharacterForDelete(undefined) }
                buttons={[
                    {
                        title: <><FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span></>,
                        className: 'gray',
                        onClick: () => setCharacterForDelete(undefined),
                    },
                    {
                        title: <><span>Delete</span> <FontAwesomeIcon icon={faTrashAlt} /></>,
                        className: 'red',
                        onClick: async () => {

                            try {
                                const result = await characterDelete(characterForDelete.uid);
                                if(result.success) {
                                    await props.reloadData();
                                    toast.success('Item has been successfully delete');
                                    setCharacterForDelete(undefined);
                                }
                            }catch (err) {
                                toast.error((err as MultiError).message);
                            }
                        },
                    },
                ]}
            >
                Are you sure want to delete character: <strong>{characterForDelete.name}</strong>?
            </Popup>
        )}
    </>)
}