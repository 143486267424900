import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation InitiatingEventDelete($uid: String!){
        initiating_event_delete (uid: $uid) {
            success
        }
    }
`;

export async function initiatingEventDelete(uid: string): Promise<{ success: boolean }> {
    const result: FetchResult<{ initiating_event_delete: { success: boolean }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.initiating_event_delete;
}
