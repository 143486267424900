import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { narrativeDisable } from '../../../../api/actions/narrative/narrative-disable';
import { narrativeEnable } from '../../../../api/actions/narrative/narrative-enable';
import { getAllNarratives } from '../../../../api/actions/narrative/narratives-get-all';
import { getAllVideoServices } from '../../../../api/actions/video-service/video-service-get-all';
import { Button } from '../../../../components/shared/button/button';
import { LinkButton } from '../../../../components/shared/link-button/link-button';
import { Select } from '../../../../components/shared/select/select';
import { Loader } from '../../../../components/shared/loader/loader';
import { CurrentUserContext } from '../../../../components/user/current-user-manager';
import { IExternalReference } from '../../../../models/external-reference';
import { basePerformError } from '../../../../helpers/error-helpers';
import { joinPath } from '../../../../helpers/path-helpers';
import {
  INarrative,
  NarrativeAvailabiltyFilter,
} from '../../../../models/narrative';
import { IVideoService } from '../../../../models/video-service';
import { compareText } from '../../../../helpers/array-helpers';
import styles from './narratives-list.module.scss';

export function NarrativeList(props: RouteComponentProps) {
  const [loading, setLoading] = useState(true);
  const [narratives, setNarratives] = useState<INarrative[]>([]);
  const [videoServices, setVideoServices] = useState<IVideoService[]>([]);
  const [filterServiceUid, setFilterServiceUid] = useState<string | undefined>(
    undefined
  );
  const [filterAvailability, setFilterAvailability] = useState<
    string | undefined
  >(undefined);

  const currentUserContext = React.useContext(CurrentUserContext);
  const loadData = React.useCallback(async () => {
    if (!currentUserContext?.currentUser) return;
    setLoading(true);
    try {
      const nrs = await getAllNarratives(false, undefined);
      setNarratives(
        nrs.filter(
          (n) =>
            currentUserContext?.isAdmin() ||
            currentUserContext.getNarratives()?.some((n1) => n1.uid === n.uid)
        )
      );
      setVideoServices(
        (await getAllVideoServices()).sort((el1, el2) =>
          compareText(el1, el2, (el) => el.name)
        )
      );
    } catch (err) {
      basePerformError(err, props.history);
    }
    setLoading(false);
  }, [props.history, currentUserContext?.currentUser]);

  useEffect(() => {
    loadData();
  }, [loadData, currentUserContext?.currentUser]);

  const renderButtons = () => (
    <div className={styles.buttons}>
      <Button className="orange" to={joinPath(props.match.url, '_')}>
        Add narrative
      </Button>
    </div>
  );

  const disableNarraive = async (uid: string) => {
    try {
      await narrativeDisable(uid);
      loadData();
      toast.success('Item has been successfully saved');
    } catch (err) {
      basePerformError(err, props.history);
    }
  };

  const getServiceList = (extServices: IExternalReference[] | undefined) => {
    let serviceList = '';
    if (!extServices) return serviceList;

    let i = 0;
    while (i < extServices.length) {
      serviceList =
        serviceList.length > 0
          ? serviceList + ', ' + extServices[i].serviceName.toUpperCase()
          : extServices[i].serviceName.toUpperCase();
      i = i + 1;
    }
    return serviceList;
  };

  const enableNarraive = async (uid: string) => {
    try {
      await narrativeEnable(uid);
      loadData();
      toast.success('Item has been successfully saved');
    } catch (err) {
      basePerformError(err, props.history);
    }
  };

  const getNarrativesByFilter = (
    narrs: INarrative[] | undefined = undefined
  ) => {
    let narrativesList = narrs;
    if (filterServiceUid) {
      const service = videoServices.find((s) => s.uid === filterServiceUid);
      narrativesList = narrativesList?.filter((n) =>
        n.externalReferences?.some(
          (er) =>
            er.serviceName &&
            er.serviceName.toLowerCase() === service?.identifier.toLowerCase()
        )
      );
    }
    if (filterAvailability) {
      narrativesList = narrativesList?.filter(
        (n) => n.available === compareValue(filterAvailability)
      );
    }
    return narrativesList;
  };
  const compareValue = (value: any) => {
    return value === '1' ? true : false;
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      {renderButtons()}

      <div className={styles.buttonView}>
        <div className={styles.filters}>
          <div className={`${styles.filter} ${styles.filterService}`}>
            <span>Service:</span>
            <Select<IVideoService, string>
              emptyTitle="All"
              data={videoServices || []}
              titleExtractor={(n) => n?.name}
              onChange={(e) => setFilterServiceUid(e.target.value)}
              value={filterServiceUid}
            />
          </div>
        </div>

        <div className={styles.filters}>
          <div className={`${styles.filter} ${styles.filterService}`}>
            <span>Availability:</span>
            <Select
              emptyTitle="All"
              data={NarrativeAvailabiltyFilter || []}
              titleExtractor={(n) => n?.name}
              onChange={(e) => setFilterAvailability(e.target.value)}
              value={filterAvailability}
            />
          </div>
        </div>
      </div>
      <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
        <thead>
          <tr>
            <th>Title / Description</th>
            <th>Type</th>
            <th>Service</th>
            <th>Platforms</th>
            <th>Status</th>
            <th>Rating</th>
            <th>Episodes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {getNarrativesByFilter(narratives)
            ?.sort((n1, n2) =>
              n1.title > n2.title ? 1 : n1.title < n2.title ? -1 : 0
            )
            .map((n) => (
              <tr key={n.uid}>
                <td>
                  <div className={styles.titleContainer}>
                    <div
                      className={`${styles.mark} ${
                        n.available ? '' : styles.disabled
                      }`}
                    >
                      &nbsp;
                    </div>
                    <div className={styles.title}>
                      {n.title}
                      <div>{n.description}</div>
                    </div>
                  </div>
                </td>
                <td>{n.mediaType?.toUpperCase()}</td>
                <td>{getServiceList(n.externalReferences)}</td>
                <td>{n.platforms ? n.platforms.toString() : ''}</td>
                <td>{n.status?.toUpperCase()}</td>
                <td>
                  {n.minAgeRating >= 7 && n.minAgeRating < 13
                    ? `Older Kids ${n.minAgeRating}+`
                    : n.minAgeRating >= 13 && n.minAgeRating < 16
                    ? `Teens ${n.minAgeRating}+`
                    : n.minAgeRating >= 16 && n.minAgeRating < 18
                    ? `Young Adults ${n.minAgeRating}+`
                    : n.minAgeRating >= 18
                    ? `Adults ${n.minAgeRating}+`
                    : 'G'}
                </td>
                <td>
                  {!!n.episodes?.length &&
                    `${n.episodes.filter((e) => e.available).length} / ${
                      n.episodes?.length
                    }`}
                </td>
                <td>
                  <LinkButton to={joinPath(props.match.url, n.uid)}>
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </LinkButton>
                  <br />
                  {!n.available && (
                    <>
                      <LinkButton
                        className={styles.enableBtn}
                        onClick={() => enableNarraive(n.uid)}
                      >
                        <FontAwesomeIcon icon={faUnlock} /> Enable
                      </LinkButton>
                      <br />
                    </>
                  )}
                  {n.available && (
                    <>
                      <LinkButton onClick={() => disableNarraive(n.uid)}>
                        <FontAwesomeIcon icon={faLock} /> Disable
                      </LinkButton>
                      <br />
                    </>
                  )}
                  <LinkButton
                    onClick={() => {
                      /* */
                    }}
                    className="red"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} /> Delete
                  </LinkButton>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {renderButtons()}
    </>
  );
}
