import { FetchResult, gql } from 'apollo-boost';
import { IVideoService } from '../../../models/video-service';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query VideoService($uid: String!) {
        video_service(uid: $uid) {
            uid,
            identifier,
            name,
            url,
            available,
            comingSoon: coming_soon,
            platforms,
            apiKey: api_key,
            internal
        }
    }
`;

export async function getVideoService(videoServiceUid: string): Promise<IVideoService> {
    const result: FetchResult<{ video_service: IVideoService }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid: videoServiceUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.video_service;
}
