import { FetchResult, gql } from 'apollo-boost';
import { IRewardConfig } from '../../../models/reward-config';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query {
        reward_configs {
            uid,
            name,
            description,
            maxWeeklyEarn: max_weekly_earn
        }
    }
`;

export async function getAllRewardConfigs(): Promise<IRewardConfig[]> {
    const result: FetchResult<{ reward_configs: IRewardConfig[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.reward_configs;
}
