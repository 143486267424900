import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../../components/layouts/main-layout';
import { AddBreadcrumbsItem } from '../../components/shared/breadcrumbs/breadcrumbs';
import { LinkButton } from '../../components/shared/link-button/link-button';
import { SecuredPage } from '../base/secured-page';
import { UserGroupEditor } from './user-groups/user-group-editor';
import { UserGroupsList } from './user-groups/user-groups-list';
import { UserEditor } from './users/user-editor';
import { UsersList } from './users/users-list';



export function UsersManagementPage(props: RouteComponentProps) {
    
    const isActive = (path: string) => new RegExp(`^${path}`, 'ig').exec(document.location.pathname);

    return (
        <SecuredPage pageName="curriculums" {...props} >

            <AddBreadcrumbsItem
                title="Home"
                url="/"
            />
            <AddBreadcrumbsItem
                title="Users"
                url="/users/users"
            />

            <MainLayout
                submenu={
                    <div className="mainsubmenu">
                        <ul>
                            <li>
                                <LinkButton
                                    to={'/users/users'}
                                    className={isActive('/users/users') && 'active'}
                                >Users</LinkButton>
                            </li>
                            <li>
                                <LinkButton
                                    to={'/users/groups'}
                                    className={isActive('/users/groups') && 'active'}
                                >Groups</LinkButton>
                            </li>
                        </ul>
                    </div>
                }
            >
                <Route exact
                    path={`${props.match.url}/users`} component={UsersList}
                />
                <Route exact
                    path={[
                        `${props.match.url}/users/:uid`,
                        `${props.match.url}/groups/:groupUid/users/:uid`,
                        `${props.match.url}/groups/:parentGroupUid/subgroups/:groupUid/users/:uid`,
                    ]}
                    component={UserEditor}
                />
                <Route exact
                    path={`${props.match.url}/groups`} component={UserGroupsList}
                />
                <Route exact
                    path={[
                        `${props.match.url}/groups/:uid`,
                        `${props.match.url}/groups/:uid/users`,
                        `${props.match.url}/groups/:uid/subgroups`,
                        `${props.match.url}/groups/:parentUid/subgroups/:uid`,
                        `${props.match.url}/groups/:parentUid/subgroups/:uid/users`,
                    ]}
                    component={UserGroupEditor}
                />
            </MainLayout>
        </SecuredPage>
    );
}
