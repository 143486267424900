import React, { useState, useEffect } from 'react';
import { Button } from '../../../components/shared/button/button';
import { Link } from 'react-router-dom';
import { getAllActionTendencies } from '../../../api/actions/action-tendencies/action-tendency-get-all';
import { deleteActionTendency } from '../../../api/actions/action-tendencies/action-tendency-delete';
import { IActionTendency } from '../../../models/action-tendency';
import { getActionTendencyFilePath } from '../../../config/app-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
import { ActionPopup } from '../../../components/shared/popup/action-popup';
import styles from '../emotion-categories/emotion-categories-list.module.scss';

interface ITendencyToDelete {
  label?: string;
  uid?: string;
}

export const ActionTendenciesList = () => {
  const [actionTendencies, setActionTendencies] = useState<IActionTendency[]>(
    []
  );
  const [tendencyToDelete, setTendencyToDelete] = useState<ITendencyToDelete>(
    {}
  );

  const handleDelete = async (uid: string) => {
    const { success } = await deleteActionTendency(uid);
    if (success) {
      toast.success('Item has been successfully deleted');
      const updatedActionTendencies = actionTendencies.filter(
        (tendency) => tendency.uid !== uid
      );
      setActionTendencies(updatedActionTendencies);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllActionTendencies();
        setActionTendencies(data.sort((a, b) => a.uid.localeCompare(b.uid)));
      } catch (error) {
        console.error('Failed to fetch action tendencies:', error);
      }
    };

    void fetchData();
  }, []);

  console.log('actionTendencies', actionTendencies);
  return (
    <>
      <Button to="/libraries/action-tendencies/_" className="orange">
        Add Action Tendency
      </Button>
      <table cellPadding="0" cellSpacing="0" className={`list`}>
        <thead>
          <tr>
            <th>UID</th>
            <th>Label</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {actionTendencies.map((tendency: IActionTendency) => (
            <tr key={tendency.uid}>
              <td>{tendency.uid}</td>
              <td>{tendency.label}</td>
              <td>
                {tendency.imgFileName ? (
                  <img
                    src={getActionTendencyFilePath(
                      tendency.uid,
                      tendency.imgFileName
                    )}
                    alt={tendency.label}
                    style={{ width: '50px', height: '50px' }}
                  />
                ) : (
                  'No Image'
                )}
              </td>
              <td>
                <Link
                  className={styles.editLink}
                  to={{
                    pathname: '/libraries/action-tendencies/_',
                    state: { tendency },
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                  />{' '}
                  Edit
                </Link>
                <span
                  className={styles.deleteIcon}
                  onClick={() =>
                    setTendencyToDelete({
                      label: tendency.label,
                      uid: tendency.uid,
                    })
                  }
                >
                  <FontAwesomeIcon
                    className={styles.deleteIcon}
                    icon={faTrashAlt}
                  />{' '}
                  Delete
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {tendencyToDelete.uid && (
        <ActionPopup
          title={`Delete ${tendencyToDelete.label}`}
          question={`Are you sure you want to delete ${tendencyToDelete.label}`}
          onClose={() => setTendencyToDelete({})}
          onAction={() => handleDelete(tendencyToDelete.uid || '')}
        />
      )}
    </>
  );
};
