import { FetchResult, gql } from 'apollo-boost';
import { ICurriculum } from '../../../models/curriculum';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation CurriculumSave($curriculum: CurriculumInput!){
        curriculum_save (curriculum_data: $curriculum) {
            uid
        }
    }
`;

export async function curriculumSave(curriculum: ICurriculum): Promise<string> {
    const result: FetchResult<{ curriculum_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { curriculum: {
            uid: curriculum.uid,
            name: curriculum.name,
            description: curriculum.description,
            basic_unit_count: curriculum.basicUnitCount,
            min_age_rating: curriculum.minAgeRating,
            default: curriculum.default
        } },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.curriculum_save.uid;
}
