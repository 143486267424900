import { INarrative } from './narrative';

export interface ICharacter {
    uid: string,
    name: string,
    shortName: string,
    description: string,
    importance: string,
    gender: string,
    age: number,
    race: string,
    ethnicity: string,
    fileName: string,
    imageFile?: File,

    selected?: boolean,

    narrative?: INarrative,
}

export enum CharacterTypes {
    major = 'major',
    supporting = 'supporting',
    minor = 'minor',
}
