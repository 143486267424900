import { FetchResult, gql } from 'apollo-boost';
import { IEndSurvey } from '../../../models/end-survey';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation EndSurveySave($survey: SurveySaveInput!){
        end_survey_save (survey_data: $survey) {
            uid
        }
    }
`;

export async function surveySave(survey: Partial<IEndSurvey>): Promise<string> {
    const result: FetchResult<{ survey_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { survey: {
            uid: survey.uid,
            name: survey.name,
            description: survey.description,
            default: survey.default,
            user_message: survey.userMessage,
            header: survey.header,
            survey_url: survey.surveyUrl,
            survey_embed_code: survey.surveyEmbedCode,
            use_embed: survey.useEmbed
        } },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.survey_save?.uid;
}
