import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const ADD_EMOTION_CATEGORY_REGULATION_STRATEGY_MUTATION = gql`
    mutation AddEmotionCategoryRegulationStrategy($uid: String!, $rs_uid: String!) {
      emotion_category_add_regulation_strategy(uid: $uid, rs_uid: $rs_uid) {
            success
        }
    }
`;

export async function addEmotionCategoryRegulationStrategy(uid: string, rs_uid: string): Promise<{ success: boolean }> {
  const result: FetchResult<{ emotion_category_add_regulation_strategy: { success: boolean } }> = await graphqlClient.mutate({
      mutation: ADD_EMOTION_CATEGORY_REGULATION_STRATEGY_MUTATION,
      variables: { uid, rs_uid },
      errorPolicy: 'all',
  });

  if (!result.data || (result.errors && result.errors.length)) {
      throw new MultiError(result.errors || ['Unknown error']);
  }

  return {
    success: result.data.emotion_category_add_regulation_strategy.success,
  };
}
