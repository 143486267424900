import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserGroupInviteCodeCreate($userGroupUid: String!){
        user_group_invite_code_create (user_group_uid: $userGroupUid) {
            inviteCode: invite_code,
            inviteCodeExpirationDate: invite_code_expiration_date,
        }
    }
`;

export async function userGroupInviteCodeCreate(userGroupUid: string):
    Promise<{ inviteCode: string, inviteCodeExpirationDate: string }>
{
    const result: FetchResult<{ user_group_invite_code_create: { inviteCode: string, inviteCodeExpirationDate: string } }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { userGroupUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_group_invite_code_create;
}
