import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getAllUserPlans } from '../../../api/actions/user-plan/user-plan-get-all';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { Grid } from '../../../components/shared/grid/grid';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { compareText } from '../../../helpers/array-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import { joinPath } from '../../../helpers/path-helpers';
import { IUserPlan } from '../../../models/user-plan';
import { userPlanEnable } from '../../../api/actions/user-plan/user-plan-enable';
import { userPlanDisable } from '../../../api/actions/user-plan/user-plan-disable';
import { toast } from 'react-toastify';

import styles from './plan-list.module.scss';

export function PlanList(props: RouteComponentProps) {
    const [loading, setLoading] = useState(true);
    const [plans, setPlans] = useState<IUserPlan[]>([]);

    const loadData = React.useCallback(async () => {
        setLoading(true);
        try {
            setPlans((await getAllUserPlans()).sort((el1, el2) => compareText(el1, el2, (el) => el.name)))
        }
        catch(err) {
            basePerformError(err, props.history);
        }
        setLoading(false);
    }, [props.history]);

    useEffect(() => {
        loadData().catch(() =>{/** */});
    }, [loadData]);

    const enableUserPlan = React.useCallback(async (uid: string) => {
        if(!uid) return;
        try {
            await userPlanEnable(uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [loadData, props.history]);


    const disableUserPlan = React.useCallback(async (uid: string) => {
        if(!uid) return;
        try {
            await userPlanDisable(uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [loadData, props.history]);

    const renderButtons = () => (
        <div className={styles.buttons}>
            <Button className="orange" to={joinPath(props.match.url, '_')}>Add User Plan</Button>
        </div>
    );

    return loading ? (<Loader />) : (<>
        <AddBreadcrumbsItem
            title="Plans"
            url={props.match.url}
        />



        <Grid<IUserPlan>
            getPageData={(page, pageSize) => {
                return Promise.resolve({
                    page,
                    count: plans?.length || 0,
                    data: plans?.slice(pageSize * (page - 1), pageSize * page),
                });
            }}

            containerRenderer={(content, pager) => (<>
                <div className={styles.pager}>{pager} {renderButtons()}</div>
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Access</th>
                            <th>Free</th>
                            <th>Last Unit Index</th>
                            <th>Trial</th>
                            <th>Price ID</th>
                            <th>Actions</th>
                        </tr>
                        {content}
                    </tbody>
                </table>
                <div className={styles.pager}>{pager}</div>
            </>)}

            itemRenderer={(plan, i) => (
                <tr key={plan.uid}>
                    <td>
                    <div className={styles.markContainer}>
                      <div className={`${styles.mark} ${!plan.enabled ? styles.disabled : ''}`}>&nbsp;</div>
                      <div className={styles.title}>
                          {i + 1}
                      </div>
                    </div>
                    </td>
                    <td>
                        {plan.name}
                    </td>
                    <td>
                        {plan.type}
                    </td>
                    <td>
                        {plan.accessType}
                    </td>
                    <td>
                        {plan.free ? "Yes" : "No"}
                    </td>
                    <td>
                        {plan.finalUnitIndex && plan.finalUnitIndex > 0 ? plan.finalUnitIndex : null}
                    </td>
                    <td>
                        {plan.freeTrial ? ( plan.trialLength ? plan.trialLength + " days" : "Yes") : ""}
                    </td>

                    <td>
                        {plan.priceId}
                    </td>
                    <td>
                        <LinkButton to={joinPath(props.match.url, plan.uid)}>
                            <FontAwesomeIcon icon={faEdit} /> Edit
                        </LinkButton><br />
                        { !plan.enabled && (<>
                            <LinkButton className={styles.enableBtn} onClick={() => enableUserPlan(plan.uid)}>
                                <FontAwesomeIcon icon={faUnlock} /> Enable
                            </LinkButton><br />
                        </>)}
                        { plan.enabled && (<>
                            <LinkButton onClick={() => disableUserPlan(plan.uid)}>
                                <FontAwesomeIcon icon={faLock} /> Disable
                            </LinkButton><br />
                        </>)}
                    </td>
                </tr>
            )}
        />

        {renderButtons()}
    </>);
}
