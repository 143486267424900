import { FetchResult, gql } from 'apollo-boost';
import { IPipOption } from '../../../models/pip-option';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query PipOption($uid: String!) {
        pip_option(uid: $uid) {
            uid,
            name,
            earnOptionId: earn_option_id,
            amount,
            description,
        }
    }
`;

export async function getPipOption(pipOptionUid: string): Promise<IPipOption> {
    const result: FetchResult<{ pip_option: IPipOption }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid: pipOptionUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.pip_option;
}
