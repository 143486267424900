import { FetchResult, gql } from 'apollo-boost';
import { IRewardConfigItem } from '../../../models/reward-config-item';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query RewardConfigItem($uid: String!) {
        reward_config_item(uid: $uid) {
            uid,
            name,
            description,

            pipOption: pip_option {
                uid,
                name,
            },

            achievement {
                uid,
                name,
            },

            rewardConfig: reward_config {
                uid,
                name,
            }
        }
    }
`;

export async function getRewardConfigItem(rewardConfigItemUid: string): Promise<IRewardConfigItem> {
    const result: FetchResult<{ reward_config_item: IRewardConfigItem }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid: rewardConfigItemUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.reward_config_item;
}
