import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { initiatingEventDelete } from '../../../../api/actions/initiating-event/initiating-event-delete';
import { initiatingEventSave } from '../../../../api/actions/initiating-event/initiating-event-save';
import { CommentButton } from '../../../../components/comments/comment-button';
import { Block } from '../../../../components/shared/block/block';
import { Button } from '../../../../components/shared/button/button';
import { LinkButton } from '../../../../components/shared/link-button/link-button';
import { Loader } from '../../../../components/shared/loader/loader';
import { DeleteQuestionPopup } from '../../../../components/shared/popup/delete-question-popup';
import { SelectField } from '../../../../components/shared/select/select';
import { enumToValueArr } from '../../../../helpers/enum-helpers';
import { basePerformError } from '../../../../helpers/error-helpers';
import { IEmotionEpisode } from '../../../../models/emotion-episode';
import { EntityTypes } from '../../../../models/enums/entity-types';
import { IInitiatingEvent, InitiatingEventTypes } from '../../../../models/initiating-event';
import { IParticipant } from '../../../../models/participant';
import { IDictionary } from '../../../../types/dictionary';
import styles from './initiating-event-editor.module.scss';

enum FormFields {
    description = 'description',
    quote = 'quote',
    type = 'type',
    participantUid = 'participantUid',
}

interface IForm {
    [FormFields.description]?: string,
    [FormFields.quote]?: string,
    [FormFields.type]: string,
    [FormFields.participantUid]: string,
}

interface IProps extends RouteComponentProps {
    emotionEpisode: IEmotionEpisode,
    loading: boolean,
    reloadData(): void,
    setLoading(state: boolean): void,
}

export function InitiatingEventEditor(props: IProps) {
    const [entityForDelete, setEntityForDelete] = React.useState<IInitiatingEvent>();


    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        props.setLoading(true);
        try {
            const newInitiatingEvent: Partial<IInitiatingEvent> = {
                uid: props.emotionEpisode.initiatingEvent && props.emotionEpisode.initiatingEvent.uid,
                description: values.description,
                quote: values.quote,
                type: values.type as InitiatingEventTypes,
                participant: { uid: values.participantUid } as IParticipant,
            };
            if(!newInitiatingEvent.uid) {
                newInitiatingEvent.emotionEpisode = { uid: props.emotionEpisode.uid } as IEmotionEpisode;
            }
            await initiatingEventSave(newInitiatingEvent);
            props.reloadData();
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        props.setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            description: Yup.string().trim().required().min(3).max(255),
            quote: Yup.string().trim().when('type', {
                is: InitiatingEventTypes.speech,
                then: Yup.string().trim().label('Quote').required().min(3).max(255),
            }),
            type: Yup.string().label('Type').required().oneOf(enumToValueArr(InitiatingEventTypes)),
            participantUid: Yup.string().label('Character').required(),
        });
    };

    const renderForm = ({ errors, touched, values }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>
                    <div className="form-buttons">
                        <div></div>
                        { props.emotionEpisode.initiatingEvent && props.emotionEpisode.initiatingEvent.uid && (
                            <LinkButton onClick={() => setEntityForDelete(props.emotionEpisode.initiatingEvent)} className="red">
                                <FontAwesomeIcon icon={faTrashAlt} /> Delete
                            </LinkButton>
                        )}
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Description
                            </div>
                            <Field component="textarea" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Type
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.type}
                                emptyTitle=""
                                data={ Object.keys(InitiatingEventTypes).map(t => ({ uid: t, name: t.toUpperCase() })) }
                            />
                        </label>
                        <div className="errors">{touched.type && errors.type}</div>
                    </div>
                    { values.type === InitiatingEventTypes.speech && (
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Quote
                                </div>
                                <Field component="textarea" name={FormFields.quote} />
                            </label>
                            <div className="errors">{touched.quote && errors.quote}</div>
                        </div>
                    )}
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Character
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.participantUid}
                                emptyTitle=""
                                data={ props.emotionEpisode.participants?.map(
                                    (participant: IParticipant) => ({ uid: participant.uid, name: participant.character.name.toUpperCase()})
                                )}
                            />
                        </label>
                        <div className="errors">{touched.participantUid && errors.participantUid}</div>
                    </div>
                    { props.emotionEpisode.initiatingEvent?.createdBy && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Created
                                </div>
                                <div>
                                    <strong>
                                        {props.emotionEpisode.initiatingEvent.createdBy?.firstName}
                                        &nbsp;
                                        {props.emotionEpisode.initiatingEvent.createdBy?.lastName}
                                    </strong>
                                    &nbsp; at &nbsp;
                                    {moment(props.emotionEpisode.initiatingEvent.createdAt).format('ddd, MM/DD/YYYY h:mm a')}
                                </div>
                            </label>
                        </div>
                    )}
                    { props.emotionEpisode.initiatingEvent?.updatedBy && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Updated
                                </div>
                                <div>
                                    <strong>
                                        {props.emotionEpisode.initiatingEvent.updatedBy?.firstName}
                                        &nbsp;
                                        {props.emotionEpisode.initiatingEvent.updatedBy?.lastName}
                                    </strong>
                                    &nbsp; at &nbsp;
                                    {moment(props.emotionEpisode.initiatingEvent.updatedAt).format('ddd, MM/DD/YYYY h:mm a')}
                                </div>
                            </label>
                        </div>
                    )}

                    <div className="form-buttons">
                        <Button onClick={props.reloadData} className="gray">
                            <span>Cancel</span>
                        </Button>
                        { props.loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>
                                {props.emotionEpisode.initiatingEvent && props.emotionEpisode.initiatingEvent.uid ? 'Save' : 'Create'}
                            </span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return (<>
        <div className={styles.tableTitle}>
            <div>Initiating Event:</div>
        </div>
        <Block>
            { !props.emotionEpisode.participants?.length && (
                <div className={styles.disabledText}>Please add at least one participant to add event</div>
            )}

            { !props.loading && !!props.emotionEpisode.participants?.length && (<>
                <Formik
                        initialValues={{
                            description: (props.emotionEpisode.initiatingEvent && props.emotionEpisode.initiatingEvent.description) || '',
                            quote: (props.emotionEpisode.initiatingEvent && props.emotionEpisode.initiatingEvent.quote) || '',
                            type: (props.emotionEpisode.initiatingEvent && props.emotionEpisode.initiatingEvent.type) || '',
                            participantUid: (
                                props.emotionEpisode.initiatingEvent
                                && props.emotionEpisode.initiatingEvent.participant
                                && props.emotionEpisode.initiatingEvent.participant.uid
                            ) || '',
                        }}
                        validationSchema={getValidationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                >
                    {renderForm}
                </Formik>
                {props.emotionEpisode.initiatingEvent?.uid && (
                    <CommentButton
                        entityType={EntityTypes.initiatingEvent}
                        entityUid={props.emotionEpisode.initiatingEvent?.uid}
                        taskEntityType={EntityTypes.narrative}
                        taskEntityUid={props.emotionEpisode.scene?.narrative?.uid}
                        className={styles.commentsButton}
                    />
                )}
            </>)}
        </Block>



        { entityForDelete && (
            <DeleteQuestionPopup
                title="Delete Initiating Event"
                question={<>Are you sure want to delete initiating event?</>}
                onClose={() => setEntityForDelete(undefined)}
                onDelete={async () => await initiatingEventDelete(entityForDelete.uid)}
                onFinish={() => { props.reloadData(); }}
            />
        )}
    </>);
}
