import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../../components/layouts/main-layout';
import { AddBreadcrumbsItem } from '../../components/shared/breadcrumbs/breadcrumbs';
import { LinkButton } from '../../components/shared/link-button/link-button';
import { AchievementsManagementPage } from '../achievements/achievements-management-page';
import { ActionTendenciesList } from './action-tendencies/action-tendencies-list';
import { ActionTendenciesEdit } from './action-tendencies/action-tendencies-edit';
import { SecuredPage } from '../base/secured-page';
import { ConceptsList } from './concepts/concepts-list';
import { EmotionCategoriesList } from './emotion-categories/emotion-categories-list';
import { EmotionCategoriesEdit } from './emotion-categories/emotion-categories-edit';
import { EmotionEpisodeTagsManagementPage } from './emotion-episode-content-tags/emotion-episode-tags-management-page';
import styles from './libraries-management-page.module.scss';
import { NeedsList } from './needs/needs-list';
import { PipOptionsManagementPage } from './pip-options/pip-options-management-page';
import { PlanManagementPage } from './plans/plan-management-page';
import { RewardSchemasManagementPage } from './reward-schemas/reward-configs-management-page';
import { Scheme } from './scheme/scheme';
import { SkillsList } from './skills/skills-list';
import { TopicsList } from './topics/topics-list';
import { RegulationDefaultsList } from './regulation-strategies/regulation-strategy-defaults';
import { RegulationStrategyEdit } from './regulation-strategies/regulation-strategy-edit';
import { VideoServicesManagementPage } from './video-services/video-services-management-page';
import { SurveyManagementPage } from './end-surveys/survey-management-page';

export function LibrariesManagementPage(props: RouteComponentProps) {
  const isActive = (path: string) =>
    new RegExp(`^${path}`, 'ig').exec(document.location.pathname);

  return (
    <SecuredPage pageName="libraries" {...props}>
      <AddBreadcrumbsItem title="Home" url="/" />
      <AddBreadcrumbsItem title="Libraries" url="/libraries" />
      <MainLayout
        submenu={
          <div className="mainsubmenu">
            <ul>
              <li>
                <LinkButton
                  to={`${props.match.url}/achievements`}
                  className={
                    isActive(`${props.match.url}/achievements`) && 'active'
                  }
                >
                  Achievements
                </LinkButton>
              </li>
              <li>
                <LinkButton
                  to={`${props.match.url}/action-tendencies`}
                  className={
                    isActive(`${props.match.url}/action-tendencies`) && 'active'
                  }
                >
                  Action Tendencies
                </LinkButton>
              </li>
              <li>
                <LinkButton
                  to={`${props.match.url}/concepts`}
                  className={
                    isActive(`${props.match.url}/concepts`) && 'active'
                  }
                >
                  Concepts
                </LinkButton>
              </li>
              <li>
                <LinkButton
                  to={`${props.match.url}/emotion-episode-tags`}
                  className={
                    isActive(`${props.match.url}/emotion-episode-tags`) &&
                    'active'
                  }
                >
                  Tags
                </LinkButton>
              </li>
              <li>
                <LinkButton
                  to={`${props.match.url}/emotion-categories`}
                  className={
                    isActive(`${props.match.url}/emotion-categories`) &&
                    'active'
                  }
                >
                  Emotion Categories
                </LinkButton>
              </li>
              <li>
                <LinkButton
                  to={`${props.match.url}/needs`}
                  className={isActive(`${props.match.url}/needs`) && 'active'}
                >
                  Needs
                </LinkButton>
              </li>
              <li>
                <LinkButton
                  to={`${props.match.url}/pip-options`}
                  className={
                    isActive(`${props.match.url}/pip-options`) && 'active'
                  }
                >
                  PIPs
                </LinkButton>
              </li>
              <li>
                <LinkButton
                  to={`${props.match.url}/plans`}
                  className={isActive(`${props.match.url}/plans`) && 'active'}
                >
                  Plans
                </LinkButton>
              </li>
              <li>
                <LinkButton
                  to={`${props.match.url}/reward-schemas`}
                  className={
                    isActive(`${props.match.url}/reward-schemas`) && 'active'
                  }
                >
                  Reward Schemas
                </LinkButton>
              </li>
              <li>
                <LinkButton
                  to={`${props.match.url}/skills`}
                  className={isActive(`${props.match.url}/skills`) && 'active'}
                >
                  Skills
                </LinkButton>
              </li>
              <li>
                <LinkButton
                  to={`${props.match.url}/regulation-defaults`}
                  className={
                    isActive(`${props.match.url}/regulation-defaults`) &&
                    'active'
                  }
                >
                  Reg Strategies
                </LinkButton>
              </li>
              <li>
                <LinkButton
                  to={`${props.match.url}/topics`}
                  className={isActive(`${props.match.url}/topics`) && 'active'}
                >
                  Topics
                </LinkButton>
              </li>
              <li>
                <LinkButton
                  to={`${props.match.url}/video-services`}
                  className={
                    isActive(`${props.match.url}/video-services`) && 'active'
                  }
                >
                  Streamers
                </LinkButton>
              </li>
            </ul>
            <ul>
              <li>
                <LinkButton
                  to={`${props.match.url}/end-surveys`}
                  className={
                    isActive(`${props.match.url}/end-surveys`) && 'active'
                  }
                >
                  End Surveys
                </LinkButton>
              </li>
            </ul>
          </div>
        }
      >
        <Route
          path={`${props.match.url}/achievements`}
          component={AchievementsManagementPage}
        />
        <Route
          exact
          path={`${props.match.url}/concepts`}
          component={ConceptsList}
        />
        <Route
          exact
          path={`${props.match.url}/emotion-categories`}
          component={EmotionCategoriesList}
        />
        <Route
          exact
          path={`${props.match.url}/emotion-categories/_`}
          component={EmotionCategoriesEdit}
        />
        <Route
          path={`${props.match.url}/emotion-episode-tags`}
          component={EmotionEpisodeTagsManagementPage}
        />
        <Route exact path={`${props.match.url}/needs`} component={NeedsList} />
        <Route
          path={`${props.match.url}/pip-options`}
          component={PipOptionsManagementPage}
        />
        <Route
          path={`${props.match.url}/plans`}
          component={PlanManagementPage}
        />
        <Route
          path={`${props.match.url}/reward-schemas`}
          component={RewardSchemasManagementPage}
        />
        <Route
          exact
          path={`${props.match.url}/skills`}
          component={SkillsList}
        />
        <Route
          exact
          path={`${props.match.url}/regulation-defaults`}
          component={RegulationDefaultsList}
        />

        <Route
          exact
          path={`${props.match.url}/regulation-defaults/_`}
          component={RegulationStrategyEdit}
        />

        <Route
          exact
          path={`${props.match.url}/topics`}
          component={TopicsList}
        />
        <Route exact path={`${props.match.url}/scheme`} component={Scheme} />
        <Route
          path={`${props.match.url}/video-services`}
          component={VideoServicesManagementPage}
        />
        <Route
          path={`${props.match.url}/end-surveys`}
          component={SurveyManagementPage}
        />
        <Route
          exact
          path={`${props.match.url}/action-tendencies`}
          component={ActionTendenciesList}
        />
        <Route
          exact
          path={`${props.match.url}/action-tendencies/_`}
          component={ActionTendenciesEdit}
        />
        <Route
          exact
          path={props.match.url}
          component={() => (
            <div className={styles.pleaseChoose}>
              Please choose one of the items from the submenu
            </div>
          )}
        />
      </MainLayout>
    </SecuredPage>
  );
}
