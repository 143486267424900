import { FetchResult, gql } from 'apollo-boost';
import { IUserGroup } from '../../../models/user-group';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetUserGroup($uid: String!) {
        user_group(uid: $uid) {
            uid,
            title,
            description,
            address,
            city,
            state,
            zipCode: zipcode,
            groupType: group_type,
            startDate: start_date,
            endDate: end_date,
            domain,
            domainForConfirmation: domain_for_confirmation_only,
            educatorConfirmationRequired: educator_confirmation_required,
            emailConfirmationRequired: email_confirmation_required,
            notificationSentOnAdd: notification_sent_on_add,
            notificationToAdminOnJoin: notification_to_admin_on_join,
            enabled,
            default,
            createdAt: created_at,
            limitContentByAge: limit_content_by_age,
            progressSync: progress_sync,
            unlisted,

            HIPAAEnforced: HIPAA_enforced,
            sharingEnabled: sharing_enabled,
            journalSubmissions: journal_submissions,
            journalPrompt: journal_prompt,
            activityModeSwitchingAllowed: activity_mode_switching_allowed,
            userCount: user_count,
            inviteCount: invite_count,
            maxAgeRating: max_age_rating,
            maxUsers: max_users,
            inviteCode: invite_code,
            inviteCodeExpirationDate: invite_code_expiration_date,
            rewardSchema: reward_schema_ref,
            userPlan: user_plan_ref,
            allowsInterstitials: allows_interstitials,
            allowsInterstitialsOnly: allows_interstitials_only,

            owner {
              uid,
              email,
              firstName: first_name,
              lastName: last_name
            },
            createdBy: created_by {
              uid,
              email,
              firstName: first_name,
              lastName: last_name,
              role
            },            

            curriculums {
                uid,
                name,
                description,
                available,
                minAgeRating: min_age_rating
            },

            narratives {
                uid,
                title,
                available
            },

            userProfile: profile {
                uid,
                name
            }

        }
    }
`;

export async function getUserGroup(uid: string): Promise<IUserGroup> {
    const result: FetchResult<{ user_group: IUserGroup }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_group;
}
