import { BaseTypes } from '../types';

type TSomething = string | number | boolean | symbol | Record<string, unknown> | unknown;

export function isNullOrEmpty(v: any): v is undefined | null | [] | '' {
  return v === undefined
      || v === null
      || (typeof v === BaseTypes.string && !v.length)
      || (Array.isArray(v) && !v.length);
}

export function isNotNullOrEmpty<T extends TSomething>(v: T | undefined | null): v is T {
  return !isNullOrEmpty(v);
}

export function removeFields<T>(obj: T, fields: string[], recursively = true) {
  fields.forEach(fn => delete((obj as any)[fn]));
  if(recursively) {
    Object.keys(obj as any).forEach(key => {
      if((obj as any)[key] && typeof (obj as any)[key] === BaseTypes.object) {
        removeFields((obj as any)[key], fields, true);
      }
    })
  }
  return obj;
}
