import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { getAllRegulationCategories } from '../../../../../../api/actions/regulation-categories-get-all';
import { getRegulationStrategy } from '../../../../../../api/actions/regulation-strategy/regulation-strategy-get';
import { regulationStrategySave } from '../../../../../../api/actions/regulation-strategy/regulation-strategy-save';
import { getRegulationGoalsByParticipant } from '../../../../../../api/actions/goal/goals-regulation-get-by-participant';
import { Button } from '../../../../../../components/shared/button/button';
import { Loader } from '../../../../../../components/shared/loader/loader';
import { SelectField } from '../../../../../../components/shared/select/select';
import { basePerformError } from '../../../../../../helpers/error-helpers';
import { IParticipant } from '../../../../../../models/participant';
import { IRegulationCategory, buildRegulationCategoryTree} from '../../../../../../models/regulation-category';
import { IGoal } from '../../../../../../models/goal';
import { IRegulationStrategy, RegulationAttemptTypes, RegulationOutcomeTypes, RegulationSuccessTypes } from '../../../../../../models/regulation-strategy';
import { IDictionary } from '../../../../../../types/dictionary';
import { cutStr } from '../../../../../../helpers/string-helpers';
import styles from './regulation-strategy-editor.module.scss';
import moment from 'moment';
import { compareText } from '../../../../../../helpers/array-helpers';

interface IProps extends RouteComponentProps {
    uid?: string,
    participantUid: string,
    onClose: () => void,
    reload(): void,
}

enum FormFields {
    description = 'description',
    attempted = 'attempted',
    intendedOutcome = 'intendedOutcome',
    success = 'success',
    regulationCategoryUid = 'regulationCategoryUid',
    goalUid = 'goalUid',
}

interface IForm {
    [FormFields.description]?: string,
    [FormFields.attempted]?: RegulationAttemptTypes,
    [FormFields.intendedOutcome]?: RegulationOutcomeTypes,
    [FormFields.success]?: RegulationSuccessTypes,
    [FormFields.regulationCategoryUid]?: string,
    [FormFields.goalUid]?: string,
}

function RegulationStrategyEditor(props: IProps) {

    const [loading, setLoading] = useState(false);
    const [regulationStrategy, setRegulationStrategy] = useState<IRegulationStrategy>()
    const [regulationCategories, setRegulationCategories] = useState<IRegulationCategory[]>();
    const [goals, setGoals] = useState<IGoal[]>();

    const loadData = useCallback(async (uid?: string) => {
        try {
            setRegulationCategories(buildRegulationCategoryTree(await getAllRegulationCategories()));
            setGoals(await getRegulationGoalsByParticipant(props.participantUid));

            if(uid || props.uid) {
                setRegulationStrategy(await getRegulationStrategy(uid || props.uid || ''));
            }
            else {
                setRegulationStrategy({} as IRegulationStrategy);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.uid]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newRegulationStrategy: Partial<IRegulationStrategy> = {
                uid: regulationStrategy?.uid,
                description: values.description,
                attempted: values.attempted,
                intendedOutcome: values.intendedOutcome,
                success: values.success,
                regulationCategory: {
                    uid: values.regulationCategoryUid,
                } as IRegulationCategory,
                goal: {
                    uid: values.goalUid,
                } as IGoal,
            };
            if(!newRegulationStrategy.uid) {
                newRegulationStrategy.doneBy = { uid: props.participantUid } as IParticipant;
            }
            const uid = await regulationStrategySave(newRegulationStrategy);
            await loadData(uid);
            props.reload();
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            description: Yup.string().label('Description').trim().required().min(3).max(255),
            regulationCategoryUid: Yup.string().label('Regulation Category').trim().required(),
            goalUid: Yup.string().label('Regulation Goal').trim().required(),
        });
    };

    const renderForm = ({ errors, touched, isValid }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Description
                            </div>
                            <Field type="text" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Attempted
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.attempted}
                                emptyTitle=""
                                data={ Object.keys(RegulationAttemptTypes)
                                    .map((el: string) => ({ uid: (RegulationAttemptTypes as any)[el], name: (RegulationAttemptTypes as any)[el] }))
                                }
                            />
                        </label>
                        <div className="errors">{touched.attempted && errors.attempted}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Intended Outcome
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.intendedOutcome}
                                emptyTitle=""
                                data={ Object.keys(RegulationOutcomeTypes)
                                    .map((el: string) => ({ uid: (RegulationOutcomeTypes as any)[el], name: (RegulationOutcomeTypes as any)[el]}))
                                }
                            />
                        </label>
                        <div className="errors">{touched.intendedOutcome && errors.intendedOutcome}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Success
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.success}
                                emptyTitle=""
                                data={ Object.keys(RegulationSuccessTypes)
                                    .map((el: string) => ({ uid: (RegulationSuccessTypes as any)[el], name: (RegulationSuccessTypes as any)[el]}))
                                }
                            />
                        </label>
                        <div className="errors">{touched.success && errors.success}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                            Regulation Category
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.regulationCategoryUid}
                                emptyTitle=""
                                data={regulationCategories}
                                titleExtractor={(el: IRegulationCategory) => el.name}
                            />
                        </label>
                        <div className="errors">{touched.regulationCategoryUid && errors.regulationCategoryUid}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Regulation Goal
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.goalUid}
                                data={ goals?.sort((a1, a2) => compareText(a1, a2, a => a.description))
                                    .map((a: IGoal) => ({ uid: a.uid, name: cutStr(a.description, 40) }))
                                }
                            />
                        </label>
                        <div className="errors">{touched.goalUid && errors.goalUid}</div>
                    </div>
                    { regulationStrategy?.createdBy && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Created
                                </div>
                                <div>
                                    <strong>
                                        {regulationStrategy.createdBy?.firstName}
                                        &nbsp;
                                        {regulationStrategy.createdBy?.lastName}
                                    </strong>
                                    &nbsp; at &nbsp;
                                    {moment(regulationStrategy.createdAt).format('ddd, MM/DD/YYYY h:mm a')}
                                </div>
                            </label>
                        </div>
                    )}
                    { regulationStrategy?.updatedBy && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Updated
                                </div>
                                <div>
                                    <strong>
                                        {regulationStrategy.updatedBy?.firstName}
                                        &nbsp;
                                        {regulationStrategy.updatedBy?.lastName}
                                    </strong>
                                    &nbsp; at &nbsp;
                                    {moment(regulationStrategy.updatedAt).format('ddd, MM/DD/YYYY h:mm a')}
                                </div>
                            </label>
                        </div>
                    )}

                    <div className="form-buttons">
                        <Button onClick={props.onClose} className="gray small">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit" className="small"><span>{regulationStrategy?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !regulationStrategy ? (<Loader />) : (
        <Formik
            initialValues={{
                description: regulationStrategy.description || '',
                attempted: regulationStrategy.attempted,
                intendedOutcome: regulationStrategy.intendedOutcome,
                success: regulationStrategy.success,
                regulationCategoryUid: regulationStrategy?.regulationCategory?.uid,
                goalUid: (regulationStrategy && regulationStrategy.goal && regulationStrategy.goal.uid) || ''
            }}
            validationSchema={getValidationSchema}
            onSubmit={handleSubmit}
        >
            {renderForm}
        </Formik>
    );
}

const EmotionEditorWithRouter = withRouter(RegulationStrategyEditor);
export { EmotionEditorWithRouter as RegulationStrategyEditor };
