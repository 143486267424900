import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IEmotion } from '../../../models/emotion';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetEmotion($uid: String!){
        emotion(uid: $uid) {
            uid,
            adjective,
            description,
            intensity,
            activation,
            createdAt: created_at,
            updatedAt: updated_at,

            createdBy: created_by {
                uid,
                firstName: first_name,
                lastName: last_name,
            },

            updatedBy: updated_by {
                uid,
                firstName: first_name,
                lastName: last_name,
            },

            experienced_by {
                uid,
            },

            category {
                uid,
            },
        }
    }
`;

export async function getEmotion(uid: string): Promise<IEmotion> {
    const result: FetchResult<{ emotion: IEmotion }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.emotion, ['__typename']);
}
