import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IEmotionEpisode } from '../../../models/emotion-episode';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query emotionEpisodesSearch($text: String!){
        emotion_episodes_search(text: $text) {
            uid,
            description,
            start,
            end,
            absoluteStart: absolute_start,
            available,

            scene {
                uid,
                description,

                narrative {
                    uid,
                    title,
                }

                episode {
                    uid,
                    name,
                    season,
                    numberInSeason: number_in_season,
                }
            }
        }
    }
`;

export async function emotionEpisodesSearch(text: string): Promise<IEmotionEpisode[]> {
    const result: FetchResult<{ emotion_episodes_search: IEmotionEpisode[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { text },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.emotion_episodes_search, ['__typename']);
}
