import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { ICurriculum } from '../../../models/curriculum';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetCurriculum($uid: String!){
        curriculum_for_editing(uid: $uid) {
            uid,
            name,
            description,
            available,
            edited,
            default,
            minAgeRating: min_age_rating,
            basicUnitCount: basic_unit_count,
            copyType: copy_type,
            carbonCopy: carbon_copy,

            lastEditedBy: last_edited_by {
                uid,
                email
            },

            units {
                uid,
                name,
                description,
                type,
                index,
                available,
                edited
            },

            curriculumAchievements: curriculum_achievements {
                uid,
                triggerType: trigger_type,
                triggerValue: trigger_value,
                available,
                edited,
                pdHours: pd_hours,

                achievement {
                    uid,
                    name,
                },

                unitTrigger: unit_trigger {
                    uid,
                    name,
                },

                requiredCompletedUnit: required_completed_unit {
                    uid,
                    name,
                },
            },
        }
    }
`;

export async function getCurriculum(uid: string): Promise<ICurriculum> {
    const result: FetchResult<{ curriculum_for_editing: ICurriculum }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.curriculum_for_editing, ['__typename']);
}
