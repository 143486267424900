import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './App.css';
import { GlobalSearchContextContainer } from './components/layouts/search';
import { BreadcrumbsManager } from './components/shared/breadcrumbs/breadcrumbs';
import { CKEditorBrowseHandler } from './components/shared/ckeditor/ckeditor-browse-handler';
import { PopupManager } from './components/shared/popup/popup-manager';
import { CurrentUserManager } from './components/user/current-user-manager';
import { CommentsRedirect } from './pages/comments/comments-redirect';
import { CurriculumsManagementPage } from './pages/curriculums/curriculums-management-page';
import { HomePage } from './pages/home/home-page';
import { LibrariesManagementPage } from './pages/libraries/libraries-management-page';
import { LoginPage } from './pages/login/login-page';
import { NarrativesManagementPage } from './pages/narratives/narratives-management-page';
import { SearchResultPage } from './pages/search/search-result-page';
import { TasksManagementPage } from './pages/tasks/tasks-management-page';
import { UsersManagementPage } from './pages/users/users-management-page';
import { EmailManagementPage } from './pages/emails/email-management-page';
import { CrowdManagementPage } from './pages/crowd/crowd-management-page';


const App: React.FC = () => {

    return (
        <BreadcrumbsManager>
            <CurrentUserManager>
                <GlobalSearchContextContainer>
                    <Router>
                        <PopupManager>

                            <Route exact path="/" component={HomePage} />

                            <Route path="/login" component={LoginPage} />

                            <Route path="/search" component={SearchResultPage} />

                            <Route path="/comments/:uid" component={CommentsRedirect} />

                            <Route path="/curriculums" component={CurriculumsManagementPage} />

                            <Route path="/crowd" component={CrowdManagementPage} />

                            <Route path="/libraries" component={LibrariesManagementPage} />

                            <Route path="/narratives" component={NarrativesManagementPage} />

                            <Route path="/tasks" component={TasksManagementPage} />

                            <Route path="/users" component={UsersManagementPage} />

                            <Route path="/emails" component={EmailManagementPage} />

                            <Route path="/ckeditor/browse" component={CKEditorBrowseHandler} />

                        </PopupManager>
                    </Router>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        newestOnTop={true}
                        closeOnClick={true}
                        pauseOnHover={true}
                    />

                </GlobalSearchContextContainer>
            </CurrentUserManager>
        </BreadcrumbsManager>
    );
};

export default App;
