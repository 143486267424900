import { FetchResult, gql } from 'apollo-boost';
import { IEndSurvey } from '../../../models/end-survey';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query EndSurvey($uid: String!) {
        end_survey(uid: $uid) {
            uid,
            name,
            description,
            available,
            default,
            header,
            userMessage: user_message,
            surveyUrl: survey_url,
            surveyEmbedCode: survey_embed_code,
            useEmbed: use_embed,

            narratives {
              uid,
              title,
              available
            }
        }
    }
`;

export async function getEndSurvey(surveyUid: string): Promise<IEndSurvey> {
    const result: FetchResult<{ end_survey: IEndSurvey }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid: surveyUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.end_survey;
}
