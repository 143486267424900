import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserGroupNarrativeRemove($userGroupUid: String!, $narrativeUid: String!){
        user_group_narrative_remove (user_group_uid: $userGroupUid, narrative_uid: $narrativeUid) {
            success,
        }
    }
`;

export async function removeNarrativeFromUserGroup(userGroupUid: string, narrativeUid: string): Promise<{ success: boolean }> {
    const result: FetchResult<{ user_group_narrative_remove: { success: boolean } }> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { userGroupUid, narrativeUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_group_narrative_remove;
}
