import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../../components/layouts/main-layout';
import { AddBreadcrumbsItem } from '../../components/shared/breadcrumbs/breadcrumbs';
import { SecuredPage } from '../base/secured-page';
import { EmailList } from './email/email-list';
import { EmailEditor } from './email/email-editor';

export function EmailManagementPage(props: RouteComponentProps) {

    return (
        <SecuredPage pageName="curriculums" {...props} >

            <AddBreadcrumbsItem
                title="Home"
                url="/"
            />
            <AddBreadcrumbsItem
                title="Emails"
                url="/emails"
            />

            <MainLayout>
                <Route exact
                    path={props.match.url} component={EmailList}
                />
                <Route exact
                    path={`${props.match.url}/:uid`} component={EmailEditor}
                />
            </MainLayout>
        </SecuredPage>
    );
}
