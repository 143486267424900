import * as React from 'react';
import styles from './checkbox-wrapper.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    children: React.ReactElement,
    label?: string | React.ReactElement,
}

export function CheckBoxWrapper(props: IProps) {
    return (
        <label className={`${styles.checkboxWrapper}`}>
            <div className={styles.checkboxContainer}>
                {props.children}
                <div className={styles.checkbox}>
                    <FontAwesomeIcon icon={faCheck} />
                </div>
            </div>
            <div>
                {props.label}
            </div>
        </label>
    )
}