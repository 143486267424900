import { compareText } from "../helpers/array-helpers";
import { IFile } from "./file";
import { IActionTendency } from "./action-tendency";
import { IRegulationStrategy } from "./regulation-strategy";

export interface IEmotionCategory {
    uid: string,
    label: string,
    theme: string,
    definition: string,
    color: string,
    adjective: string,
    valence: string,
    parent: string[],
    minAgeRating?: string,
    imgFileName?: string,

    children?: IEmotionCategory[],
    actionTendencies?: IActionTendency[]
    wrongActionTendencies?: IActionTendency[]
    regulationStrategies?: IRegulationStrategy[]
}

export interface IEmotionCategoryForm {
    uid?: string;
    parent: string[] | null;
    label: string;
    definition: string;
    image?: IFile;
    imgFileName?: string | null;
    valence: string;
    color: string;
    adjective: string;
    minAgeRating: number;
  }


export const buildEmotionCategoriesTree = (categories: IEmotionCategory[]) => {
    if(!categories) return categories;

    //const love = categories.find(ec => ec.uid==='affection');
    //if (love) console.log("affection parent length: " + love.parent?.length);

    const roots = categories.filter(ec => !ec.parent || (ec.parent?.length === 0))
        .sort((el1, el2) => compareText(el1, el2, (obj: IEmotionCategory) => obj && obj.label));

    roots.forEach(el => buildTreeNode(el, categories));

    return roots;
}

const buildTreeNode = (emotionCategory: IEmotionCategory, categories: IEmotionCategory[]) => {
    const children = categories.filter(ec => ec.parent && ec.parent.includes(emotionCategory.uid))
        .sort((el1, el2) => compareText(el1, el2, (obj: IEmotionCategory) => obj && obj.label));

    if(children && children.length) {
        children.forEach(el => buildTreeNode(el, categories));
        emotionCategory.children = children;
    }
}
