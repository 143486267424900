import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from '../../../components/shared/button/button';
import { Grid } from '../../../components/shared/grid/grid';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { joinPath } from '../../../helpers/path-helpers';
import { IRewardConfig } from '../../../models/reward-config';
import { IRewardConfigItem } from '../../../models/reward-config-item';
import styles from './reward-configs-list.module.scss';

interface IProps extends RouteComponentProps<{uid: string}>{
    rewards?: IRewardConfigItem[],
}

export function RewardConfigItemsList(props: IProps) {

    const editBasePath = useMemo(() => joinPath(props.match.url, /\/rewards\/?$/.test(props.match.url) ? undefined : 'rewards'), [props.match.url]);

    const renderButtons = () => (
        <div className={styles.buttons}>
            <Button className="orange" to={joinPath(editBasePath, '_')}>Add Reward</Button>
        </div>
    );

    return (<>

        <Grid<IRewardConfig>
            getPageData={(page, pageSize) => {
                return Promise.resolve({
                    page,
                    count: props.rewards?.length || 0,
                    data: props.rewards?.slice(pageSize * (page - 1), pageSize * page),
                });
            }}

            containerRenderer={(content, pager) => (<>
                <div className={styles.pager}>{pager} {renderButtons()}</div>
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Actions</th>
                        </tr>
                        {content}
                    </tbody>
                </table>
                <div className={styles.pager}>{pager}</div>
            </>)}

            itemRenderer={(item, i) => (
                <tr key={item.uid}>
                    <td>
                        {i + 1}
                    </td>
                    <td>
                        {item.name}
                    </td>
                    <td>
                        <LinkButton to={joinPath(editBasePath, item.uid)}>
                            <FontAwesomeIcon icon={faEdit} /> Edit
                        </LinkButton><br />
                    </td>
                </tr>
            )}
        />

        {renderButtons()}
    </>);
}