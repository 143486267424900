export function getHIPAANameReplacementString (name: string | undefined) {
        if (!name || name.length == 0) return '';
        return name[0] + "******" + name[name.length - 1];
    }

export function getHIPAAEmailReplacementString (email: string | undefined) {
      if (!email || email.length == 0) return '';
      return email[0] + "******" + email[email.length - 1];
    }

export function getHIPAAGenderReplacementString (gender: string | undefined) {
    if (!gender || gender.length == 0) return '';
    return "******";
  }
