import { EntityTypes } from "./enums/entity-types";
import { INarrative } from "./narrative";
import { IUser } from "./user";

export enum TaskTypes {
    mapping = 'Mapping',
    review = 'Review',
    other = 'Other',
}

export enum TaskStatuses {
    newTask = 'new',
    notStarted = 'not_started',
    inProgress = 'in_progress',
    done = 'done',
    review = 'review',
    closed = 'closed',
}


export const TaskStatusesInfo: Record<string, { status: TaskStatuses, statusText: string }> = {
    [TaskStatuses.newTask]: {
        status: TaskStatuses.newTask,
        statusText: 'New',
    },
    [TaskStatuses.notStarted]: {
        status: TaskStatuses.notStarted,
        statusText: 'To Do',
    },
    [TaskStatuses.inProgress]: {
        status: TaskStatuses.inProgress,
        statusText: 'In Progress',
    },
    [TaskStatuses.done]: {
        status: TaskStatuses.done,
        statusText: 'Done',
    },
    [TaskStatuses.review]: {
        status: TaskStatuses.review,
        statusText: 'In Review',
    },
    [TaskStatuses.closed]: {
        status: TaskStatuses.closed,
        statusText: 'Closed',
    }
}


export enum TaskPriorities {
    low = 'low',
    normal = 'normal',
    high = 'high',
}



export interface ITask {
    uid: string,
    name: string,
    description: string,
    taskType: TaskTypes,
    status: TaskStatuses,
    priority: TaskPriorities,
    entityUid: string,
    entityName: string,
    entityType: EntityTypes,
    createdAt?: string,
    updatedAt?: string,
    deadlineAt?: string,

    narrative?: INarrative,

    author: IUser,
    assignee?: IUser,

}