import { faEdit, faLock, faPlus, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { toast } from 'react-toastify';
import { sceneDisable } from '../../../../../../api/actions/scene/scene-disable';
import { sceneEnable } from '../../../../../../api/actions/scene/scene-enable';
import { Button } from '../../../../../../components/shared/button/button';
import { LinkButton } from '../../../../../../components/shared/link-button/link-button';
import { secondsToTimeString } from '../../../../../../helpers/datetime-helpers';
import { basePerformError } from '../../../../../../helpers/error-helpers';
import { INarrative } from '../../../../../../models/narrative';
import { IScene } from '../../../../../../models/scene';
import styles from '../../narrative-editor.module.scss';


interface IProps {
    narrative: INarrative,
    history: any,
    addScene(): void,
    editScene(sceneUid: string): void,
    reloadData(): Promise<void>,
}


export function ScenesList(props: IProps) {


    const enableScene = async (uid: string) => {
        try {
            await sceneEnable(uid);
            await props.reloadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const disableScene = async (uid: string) => {
        try {
            await sceneDisable(uid);
            await props.reloadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };


    const sortScenes = (scenes: IScene[]) => {
        return scenes.sort((s1, s2) => {
            if(s1.episode && s2.episode) {
                const e1 = s1.episode;
                const e2 = s2.episode;
                if(e1.season !== e2.season || e1.numberInSeason !== e2.numberInSeason) {
                    return (e1.season * 10_000_000 + e1.numberInSeason * 100_000 + s1.start)
                        - (e2.season * 10_000_000 + e2.numberInSeason * 100_000 + s2.start);
                }
            }

            return s1.start - s2.start;
        });
    };

    return (<>
        <div className="list-title">
            <div>Scenes: <strong>{ (props.narrative.scenes && props.narrative.scenes.length) || 0 }</strong></div>
            <div>
                <Button className="orange" onClick={props.addScene}>
                    <FontAwesomeIcon icon={faPlus} /> <span>Add Scene</span>
                </Button>
            </div>
        </div>
        { props.narrative.scenes && !!props.narrative.scenes.length && (
            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <thead>
                    <tr>
                        <th>Scene</th>
                        <th>Mapping</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sortScenes(props.narrative.scenes)
                        .map((s: IScene) => (
                            <tr key={s.description}>
                                <td>
                                    <div className={styles.markContainer}>
                                        <div className={`${styles.mark} ${s.available ? '' : styles.disabled}`}>&nbsp;</div>
                                        <div className={styles.title}>
                                            {s.episode && `S-${s.episode.season}: E-${s.episode.numberInSeason}: `}
                                            {s.description}
                                        </div>
                                    </div>
                                </td>
                                <td>{s.emotionEpisodeTypes}</td>
                                <td>{secondsToTimeString(s.start, true)}</td>
                                <td>{secondsToTimeString(s.end, true)}</td>
                                <td>
                                    <LinkButton onClick={() => props.editScene(s.uid)}>
                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                    </LinkButton><br />
                                    { !s.available && (<>
                                        <LinkButton onClick={() => enableScene(s.uid)}>
                                            <FontAwesomeIcon icon={faUnlock} /> Enable
                                        </LinkButton><br />
                                    </>)}
                                    { s.available && (<>
                                        <LinkButton onClick={() => disableScene(s.uid)}>
                                            <FontAwesomeIcon icon={faLock} /> Disable
                                        </LinkButton><br />
                                    </>)}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )}
    </>);
}
