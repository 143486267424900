import { faArrowLeft, faTrashAlt, faUnlock, faLock, faPlus, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { surveyDelete } from '../../../api/actions/end-survey/survey-delete';
import { getEndSurvey } from '../../../api/actions/end-survey/survey-get';
import { surveySave } from '../../../api/actions/end-survey/survey-save';
import { surveyEnable } from '../../../api/actions/end-survey/survey-enable';
import { surveyDisable } from '../../../api/actions/end-survey/survey-disable';
import { addNarrativeToSurvey } from '../../../api/actions/end-survey/survey-narrative-add';
import { removeNarrativeFromSurvey } from '../../../api/actions/end-survey/survey-narrative-remove';
import { Block } from '../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { basePerformError } from '../../../helpers/error-helpers';
import { getParentPath, joinPath } from '../../../helpers/path-helpers';
import { IEndSurvey } from '../../../models/end-survey';
import { INarrative } from '../../../models/narrative';
import { getAllNarratives } from '../../../api/actions/narrative/narratives-get-all';
import { IDictionary } from '../../../types/dictionary';
import { SelectField } from '../../../components/shared/select/select';
import { SectorTypes } from '../../../models/enums/sector-types';
import { CheckBoxWrapper } from '../../../components/shared/checkbox/checkbox-wrapper';
import { Popup } from '../../../components/shared/popup/popup';
import { compareText } from '../../../helpers/array-helpers';
import styles from './survey-editor.module.scss';


enum FormFields {
    name = 'name',
    description = 'description',
    default = 'default',
    header = 'header',
    userMessage = 'userMessage',
    surveyUrl = 'surveyUrl',
    surveyEmbedCode = 'surveyEmbedCode',
    useEmbed = 'useEmbed'
}

interface IForm {
    [FormFields.name]: string,
    [FormFields.description]?: string,
    [FormFields.default]?: boolean,
    [FormFields.useEmbed]?: boolean,
    [FormFields.header]?: string,
    [FormFields.userMessage]?: string,
    [FormFields.surveyUrl]?: string,
    [FormFields.surveyEmbedCode]?: string
}

export function SurveyEditor(props: RouteComponentProps<{uid: string}>) {

    const [loading, setLoading] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [survey, setSurvey] = useState<IEndSurvey>();
    const [allNarratives, setAllNarratives] = React.useState<INarrative[]>();
    const [narrativeSelectorPopupVisible, setNarrativeSelectorPopupVisible] = React.useState(false);


    const loadData = useCallback(async (uid?: string) => {
        try {
            if(uid || props.match.params.uid !== '_') {
                setSurvey(await getEndSurvey(uid || props.match.params.uid));
                setAllNarratives(await getAllNarratives(false, undefined));
            }
            else {
                setSurvey({} as IEndSurvey);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData().catch(() => {/** */});
    }, []);

    const addNarrative = async (narrativeUid: string) => {
        if(!survey?.uid) return;
        await addNarrativeToSurvey(survey.uid, narrativeUid);
        setNarrativeSelectorPopupVisible(false);
        loadData();
    }

    const removeNarrative = async (narrativeUid: string) => {
        if(!survey?.uid) return;
        await removeNarrativeFromSurvey(survey.uid, narrativeUid);
        setNarrativeSelectorPopupVisible(false);
        loadData();
    }

    const handleSubmit = async (
        values: IForm,
        { setSubmitting }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newSurvey: Partial<IEndSurvey> = {
                uid: survey?.uid,
                name: values.name,
                description: values.description,
                header: values.header,
                default: values.default,
                userMessage: values.userMessage,
                surveyUrl: values.surveyUrl,
                surveyEmbedCode: values.surveyEmbedCode,
                useEmbed: values.surveyEmbedCode ? values.useEmbed : false
            };

            const uid = await surveySave(newSurvey);
            if(!survey?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            }
            await loadData(uid);
            toast.success('Survey has been successfully saved');
        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().label('Name').trim().required().min(2).max(75),
            description: Yup.string().label('Description').trim().min(3).max(250),
            userMessage: Yup.string().label('Message').trim().min(3).max(250),
            surveyUrl: Yup.string().label('Survey URL').url()
        });
    };

    const enableSurvey = React.useCallback(async () => {
        if(!survey?.uid) return;
        try {
            await surveyEnable(survey?.uid);
            await loadData();
            toast.success('Survey has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [loadData, props.history, survey?.uid]);


    const disableSurvey = React.useCallback(async () => {
        if(!survey?.uid) return;
        try {
            await surveyDisable(survey?.uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [loadData, props.history, survey?.uid]);

    const deleteSurvey = async () => {
        if(!survey?.uid) return;
        await surveyDelete(survey?.uid);
        setDeleteDialogVisible(false);
    };

    const renderForm = ({ errors, touched, values }: FormikProps<IForm>): React.ReactElement => {
        return (<>
            <div className={styles.formContainer}>
                <Form noValidate>

                    <div className="form-buttons">

                        { !survey?.available && (<>
                          <LinkButton onClick={enableSurvey}>
                              <FontAwesomeIcon icon={faUnlock} /> Enable
                            </LinkButton><br />
                        </>)}
                        { survey?.available && (<>
                          <LinkButton onClick={disableSurvey} className="orange">
                              <FontAwesomeIcon icon={faLock} /> Disable
                            </LinkButton><br />
                        </>)}

                        <div></div>
                        { survey?.uid && (
                            <LinkButton onClick={() => setDeleteDialogVisible(true)} className="red"><FontAwesomeIcon icon={faTrashAlt} /> Delete</LinkButton>
                        )}
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name (Internal)
                            </div>
                            <Field type="text" name={FormFields.name} />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description (Internal)
                            </div>
                            <Field component="textarea" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Header (External)
                            </div>
                            <Field component="textarea" name={FormFields.header} />
                        </label>
                        <div className="errors">{touched.header && errors.header}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Message (External)
                            </div>
                            <Field component="textarea" name={FormFields.userMessage} />
                        </label>
                        <div className="errors">{touched.userMessage && errors.userMessage}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Survey URL
                            </div>
                            <Field component="textarea" name={FormFields.surveyUrl} />
                        </label>
                        <div className="errors">{touched.surveyUrl && errors.surveyUrl}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Survey Embed Code
                            </div>
                            <Field component="textarea" name={FormFields.surveyEmbedCode} />
                        </label>
                        <div className="errors">{touched.surveyEmbedCode && errors.surveyEmbedCode}</div>
                    </div>

                    {!!survey?.surveyEmbedCode && (
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Use Embed">
                                <Field type="checkbox" name={FormFields.useEmbed} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.useEmbed && errors.useEmbed}</div>
                    </div>
                    )}


                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Default">
                                <Field type="checkbox" name={FormFields.default} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.default && errors.default}</div>
                    </div>

                    <div className="form-buttons">
                        <Button className="gray" to={getParentPath(props.match.url, 1)}>
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>Save</span></Button>)
                        }
                    </div>
                </Form>
            </div>

        </>);
    };

    return !survey ? null : (<>
        <Block className={styles.editor}>

            <AddBreadcrumbsItem
                title="End Surveys"
                url={getParentPath(props.match.url, 1)}
            />
            <AddBreadcrumbsItem
                key={survey.uid}
                title={survey.uid ? `Edit: ${survey.name}` : 'Add Plan'}
                url={props.match.url}
            />
            <Formik<IForm>
                initialValues={{
                    name: survey?.name || '',
                    description: survey?.description || '',
                    default: survey?.default || false,
                    useEmbed: survey?.useEmbed || false,
                    header: survey?.header || '',    
                    userMessage: survey?.userMessage || '',
                    surveyUrl: survey?.surveyUrl || '',
                    surveyEmbedCode: survey?.surveyEmbedCode || ''
                }}
                validationSchema={getValidationSchema}
                onSubmit={handleSubmit}
            >
                {renderForm}
            </Formik>
        </Block>

        {!!survey?.uid && (
        <div>
          <div className="list-title">
            <div>Narratives:
                <strong>{ (survey?.narratives?.length) || 0 }</strong>
            </div>
            <div>
                <Button
                    className="orange"
                    onClick={() => setNarrativeSelectorPopupVisible(true) }
                >
                    <FontAwesomeIcon icon={faPlus} /> <span>Add Narrative</span>
                </Button>
            </div>
          </div>

          { !!survey?.narratives?.length && (
            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        survey?.narratives?.sort((n1, n2) => compareText(n1, n2, (n) => n.title)).map(((narrative, i) => (
                            <tr key={narrative.uid}>
                                <td>
                                    <div className={styles.markContainer}>
                                        <div className={`${styles.mark} ${narrative.available ? '' : styles.disabled}`}>&nbsp;</div>
                                        <div className={styles.title}>
                                            {i + 1}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {narrative.title}
                                </td>
                                <td>
                                    {narrative.description}
                                </td>
                                <td>
                                    <LinkButton onClick={() => removeNarrative(narrative.uid)}>
                                        <FontAwesomeIcon icon={faEdit} /> Delete
                                    </LinkButton>
                                </td>
                            </tr>
                        )))
                    }
                </tbody>
            </table>
         )}
        </div>
        )}


        {deleteDialogVisible && (
            <DeleteQuestionPopup
                title="Survey Deletion"
                question={<>Are you sure want to delete this survey?</>}
                onClose={() => setDeleteDialogVisible(false)}
                onDelete={deleteSurvey}
                onFinish={() => { props.history.push(getParentPath(props.match.url, 1)) }}
            />
        )}

        { narrativeSelectorPopupVisible && (
            <Popup
                title="Add Narrative"
                onClose={() => setNarrativeSelectorPopupVisible(false)}
                buttons={[
                    {
                        title: <><FontAwesomeIcon icon={faTimes} /> <span>Close</span></>,
                        className: 'gray',
                        onClick: () => setNarrativeSelectorPopupVisible(false),
                    },
                ]}
            >
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allNarratives?.sort((n1, n2) => compareText(n1, n2, (n) => n.title)).map(((narrative, i) => (
                                <tr key={narrative.uid}>
                                    <td>
                                        <div className={styles.markContainer}>
                                            <div className={`${styles.mark} ${narrative.available ? '' : styles.disabled}`}>&nbsp;</div>
                                            <div className={styles.title}>
                                                { i + 1 }
                                            </div>
                                        </div>
                                    </td>
                                    <td>{narrative.title}</td>
                                    <td>
                                        { survey?.narratives?.every(n => n.uid !== narrative.uid) && (
                                            <LinkButton onClick={() => addNarrative(narrative.uid)}>
                                                <FontAwesomeIcon icon={faPlus} /> Add
                                            </LinkButton>
                                        )}
                                    </td>
                                </tr>
                            )))
                        }
                    </tbody>
                </table>

            </Popup>
        )}

    </>);
}
