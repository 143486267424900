import * as React from 'react';
import { IUser } from '../../models/user';
import { getCurrentUser } from '../../api/actions/user/get-current-user';
import { UserRoles } from '../../models/enums/user-roles-enum';
import { ICurriculum } from '../../models/curriculum';
import { INarrative } from '../../models/narrative';
import { compareText } from '../../helpers/array-helpers';
import { UserGroupTypes } from '../../models/enums/user-group-types';
import { MultiError } from '../../types/multi-error';


interface ICurrentUserManagerProps {
    children: any,
}

export interface ICurrentUserManagerState {
    currentUser?: IUser,
    setCurrentUser(user: IUser): void,
    reloadCurrentUser(): Promise<void>,
    isAdmin(): boolean,
    getCurriculums(): ICurriculum[] | null | undefined,
    getNarratives(): INarrative[] | null | undefined,
}

export const CurrentUserContext = React.createContext<ICurrentUserManagerState | null>(null);

export function useCurrentUser() {
    const currentUserContext = React.useContext(CurrentUserContext);
    return currentUserContext?.currentUser;
}

export class CurrentUserManager extends React.PureComponent<ICurrentUserManagerProps, ICurrentUserManagerState> {

    constructor(props: ICurrentUserManagerProps) {
        super(props);

        this.state = {
            setCurrentUser: this.setCurrentUser,
            reloadCurrentUser: this.reloadCurrentUser,
            isAdmin: this.isAdmin,
            getCurriculums: this.getCurriculums,
            getNarratives: this.getNarratives,
        };
    }

    componentDidMount() {
        this.reloadCurrentUser();
    }

    isAdmin = () => this.state.currentUser?.role === UserRoles.admin;
    isEditor = () => this.state.currentUser?.role === UserRoles.editor;

    getCurriculums = () => { 
        const cs = this.state.currentUser?.userGroups?.filter(g => g.groupType === UserGroupTypes.editor)
        .reduce<ICurriculum[]>((curriculums, group) => {
        return [...curriculums, ...(group.curriculums?.filter(c => !curriculums.some(cr => cr.uid === c.uid)) || [])]
        }, []);

        //console.log("got curriculums from editor group(s); count: " + cs?.length);

        return cs?.sort((c1, c2) => compareText(c1, c2, (c) => c.name));
    }

    getNarratives = () => this.state.currentUser?.userGroups?.filter(g => g.groupType === UserGroupTypes.editor)
    .reduce<INarrative[]>((narratives, group) => {
        return [...narratives, ...(group.narratives?.filter(n => !narratives.some(nr => nr.uid === n.uid)) || [])]
    }, [])
    .sort((n1, n2) => compareText(n1, n2, (n) => n.title));

    setCurrentUser = (user: IUser) => {
        this.setState({ currentUser: user });
    }

    reloadCurrentUser = async () => {
        try {
            const user = await getCurrentUser();
            this.setState({ currentUser: user });
        }
        catch(err) {
            if((err as MultiError).code === 401 && window.location.pathname !== '/login') window.location.href = '/login';
        }
    }

    render() {
        return (
            <CurrentUserContext.Provider value={this.state}>
                { this.props.children }
            </CurrentUserContext.Provider>
        );
    }
}
