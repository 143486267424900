import { InMemoryCache } from 'apollo-boost';
import ApolloClient, { OperationVariables, QueryOptions } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import Cookies from 'js-cookie';
import { AppConfig } from '../config/app-config';

const config = {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    } as Partial<QueryOptions<OperationVariables>>,
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    } as Partial<QueryOptions<OperationVariables>>,
};

const httpLink = createUploadLink({
    uri: `${AppConfig.apiProtocol}://${AppConfig.apiHost}${AppConfig.apiPort ? ':' + AppConfig.apiPort : ''}${AppConfig.apiPath}`,
});

const authLink = setContext((_, { headers }) => {

    const token = Cookies.get(AppConfig.tokenName);

    return {
        headers: {
            ...headers,
            authorization: token,
        },
    };
  });

const graphqlClient = new ApolloClient({
    link: authLink.concat(httpLink as any),
    cache: new InMemoryCache(),
});

graphqlClient.defaultOptions = {...graphqlClient.defaultOptions, ...config};


export { graphqlClient };

