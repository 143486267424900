import { faArrowLeft, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { narrativeTopicAdd } from '../../../../../../api/actions/narrative/narrative-topic-add';
import { narrativeTopicDelete } from '../../../../../../api/actions/narrative/narrative-topic-delete';
import { getAllTopics } from '../../../../../../api/actions/topic/topics-get-all';
import { Button } from '../../../../../../components/shared/button/button';
import { LinkButton } from '../../../../../../components/shared/link-button/link-button';
import { DeleteQuestionPopup } from '../../../../../../components/shared/popup/delete-question-popup';
import { Popup } from '../../../../../../components/shared/popup/popup';
import { compareText } from '../../../../../../helpers/array-helpers';
import { basePerformError } from '../../../../../../helpers/error-helpers';
import { INarrative } from '../../../../../../models/narrative';
import { ITopic } from '../../../../../../models/topic';
import styles from './topics-management.module.scss';

interface IProps {
    narrative: INarrative,
    reloadData(): void,
}

export function TopicsManagement(props: IProps) {

    const [loading, setLoading] = React.useState(false);

    const [addTopicPopupVisible, setAddTopicPopupVisible] = React.useState(false);
    const [topicEntityForDelete, setEntityTopicForDelete] = React.useState<ITopic>();

    const [topics, setTopics] = React.useState<ITopic[]>();
    const [newTopicUid, setNewTopicUid] = React.useState<string>();

    React.useEffect(() => {
        (async () => {
            setTopics((await getAllTopics())?.sort((t1, t2) => compareText(t1, t2, (t) => t.name)));
        })().catch(() => {/** */});
    }, []);

    const topicAdd = async (narrativeUid: string, topicUid: string) => {
        setLoading(true);
        await narrativeTopicAdd(narrativeUid, topicUid)
        setLoading(false);
    };

    const topicDelete = async (narrativeUid: string, topicUid: string) => {
        setLoading(true);
        await narrativeTopicDelete(narrativeUid, topicUid)
        setLoading(false);
    };

    return (<>
        <div className="list-title">
            <div>Topics:
                <strong> {props.narrative.topics?.length || 0 }</strong>
            </div>
            <div>
                <Button
                    className="orange"
                    onClick={() => setAddTopicPopupVisible(true) }
                >
                    <FontAwesomeIcon icon={faPlus} /> <span>Add Topic</span>
                </Button>
            </div>
        </div>
        { !!props.narrative.topics?.length && (
            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Topic Name</th>
                        <th>Descriptions</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.narrative.topics
                        .sort((t1, t2) => compareText(t1, t2, (t) => t.name) )
                        .map((t: ITopic, i: number) => (
                            <tr key={t.uid}>
                                <td>
                                    {i + 1}
                                </td>
                                <td>
                                    {t.name}
                                </td>
                                <td>
                                    { t.description }
                                </td>
                                <td>
                                    <LinkButton
                                        className="red"
                                        onClick={() => { setEntityTopicForDelete(t) }}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                    </LinkButton><br />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )}

        { addTopicPopupVisible && (
            <Popup
                title="Add Topic"
                buttons={[
                    {
                        title: <><FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span></>,
                        className: 'gray',
                        onClick: () => { setAddTopicPopupVisible(false); },
                    },
                    {
                        title: <><span>Add</span> <FontAwesomeIcon icon={faPlus} /></>,
                        className: 'orange',
                        onClick: async () => {
                            try {
                                if(!newTopicUid) return;
                                await topicAdd(props.narrative.uid, newTopicUid);
                                props.reloadData();
                                setAddTopicPopupVisible(false);
                            }
                            catch(err) {
                                basePerformError(err);
                            }
                        },
                        disabled: !newTopicUid || loading,
                    },
                ]}
            >
                Topic:
                <select
                    onChange={(event: React.FormEvent<HTMLSelectElement>) => setNewTopicUid(event.currentTarget.value)}
                >
                    <option></option>
                    { topics?.map(
                        (topic: ITopic) => (
                            <option
                                key={topic.uid}
                                value={topic.uid}
                                disabled={props.narrative.topics?.some(
                                    (t: ITopic) => t.uid === topic.uid)
                                }
                            >
                                {topic.name}
                            </option>
                        ),
                    )}
                </select>
            </Popup>
        )}


        { topicEntityForDelete && (
            <DeleteQuestionPopup
                title="Delete Topic"
                question={<>Are you sure want to delete topic: {topicEntityForDelete.name}?</>}
                onClose={() => setEntityTopicForDelete(undefined)}
                onDelete={async () => await topicDelete(props.narrative.uid, topicEntityForDelete.uid)}
                onFinish={props.reloadData}
            />
        )}
    </>);
}
