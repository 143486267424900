import { faBars, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { imagesPath } from '../../helpers/path-helpers';
import { Breadcrumbs } from '../shared/breadcrumbs/breadcrumbs';
import { LinkButton } from '../shared/link-button/link-button';
import { CurrentUser } from '../shared/user/current-user';
import { CurrentUserContext, useCurrentUser } from '../user/current-user-manager';
import styles from './main-layout.module.scss';
import { Search } from './search';

interface IProps {
    submenu?: React.ReactElement,
    children: Array<React.ReactElement | string> | React.ReactElement | string,
}

const SEARCH_MINIMIZE_TIMEOUT = 4000;

export function MainLayout(props: IProps) {
    const isActive = (path: string) => new RegExp(`^${path}`, 'ig').exec(document.location.pathname);

    const currentUserContext = React.useContext(CurrentUserContext);
    const currentUser = useCurrentUser();

    const [searchActive, setSearchActive] = React.useState(false)

    const minimizeSearchTimeout = React.useRef<NodeJS.Timeout>();
    const maximizeSearch = React.useCallback(() => {
        if(minimizeSearchTimeout.current) {
            clearTimeout(minimizeSearchTimeout.current);
        }
        setSearchActive(true);
    }, []);
    const minimizeSearch = React.useCallback((immediately?: boolean) => {
        if(minimizeSearchTimeout.current) {
            clearTimeout(minimizeSearchTimeout.current);
        }

        if(!searchActive) return;

        if(immediately === true) {
            setSearchActive(false);
            return;
        }

        minimizeSearchTimeout.current = setTimeout(() => {
            setSearchActive(false);
        }, SEARCH_MINIMIZE_TIMEOUT);
    }, [searchActive]);

    return (
        <div className={`${styles.main} ${currentUser ? styles.auntentificated : ''}`}>
            <div className={styles.header}>
                { currentUser && (<>
                    <CurrentUser user={currentUserContext?.currentUser} />
                    <div className={styles.menuContainer}>
                        <div className={styles.search}>
                            <Search isMaximized={searchActive} onFocus={maximizeSearch} onBlur={minimizeSearch} />
                        </div>
                        <ul>
                            {searchActive && (<>
                                <li></li>
                                <li><FontAwesomeIcon icon={faBars} className={styles.menuBtn} onClick={() => minimizeSearch(true)} /></li>
                            </>)}
                            {!searchActive && (<>
                                <li>
                                    <LinkButton to="/tasks" className={isActive('/tasks') ? styles.active : ''} >My Tasks</LinkButton>
                                </li>
                                { (currentUserContext?.isAdmin() || !!currentUserContext?.getCurriculums()?.length) && (
                                    <li>
                                        <LinkButton to="/curriculums" className={isActive('/curriculums') ? styles.active : ''} >
                                            Curricula
                                        </LinkButton>
                                    </li>
                                )}
                                
                                <li>
                                    <LinkButton to="/crowd" className={isActive('/crowd') ? styles.active : ''} >Crowd</LinkButton>
                                </li>

                                <li>
                                    <LinkButton to="/libraries" className={isActive('/libraries') ? styles.active : ''} >Libraries</LinkButton>
                                </li>
                                { (currentUserContext?.isAdmin() || !!currentUserContext?.getNarratives()?.length) && (
                                    <li>
                                        <LinkButton to="/narratives" className={isActive('/narratives') ? styles.active : ''} >Narratives</LinkButton>
                                    </li>
                                )}
                                { currentUserContext?.isAdmin() && (
                                    <li>
                                        <LinkButton to="/users/users" className={isActive('/users/users') ? styles.active : ''} >Users</LinkButton>
                                    </li>
                                )}
                                { currentUserContext?.isAdmin() && (
                                    <li>
                                        <LinkButton to="/emails" className={isActive('/emails') ? styles.active : ''} >Emails</LinkButton>
                                    </li>
                                )}

                            </>)}
                        </ul>
                    </div>
                </>)}
                <div className={styles.logoContainer}>
                    <img alt="Affectifi" src={imagesPath('/logo.png')} className={styles.logo} />
                    Affectifi: <strong>&nbsp; Admin</strong>
                </div>
            </div>
            <div className={styles.body}>
                {props.submenu}

                <Breadcrumbs />
                {props.children}
            </div>
        </div>
    );
}
