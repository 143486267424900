import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { ITask } from '../../../models/task';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query TasksGetAll($entityType: String, $entityUid: String) {
        tasks(entity_type: $entityType, entity_uid: $entityUid) {
            uid,
            name,
            description,
            status,
            priority,
            deadlineAt: deadline_at,
            taskType: task_type,
            entityUid: entity_uid,
            entityName: entity_name,
            entityType: entity_type,
            createdAt: created_at,
            updatedAt: updated_at,

            narrative {
                uid,
                title,
            }

            author {
                uid,
                firstName: first_name,
                lastName: last_name,
                email,
                role,
            },

            assignee {
                uid,
                firstName: first_name,
                lastName: last_name,
                email,
                role,
            },
        }
    }
`;

export async function getAllTasks(entityType: string = '', entityUid: string = ''): Promise<ITask[]> {
    const result: FetchResult<{ tasks: ITask[]}> = await graphqlClient.query({
        query: QUERY,
        variables: { entityType, entityUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }
    
    return removeFields(result.data.tasks, ['__typename']);
}
