import { FetchResult, gql } from 'apollo-boost';
import { AppConfig } from '../../../config/app-config';
import { joinPath } from '../../../helpers/path-helpers';
import { IScene } from '../../../models/scene';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';
import { s3FileUpload } from '../s3-file-upload';

const MUTATION = gql`
    mutation SceneSave($scene: SceneInput!, $file: Upload){
        scene_save (scene_data: $scene, file: $file) {
            uid
        }
    }
`;

export async function sceneSave(scene: Partial<IScene>, file?: File): Promise<string> {
    const result: FetchResult<{ scene_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: {
            scene,
            file: AppConfig.directUploadToS3 ? undefined : file,
        },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    if(file && AppConfig.directUploadToS3) {
        const newFileName = await s3FileUpload(
            joinPath(AppConfig.sceneFilePath, result.data.scene_save.uid),
            file,
        );

        scene.uid = result.data.scene_save.uid;
        scene.fileName = newFileName;

        await sceneSave(scene);
    }

    return result.data.scene_save.uid;
}
