import { faCopy, faEdit, faUser } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faArrowRight, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from "react-router-dom";
import { taskSave } from '../../../api/actions/task/task-save';
import { getAllTasks } from '../../../api/actions/task/tasks-get-all';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { Popup } from '../../../components/shared/popup/popup';
import { CustomSelect } from '../../../components/shared/select/custom-select';
import { Select } from '../../../components/shared/select/select';
import { useCurrentUser } from '../../../components/user/current-user-manager';
import { getAbsoluteUrl } from '../../../config/app-config';
import { compareText, onlyUnique } from '../../../helpers/array-helpers';
import { setToClipboard } from '../../../helpers/clipboar-helpers';
import { isNotNullOrEmpty } from '../../../helpers/common-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import { getRelativeLinkToEntity } from '../../../helpers/path-helpers';
import { UserRoles } from '../../../models/enums/user-roles-enum';
import { INarrative } from '../../../models/narrative';
import { ITask, TaskPriorities, TaskStatuses, TaskStatusesInfo } from '../../../models/task';
import { IUser } from '../../../models/user';
import { SelectUsersList } from '../../users/users/parts/select-users-list';
import { TaskEditor } from './task-editor';
import styles from './tasks-list.module.scss';

enum FilterRole {
    all = 'All',
    assignee = 'Assignee',
    creator = 'Creator',
}

export function TasksList(props: RouteComponentProps<{uid?: string}>) {

    const currentUser = useCurrentUser();

    const [filterNarrativeUid, setFilterNarrativeUid] = React.useState<string | undefined>();
    const [filterRole, setFilterRole] = React.useState<FilterRole | undefined>(FilterRole.all);
    const [filterUser, setFilterUser] = React.useState<IUser | undefined>(currentUser);

    const [loading, setLoading] = React.useState(true);
    const [tasks, setTasks] = React.useState<ITask[]>();
    const [editableTask, setEditableTask] = React.useState<ITask>();

    const statusesSequence = [
        TaskStatuses.newTask,
        TaskStatuses.notStarted,
        TaskStatuses.inProgress,
        TaskStatuses.done,
        TaskStatuses.review,
        TaskStatuses.closed,
    ]

    const loadData = async () => {
        setLoading(true);

        try {
            const tsks = await getAllTasks();
            setTasks(tsks);


            let taskUid = (new URL(window.location.href)).searchParams.get('uid');
            if(props.match.params.uid) {
                taskUid = props.match.params.uid;
            }

            if(editableTask?.uid) {
                setEditableTask(tsks.find(t => t.uid === editableTask.uid));
            }
            else if(isNotNullOrEmpty(taskUid)) {
                setEditableTask(tsks.find(t => t.uid === taskUid));
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }

        setLoading(false);
    };

    React.useEffect(() => {
        loadData();
    }, []);


    React.useEffect(() => {
        setFilterUser(currentUser);
    }, [currentUser])

    const narratives = React.useMemo(
        () => tasks?.map(t => t.narrative)
            .filter(isNotNullOrEmpty)
            .filter((n, i, arr) => onlyUnique(n, i, arr, (nr) => nr.uid))
            .sort((n1, n2) => compareText(n1, n2, (n) => n?.title)),
        [tasks],
    );


    const goToEntity = async (task: ITask, isNewWindow = false) => {
        const url = await getRelativeLinkToEntity(task.entityType, task.entityUid);
        if(isNewWindow) {
            window.open(url);
        }
        else {
            props.history.push(url);
        }
    }

    const applyFilter = (tsks?: ITask[]) => {
        if(filterNarrativeUid) {
            tsks = tsks?.filter(t => t.narrative?.uid === filterNarrativeUid);
        }

        if(filterRole && filterUser) {
            if(filterRole === FilterRole.all) {
                tsks = tsks?.filter(t => t.assignee?.uid === filterUser.uid || t.author.uid === filterUser.uid)
            }
            else if(filterRole === FilterRole.assignee) {
                tsks = tsks?.filter(t => t.assignee?.uid === filterUser.uid);
            }
            else if(filterRole === FilterRole.creator) {
                tsks = tsks?.filter(t => t.author?.uid === filterUser.uid);
            }
        }

        return tsks;
    }

    const getTasksByStatus = (tsks: ITask[] | undefined = undefined, status: TaskStatuses) => {

        tsks = applyFilter(tsks?.filter(t => t.status === status));

        const calc = (t: ITask) => t.deadlineAt
            ? Number.MAX_SAFE_INTEGER - moment(t.deadlineAt).valueOf()
            : (t.priority === TaskPriorities.high ? 1 : (t.priority === TaskPriorities.normal ? 0 : -1));

        tsks = tsks?.sort((t1, t2) => calc(t2) - calc(t1));

        return tsks;
    }

    const assignToMe = async (task: ITask) => {
        if(task.status === TaskStatuses.closed || !currentUser?.uid) return;

        setLoading(true);
        try {
            const modifiedTask: Partial<ITask> = {...task};
            modifiedTask.author = undefined;
            modifiedTask.assignee = { uid: currentUser?.uid } as IUser;
            await taskSave(modifiedTask);
            await loadData();
        }
        catch(err) { /** */ }
        setLoading(false);
    }

    const moveToStatus = async (task: ITask, status: TaskStatuses) => {
        if(task.status === TaskStatuses.closed || !statusesSequence.includes(status)) return;

        setLoading(true);
        try {
            const modifiedTask: Partial<ITask> = {...task};
            modifiedTask.status = status;
            modifiedTask.author = undefined;
            modifiedTask.assignee = undefined;
            await taskSave(modifiedTask);
            await loadData();
        }
        catch(err) { /** */ }
        setLoading(false);
    }

    const moveToPrevStatus = (task: ITask) => {
        const currPos = statusesSequence.indexOf(task.status);
        if(currPos <= 0) return;
        moveToStatus(task, statusesSequence[currPos - 1]).catch(() => {/** */});
    }

    const moveToNextStatus = (task: ITask) => {
        let currPos = statusesSequence.indexOf(task.status);
        if(currPos === -1) currPos = 0;
        moveToStatus(task, statusesSequence[currPos + 1]).catch(() => {/** */});
    }



    const renderTaskItem = (task: ITask, prevAllowed = true, nextAllowed = true) => (
        <div key={task.uid} className={styles.task}>
            <div className={styles.taskContent}>
                <div className={`${styles.priority} ${task.priority === TaskPriorities.high || task.deadlineAt ? styles.hight : (task.priority === TaskPriorities.low ? styles.low : '')}`}></div>
                {task.name}
                <div className={styles.entityName}>
                    {task.narrative?.title}{task.narrative && task.narrative?.title !== task.entityName ? ': ' : ''}
                    {task.narrative?.title !== task.entityName ? task.entityName : ''}
                </div>
                {task.deadlineAt && (<div className={styles.deadline}>Deadline: {moment(task.deadlineAt).format('ll')}</div>)}
                <div className={styles.author}>Author: {task.author.firstName} {task.author.lastName}</div>
                <div className={styles.assignee}>Assignee: {task.assignee?.firstName} {task.assignee?.lastName}</div>

                <div className={styles.mainButtons}>
                    {task.assignee?.uid !== currentUser?.uid && task.status !== TaskStatuses.closed
                        ? (
                            <LinkButton onClick={() => assignToMe(task)} className="green">
                                <FontAwesomeIcon icon={faUser} /> Assign to Me
                            </LinkButton>
                        )
                        : (<div></div>)
                    }
                    <LinkButton onClick={() => setEditableTask(task)} className="warn">
                        <FontAwesomeIcon icon={faEdit} /> View / Edit
                    </LinkButton>
                </div>

                <div className={styles.additionalButtons}>
                    <LinkButton onClick={() => setToClipboard(getAbsoluteUrl('tasks', task.uid))}>
                        <FontAwesomeIcon icon={faCopy} /> Copy Link
                    </LinkButton>
                    <LinkButton
                        onClick={() => goToEntity(task)}
                        onContextMenu={(ev) => { ev.preventDefault(); goToEntity(task, true); }}
                    >
                        <FontAwesomeIcon icon={faExternalLinkAlt} /> Go to Entity
                    </LinkButton>
                </div>
            </div>
            {(prevAllowed || nextAllowed) && (
                <div className={styles.moveButtons}>
                    {prevAllowed ? (
                        <LinkButton onClick={() => moveToPrevStatus(task)} className="gray">
                            <FontAwesomeIcon icon={faArrowLeft} /> Prev
                        </LinkButton>
                        ) : (<div></div>)
                    }

                    {nextAllowed ? (
                        <LinkButton onClick={() => moveToNextStatus(task)} className="gray">
                            Next <FontAwesomeIcon icon={faArrowRight} />
                        </LinkButton>
                        ) : (<div></div>)
                    }
                </div>
            )}
        </div>
    );

    return loading ? (<Loader />) :(<>
        <div className={styles.filters}>
            <div className={`${styles.filter} ${styles.filterNarrative}`}>
                <span>Narrative:</span>
                <Select<INarrative, string>
                    emptyTitle='All'
                    data={narratives || []}
                    titleExtractor={(n) => n?.title}
                    onChange={(e) => setFilterNarrativeUid(e.target.value)}
                    value={filterNarrativeUid}
                />
            </div>
            <div className={`${styles.filter} ${styles.filterRole}`}>
                <span>Role:</span>
                <Select<FilterRole, FilterRole>
                    data={[FilterRole.all, FilterRole.assignee, FilterRole.creator]}
                    onChange={(e) => setFilterRole(e.target.value)}
                    value={filterRole}
                />
            </div>
            { currentUser?.role === UserRoles.admin && (
                <div className={`${styles.filter} ${styles.filterUser}`}>
                    <span>User:</span>
                    <CustomSelect<IUser, { roles: UserRoles[], children?: React.ReactNode}>
                        component={SelectUsersList}
                        params={{ roles: [UserRoles.admin, UserRoles.editor ]}}
                        titleExtractor={(user?: IUser) => !user ? '' : `${user?.firstName} ${user?.lastName} [${user?.email}]`}
                        value={filterUser}
                        onChange={(e) => setFilterUser(e.target.value)}
                    />
                </div>
            )}
        </div>

        <div className={styles.board}>
            <div className={styles.boardColumn}>
                <div className={styles.borderColumnHeader}>{TaskStatusesInfo[TaskStatuses.newTask].statusText}</div>
                {
                    getTasksByStatus(tasks, TaskStatuses.newTask)?.map((t) => renderTaskItem(t, false))
                }
            </div>
            <div className={styles.boardColumn}>
                <div className={styles.borderColumnHeader}>{TaskStatusesInfo[TaskStatuses.notStarted].statusText}</div>
                {
                    getTasksByStatus(tasks, TaskStatuses.notStarted)?.map((t) => renderTaskItem(t))
                }
            </div>
            <div className={styles.boardColumn}>
                <div className={styles.borderColumnHeader}>{TaskStatusesInfo[TaskStatuses.inProgress].statusText}</div>
                {
                    getTasksByStatus(tasks, TaskStatuses.inProgress)?.map((t) => renderTaskItem(t))
                }
            </div>
            <div className={styles.boardColumn}>
                <div className={styles.borderColumnHeader}>{TaskStatusesInfo[TaskStatuses.done].statusText}</div>
                {
                    getTasksByStatus(tasks, TaskStatuses.done)?.map((t) => renderTaskItem(t))
                }
            </div>
            <div className={styles.boardColumn}>
                <div className={styles.borderColumnHeader}>{TaskStatusesInfo[TaskStatuses.review].statusText}</div>
                {
                    getTasksByStatus(tasks, TaskStatuses.review)?.map((t) => renderTaskItem(t))
                }
            </div>
            <div className={styles.boardColumn}>
                <div className={styles.borderColumnHeader}>{TaskStatusesInfo[TaskStatuses.closed].statusText}</div>
                {
                    getTasksByStatus(tasks, TaskStatuses.closed)?.map((t) => renderTaskItem(t, false, false))
                }
            </div>
        </div>



        {editableTask && (
            <Popup
                modal={true}
                title={(editableTask.uid ? 'Edit' : 'Add') + ' Task'}
                onClose={() => {
                    setEditableTask(undefined);
                }}
            >
                <TaskEditor
                    task={editableTask}
                    updateTask={setEditableTask}
                    onClose={() => setEditableTask(undefined)}
                    reload={loadData}
                    history={props.history}
                />
            </Popup>
        )}
    </>);
}
