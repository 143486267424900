import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getComment } from '../../api/actions/comment/comment-get';
import { MainLayout } from '../../components/layouts/main-layout';
import { LinkButton } from '../../components/shared/link-button/link-button';
import { Loader } from '../../components/shared/loader/loader';
import { getRelativeLinkToEntity } from '../../helpers/path-helpers';
import { SecuredPage } from '../base/secured-page';
import styles from './comment-redirect.module.scss';

export function CommentsRedirect(props: RouteComponentProps<{uid: string}>) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        goToEntity();
    }, []);

    const goToEntity = async () => {
        if(props.match.params.uid) {
            try {
                const comment = await getComment(props.match.params.uid);
                if(!comment) return;
                const url = await getRelativeLinkToEntity(comment.entityType, comment.entityUid);
                props.history.push(url);

                return;
            }
            catch(err) {
                //
            }
        }

        setLoading(false);
    }


    return props.match.params.uid && loading ? (<Loader />) : (
        <SecuredPage pageName="comments" {...props} >
            <MainLayout>
                <div className={styles.container}>
                    <div>Can not find requested information, try to find it manually.</div>
                    <div><LinkButton to={'/'}>Go to Home</LinkButton></div>
                </div>
            </MainLayout>
        </SecuredPage>
    )
}