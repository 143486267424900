
import { toast } from 'react-toastify';

export function setToClipboard(str: string) {
    const textArea = document.createElement("textarea");
    textArea.value = str;
    textArea.style.position="fixed";  //avoid scrolling to bottom
    textArea.style.top="0";
    textArea.style.left="0";
    textArea.style.fontSize="1px";
    textArea.style.height="1px";
    textArea.style.width="1px";
    textArea.style.background="transparent";
    textArea.style.border="none";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const result = document.execCommand('copy');
        document.body.removeChild(textArea);
        toast.info('Element has been successfully copied to clipboard');
        return result;
    } catch (err) {
        document.body.removeChild(textArea);
    }
    toast.error('Operation has been failed');
    return false;
}