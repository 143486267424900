import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { getAllNarratives } from '../../../../api/actions/narrative/narratives-get-all';
import { addNarrativeToUserGroup } from '../../../../api/actions/user-group/user-group-narrative-add';
import { removeNarrativeFromUserGroup } from '../../../../api/actions/user-group/user-group-narrative-remove';
import { Button } from '../../../../components/shared/button/button';
import { LinkButton } from '../../../../components/shared/link-button/link-button';
import { Popup } from '../../../../components/shared/popup/popup';
import { compareText } from '../../../../helpers/array-helpers';
import { INarrative } from '../../../../models/narrative';
import { IUserGroup } from '../../../../models/user-group';
import styles from './user-group-narratives-list.module.scss';


interface IProps {
    userGroup?: IUserGroup,
    reloadUserGroup(): void,
}

export function UserGroupNarrativesList(props: IProps) {

    const [allNarratives, setAllNarratives] = React.useState<INarrative[]>();
    const [narrativeSelectorPopupVisible, setNarrativeSelectorPopupVisible] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            setAllNarratives(await getAllNarratives(false, undefined));
        })().catch(() => {/** */});
    }, []);

    const addNarrative = React.useCallback((narrativeUid: string) => {
        (async () => {
            if(!props.userGroup?.uid) return;
            await addNarrativeToUserGroup(props.userGroup.uid, narrativeUid);
            props.reloadUserGroup();
        })().catch(() => {/** */});
    }, [props]);

    const removeNarrative = React.useCallback((narrativeUid: string) => {
        (async () => {
            if(!props.userGroup?.uid) return;
            await removeNarrativeFromUserGroup(props.userGroup.uid, narrativeUid);
            props.reloadUserGroup();
        })().catch(() => {/** */});
    }, [props]);

    return (
        <>
            <div className="list-title">
                <div>Narratives:
                    <strong>{ (props.userGroup?.narratives?.length) || 0 }</strong>
                </div>
                <div>
                    <Button
                        className="orange"
                        onClick={() => setNarrativeSelectorPopupVisible(true) }
                    >
                        <FontAwesomeIcon icon={faPlus} /> <span>Add Narrative</span>
                    </Button>
                </div>
            </div>
            { !!props.userGroup?.narratives?.length && (
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.userGroup?.narratives?.sort((n1, n2) => compareText(n1, n2, (n) => n.title)).map(((narrative, i) => (
                                <tr key={narrative.uid}>
                                    <td>
                                        <div className={styles.markContainer}>
                                            <div className={`${styles.mark} ${narrative.available ? '' : styles.disabled}`}>&nbsp;</div>
                                            <div className={styles.title}>
                                                {i + 1}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {narrative.title}
                                    </td>
                                    <td>
                                        {narrative.description}
                                    </td>
                                    <td>
                                        <LinkButton onClick={() => removeNarrative(narrative.uid)}>
                                            <FontAwesomeIcon icon={faEdit} /> Delete
                                        </LinkButton>
                                    </td>
                                </tr>
                            )))
                        }
                    </tbody>
                </table>
            )}




            { narrativeSelectorPopupVisible && (
                <Popup
                    title="Add Narrative"
                    onClose={() => setNarrativeSelectorPopupVisible(false)}
                    buttons={[
                        {
                            title: <><FontAwesomeIcon icon={faTimes} /> <span>Close</span></>,
                            className: 'gray',
                            onClick: () => setNarrativeSelectorPopupVisible(false),
                        },
                    ]}
                >
                    <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Title</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allNarratives?.sort((n1, n2) => compareText(n1, n2, (n) => n.title)).map(((narrative, i) => (
                                    <tr key={narrative.uid}>
                                        <td>
                                            <div className={styles.markContainer}>
                                                <div className={`${styles.mark} ${narrative.available ? '' : styles.disabled}`}>&nbsp;</div>
                                                <div className={styles.title}>
                                                    { i + 1 }
                                                </div>
                                            </div>
                                        </td>
                                        <td>{narrative.title}</td>
                                        <td>
                                            { props.userGroup?.narratives?.every(n => n.uid !== narrative.uid) && (
                                                <LinkButton onClick={() => addNarrative(narrative.uid)}>
                                                    <FontAwesomeIcon icon={faPlus} /> Add
                                                </LinkButton>
                                            )}
                                        </td>
                                    </tr>
                                )))
                            }
                        </tbody>
                    </table>

                </Popup>
            )}
        </>
    );
}
