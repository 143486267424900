import * as React from 'react';

export function CKEditorBrowseHandler() {

    React.useEffect(() => {
        window.opener.CKEDITOR_browseCallBack = undefined;
        const urlParts: any = window.location.href?.match(/[?&]([^=]+)=([^&]*)/ig)?.reduce((obj, prop) => {
                const parts = prop.replace(/[?&]/ig, '').split('=');
                obj[parts[0]] = parts[1];
                return obj;
            }, {} as any);
        const callFunction: (n: number, url: string) => void = window.opener.CKEDITOR.tools.callFunction;
        window.opener.CKEDITOR_browseCallBack = (url: string) => {
            callFunction( urlParts.CKEditorFuncNum, url );
        };
        if(window.opener.CKEDITOR_openFileBrowser) {
            window.opener.CKEDITOR_openFileBrowser();
        }
        window.close();
    }, []);

    return (<h1>This window should be closed automaticaly!!!</h1>);
}
