import { FetchResult, gql } from 'apollo-boost';
import { IConcept } from '../../models/concept';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
    {
        concepts {
            uid,
            name,
            description,
            parent,
            index,
            icon,
        }
    }
`;

export async function getAllConcepts(): Promise<IConcept[]> {
    const result: FetchResult<{ concepts: IConcept[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.concepts;
}
