import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IScene } from '../../../models/scene';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetScene($uid: String!){
        scene(uid: $uid) {
            uid,
            description,
            start,
            end,
            fileName: file_name,
            fileUrl: file_url,
            available,
            transcodingState: transcoding_state

            episode {
                uid
            }

            narrative {
                uid,
                title,
            }

            emotionEpisodes: emotion_episodes {
                uid,
                description,
                start,
                end,
                absoluteStart: absolute_start,
                available,
                status
            },
        }
    }
`;

export async function getScene(uid: string): Promise<IScene> {
    const result: FetchResult<{ scene: IScene }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.scene, ['__typename']);
}
