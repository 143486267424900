import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation EmotionEpisodeLink($uid: String!){
        video_service_locator_set (uid: $uid) {
            url
        }
    }
`;

export async function emotionEpisodeLink(uid: string): Promise<string> {
    const result: FetchResult<{ video_service_locator_set: { url: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.video_service_locator_set.url;
}
