import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../../components/layouts/main-layout';
import { SecuredPage } from '../base/secured-page';
import styles from './home-page.module.scss';

export function HomePage(props: RouteComponentProps) {

    return (
        <SecuredPage pageName="home" {...props} >
            <MainLayout>
                <div className={styles.container}>
                    Administrative Suite
                </div>
            </MainLayout>
        </SecuredPage>
    );
}
