import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IComment } from '../../../models/comment';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetComment($uid: String!){
        comment(uid: $uid) {
            uid,
            html,
            entityUid: entity_uid,
            entityType: entity_type,
        }
    }
`;

export async function getComment(uid: string): Promise<IComment> {
    const result: FetchResult<{ comment: IComment }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.comment, ['__typename']);
}
