import { FetchResult, gql } from 'apollo-boost';
import { IUserGroup } from '../../../models/user-group';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserGroupSave($userGroup: UserGroupSaveInput!){
        user_group_save (user_group_data: $userGroup) {
            uid
        }
    }
`;

export async function userGroupSave(userGroup: Partial<IUserGroup>): Promise<string> {
    const result: FetchResult<{ user_group_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { userGroup: {
            uid: userGroup.uid,
            group_type: userGroup.groupType,
            title: userGroup.title,
            address: userGroup.address,
            city: userGroup.city,
            state: userGroup.state,
            zipcode: userGroup.zipCode,
            description: userGroup.description,
            max_age_rating: userGroup.maxAgeRating,
            reward_schema: userGroup.rewardSchema,
            user_plan: userGroup.userPlan,
            invite_code_expiration_date: userGroup.inviteCodeExpirationDate,
            parent_group: userGroup.parent,
            domain: userGroup.domain,
            max_users: userGroup.maxUsers,
            default: userGroup.default,
            sharing_enabled: userGroup.sharingEnabled,
            HIPAA_enforced: userGroup.HIPAAEnforced,
            journal_prompt: userGroup.journalPrompt,
            journal_submissions: userGroup.journalSubmissions,
            activity_mode_switching_allowed: userGroup.activityModeSwitchingAllowed,
            email_confirmation_required: userGroup.emailConfirmationRequired,
            notification_sent_on_add: userGroup.notificationSentOnAdd,
            notification_to_admin_on_join: userGroup.notificationToAdminOnJoin,
            progress_sync: userGroup.progressSync,
            limit_content_by_age: userGroup.limitContentByAge,
            start_date: userGroup.startDate,
            end_date: userGroup.endDate,
            unlisted: userGroup.unlisted,
            domain_for_confirmation_only: userGroup.domainForConfirmation,
            educator_confirmation_required: userGroup.educatorConfirmationRequired,
            allows_interstitials: userGroup.allowsInterstitials,
            allows_interstitials_only: userGroup.allowsInterstitialsOnly,
        } },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_group_save.uid;
}
