import { FetchResult, gql } from 'apollo-boost';
import { IAchievement } from '../../../models/achievement';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation AchievementSave($achievement: AchievementInput!, $iconFile: Upload){
        achievement_save (input_data: $achievement, iconFile: $iconFile) {
            uid
        }
    }
`;

export async function achievementSave(achievement: Partial<IAchievement>, iconFile?: File): Promise<string> {
    const result: FetchResult<{ achievement_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { achievement: {
            uid: achievement.uid,
            name: achievement.name,
            description: achievement.description,
            achievement_type: achievement.achievementType,
            icon_file_name: achievement.iconFileName,
            index: achievement.index,
        }, iconFile },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.achievement_save?.uid;
}
