import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { EmotionEpisodeContentTagEditor } from './emotion-episode-content-tag-editor';
import { EmotionEpisodeContentTagsList } from './emotion-episode-content-tags-list';


export function EmotionEpisodeTagsManagementPage(props: RouteComponentProps) {

    return (<>
        <Route exact
            path={props.match.url} component={EmotionEpisodeContentTagsList}
        />
        <Route exact
            path={`${props.match.url}/:uid`} component={EmotionEpisodeContentTagEditor}
        />
    </>);
}
