import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IEmotionCategory } from '../../../models/emotion-category';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetEmotionCategory($uid: String!){
        emotion_category(uid: $uid) {
            uid,
            label,
            theme,
            definition,
            color,
            adjective,
            valence,
            parent,
            minAgeRating: min_age_rating,
            imgFileName: img_file_name,
            actionTendencies: action_tendencies {
                uid,
                label,
                imgFileName: img_file_name
            }
            wrongActionTendencies: wrong_action_tendencies {
                uid,
                label,
                imgFileName: img_file_name
            }
            regulationStrategies: regulation_strategies {
                uid,
                description,
                imgFileName: image_file_name,
                minAgeRating: min_age_rating,
                maxAgeRating: max_age_rating
            }
        }
    }
`;

export async function getEmotionCategory(uid: string): Promise<IEmotionCategory> {
    const result: FetchResult<{ emotion_category: IEmotionCategory }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.emotion_category, ['__typename']);
}
