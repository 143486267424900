import { FetchResult, gql } from 'apollo-boost';
import { IEmotionEpisode } from '../../../models/emotion-episode';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation EmotionEpisodeSave($emotionEpisode: EmotionEpisodeInput!, $audioFile: Upload,){
        emotion_episode_save (emotion_episode_data: $emotionEpisode, audio_file: $audioFile) {
            uid
        }
    }
`;

export async function emotionEpisodeSave(emotionEpisode: Partial<IEmotionEpisode>, audioFile?: File): Promise<string> {
    const result: FetchResult<{ emotion_episode_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { emotionEpisode : {
            uid: emotionEpisode.uid,
            description: emotionEpisode.description,
            content_tags: emotionEpisode.contentTags,
            start: emotionEpisode.start,
            end: emotionEpisode.end,
            clip_start: emotionEpisode.clipStart,
            clip_end: emotionEpisode.clipEnd,
            available: emotionEpisode.available,
            status: emotionEpisode.status,
            scene: emotionEpisode.scene,
            interstitial_url: emotionEpisode.interstitialURL,
        }, audioFile},
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.emotion_episode_save.uid;
}
