import { FetchResult, gql } from 'apollo-boost';
import { IUnit } from '../../../models/unit';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query Unit($unitUid: String!) {
        unit(unitUid: $unitUid) {
            uid,
            name,
            description,
            teaser,
            type,
            educatorGuide: educator_guide,
            finalEmotionModelState: final_emotion_model_state,
            iconFileName: icon_file_name,
            index,
            available,
            edited,
            worksheet,

            curriculum {
                uid,
                name,
            },

            unitSections: unit_sections {
                uid,
                name,
                index,
                description,
                type,
                available,
                edited
            },

            conceptsCovered: concepts_covered {
                uid,
                name,
                description,
            },
        }
    }
`;

export async function getUnit(unitUid: string): Promise<IUnit> {
    const result: FetchResult<{ unit: IUnit }> = await graphqlClient.query({
        query: QUERY,
        variables: { unitUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.unit;
}
