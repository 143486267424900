import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation ParticipantAdd($emotionEpisodeUid: String!, $characterUid: String!){
        participant_add (emotion_episode_uid: $emotionEpisodeUid, character_uid: $characterUid) {
            success
        }
    }
`;

export async function participantAdd(emotionEpisodeUid: string, characterUid: string): Promise<{ success: boolean }> {
    const result: FetchResult<{ participant_add: { success: boolean }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { emotionEpisodeUid, characterUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.participant_add;
}
