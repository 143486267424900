
import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation NarrativeTopicAdd($narrativeUid: String!, $topicUid: String!){
        narrative_topic_add (narrative_uid: $narrativeUid, topic_uid: $topicUid) {
            success
        }
    }
`;

export async function narrativeTopicAdd(narrativeUid: string, topicUid: string): Promise<{ success: boolean }> {
    const result: FetchResult<{ participant_add: { success: boolean }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { narrativeUid, topicUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.participant_add;
}
