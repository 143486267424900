import { FetchResult, gql } from 'apollo-boost';
import { IVideoService } from '../../../models/video-service';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation VideoServiceSave($videoService: VideoServiceInput!){
        video_service_save (input_data: $videoService) {
            uid
        }
    }
`;

export async function videoServiceSave(videoService: Partial<IVideoService>): Promise<string> {
    const result: FetchResult<{ video_service_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { videoService: {
            uid: videoService.uid,
            identifier: videoService.identifier,
            name: videoService.name,
            url: videoService.url,
            coming_soon: videoService.comingSoon,
            platforms: videoService.platforms,
            internal: videoService.internal
        } },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.video_service_save?.uid;
}
