import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getRegulationStrategyDefaults } from '../../../api/actions/regulation-strategy/regulation-strategy-defaults-get';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { compareText, repeat } from '../../../helpers/array-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import { IRegulationStrategy } from '../../../models/regulation-strategy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import { getRegulationStrategyFilePath } from '../../../config/app-config';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/shared/button/button';
import styles from './regulation-strategy-defaults.module.scss';


export function RegulationDefaultsList(props: RouteComponentProps) {
    const [strategies, setStrategies] = useState<IRegulationStrategy[]>([]);
    const [parents, setParents] = useState<string[]>([]);

    const loadData = React.useCallback(async () => {
        try {
            const regs = await getRegulationStrategyDefaults();
            setStrategies(buildTree(regs));
            const ps = regs.filter((st)=> !st.layParentCategory).map((st) => st.uid);
            setParents(ps);
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history]);

    useEffect(() => {
        loadData();
    }, [loadData]);


    const buildTree = (elements: IRegulationStrategy[]) => {
        if(!elements) return elements;

        const roots = elements.filter(ec => !ec.layParentCategory)
            .sort((el1, el2) => compareText(el1, el2, (obj) => obj && obj.description));

        roots.forEach(el => buildTreeNode(el, elements));

        return roots;
    }

    const buildTreeNode = (element: IRegulationStrategy, elements: IRegulationStrategy[]) => {
        element.children = elements.filter(ec => ec.layParentCategory && ec.layParentCategory.includes(element.uid))
            .sort((el1, el2) => compareText(el1, el2, (obj) => obj && obj.description));

            element.children.forEach(el => buildTreeNode(el, elements));

    }

    const printLevel = (elements?: IRegulationStrategy[], level = 0) => elements
        && elements.map((strategy => (
            <React.Fragment key={strategy.uid}>
                <tr key={strategy.uid}>
                    <td>
                        {!!level && repeat(level, () => (
                            <span>
                                &raquo;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        ))}
                        {strategy.description}
                    </td>
                    <td>
                        {(strategy.minAgeRating !== undefined && strategy.minAgeRating >= 0) ? strategy.minAgeRating + '' : '' }
                    </td>   
                    <td>
                        {strategy.maxAgeRating}
                    </td>                    

                    <td>
                        {strategy.color}
                    </td>
                    <td>
                    {strategy.imgFileName ? (
                    <img
                        src={getRegulationStrategyFilePath(
                        strategy.uid,
                        strategy.imgFileName
                        )}
                        alt={strategy.description}
                        style={{ width: '50px', height: '50px' }}
                      />
                    ) : (
                      'No Image'
                    )}
                  </td>

                    <td>
                    <Link
                        className={styles.editLink}
                    to={{
                        pathname: '/libraries/regulation-defaults/_',
                        state: { strategy, parents },
                    }}
                    >
                    <FontAwesomeIcon
                        icon={faEdit}
                    />{' '}
                    Edit
                    </Link>

                    </td>
                </tr>
                {printLevel(strategy.children, level + 1)}
            </React.Fragment>
        )));


    return !strategies ? null : (
        <>
            <AddBreadcrumbsItem
                title="Reg Strategy Defaults"
                url={'/libraries/regulation-defaults'}
            />
            <Button to={{
                            pathname: '/libraries/regulation-defaults/_',
                            state: {
                                parents: parents,
                            },
                        }} className="orange">
                Add Strategy
            </Button>
            <table cellPadding="0" cellSpacing="0" className={`list`}>
                <thead>
                    <tr>
                        <th>Strategy</th>
                        <th>Min Age</th>
                        <th>Max Age</th>
                        <th>Color</th>
                        <th>Icon</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    { printLevel(strategies) }
                </tbody>
            </table>
        </>
    );
}
