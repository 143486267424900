import { FetchResult, gql } from 'apollo-boost';
import { IRegulationStrategy } from '../../../models/regulation-strategy';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation RegulationStrategySave($regulationStrategy: RegulationStrategyInput!, $imageFile: Upload){
        regulation_strategy_save (regulation_strategy_data: $regulationStrategy, imageFile: $imageFile) {
            uid
        }
    }
`;

export async function regulationStrategySave(regulationStrategy: Partial<IRegulationStrategy>, imageFile?: File): Promise<string> {
    const result: FetchResult<{ regulation_strategy_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { regulationStrategy, imageFile },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.regulation_strategy_save.uid;
}
