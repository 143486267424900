import { faArrowLeft, faLock, faTrashAlt, faUnlock, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { videoServiceDelete } from '../../../api/actions/video-service/video-service-delete';
import { videoServiceDisable } from '../../../api/actions/video-service/video-service-disable';
import { videoServiceEnable } from '../../../api/actions/video-service/video-service-enable';
import { videoServiceApiKeyCreate } from '../../../api/actions/video-service/video-service-api-key-create';
import { getVideoService } from '../../../api/actions/video-service/video-service-get';
import { videoServiceSave } from '../../../api/actions/video-service/video-service-save';
import { Block } from '../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { CheckBoxWrapper } from '../../../components/shared/checkbox/checkbox-wrapper';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { ActionPopup } from '../../../components/shared/popup/action-popup';
import { basePerformError } from '../../../helpers/error-helpers';
import { getParentPath, joinPath } from '../../../helpers/path-helpers';
import { IVideoService } from '../../../models/video-service';
import { IDictionary } from '../../../types/dictionary';
import { PlatformTypes} from '../../../models/enums/platform-types';
import { SelectField } from '../../../components/shared/select/select';
import styles from './video-service-editor.module.scss';


enum FormFields {
    identifier = 'identifier',
    name = 'name',
    url = 'url',
    comingSoon = 'comingSoon',
    platforms = 'platforms',
    internal = 'internal'
}

interface IForm {
    [FormFields.identifier]: string,
    [FormFields.name]: string,
    [FormFields.url]: string,
    [FormFields.comingSoon]?: boolean,
    [FormFields.platforms]?: string[],
    [FormFields.internal]?: boolean
}

export function VideoServiceEditor(props: RouteComponentProps<{uid: string}>) {

    const [loading, setLoading] = useState(false);
    const [generateApiKeyDialogVisible, setGenerateApiKeyDialogVisible] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [videoService, setVideoService] = useState<IVideoService>();
    const [addNewPlatform, setAddNewPlatform] = useState<string>();

    const loadData = useCallback(async (uid?: string) => {
        try {
            if(uid || props.match.params.uid !== '_') {
                setVideoService(await getVideoService(uid || props.match.params.uid));
            }
            else {
                setVideoService({} as IVideoService);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData().catch(() => {/** */});
    }, []);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newVideoService: Partial<IVideoService> = {
                uid: videoService?.uid,
                identifier: values.identifier,
                name: values.name,
                url: values.url,
                comingSoon: values.comingSoon,
                platforms: values.platforms,
                internal: values.internal 
            };
            
            const uid = await videoServiceSave(newVideoService);
            if(!videoService?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            }
            await loadData(uid);
            toast.success('Item has been successfully saved');
        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            identifier: Yup.string().label('Identifier').trim().required().min(2).max(50)
                .matches(/^[a-z0-9_]+$/, 'Allowed only a-z letters in lowercase and symbol underscore'),
            name: Yup.string().label('Name').trim().required().min(2).max(50),
            url: Yup.string().label('URL').trim().required().url(),
        });
    };

    const enableVideoService = async () => {
        if(!videoService?.uid) return;
        try {
            await videoServiceEnable(videoService.uid);
            setVideoService(undefined);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const disableVideoService = async () => {
        if(!videoService?.uid) return;
        try {
            await videoServiceDisable(videoService.uid);
            setVideoService({...videoService, available: false });
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const deleteVideoService = async () => {
        if(!videoService?.uid) return;
        await videoServiceDelete(videoService?.uid);
        setDeleteDialogVisible(false);
    };


    const generateApiKey = () => {
        if(!videoService?.uid) return;

        if(!videoService?.apiKey) handleApiKeyGeneration()
        else {
            //open popup
            //console.log("opening generation popup");
            setGenerateApiKeyDialogVisible(true);
        }
    }

    const handleApiKeyGeneration = async () => {
        
        if(!videoService?.uid) return;

        try {
            const result = await videoServiceApiKeyCreate(videoService?.uid);
            if(result) {
                toast.success('Api Key has been successfully generated');
                //reload object
                await loadData();
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }

    }

    const renderForm = ({ errors, touched }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>

                    <div className="form-buttons">
                        { videoService?.uid && !videoService.available && (
                            <LinkButton onClick={enableVideoService}><FontAwesomeIcon icon={faUnlock} /> Enable</LinkButton>
                        )}
                        { videoService?.uid && videoService.available && (
                            <LinkButton onClick={disableVideoService}><FontAwesomeIcon icon={faLock} /> Disable</LinkButton>
                        )}
                        { videoService?.uid && (
                            <LinkButton onClick={() => setDeleteDialogVisible(true)} className="red"><FontAwesomeIcon icon={faTrashAlt} /> Delete</LinkButton>
                        )}
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Identifier
                            </div>
                            <Field type="text" name={FormFields.identifier} />
                        </label>
                        <div className="errors">{touched.identifier && errors.identifier}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name
                            </div>
                            <Field type="text" name={FormFields.name} />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                URL
                            </div>
                            <Field type="text" name={FormFields.url} />
                        </label>
                        <div className="errors">{touched.url && errors.url}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Platforms
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.platforms}
                                data={Object.keys(PlatformTypes).map(el => ({
                                    uid: PlatformTypes[el],
                                    name: PlatformTypes[el].toUpperCase(),
                                }))}
                                multiSelect={true}
                                onAddNew={setAddNewPlatform}
                            />
                        </label>
                        <div className="errors">{touched.platforms && errors.platforms}</div>
                    </div>

                    <div className="form-item">
                            <label>
                                <div className="form-label">
                                    &nbsp;
                                </div>
                                <CheckBoxWrapper label="Internal">
                                    <Field type="checkbox" name={FormFields.internal} />
                                </CheckBoxWrapper>
                            </label>
                            <div className="errors">{touched.internal && errors.internal}</div>
                    </div>

                    {!videoService?.available && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    &nbsp;
                                </div>
                                <CheckBoxWrapper label="Coming Soon">
                                    <Field type="checkbox" name={FormFields.comingSoon} />
                                </CheckBoxWrapper>
                            </label>
                            <div className="errors">{touched.comingSoon && errors.comingSoon}</div>
                        </div>
                    )}
            

                    <div className="form-buttons">
                        <Button className="gray" to={getParentPath(props.match.url, 1)}>
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>Save</span></Button>)
                        }
                    </div>

                    <div className="form-item">
                        
                        <LinkButton onClick={generateApiKey} ><FontAwesomeIcon icon={faPlus} /> Generate New Api Key</LinkButton>
                        <div>
                            API KEY: {videoService?.apiKey ? videoService?.apiKey : 'None'}
                        </div>
                    </div>

                </Form>
            </div>
        );
    };

    return !videoService ? null : (<>
        <Block className={styles.editor}>

            <AddBreadcrumbsItem
                title="Video Services"
                url={'/libraries/video-services'}
            />
            <AddBreadcrumbsItem
                title={videoService.uid ? `Edit: ${videoService.name}` : 'Add Video Service'}
                url={props.match.url}
            />
            <Formik
                initialValues={{
                    identifier: videoService?.identifier || '',
                    name: videoService?.name || '',
                    url: videoService?.url || '',
                    comingSoon: !!videoService.comingSoon,
                    platforms: videoService?.platforms || [],
                    internal: !!videoService.internal
                }}
                validationSchema={getValidationSchema}
                onSubmit={handleSubmit}
            >
                {renderForm}
            </Formik>

        </Block>

        {deleteDialogVisible && (
            <DeleteQuestionPopup
                title="Video Service Deletion"
                question={<>Are you sure want to delete Video Service?</>}
                onClose={() => setDeleteDialogVisible(false)}
                onDelete={deleteVideoService}
                onFinish={() => { props.history.push(getParentPath(props.match.url, 1)) }}
            />
        )}

        {generateApiKeyDialogVisible && (
            <ActionPopup
                title="Generate Api Key"
                question={<>Are you sure want to generate a new Api Key?</>}
                onClose={() => setGenerateApiKeyDialogVisible(false)}
                onAction={handleApiKeyGeneration}
                //onFinish={() => { props.history.push(getParentPath(props.match.url, 1)) }}
            />
        )}

    </>);
}
