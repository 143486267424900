import { faArrowLeft, faEdit, faLock, faPlus, faTrashAlt, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { toast } from 'react-toastify';
import { episodeDelete } from '../../../../../../api/actions/episode/episode-delete';
import { episodeDisable } from '../../../../../../api/actions/episode/episode-disable';
import { episodeEnable } from '../../../../../../api/actions/episode/episode-enable';
import { Button } from '../../../../../../components/shared/button/button';
import { LinkButton } from '../../../../../../components/shared/link-button/link-button';
import { Popup } from '../../../../../../components/shared/popup/popup';
import { compareText } from '../../../../../../helpers/array-helpers';
import { basePerformError } from '../../../../../../helpers/error-helpers';
import { IEpisode } from '../../../../../../models/episode';
import { INarrative } from '../../../../../../models/narrative';
import styles from '../../narrative-editor.module.scss';

interface IProps {
    narrative: INarrative,
    history: any,
    addEpisode(): void,
    editEpisode(episodeUid: string): void,
    reloadData(): Promise<void>,
}


export function EpisodesManagement(props: IProps) {

    const [episodeForDelete, setEpisodeForDelete] = React.useState<IEpisode>();

    const enableEpisode = async (uid: string) => {
        try {
            await episodeEnable(uid);
            await props.reloadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const disableEpisode = async (uid: string) => {
        try {
            await episodeDisable(uid);
            await props.reloadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    return (<>
        <div className="list-title">
            <div>Episodes: <strong>{ (props.narrative.episodes && props.narrative.episodes.length) || 0}</strong></div>
            <div>
                <Button className="orange" onClick={props.addEpisode}>
                    <FontAwesomeIcon icon={faPlus} /> <span>Add Episode</span>
                </Button>
            </div>
        </div>
        { props.narrative.episodes && !!props.narrative.episodes.length && (
            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <thead>
                    <tr>
                        <th>Episode</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.narrative.episodes
                        .sort((e1, e2) => compareText(e1, e2, (e) => `${e.season}${e.numberInSeason}${e.name}`))
                        .map((e: IEpisode) => (
                            <tr key={e.uid}>
                                <td>
                                    <div className={styles.markContainer}>
                                        <div className={`${styles.mark} ${e.available ? '' : styles.disabled}`}>&nbsp;</div>
                                        <div className={styles.title}>
                                            Season {e.season}: Episode: {e.numberInSeason}: {e.name}
                                        </div>
                                    </div>
                                </td>
                                <td>{e.status?.toUpperCase()}</td>
                                <td>
                                    <LinkButton onClick={() => props.editEpisode(e.uid)}>
                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                    </LinkButton><br />
                                    <LinkButton
                                        className="red"
                                        onClick={() => { setEpisodeForDelete(e); }}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                    </LinkButton><br />
                                    { !e.available && (<>
                                        <LinkButton onClick={() => enableEpisode(e.uid)}>
                                            <FontAwesomeIcon icon={faUnlock} /> Enable
                                        </LinkButton><br />
                                    </>)}
                                    { e.available && (<>
                                        <LinkButton onClick={() => disableEpisode(e.uid)}>
                                            <FontAwesomeIcon icon={faLock} /> Disable
                                        </LinkButton><br />
                                    </>)}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )}



        { episodeForDelete && (
            <Popup
                modal={true}
                className="red"
                title="Delete Episode"
                onClose={() => {
                    props.reloadData();
                    setEpisodeForDelete(undefined);
                }}
                buttons={[
                    {
                        title: <><FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span></>,
                        className: 'gray',
                        onClick: () => setEpisodeForDelete(undefined),
                    },
                    {
                        title: <><span>Delete</span> <FontAwesomeIcon icon={faTrashAlt} /></>,
                        className: 'red',
                        onClick: async () => {
                            const result = await episodeDelete(episodeForDelete.uid);
                            if(result.success) {
                                props.reloadData().catch(() => {/** */});
                                toast.success('Item has been successfully delete');
                                setEpisodeForDelete(undefined);
                            }
                        },
                    },
                ]}
            >
                Are you sure want to delete episode?<br />
                <br />
                Season: <strong>{episodeForDelete.season}</strong><br />
                Episode: <strong>{episodeForDelete.name}</strong>
            </Popup>
        )}
    </>)
}
