import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IListResult } from '../../../models/list-result';
import { IAnswer } from '../../../models/answer';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

function getQuery() {

    return gql`
        query FlaggedResponsesGetPaged($page: Int, $searchText: String, $status: String, $pageSize: Int) {
            responses_flagged_paged(page: $page, search_text: $searchText, status: $status, page_size: $pageSize) {
                count,
                page,
                pageSize: page_size,

                data {
                    uid,
                    flagStatus: flag_status,
                    answer
                }
            }
        }
    `;
}

export async function getFlaggedAnswersPaged(params: {
    page: number,
    searchText?: string,
    status?: string,
    pageSize?: number,
}): Promise<IListResult<IAnswer>> {
    const result: FetchResult<{ responses_flagged_paged: IListResult<IAnswer> }> = await graphqlClient.query({
        query: getQuery(),
        variables: {
            page: params.page,
            searchText: params.searchText,
            status: params.status,
            pageSize: params.pageSize || 20,
        },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.responses_flagged_paged, ['__typename']);
}
