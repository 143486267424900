import { getFullFileName } from '../../helpers/path-helpers';
import { s3SignUploadRequet } from './s3-sign-upload-request';

export async function s3FileUpload(path: string, file: File): Promise<string> {
    const signedRequestParams = await s3SignUploadRequet(path, file.name);
    const newFileName = getFullFileName(signedRequestParams.fields.key);

    const formData = new FormData();
    Object.keys(signedRequestParams.fields).forEach((field: string) => {
        formData.append(field, signedRequestParams.fields[field]);
    });
    formData.append('file', file);

    const response = await fetch(
        signedRequestParams.url,
        {
            method: 'POST',
            cache: 'no-cache',
            mode: 'no-cors',
            credentials: 'same-origin',
            referrer: 'no-referrer',
            body: formData,
        },
    );

    return newFileName;
}
