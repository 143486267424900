import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IEmotionEpisodeContentTag } from '../../../models/emotion-episode-content-tag';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query {
        emotion_episode_content_tags {
            uid,
            name,
            tagType: tag_type,
        }
    }
`;

export async function getAllEmotionEpisodeContentTags(): Promise<IEmotionEpisodeContentTag[]> {
    const result: FetchResult<{ emotion_episode_content_tags: IEmotionEpisodeContentTag[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.emotion_episode_content_tags, ['__typename']);
}
