import { FetchResult, gql } from 'apollo-boost';
import { IBelief } from '../../../models/belief';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation BeliefSave($belief: BeliefInput!){
        belief_save (belief_data: $belief) {
            uid
        }
    }
`;

export async function beliefSave(belief: Partial<IBelief>): Promise<string> {
    const result: FetchResult<{ belief_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { belief },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.belief_save.uid;
}
