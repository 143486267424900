import { FetchResult, gql } from 'apollo-boost';
import { IGoal } from '../../../models/goal';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query RegulationGoalsByParticipant($uid: String!){
        regulation_goals_by_participant(uid: $uid) {
            uid,
            description
        }
    }
`;

export async function getRegulationGoalsByParticipant(participantUid: string): Promise<IGoal[]> {
    const result: FetchResult<{ regulation_goals_by_participant: IGoal[]}> = await graphqlClient.query({
        query: QUERY,
        variables: { uid: participantUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.regulation_goals_by_participant;
}
