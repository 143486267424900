import { IUserPlan } from '../../../models/user-plan';
import { IAchievement } from '../../../models/achievement';
import { getAchievementFilePath } from '../../../config/app-config';
import {userPlanAddCertification } from '../../../api/actions/user-plan/user-plan-add-certification';
import { userPlanRemoveCertification } from '../../../api/actions/user-plan/user-plan-remove-certification';
import { faPlus, faTrashAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { joinPath } from '../../../helpers/path-helpers';
import { Button } from '../../../components/shared/button/button';
import { compareText } from '../../../helpers/array-helpers';
import { Popup } from '../../../components/shared/popup/popup';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { toast } from 'react-toastify';
import * as React from 'react';
import styles from './plan-certification-list.module.scss';

interface IProps {
    plan: IUserPlan,
    certifications?: IAchievement[],
    reloadData(): Promise<void>,
}

export function PlanCertificationList(props: IProps) {


        const [certificateToDelete, setCertificateToDelete] = React.useState<IAchievement>();
        const [planSelectVisible, setPlanSelectVisible] = React.useState(false);
        const [newCertificationUid, setNewCertificationUid] = React.useState<string | undefined>(undefined);

        return (<>
        <div className="list-title">
            <div>Certifications: <strong>{ (props.plan.certifications && props.plan.certifications.length) || 0 }</strong></div>
            <div>
                <Button className="orange" onClick={() => { setPlanSelectVisible(true); }}>
                    <FontAwesomeIcon icon={faPlus} /> <span>Add Certification</span>
                </Button>
            </div>
        </div>

        { props.plan.certifications && !!props.plan.certifications.length && (
            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <thead>
                    <tr>
                        <th>Achievement Index</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.plan.certifications
                        .sort((c1, c2) => compareText(c1, c2, (achievement) => achievement.name))
                        .map((achievement: IAchievement) => (
                      <tr key={achievement.uid}>
                            <td>
                                <div className={styles.markContainer}>
                                    <div className={`${styles.mark} ${achievement.available ? '' : styles.disabled}`}>&nbsp;</div>
                                    <div className={styles.title}>
                                        {achievement.index}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <img
                                    alt={achievement.name}
                                    className={styles.icon}
                                    src={joinPath(getAchievementFilePath(achievement.uid), achievement.iconFileName)}
                                />
                            </td>
                            <td>
                                {achievement.name}
                            </td>
                            <td>
                                    <LinkButton
                                        className="red"
                                        onClick={() => { setCertificateToDelete(achievement); }}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                    </LinkButton><br />
                            </td>
                        </tr>
                        ))
                    }
                </tbody>
            </table>
            )}

        { certificateToDelete && (
            <DeleteQuestionPopup
                title="Remove Certification?"
                question={<>Are you sure want to remove this certification: {certificateToDelete.name}?</>}
                onClose={() => setCertificateToDelete(undefined)}
                onDelete={async () => await userPlanRemoveCertification(props.plan.uid, certificateToDelete.uid)}
                onFinish={() => { props.reloadData(); }}
            />
        )}


            { planSelectVisible && (
            <Popup
                className={styles.addCertificationPopup}
                title="Add Certification"
                buttons={[
                    {
                        title: <><FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span></>,
                        className: 'gray',
                        onClick: () => { setPlanSelectVisible(false); },
                    },
                    {
                        title: <><span>Add</span> <FontAwesomeIcon icon={faPlus} /></>,
                        className: 'red',
                        onClick: async () => {
                            try {
                                if(!newCertificationUid) return;
                                await userPlanAddCertification(props.plan.uid, newCertificationUid);
                                if (props.reloadData)
                                    props.reloadData();
                                setPlanSelectVisible(false);
                            }
                            catch(err) {
                                toast.error("Error adding certificaiton");
                            }
                        },
                        disabled: !newCertificationUid,
                    },
                ]}
            >
                Certification:
                <select
                    onChange={(event: React.FormEvent<HTMLSelectElement>) => setNewCertificationUid(event.currentTarget.value)}
                >
                    <option></option>
                    { props.certifications?.map(
                        (cert: IAchievement) => (
                            <option
                                key={cert.uid}
                                value={cert.uid}
                                disabled={props.plan?.certifications?.some(
                                    (certification: IAchievement) => certification.uid === cert.uid)
                                }
                            >
                                {cert.name}
                            </option>
                        ),
                    )}
                </select>
            </Popup>
        )}
        </>)

}