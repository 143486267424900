import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { addUserToUserGroup } from '../../../../api/actions/user-group/user-group-user-add';
import { removeUserFromUserGroup } from '../../../../api/actions/user-group/user-group-user-remove';
import { getUserGroupsPaged } from '../../../../api/actions/user-group/user-groups-get-paged';
import { Button } from '../../../../components/shared/button/button';
import { Checkbox } from '../../../../components/shared/checkbox/checkbox';
import { CheckboxGroup } from '../../../../components/shared/checkbox/checkbox-group';
import { LinkButton } from '../../../../components/shared/link-button/link-button';
import { PageSwitcher } from '../../../../components/shared/page-switcher/page-switcher';
import { Popup } from '../../../../components/shared/popup/popup';
import { SearchField } from '../../../../components/shared/search-field/search-field';
import { compareText } from '../../../../helpers/array-helpers';
import { UserRoles } from '../../../../models/enums/user-roles-enum';
import { IListResult } from '../../../../models/list-result';
import { IUser } from '../../../../models/user';
import { IUserGroup } from '../../../../models/user-group';
import { GroupPermissionsEditor } from './group-permissions-editor';
import styles from './user-user-groups-list.module.scss';


interface IProps {
    user: IUser,
    reloadUser(): void,
}

export function UserUserGroupsList(props: IProps) {

    const [pagedUserGroups, setPagedUserGroups] = React.useState<IListResult<IUserGroup>>();
    const [userGroupSelectorPopupVisible, setUserGroupSelectorPopupVisible] = React.useState(false);

    const [editableGroup, setEditableGroup] = React.useState<IUserGroup>()

    const loadData = React.useCallback(async (page: number = 1, searchText?: string) => {
        setPagedUserGroups(await getUserGroupsPaged({
            page,
            searchText,
            onlyRoot: false,
            groupType: (props.user?.role === UserRoles.user ? UserRoles.user : undefined),
        }));
    }, [props.user?.role]);

    React.useEffect(() => {
        loadData();
    }, [loadData]);

    const addUserGroup = React.useCallback((userGroupUid: string) => {
        (async () => {
            if(!props.user?.uid) return;
            await addUserToUserGroup(userGroupUid, props.user.uid);
            props.reloadUser();
        })().catch(() => {/** */});
    }, [props]);

    const removeUserGroup = React.useCallback((userGroupUid: string) => {
        (async () => {
            if(!props.user?.uid) return;
            await removeUserFromUserGroup(userGroupUid, props.user.uid);
            props.reloadUser();
        })().catch(() => {/** */});
    }, [props]);

    return (
        <>
            <div className="list-title">
                <div>User Groups:
                    <strong>{ (props.user?.userGroups?.length) || 0 }</strong>
                </div>
                <div>
                    <Button
                        className="orange"
                        onClick={() => setUserGroupSelectorPopupVisible(true) }
                    >
                        <FontAwesomeIcon icon={faPlus} /> <span>Add User Group</span>
                    </Button>
                </div>
            </div>
            { !!props.user?.userGroups?.length && (
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Type</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Permissions</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.user?.userGroups?.sort((g1, g2) => compareText(g1, g2, (g) => g.title)).map(((userGroup, i) => (
                                <tr key={userGroup.uid}>
                                    <td>
                                        <div>
                                            {i + 1}
                                        </div>
                                    </td>
                                    <td>
                                        {userGroup.groupType?.toUpperCase()}
                                    </td>
                                    <td className={styles.titleCell}>
                                        {userGroup.title}
                                    </td>
                                    <td>
                                        {userGroup.description}
                                    </td>
                                    <td>
                                        {userGroup.userPermissions?.join(', ')}
                                    </td>
                                    <td>
                                        <LinkButton onClick={() => userGroup.uid && setEditableGroup(userGroup)}>
                                            <FontAwesomeIcon icon={faEdit} /> Permissions
                                        </LinkButton><br />
                                        <LinkButton className="red" onClick={() => userGroup.uid && removeUserGroup(userGroup.uid)}>
                                            <FontAwesomeIcon icon={faTrash} /> Delete
                                        </LinkButton>
                                    </td>
                                </tr>
                            )))
                        }
                    </tbody>
                </table>
            )}

            { userGroupSelectorPopupVisible && pagedUserGroups && (
                <Popup
                    title="Add User Group"
                    onClose={() => setUserGroupSelectorPopupVisible(false)}
                    buttons={[
                        {
                            title: <><FontAwesomeIcon icon={faTimes} /> <span>Close</span></>,
                            className: 'gray',
                            onClick: () => setUserGroupSelectorPopupVisible(false),
                        },
                    ]}
                >
            
                    <SearchField onChange={(val?: string) => {loadData(1, val)}} />
                    
                    <div className={styles.buttonsContainer}>
                        <PageSwitcher
                            page={pagedUserGroups.page}
                            pageSize={pagedUserGroups.pageSize}
                            itemsCount={pagedUserGroups.count}
                            onChange={(page) => { loadData(page) }}
                        />
                    </div>
                    <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Type</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                pagedUserGroups?.data?.map(((userGroup, i) => (
                                    <tr key={userGroup.uid}>
                                        <td>
                                            <div>
                                                { i + 1 }
                                            </div>
                                        </td>
                                        <td>
                                            {userGroup.groupType?.toUpperCase()}
                                        </td>
                                        <td className={styles.titleCell}>
                                            {userGroup.title}
                                        </td>
                                        <td>
                                            {userGroup.description}
                                        </td>
                                        <td>
                                            { props.user?.userGroups?.every(g => g.uid !== userGroup.uid) && (
                                                <LinkButton onClick={() => userGroup.uid && addUserGroup(userGroup.uid)}>
                                                    <FontAwesomeIcon icon={faPlus} /> Add
                                                </LinkButton>
                                            )}
                                        </td>
                                    </tr>
                                )))
                            }
                        </tbody>
                    </table>
                    
                    <div className={styles.buttonsContainer}>
                        <PageSwitcher
                            page={pagedUserGroups.page}
                            pageSize={pagedUserGroups.pageSize}
                            itemsCount={pagedUserGroups.count}
                            onChange={(page) => { loadData(page) }}
                        />
                    </div>
                    
                </Popup>
            )}

            {editableGroup && (
                <Popup
                    title="Edit permissions"
                    onClose={() => setEditableGroup(undefined)}
                >
                    <GroupPermissionsEditor
                        group={editableGroup}
                        user={props.user}
                        permissions={editableGroup.userPermissions}
                        reloadData={props.reloadUser}
                        onClose={() => setEditableGroup(undefined)}
                    />
                </Popup>
            )}
        </>
    );
}