import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getAllEndSurveys } from '../../../api/actions/end-survey/survey-get-all';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { Grid } from '../../../components/shared/grid/grid';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { compareText } from '../../../helpers/array-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import { joinPath } from '../../../helpers/path-helpers';
import { IEndSurvey } from '../../../models/end-survey';
import { surveyEnable } from '../../../api/actions/end-survey/survey-enable';
import { surveyDisable } from '../../../api/actions/end-survey/survey-disable';
import { toast } from 'react-toastify';

import styles from './survey-list.module.scss';

export function SurveyList(props: RouteComponentProps) {
    const [loading, setLoading] = useState(true);
    const [surveys, setSurveys] = useState<IEndSurvey[]>([]);

    const loadData = React.useCallback(async () => {
        setLoading(true);
        try {
            setSurveys((await getAllEndSurveys(false)).sort((el1, el2) => compareText(el1, el2, (el) => el.name)))
        }
        catch(err) {
            basePerformError(err, props.history);
        }
        setLoading(false);
    }, [props.history]);

    useEffect(() => {
        loadData().catch(() =>{/** */});
    }, [loadData]);

    const enableSurvey = React.useCallback(async (uid: string) => {
        if(!uid) return;
        try {
            await surveyEnable(uid);
            await loadData();
            toast.success('Survey has been successfully enabled');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [loadData, props.history]);


    const disableSurvey = React.useCallback(async (uid: string) => {
        if(!uid) return;
        try {
            await surveyDisable(uid);
            await loadData();
            toast.success('Survey has been successfully disabled');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [loadData, props.history]);

    const renderButtons = () => (
        <div className={styles.buttons}>
            <Button className="orange" to={joinPath(props.match.url, '_')}>Add Survey</Button>
        </div>
    );

    return loading ? (<Loader />) : (<>
        <AddBreadcrumbsItem
            title="End Surveys"
            url={props.match.url}
        />



        <Grid<IEndSurvey>
            getPageData={(page, pageSize) => {
                return Promise.resolve({
                    page,
                    count: surveys?.length || 0,
                    data: surveys?.slice(pageSize * (page - 1), pageSize * page),
                });
            }}

            containerRenderer={(content, pager) => (<>
                <div className={styles.pager}>{pager} {renderButtons()}</div>
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Default</th>
                            <th>Actions</th>
                        </tr>
                        {content}
                    </tbody>
                </table>
                <div className={styles.pager}>{pager}</div>
            </>)}

            itemRenderer={(survey, i) => (
                <tr key={survey.uid}>
                    <td>
                    <div className={styles.markContainer}>
                      <div className={`${styles.mark} ${!survey.available ? styles.disabled : ''}`}>&nbsp;</div>
                      <div className={styles.title}>
                          {i + 1}
                      </div>
                    </div>
                    </td>
                    <td>
                        {survey.name}
                    </td>
                    <td>
                        {survey.description}
                    </td>
                    <td>
                        {survey.default ? 'Yes' : 'No'}
                    </td>

                    <td>
                        <LinkButton to={joinPath(props.match.url, survey.uid)}>
                            <FontAwesomeIcon icon={faEdit} /> Edit
                        </LinkButton><br />
                        { !survey.available && (<>
                            <LinkButton className={styles.enableBtn} onClick={() => enableSurvey(survey.uid)}>
                                <FontAwesomeIcon icon={faUnlock} /> Enable
                            </LinkButton><br />
                        </>)}
                        { survey.available && (<>
                            <LinkButton onClick={() => disableSurvey(survey.uid)}>
                                <FontAwesomeIcon icon={faLock} /> Disable
                            </LinkButton><br />
                        </>)}
                    </td>
                </tr>
            )}
        />

        {renderButtons()}
    </>);
}
