import { FetchResult, gql } from 'apollo-boost';
import { IUnit } from '../../../models/unit';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UnitSave($unit: UnitInput!, $modelFile: Upload, $iconFile: Upload){
        unit_save (unit_data: $unit, modelFile: $modelFile, iconFile: $iconFile) {
            uid
        }
    }
`;

export async function unitSave(unit: Partial<IUnit>, modelFile?: File, iconFile?: File): Promise<string> {
    const result: FetchResult<{ unit_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { unit, modelFile, iconFile },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.unit_save.uid;
}
