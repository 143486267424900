import { FetchResult, gql } from 'apollo-boost';
import { IUnitSection } from '../../../models/unit-section';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UnitSectionSave($unitSection: UnitSectionInput!){
        unit_section_save (unit_section_data: $unitSection) {
            uid
        }
    }
`;

export async function unitSectionSave(unitSection: Partial<IUnitSection>): Promise<string> {
    const result: FetchResult<{ unit_section_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { unitSection: {
            ...unitSection,
            exerciseParams: unitSection.exerciseParams && unitSection.exerciseParams.length
                ? JSON.stringify(unitSection.exerciseParams)
                : undefined,
         } },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.unit_section_save.uid;
}
