import { FetchResult, gql } from 'apollo-boost';
import { INarrative } from '../../../models/narrative';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetNarratives($enabled_only: Boolean, $platform: String) {
        narratives (enabled_only: $enabled_only, platform: $platform) {
            uid,
            title,
            fileName: file_name,
            available,
            mediaType: media_type,
            status,
            platforms,
            audioSignatureFileName: audio_signature_file_name,
            audioCatalogFileName: audio_catalog_file_name,

            minAgeRating: min_age_rating,
            externalReferences: external_references {
                serviceName: service_name,
            }

            episodes {
                uid,
                available,
                status,
            }

            tasksAvailableForUserCount: tasks_available_for_user_count,
        }
    }
`;

export async function getAllNarratives(enabled_only: boolean, platform: string | undefined): Promise<INarrative[]> {
    const result: FetchResult<{ narratives: INarrative[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { enabled_only: enabled_only, platform: platform },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.narratives;
}
