import { FetchResult, gql } from 'apollo-boost';
import { IPipOption } from '../../../models/pip-option';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation PipOptionSave($pipOption: PipOptionInput!){
        pip_option_save (input_data: $pipOption) {
            uid
        }
    }
`;

export async function pipOptionsSave(pipOption: Partial<IPipOption>): Promise<string> {
    const result: FetchResult<{ pip_option_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { pipOption: {
            uid: pipOption.uid,
            name: pipOption.name,
            earn_option_id: pipOption.earnOptionId,
            amount: pipOption.amount,
            description: pipOption.description,
        } },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.pip_option_save?.uid;
}
