import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { ResponseViewer } from './response-viewer';
import { ResponseList } from './response-list';


export function ResponseManagementPage(props: RouteComponentProps) {

    return (<>
        <Route exact
            path={props.match.url} component={ResponseList}
        />
        <Route exact
            path={`${props.match.url}/:uid`} component={ResponseViewer}
        />
    </>);
}
