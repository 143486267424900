/* eslint-disable sonarjs/no-extra-arguments */
import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../components/shared/button/button';
import { UploaderField } from '../../../components/shared/uploader/uploader';
import { UploaderImagePreview } from '../../../components/shared/uploader/uploader-image-preview';
import { getActionTendencyFilePath } from '../../../config/app-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import styles from '../emotion-categories/emotion-categories-list.module.scss';
import { IActionTendencyForm } from '../../../models/action-tendency';
import { actionTendencySave } from '../../../api/actions/action-tendencies/action-tendency-save';
import { deleteActionTendencyImage } from '../../../api/actions/action-tendencies/action-tendency-delete-image';
import { toast } from 'react-toastify';

interface LocationState {
  tendency?: {
    uid: string;
    label: string;
    imgFileName: string;
    __typename: string;
  };
}

export const ActionTendenciesEdit = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const routeState = location.state as {
    tendency: IActionTendencyForm | undefined;
  };

  const tendency = location.state?.tendency;
  const filePath = tendency
    ? getActionTendencyFilePath(tendency.uid, tendency.imgFileName)
    : getActionTendencyFilePath('');
  const [imagePresent, setImagePresent] = useState(!!tendency?.imgFileName);

  const saveActionTendency = async (values: IActionTendencyForm) => {
    console.log('Submitting', values);
    try {
      const { success, error_msg, success_msg } = await actionTendencySave(
        values,
        //@ts-ignore
        values.image ? values.image.file : undefined
      );
      if (success) {
        toast.success(`${success_msg}`);
        history.push('/libraries/action-tendencies');
      } else {
        toast.error(`Error: ${error_msg}`);
      }
    } catch (error) {
      console.error('Error saving emotion category:', error);
    }
  };

  const handleDeleteImage = async (uid: string) => {
    const { success } = await deleteActionTendencyImage(uid);
    if (success) {
      toast.success('Image has been deleted.');
      setImagePresent(false);
    }
  };

  const initialFormState: IActionTendencyForm = routeState?.tendency || {
    uid: '',
    label: '',
    image: undefined,
  };

  return (
    <div className={styles.formContainer}>
      <Formik
        initialValues={initialFormState}
        onSubmit={async (values, { setSubmitting }) => {
          await saveActionTendency(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <h3>Action Tendency</h3>
            {tendency && tendency.uid && (
              <>
                <div className="form-item">
                  <span>UID: </span>
                  {tendency.uid}
                </div>
              </>
            )}
            {!tendency && (
              <div className="form-item">
                <label>
                  <div className="form-label">UID</div>
                  <Field name="uid" type="text" placeholder="UID" />
                </label>
              </div>
            )}

            <div className="form-item">
              <label>
                <div className="form-label">Label</div>
                <Field name="label" type="text" placeholder="Label" />
              </label>
            </div>

            {!imagePresent && (
              <div className="form-item">
                <label>Image</label>
                <Field
                  name="image"
                  acceptFileTypes=".png,.jpg,.jpeg,.gif"
                  component={UploaderField}
                  path={filePath}
                  previewComponent={UploaderImagePreview}
                />
              </div>
            )}

            {imagePresent && tendency && tendency.imgFileName && (
              <div className="form-item">
                <label htmlFor="current image"></label>
                <div onClick={() => handleDeleteImage(tendency.uid)}>
                  <span className={styles.deleteIcon}>
                    Delete current image{' '}
                    <FontAwesomeIcon
                      className={styles.deleteIcon}
                      icon={faTrashAlt}
                    />
                  </span>
                </div>
                <img
                  className={styles.emotionImage}
                  src={filePath}
                  alt={tendency.imgFileName}
                />
                <div>{tendency.imgFileName}</div>
              </div>
            )}

            <div className="form-item">
              <Button type="submit" disabled={isSubmitting}>
                {tendency && tendency.uid ? 'Update' : 'Create'}
              </Button>
              <Button
                onClick={() => history.push('/libraries/action-tendencies')}
              >
                Back
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
