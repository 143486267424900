import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../../components/layouts/main-layout';
import { AddBreadcrumbsItem } from '../../components/shared/breadcrumbs/breadcrumbs';
import { SecuredPage } from '../base/secured-page';
import { TasksList } from './parts/tasks-list';



export function TasksManagementPage(props: RouteComponentProps) {

    return (
        <SecuredPage pageName="curriculums" {...props} >

            <AddBreadcrumbsItem
                title="Home"
                url="/"
            />
            <AddBreadcrumbsItem
                title="My Tasks"
                url="/tasks"
            />

            <MainLayout>
                <Route exact
                    path={`${props.match.url}`} component={TasksList}
                />
                <Route exact
                    path={`${props.match.url}/:uid`} component={TasksList}
                />
            </MainLayout>
        </SecuredPage>
    );
}
