import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { userGroupSendPrivateInvite } from '../../../../api/actions/user-group/user-group-send-private-invite';
import { Button } from '../../../../components/shared/button/button';
import { Loader } from '../../../../components/shared/loader/loader';
import { SelectField } from '../../../../components/shared/select/select';
import { IUserGroup } from '../../../../models/user-group';
import { IUserInvite } from '../../../../models/user-invite';
import { IDictionary } from '../../../../types/dictionary';
import { MultiError } from '../../../../types/multi-error';
import { GroupUserRoles } from '../../../../models/enums/group-user-roles';


enum FormFields {
    email = 'email',
    firstName = 'firstName',
    lastName = 'lastName',
    role = 'role',
    expirationDate = 'expirationDate',
}

interface IForm {
    [FormFields.email]: string,
    [FormFields.firstName]?: string,
    [FormFields.lastName]?: string,
    [FormFields.role]?: string,
    [FormFields.expirationDate]: string,
}

interface IProps {
    userGroup: IUserGroup,
    invite?: IUserInvite,
    onSuccess(): void,
}

export function UserGroupCreateInviteComponent(props: IProps) {

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            if(!props.userGroup.uid) return;

            const inviter_uid = (props.invite && props.invite.inviterUid) ? props.invite.inviterUid : undefined
            await userGroupSendPrivateInvite(props.userGroup.uid, values.email, values.expirationDate, values.firstName, values.lastName, values.role, inviter_uid);
            toast.success('Invite has been successfully sent');
            props.onSuccess();
        }
        catch (err) {
            setSubmitting(false);
            setLoading(false);
            toast.error((err as MultiError).message);
        }
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            email: Yup.string().label('Email').required().email(),
            expirationDate: Yup.string().label('Expiration Date').required(),
            firstName: Yup.string().label('First Name').min(2).max(50),
            lastName: Yup.string().label('First Name').min(2).max(50),
        });
    };

    const renderLoginForm = ({ errors, touched, submitForm, values, setFieldValue }: FormikProps<IForm>): ReactElement => {
        return (
            <Form noValidate >
                <div className="form-item">
                </div>
                <div className="form-item">
                    <label>
                        <div className="form-label required">
                            Email
                        </div>
                        <Field type="email" name={FormFields.email} />
                    </label>
                    <div className="errors">{touched.email && errors.email}</div>
                </div>
                <div className="form-item">
                    <label>
                        <div className="form-label required">
                            Invite Code Expiration Date
                        </div>
                        <DateTimePicker
                            value={values.expirationDate && moment(values.expirationDate).toDate()}
                            onChange={(v: any) => {
                                setFieldValue(FormFields.expirationDate, moment(v).format('YYYY-MM-DDTHH:mm:ss'));
                            }}
                            clearIcon={null}
                            showLeadingZeros={true}
                            minDate={moment().add(2, 'days').toDate()}
                            maxDate={moment().add(1, 'year').toDate()}
                        />
                    </label>
                    <div className="errors">{errors.expirationDate}</div>
                </div>
                <div className="form-item">
                    <label>
                        <div className="form-label">
                            First Name
                        </div>
                        <Field type="text" name={FormFields.firstName} />
                    </label>
                    <div className="errors">{touched.firstName && errors.firstName}</div>
                </div>
                <div className="form-item">
                    <label>
                        <div className="form-label">
                            Last Name
                        </div>
                        <Field type="text" name={FormFields.lastName} />
                    </label>
                    <div className="errors">{touched.lastName && errors.lastName}</div>
                </div>
                <div className="form-item">
                    <label>
                        <div className="form-label">
                            Role
                        </div>
                        <Field
                            component={SelectField}
                            name={FormFields.role}
                            emptyTitle=""
                            data={Object.keys(GroupUserRoles).map(k => ({ uid: k, name: `${GroupUserRoles[k]}`.toUpperCase()}))}
                        />
                      </label>
                    <div className="errors">{touched.role && errors.role}</div>
                </div>
                <div className="form-buttons">
                    <Button onClick={props.onSuccess} className="gray"><span>Close</span></Button>
                    { loading
                    ? (<Loader />)
                    : (<Button onClick={submitForm}><span>{props.invite ? "Resend" : "Send"} Invite</span></Button>)
                    }
                </div>
            </Form>
        );
    };

    return (
        <Formik
            initialValues={{
                email: props.invite ? props.invite.inviteeEmail : '',
                firstName: props.invite ? props.invite.inviteeFirstName : '',
                lastName: props.invite ? props.invite.inviteeLastName : '',
                role: props.invite ? props.invite.inviteeRole : GroupUserRoles.learner,
                expirationDate: moment().add(15, 'days').format('YYYY-MM-DDTHH:mm:ss'),
            }}
            validationSchema={getValidationSchema}
            onSubmit={handleSubmit}
        >
            {renderLoginForm}
        </Formik>
    )
}
