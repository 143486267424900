import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getFlaggedAnswersPaged } from '../../../api/actions/answers/flagged-answers-get-paged';
import { Button } from '../../../components/shared/button/button';
import { Grid } from '../../../components/shared/grid/grid';
import { Popup } from '../../../components/shared/popup/popup';
import { SearchField } from '../../../components/shared/search-field/search-field';
import { basePerformError } from '../../../helpers/error-helpers';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { IAnswer } from '../../../models/answer';
import { getParentPath, joinPath } from '../../../helpers/path-helpers';
import { RouteComponentProps } from 'react-router-dom';
import styles from './flagged-response-list.module.scss';


export function FlaggedResponseList(props: RouteComponentProps) {
    const history = useHistory();
    const [status, setStatus] = useState<string | undefined>();
    const [searchText, setSearchText] = useState<string | undefined>();

    const loadData = React.useCallback(async (page: number = 1) => {
        try {
            return await getFlaggedAnswersPaged({
                page,
                searchText,
                status,
            });
        }
        catch(err) {
            basePerformError(err, history);
        }
    }, [history, status, searchText]);

    const gridContainerRenderer = React.useCallback(
        (content, pager) => (<>
            <div className={styles.pager}>
                {pager}
            </div>
            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <tbody>
                    <tr>
                        <th>#</th>
                        <th>Submission</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    {content}
                </tbody>
            </table>
            <div className={styles.pager}>{pager}</div>
        </>),
        [],
    );

    const gridItemRenderer = React.useCallback(
        (answer: IAnswer, i: number, count: number, reloadData: () => void) => (
            <tr key={answer.uid}>
                <td>
                    <div className={styles.markContainer}>
                        <div className={`${styles.mark} ${answer.flagStatus?.toLowerCase() !== 'open' ? '' : styles.disabled}`}>&nbsp;</div>
                        <div className={styles.title}>
                            {i + 1}
                        </div>
                    </div>
                </td>
                <td>{answer.answer}</td>
                <td>{answer.flagStatus}</td>
                <td>
                    <LinkButton to={joinPath(props.match.url, answer.uid)} className="orange">
                        View
                    </LinkButton><br />
                </td>
            </tr>
        ),
        [],
    );

    return (<>

        <SearchField onChange={setSearchText} />

        <Grid<IAnswer>
            getPageData={loadData}
            containerRenderer={gridContainerRenderer}
            itemRenderer={gridItemRenderer}
        />
    </>)
}
