import { IEmotionCategory } from '../../../models/emotion-category';
import { IRegulationStrategy } from '../../../models/regulation-strategy';
import { getRegulationStrategyFilePath } from '../../../config/app-config';
import { addEmotionCategoryRegulationStrategy } from '../../../api/actions/emotion-categories/emotion-category-add-regulation-strategy';
import { removeEmotionCategoryRegulationStrategy } from '../../../api/actions/emotion-categories/emotion-category-remove-regulation-strategy';
import { faPlus, faTrashAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { joinPath } from '../../../helpers/path-helpers';
import { Button } from '../../../components/shared/button/button';
import { compareText } from '../../../helpers/array-helpers';
import { Popup } from '../../../components/shared/popup/popup';
import { ActionPopup } from '../../../components/shared/popup/action-popup';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import styles from './emotion-regulation-strategy-list.module.scss';

interface IProps {
    emotionUid: string, 
    emotionStrategies?: IRegulationStrategy[],
    allStrategies?: IRegulationStrategy[],
    reloadData: any,
}

export function EmotionRegulationStrategyList(props: IProps) {

  const [strategyToDelete, setStrategyToDelete] = useState<IRegulationStrategy | undefined>(undefined);
  const [strategySelectVisible, setStrategySelectVisible] = useState(false);
  const [newStrategyUid,setNewStrategyUid] = useState<string | undefined>(undefined);




  const handleDelete = async (uid: string) => {
    const { success } = await removeEmotionCategoryRegulationStrategy(props.emotionUid, uid);
    if (success) {
      toast.success('Regulation strategy has been successfully removed');
      props.reloadData()
    } else {
        toast.error('Error removing regulation strategy')
    }
  };


return (
    <>
        <div className="list-title">
            <div>Regulation Strategy: <strong>{ (props.emotionStrategies && props.emotionStrategies.length) || 0 }</strong></div>
            <div>
                <Button className="orange" onClick={() => { setStrategySelectVisible(true); }}>
                    <FontAwesomeIcon icon={faPlus} /> <span>Add Regulation Strategy</span>
                </Button>
            </div>
        </div>

      <table cellPadding="0" cellSpacing="0" className={`list`}>
        <thead>
          <tr>
            <th>UID</th>
            <th>Label</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.emotionStrategies?.map((strategy: IRegulationStrategy) => (
            <tr key={strategy.uid}>
              <td>{strategy.uid}</td>
              <td>{strategy.description}</td>
              <td>
                {strategy.imgFileName ? (
                  <img
                    src={getRegulationStrategyFilePath(
                      strategy.uid,
                      strategy.imgFileName
                    )}
                    alt={strategy.description}
                    style={{ width: '50px', height: '50px' }}
                  />
                ) : (
                  'No Image'
                )}
              </td>
              <td>
                <span
                  className={styles.deleteIcon}
                  onClick={() => setStrategyToDelete(strategy)}
                >
                  <FontAwesomeIcon
                    className={styles.deleteIcon}
                    icon={faTrashAlt}
                  />{' '}
                  Remove
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {strategyToDelete && (
        <ActionPopup
          title={`Delete ${strategyToDelete.description}`}
          question={`Are you sure you want to remove ${strategyToDelete.description}`}
          onClose={() => setStrategyToDelete(undefined)}
          onAction={() => handleDelete(strategyToDelete.uid || '')}
        />
      )}

          { strategySelectVisible && (
            <Popup
                className={styles.addStrategyPopup}
                title="Add Regulation Strategy"
                buttons={[
                    {
                        title: <><FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span></>,
                        className: 'gray',
                        onClick: () => { setStrategySelectVisible(false); },
                    },
                    {
                        title: <><span>Add</span> <FontAwesomeIcon icon={faPlus} /></>,
                        className: 'red',
                        onClick: async () => {
                            try {
                                if(!newStrategyUid) return;
                                await addEmotionCategoryRegulationStrategy(props.emotionUid, newStrategyUid);
                                if (props.reloadData)
                                    props.reloadData();
                                setStrategySelectVisible(false);
                            }
                            catch(err) {
                                toast.error("Error adding certificaiton");
                            }
                        },
                        disabled: !newStrategyUid,
                    },
                ]}
            >
                Regulation Strategies:
                <select
                    onChange={(event: React.FormEvent<HTMLSelectElement>) => setNewStrategyUid(event.currentTarget.value)}
                >
                    <option></option>
                    { props.allStrategies?.filter((rs: IRegulationStrategy) => (!!rs.layParentCategory)).sort((c1, c2) => compareText(c1, c2, (c) => c.description)).map(
                        (rs: IRegulationStrategy) => (
                            <option
                                key={rs.uid}
                                value={rs.uid}
                                disabled={props.emotionStrategies?.some(
                                    (strategy: IRegulationStrategy) => strategy.uid === rs.uid)
                                }
                            >
                                {rs.layParentCategory ? (rs.description + ' (' + rs.layParentCategory + ' | Min age: ' + rs.minAgeRating + (rs.imgFileName ? ' | Has Icon' : ' | No Icon') + ')') : (rs.description + ' (parent)')}
                            </option>
                        ),
                    )}
                </select>
            </Popup>
        )}      
    </>
  );    
}