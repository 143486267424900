import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { joinPath } from '../../../helpers/path-helpers';
import { UserRoles } from '../../../models/enums/user-roles-enum';
import { IUser } from '../../../models/user';
import { UsersListComponent } from './parts/users-list-component';
import { toast } from 'react-toastify';
import { basePerformError } from '../../../helpers/error-helpers';
import { userDisable } from '../../../api/actions/user/user-disable';
import { userEnable } from '../../../api/actions/user/user-enable';
import { useCurrentUser } from '../../../components/user/current-user-manager';



export function UsersList(props: RouteComponentProps) {
    const currentUser = useCurrentUser();

    const disableUser = async (uid: string, reloadData: () => void) => {
        try {
            await userDisable(uid);
            reloadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const enableUser = async (uid: string, reloadData: () => void) => {
        try {
            await userEnable(uid);
            reloadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };
    

    return !currentUser ? null : (<>
        <AddBreadcrumbsItem
            title="Users"
            url={props.match.url}
        />

        <UsersListComponent
            onUserAddClick={() => props.history.push(joinPath(props.match.url, '_'))}

            actionsRenderer={(user: IUser, reloadData: () => void) => currentUser.role !== UserRoles.admin ? null : (<>
                <LinkButton to={joinPath(props.match.url, user.uid)}>
                    <FontAwesomeIcon icon={faEdit} /> Edit
                </LinkButton><br />
                {user.disabled && (<>
                    <LinkButton onClick={() => enableUser(user.uid, reloadData)}>
                        <FontAwesomeIcon icon={faUnlock} /> Enable
                    </LinkButton><br />
                </>)}
                {user.role !== UserRoles.admin && !user.disabled && (<>
                    <LinkButton className="orange" onClick={() => disableUser(user.uid, reloadData)}>
                        <FontAwesomeIcon icon={faLock} /> Disable
                    </LinkButton><br />
                </>)}
            </>)}
        />
    </>);
}
