import { FetchResult, gql } from 'apollo-boost';
import { ITopic } from '../../../models/topic';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query {
        topics {
            uid,
            name,
            description,
            motivations: associated_motivations
        }
    }
`;

export async function getAllTopics(): Promise<ITopic[]> {
    const result: FetchResult<{ topics: ITopic[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.topics;
}
