export interface IRegulationCategory {
    uid: string,
    name: string,
    description: string,
    parent: string,

    children?: IRegulationCategory[],
}

export function buildRegulationCategoryTree(categories: IRegulationCategory[]) {
    return categories.filter(s => !s.parent).map(s => ({ ...s, ...(getChildren(s, categories) ? { children: getChildren(s, categories)} : {})}));
}

function getChildren(category: IRegulationCategory, categories: IRegulationCategory[]) {
    const children: IRegulationCategory[] = categories.filter(ss => ss.parent === category.uid)
        .map(s => ({ ...s, ...(getChildren(s, categories) ? { children: getChildren(s, categories)} : {})} as IRegulationCategory));
    return children?.length ? children : undefined;
}
