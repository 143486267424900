import { faArrowLeft, faEdit, faList, faLock, faPlus, faTrashAlt, faUnlock, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { TestContext } from 'yup';
import { userGroupInviteCodeCreate } from '../../../api/actions/user-group/user-group-create-invite-code';
import { userGroupInviteCodeDelete } from '../../../api/actions/user-group/user-group-delete-invite-code';
import { userGroupDisable } from '../../../api/actions/user-group/user-group-disable';
import { userGroupEnable } from '../../../api/actions/user-group/user-group-enable';
import { userMakeGroupOwner } from '../../../api/actions/user/user-make-group-owner';
import { getUserGroup } from '../../../api/actions/user-group/user-group-get';
import { userGroupSave } from '../../../api/actions/user-group/user-group-save';
import { addUserToUserGroup } from '../../../api/actions/user-group/user-group-user-add';
import { removeUserFromUserGroup } from '../../../api/actions/user-group/user-group-user-remove';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { Popup } from '../../../components/shared/popup/popup';
import { SelectField } from '../../../components/shared/select/select';
import { Tabs } from '../../../components/shared/tabs/tabs';
import { basePerformError } from '../../../helpers/error-helpers';
import { getParentPath, joinPath } from '../../../helpers/path-helpers';
import { UserGroupTypes } from '../../../models/enums/user-group-types';
import { UserRoles } from '../../../models/enums/user-roles-enum';
import { ContentAgeRating } from '../../../models/narrative';
import { IUser } from '../../../models/user';
import { IUserGroup } from '../../../models/user-group';
import { IUserPlan } from '../../../models/user-plan';
import { IDictionary } from '../../../types/dictionary';
import { MultiError } from '../../../types/multi-error';
import { GroupPermissionsEditor } from '../users/parts/group-permissions-editor';
import { SelectUsersList } from '../users/parts/select-users-list';
import { UserUnitStatsComponent } from '../users/parts/user-unit-stats-component';
import { UsersListComponent } from '../users/parts/users-list-component';
import { UserGroupCurriculumsList } from './parts/user-group-curriculums-list';
import { UserGroupInvitesList } from './parts/user-group-invites-list';
import { UserGroupNarrativesList } from './parts/user-group-narratives-list';
import { UserGroupsListComponent } from './parts/user-groups-list-component';
import { CheckBoxWrapper } from '../../../components/shared/checkbox/checkbox-wrapper';
import { getAllRewardConfigs } from '../../../api/actions/reward-config/reward-config-get-all';
import { getAllUserPlans } from '../../../api/actions/user-plan/user-plan-get-all';
import { IRewardConfig } from '../../../models/reward-config';
import { compareText } from '../../../helpers/array-helpers';
import styles from './user-group-editor.module.scss';

enum FormFields {
    title = 'title',
    description = 'description',
    groupType = 'groupType',
    maxAgeRating = 'maxAgeRating',
    rewardSchema = 'rewardSchema',
    userPlan = 'userPlan',
    domain = 'domain',
    domainForConfirmation = 'domainForConfirmation',
    maxUsers = 'maxUsers',
    default = 'default',
    emailConfirmationRequired = 'emailConfirmationRequired',
    inviteCode = 'inviteCode',
    inviteCodeExpirationDate = 'inviteCodeExpirationDate',
    notificationSentOnAdd = 'notificationSentOnAdd',
    notificationToAdminOnJoin = 'notificationToAdminOnJoin',
    HIPAAEnforced = 'HIPAAEnforced',
    sharingEnabled = 'sharingEnabled',
    journalPrompt = 'journalPrompt',
    journalSubmissions = 'journalSubmissions',
    activityModeSwitchingAllowed = 'activityModeSwitchingAllowed',
    limitContentByAge = 'limitContentByAge',
    progressSync = 'progressSync',
    address = 'address',
    city = 'city',
    state = 'state',
    zipCode = 'zipCode',
    startDate = 'startDate',
    endDate = 'endDate',
    unlisted = 'unlisted',
    educatorConfirmationRequired = 'educatorConfirmationRequired',
    allowsInterstitials = 'allowsInterstitials',
    allowsInterstitialsOnly = 'allowsInterstitialsOnly',
}

interface IForm extends Partial<IUserGroup> {
}

function UserGroupEditor(props: RouteComponentProps<{parentUid?: string, uid: string}>) {

    const history = useHistory();
    const journalPromptPlaceholder = "Write a brief journal entry that connects one or more concepts from ThinkHuman.tv and/or the content you watched to your own emotional experiences.";
    const states = [{uid: 'AL', name:'AL'}, {uid: 'AK', name: 'AK'}, {uid: 'AZ', name: 'AZ'}, {uid: 'AR', name: 'AR'},
                    {uid: 'AS', name: 'AS'}, {uid: 'CA', name: 'CA'}, {uid: 'CO', name: 'CO'}, {uid: 'CT', name: 'CT'},
                    {uid: 'DE', name: 'DE'}, {uid: 'DC', name: 'DC'}, {uid: 'FL', name: 'FL'}, {uid: 'GA', name: 'GA'},
                    {uid: 'GU', name: 'GU'}, {uid: 'HI', name: 'HI'}, {uid: 'ID', name: 'ID'}, {uid: 'IL', name: 'IL'},
                    {uid: 'IN', name: 'IN'}, {uid: 'IA', name: 'IA'}, {uid: 'KS', name: 'KS'}, {uid: 'KY', name: 'KY'},
                    {uid: 'LA', name: 'LA'}, {uid: 'ME', name: 'ME'}, {uid: 'MD', name: 'MD'}, {uid: 'MA', name: 'MA'},
                    {uid: 'MI', name: 'MI'}, {uid: 'MN', name: 'MN'}, {uid: 'MS', name: 'MS'}, {uid: 'MS', name: 'MS'},
                    {uid: 'MO', name: 'MO'}, {uid: 'MT', name: 'MT'}, {uid: 'NE', name: 'NE'}, {uid: 'NV', name: 'NV'},
                    {uid: 'NH', name: 'NH'}, {uid: 'NJ', name: 'NJ'}, {uid: 'NM', name: 'NM'}, {uid: 'NY', name: 'NY'},
                    {uid: 'NC', name: 'NC'}, {uid: 'ND', name: 'ND'}, {uid: 'MP', name: 'MP'}, {uid: 'OH', name: 'OH'},
                    {uid: 'OK', name: 'OK'}, {uid: 'OR', name: 'OR'}, {uid: 'PA', name: 'PA'}, {uid: 'PR', name: 'PR'},
                    {uid: 'RI', name: 'RI'}, {uid: 'SC', name: 'SC'}, {uid: 'SD', name: 'SD'}, {uid: 'TN', name: 'TN'},
                    {uid: 'TX', name: 'TX'}, {uid: 'TT', name: 'TT'}, {uid: 'UT', name: 'UT'}, {uid: 'VT', name: 'VT'},
                    {uid: 'VA', name: 'VA'}, {uid: 'VI', name: 'VI'}, {uid: 'WA', name: 'WA'}, {uid: 'WV', name: 'WV'},
                    {uid: 'WI', name: 'WI'}, {uid: 'WY', name: 'WY'}];


    const [loading, setLoading] = useState(true);
    const [parentUserGroup, setParentUserGroup] = useState<IUserGroup>();
    const [userGroup, setUserGroup] = useState<IUserGroup>();

    const [userListUpdatedAt, setUsersListUpdatedAt] = React.useReducer(() => (new Date()).getTime(), 0);
    const [visibleAddUserPopup, setVisibleAddUserPopup] = useState(false);
    const [editableUser, setEditableUser] = React.useState<IUser>()
    const [userToRemove, setUserToRemove] = useState<{ user: IUser, reloadData: () => void }>();
    const [rewardSchemas, setRewardSchemas] = useState<IRewardConfig[]>([]);
    const [userPlans, setUserPlans] = useState<IUserPlan[]>([]);
    const [journalsEnabled, setJournalsEnabled] = useState(userGroup && userGroup.journalSubmissions === true);
    
    const form = React.useRef<FormikProps<IForm>>(null);

    const loadData = useCallback(async (uid?: string) => {
        setLoading(true);
        setParentUserGroup(undefined);
        setUserGroup(undefined);
        try {
            if(props.match.params.parentUid) {
                const parentGroup = await getUserGroup(props.match.params.parentUid);
                if(parentGroup.groupType !== UserGroupTypes.organization) {
                    toast.error('Invalid parent group');
                    props.history.push(getParentPath(props.match.url, 2));
                    return;
                }
                setParentUserGroup(parentGroup);
            }

            if(uid || props.match.params.uid !== '_') {
                setUserGroup(await getUserGroup(uid || props.match.params.uid));
            }
            else {
                setUserGroup({} as IUserGroup);
            }

            setRewardSchemas((await getAllRewardConfigs()).sort((el1, el2) => compareText(el1, el2, (el) => el.name)))
            setUserPlans((await getAllUserPlans()).sort((el1, el2) => compareText(el1, el2, (el) => el.name)))
        }
        catch(err) {
            if([401, 403].includes((err as MultiError).code || 0)) {
                props.history.push('/login');
            }
            else props.history.push('/users/groups');
        }

        setLoading(false);
    }, [props.history, props.match.params.parentUid, props.match.params.uid, props.match.url]);

    useEffect(() => {
        loadData();
    }, [loadData, props.history, props.match.params.uid]);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newUserGroup: Partial<IUserGroup> = {
                uid: userGroup?.uid,
                groupType: parentUserGroup ? UserGroupTypes.user : values.groupType,
                title: values.title?.trim(),
                description: values.description,
                address: values.address,
                city: values.city,
                state: values.state,
                zipCode: values.zipCode,
                rewardSchema: values.rewardSchema,
                userPlan: values.userPlan,
                maxAgeRating: values.maxAgeRating || 0,
                maxUsers: values.maxUsers,
                inviteCodeExpirationDate: values.inviteCodeExpirationDate,
                domain: values.domain,
                default: values.default === true,
                domainForConfirmation: values.domainForConfirmation === true,
                sharingEnabled: values.sharingEnabled === true,
                HIPAAEnforced: values.HIPAAEnforced === true,
                allowsInterstitials: values.allowsInterstitials === true,
                allowsInterstitialsOnly: values.allowsInterstitialsOnly === true,
                journalPrompt: values.journalPrompt,
                journalSubmissions:values.journalSubmissions,
                activityModeSwitchingAllowed: values.activityModeSwitchingAllowed === true,
                emailConfirmationRequired: values.emailConfirmationRequired === true,
                notificationSentOnAdd: values.notificationSentOnAdd === true,
                notificationToAdminOnJoin: values.notificationToAdminOnJoin === true,
                limitContentByAge: values.limitContentByAge === true,
                progressSync: values.progressSync === true,
                startDate: values.startDate ? moment(values.startDate).format() : undefined,
                endDate: values.endDate ? moment(values.endDate).format() : undefined,
                unlisted: values.unlisted === true,
                educatorConfirmationRequired: values.educatorConfirmationRequired === true,
            };

            if(parentUserGroup) {
                newUserGroup.parent = { uid: parentUserGroup.uid } as IUserGroup;
            }

            const uid = await userGroupSave(newUserGroup);
            if(!userGroup?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            }
            loadData(uid);
            toast.success('User Group has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const makeGroupOwner = async (userUid: string) => {
        if(!userGroup?.uid) return;
        try {
            const result = await userMakeGroupOwner(userUid, userGroup.uid);
            if(result) {
                loadData();
                setUsersListUpdatedAt();
                toast.success('Group owner set');
            }
        } catch (err) {
            basePerformError(err, props.history);
        }
    }

    const generateInviteCode = async () => {
        if(!userGroup?.uid) return;
        try {
            const { inviteCode, inviteCodeExpirationDate } = await userGroupInviteCodeCreate(userGroup.uid);
            form.current?.setFieldValue(FormFields.inviteCode, inviteCode.toUpperCase());
            form.current?.setFieldValue(FormFields.inviteCodeExpirationDate, inviteCodeExpirationDate);
        }
        catch (err) {
            basePerformError(err, props.history);
        }
    }

    const deleteInviteCode = async () => {
        if(!userGroup?.uid) return;
        try {
            await userGroupInviteCodeDelete(userGroup.uid);
            form.current?.setFieldValue(FormFields.inviteCode, null);
            form.current?.setFieldValue(FormFields.inviteCodeExpirationDate, null);
        }
        catch (err) {
            basePerformError(err, props.history);
        }
    }


    const enableUserGroup = React.useCallback(async () => {
        if(!userGroup?.uid) return;
        try {
            await userGroupEnable(userGroup?.uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [loadData, props.history, userGroup?.uid]);


    const disableUserGroup = React.useCallback(async () => {
        if(!userGroup?.uid) return;
        try {
            await userGroupDisable(userGroup.uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [loadData, props.history, userGroup?.uid]);


    const addUserToGroup = async (userUid: string) => {
        if(!userGroup?.uid) return;
        await addUserToUserGroup(userGroup.uid, userUid);

        toast.success('User has been successfully added to the Group');
    }

    const removeUserFromGroup = async (userUid: string) => {
        if(!userGroup?.uid) return;
        await removeUserFromUserGroup(userGroup.uid, userUid);
    }

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            groupType: Yup.mixed<UserGroupTypes>().label('Type').required().oneOf(Object.values(UserGroupTypes)),
            title: Yup.string().label('Title').trim().required().min(3).max(255),
            maxAgeRating: Yup.number().label('Highest Accessible Group Content').required(),
            description: Yup.string().label('Description').trim().max(4000),
            domain: Yup.string().label('Domain').trim().min(4).max(25),
            maxUsers: Yup.number().label('Max User Count'),
            inviteCodeExpirationDate: Yup.mixed<string>().label('Invite code expiration date')
                .test(
                    'not expired',
                    "Invite code expiration date can not be less than " + moment().add(1, 'day').toDate(),
                    function(this: TestContext, date) {
                        return date === userGroup?.inviteCodeExpirationDate || moment(date) > moment().add(1, 'day');
                    }
                )
        });
    };

    const renderForm = ({ errors, touched, values, setFieldValue }: FormikProps<IForm>): React.ReactElement => {
        return loading ? (<Loader />) : (
            <div className={styles.formContainer}>
                <Form noValidate>
                    <br />
                    <div className="form-buttons">
                        { !userGroup?.enabled && (<>
                            <LinkButton onClick={enableUserGroup}>
                                <FontAwesomeIcon icon={faUnlock} /> Enable
                            </LinkButton><br />
                        </>)}
                        { userGroup?.enabled && (<>
                            <LinkButton onClick={disableUserGroup} className="orange">
                                <FontAwesomeIcon icon={faLock} /> Disable
                            </LinkButton><br />
                        </>)}
                    </div>

                    {userGroup?.groupType === UserGroupTypes.organization && userGroup?.uid && (
                    <div>
                        <div>
                          <b>Owner:</b> {userGroup.owner ? (userGroup.owner.firstName + ' ' + userGroup.owner.lastName + ' (' + userGroup.owner.email + ')') : 'None'}
                        </div>
                        <div>
                          <b>Created:</b> {userGroup.createdAt ? moment.utc(userGroup.createdAt).format('H:ma MMM DD, YYYY') : 'No date available' }
                        </div>
                    </div>)}

                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Type
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.groupType}
                                emptyTitle=""
                                data={Object.keys(UserGroupTypes).map(k => ({ uid: k, name: `${UserGroupTypes[k]}`.toUpperCase()}))}
                                disabled={userGroup?.uid || parentUserGroup}
                            />
                        </label>
                        <div className="errors">{errors.groupType}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Title
                            </div>
                            <Field type="text" name={FormFields.title} />
                        </label>
                        <div className="errors">{touched.title && errors.title}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Highest Accessible Group Content
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.maxAgeRating}
                                emptyTitle=""
                                data={Object.keys(ContentAgeRating).map(rating => ({ uid: parseInt(rating), name: ContentAgeRating[parseInt(rating)]}))}
                            />
                        </label>
                        <div className="errors">{errors.maxAgeRating}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field component="textarea" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>

                    { userGroup?.uid && userGroup.groupType === UserGroupTypes.organization && (<>
                        <div className="form-item">
                        <label>
                            <div className="form-label">
                                Address
                            </div>
                            <Field component="textarea" name={FormFields.address} />
                        </label>
                        <div className="errors">{touched.address && errors.address}</div>
                        </div>

                        <div className="form-item">
                        <label>
                            <div className="form-label">
                                City
                            </div>
                            <Field component="textarea" name={FormFields.city} />
                        </label>
                        <div className="errors">{touched.city && errors.city}</div>
                        </div>

                        <div className="form-item">
                        <label>
                            <div className="form-label">
                                State
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.state}
                                emptyTitle=""
                                data={states}
                            />
                        </label>
                        <div className="errors">{errors.state}</div>
                        </div>

                        <div className="form-item">
                        <label>
                            <div className="form-label">
                                Zip code
                            </div>
                            <Field component="textarea" name={FormFields.zipCode} />
                        </label>
                        <div className="errors">{touched.zipCode && errors.zipCode}</div>
                        </div>

                        </>)}

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                User Plan
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.userPlan}
                                emptyTitle=""
                                data={ userPlans?.sort((a1, a2) =>
                                        a1.name > a2.name ? 1 : (a1.name < a2.name ? -1 : 0),
                                    )
                                    .map((a: IUserPlan) => ({ uid: a.uid, name: a.name }))
                                }
                            />
                        </label>
                        <div className="errors">{errors.userPlan}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                PIPs Reward Schema
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.rewardSchema}
                                emptyTitle=""
                                data={ rewardSchemas?.sort((a1, a2) =>
                                        a1.name > a2.name ? 1 : (a1.name < a2.name ? -1 : 0),
                                    )
                                    .map((a: IRewardConfig) => ({ uid: a.uid, name: a.name }))
                                }
                            />
                        </label>
                        <div className="errors">{errors.rewardSchema}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Domain
                            </div>
                            <Field type="text" name={FormFields.domain} />
                        </label>
                        <div className="errors">{touched.domain && errors.domain}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Domain Used Only for Email Confirmation">
                                <Field type="checkbox" name={FormFields.domainForConfirmation} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.domainForConfirmation && errors.domainForConfirmation}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Max User Count (set 0 for no limit)
                            </div>
                            <Field type="text" name={FormFields.maxUsers} />
                        </label>
                        <div className="errors">{touched.maxUsers && errors.maxUsers}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Default User Group">
                                <Field type="checkbox" name={FormFields.default} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.default && errors.default}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Email Confirmation Required">
                                <Field type="checkbox" name={FormFields.emailConfirmationRequired} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.emailConfirmationRequired && errors.emailConfirmationRequired}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Unlisted (if true group does not appear in client-side select group search)">
                                <Field type="checkbox" name={FormFields.unlisted} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.unlisted && errors.unlisted}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Educator Confirmation Required (if false, educator permissions granted without explicit confirmation)">
                                <Field type="checkbox" name={FormFields.educatorConfirmationRequired} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.educatorConfirmationRequired && errors.educatorConfirmationRequired}</div>
                    </div>

                    { userGroup?.uid && userGroup.groupType === UserGroupTypes.user && (<>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Start Date
                            </div>
                            <DateTimePicker
                                value={values.startDate && moment(values.startDate).toDate()}
                                onChange={(v: any) => {
                                    setFieldValue(FormFields.startDate, moment(v).format('YYYY-MM-DDTHH:mm:ss'));
                                }}
                                clearIcon={null}
                                disableClock={true}
                                showLeadingZeros={true}
                                minDate={null}
                                maxDate={moment().add(1, 'year').toDate()}
                                disabled={false}
                            />
                        </label>
                        <div className="errors">{errors.startDate}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                End Date
                            </div>
                            <DateTimePicker
                                value={values.endDate && moment(values.endDate).toDate()}
                                onChange={(v: any) => {
                                    setFieldValue(FormFields.endDate, moment(v).format('YYYY-MM-DDTHH:mm:ss'));
                                }}
                                clearIcon={null}
                                disableClock={true}
                                showLeadingZeros={true}
                                minDate={null}
                                maxDate={moment().add(1, 'year').toDate()}
                                disabled={false}
                            />
                        </label>
                        <div className="errors">{errors.endDate}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Activity Display Mode Switching Permitted (Auto only if not set)">
                                <Field type="checkbox" name={FormFields.activityModeSwitchingAllowed} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.activityModeSwitchingAllowed && errors.activityModeSwitchingAllowed}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Limit Media Content by Age">
                                <Field type="checkbox" name={FormFields.limitContentByAge} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.limitContentByAge && errors.limitContentByAge}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Sync Group & Individual Curriculum Progress">
                                <Field type="checkbox" name={FormFields.progressSync} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.progressSync && errors.progressSync}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Sharing Enabled (users can see each others' bookmarks)">
                                <Field type="checkbox" name={FormFields.sharingEnabled} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.sharingEnabled && errors.sharingEnabled}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Allows Interstitials">
                                <Field type="checkbox" name={FormFields.allowsInterstitials} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.allowsInterstitials && errors.allowsInterstitials}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Allows Interstitials Only (i.e. no curriculum training)">
                                <Field type="checkbox" name={FormFields.allowsInterstitialsOnly} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.allowsInterstitialsOnly && errors.allowsInterstitialsOnly}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="HIPAA enforced (limits what is visible to whom)">
                                <Field type="checkbox" name={FormFields.HIPAAEnforced} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.HIPAAEnforced && errors.HIPAAEnforced}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Enable Journal Submissions">
                                <Field type="checkbox" name={FormFields.journalSubmissions} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.journalSubmissions && errors.journalSubmissions}</div>
                    </div>


                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Journal Prompt
                            </div>
                            <Field component="textarea" name={FormFields.journalPrompt} />
                        </label>
                        <div className="errors">{touched.journalPrompt && errors.journalPrompt}</div>
                    </div>
                    </>)}

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Notification Sent to User When Added to Group">
                                <Field type="checkbox" name={FormFields.notificationSentOnAdd} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.notificationSentOnAdd && errors.notificationSentOnAdd}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Notification Sent to Admin on User Join">
                                <Field type="checkbox" name={FormFields.notificationToAdminOnJoin} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.notificationToAdminOnJoin && errors.notificationToAdminOnJoin}</div>
                    </div>

                    { userGroup?.uid && userGroup.groupType === UserGroupTypes.user && (<>
                        {values.inviteCode && (<>
                            <div className="form-item">
                                <label>
                                    <div className="form-label">
                                        Invite Code
                                    </div>
                                    <Field type="text" name={FormFields.inviteCode} disabled={true} />
                                </label>
                                <div className="errors">{touched.inviteCode && errors.inviteCode}</div>
                            </div>

                            <div className="form-item">
                                <label>
                                    <div className="form-label">
                                        Invite Code Expiration Date
                                    </div>
                                    <DateTimePicker
                                        value={values.inviteCodeExpirationDate && moment(values.inviteCodeExpirationDate).toDate()}
                                        onChange={(v: any) => {
                                            setFieldValue(FormFields.inviteCodeExpirationDate, moment(v).format('YYYY-MM-DDTHH:mm:ss'));
                                        }}
                                        clearIcon={null}
                                        showLeadingZeros={true}
                                    />
                                </label>
                                <div className="errors">
                                    {errors.inviteCodeExpirationDate
                                    || (moment(values.inviteCodeExpirationDate) < moment() && 'Invite Code already expired')}</div>
                            </div>
                        </>)}

                        <div className="form-item">
                            <LinkButton onClick={generateInviteCode} ><FontAwesomeIcon icon={faPlus} /> Generate Invite Code</LinkButton>
                            {values.inviteCode && (
                                <LinkButton className="red" onClick={deleteInviteCode} ><FontAwesomeIcon icon={faTrashAlt} /> Delete Invite Code</LinkButton>
                            )}
                            <br /><br /><br />
                        </div>
                    </>)}

                    <div className="form-buttons">
                        <Button onClick={() => { props.history.push(getParentPath(props.match.url, 1)); }} className="gray">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>{userGroup?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>

                {!!userGroup?.userProfile && (
                    <UserUnitStatsComponent
                        profile={userGroup.userProfile}
                    />
                )}
            </div>
        );
    };

    return !userGroup ? null : (<React.Fragment key={userGroup.uid}>

        <AddBreadcrumbsItem
            title="Groups"
            url="/users/groups"
        />

        {parentUserGroup && (
            <AddBreadcrumbsItem
                title={`Org. Name: ${parentUserGroup?.title}`}
                url={getParentPath(props.match.url, 1)}
            />
        )}

        <AddBreadcrumbsItem
            title={userGroup?.uid ? `Edit: ${userGroup.title}` : 'Add User Group'}
            url={props.match.url}
        />

        <Tabs
            activeTabUid={
                props.match.url.endsWith('subgroups')
                ? 'subgroups'
                : (
                    props.match.url.endsWith('users')
                    ? 'users'
                    : undefined
                )
            }
            data={[
                {
                    title: 'Group Info',
                    content: userGroup && (
                        <div className={styles.formContainer}>
                            <Formik
                                innerRef={form}
                                initialValues={{
                                    groupType: parentUserGroup ? UserGroupTypes.user : userGroup.groupType,
                                    title: userGroup.title || '',
                                    description: userGroup.description || '',
                                    address: userGroup.address || '',
                                    city: userGroup.city || '',
                                    state: userGroup.state || '',
                                    zipCode: userGroup.zipCode || '',
                                    maxAgeRating: userGroup.maxAgeRating || 0,
                                    maxUsers: userGroup.maxUsers || 0,
                                    rewardSchema: userGroup.rewardSchema || '',
                                    userPlan: userGroup.userPlan || '',
                                    domain: userGroup.domain || '',
                                    default: userGroup.default === true,
                                    sharingEnabled: userGroup.sharingEnabled === true,
                                    HIPAAEnforced: userGroup.HIPAAEnforced === true,
                                    emailConfirmationRequired: userGroup.emailConfirmationRequired === true,
                                    inviteCode: userGroup.inviteCode?.toUpperCase(),
                                    inviteCodeExpirationDate: userGroup.inviteCodeExpirationDate,
                                    notificationSentOnAdd: userGroup.notificationSentOnAdd,
                                    notificationToAdminOnJoin: userGroup.notificationToAdminOnJoin,
                                    journalSubmissions: userGroup.journalSubmissions === true,
                                    journalPrompt: userGroup.journalPrompt || journalPromptPlaceholder,
                                    activityModeSwitchingAllowed: userGroup.activityModeSwitchingAllowed === true,
                                    limitContentByAge: userGroup.uid ? userGroup.limitContentByAge === true : true,
                                    progressSync: userGroup.uid ? userGroup.progressSync === true : true,
                                    startDate: userGroup.startDate,
                                    endDate: userGroup.endDate,
                                    unlisted: userGroup.unlisted === true,
                                    domainForConfirmation: userGroup.domainForConfirmation === true,
                                    educatorConfirmationRequired: userGroup.educatorConfirmationRequired === true,
                                    allowsInterstitials: userGroup.allowsInterstitials === true,
                                    allowsInterstitialsOnly: userGroup.allowsInterstitialsOnly === true,

                                }}
                                validationSchema={getValidationSchema}
                                onSubmit={handleSubmit}
                            >
                                {renderForm}
                            </Formik>
                        </div>
                    )
                },

                ...(userGroup?.uid && userGroup.groupType === UserGroupTypes.organization
                    ? [
                        {
                            uid: 'subgroups',
                            title: 'Groups',
                            content: (
                                <UserGroupsListComponent
                                    pagePath={/subgroups\/?$/i.exec(props.match.url) ? props.match.url : joinPath(props.match.url, 'subgroups')}
                                    parentGroup={userGroup}
                                />),
                        },
                    ] : []
                ),
                ...(userGroup?.uid
                    ? [
                        {
                            uid: 'users',
                            title: 'Users',
                            content: (<>
                                <UsersListComponent
                                    userGroup={userGroup}
                                    actionsRenderer={(user: IUser, reloadData: () => void) => (<>
                                        <LinkButton to={
                                            joinPath(
                                              props.match.url.endsWith('users')
                                                ? getParentPath(props.match.url, 1)
                                                : props.match.url,
                                                'users',
                                                user.uid,
                                            )
                                        }>
                                            <FontAwesomeIcon icon={faList} /> User Info
                                        </LinkButton><br />

                                        {userGroup.owner?.uid !== user.uid && !!user.userPermissionsForGroup?.length && (
                                            <LinkButton onClick={() => makeGroupOwner(user.uid)} className="orange">
                                               <FontAwesomeIcon icon={faKey} /> Make Owner
                                            </LinkButton>
                                        )}

                                        <LinkButton onClick={() => userGroup.uid && setEditableUser(user)}>
                                            <FontAwesomeIcon icon={faEdit} /> Permissions
                                        </LinkButton><br />
                                        <LinkButton onClick={() => setUserToRemove({ user, reloadData })} className="orange">
                                            <FontAwesomeIcon icon={faTrashAlt} /> Remove from Group
                                        </LinkButton>
                                    </>)}
                                    onUserAddClick={() => setVisibleAddUserPopup(true)}
                                    updateFlag={userListUpdatedAt}
                                />

                                {visibleAddUserPopup && (
                                    <Popup
                                        title="Add User to the Group"
                                        onClose={() => setVisibleAddUserPopup(false)}
                                        buttons={[{
                                            title: "Cancel",
                                            onClick: () => setVisibleAddUserPopup(false),
                                        }]}
                                    >
                                        <SelectUsersList
                                            roles={[UserRoles.editor, UserRoles.tester, UserRoles.user]}
                                            onChange={async (u: IUser) => {
                                                await addUserToGroup(u.uid);
                                                setUsersListUpdatedAt();
                                                setVisibleAddUserPopup(false);
                                            }}
                                            isNotInUserGroup={userGroup}
                                        />
                                    </Popup>
                                )}

                                {editableUser && (
                                    <Popup
                                        title="Edit permissions"
                                        onClose={() => setEditableUser(undefined)}
                                    >
                                        <GroupPermissionsEditor
                                            group={userGroup}
                                            user={editableUser}
                                            permissions={editableUser.userPermissionsForGroup}
                                            reloadData={setUsersListUpdatedAt}
                                            onClose={() => setEditableUser(undefined)}
                                        />
                                    </Popup>
                                )}

                                {userToRemove && (
                                    <DeleteQuestionPopup
                                        title="Remove user from the group"
                                        question={`Are you sure want remove user: ${userToRemove?.user.firstName} ${userToRemove?.user.lastName}`}
                                        successText="User has been successfully removed from the group"
                                        onClose={() => setUserToRemove(undefined)}
                                        onDelete={() => removeUserFromGroup(userToRemove.user.uid)}
                                        onFinish={() => {
                                            userToRemove.reloadData();
                                            setUserToRemove(undefined);
                                        }}
                                    />
                                )}

                            </>),
                        },
                        {
                            title: 'Curricula',
                            content: (<UserGroupCurriculumsList userGroup={userGroup} reloadUserGroup={loadData} />),
                        },
                        {
                            title: 'Invites',
                            content: (<UserGroupInvitesList userGroup={userGroup} reloadUserGroup={loadData} />),
                        },
                        ...(userGroup.groupType === UserGroupTypes.editor
                            ? [
                                {
                                    title: 'Narratives',
                                    content: (<UserGroupNarrativesList userGroup={userGroup} reloadUserGroup={loadData} />),
                                },
                            ]
                            : []
                        )
                    ]
                    : []
                )
            ]}
        />
    </React.Fragment>);
}

const UserEditorWithRouter = withRouter(UserGroupEditor);
export { UserEditorWithRouter as UserGroupEditor };
