/* eslint-disable max-len */
import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';
import { IActionTendencyForm } from '../../../models/action-tendency';

const ACTION_TENDENCY_MUTATION = gql`
    mutation SaveActionTendency($actionTendencyData: ActionTendencyInput!, $imageFile: Upload) {
        action_tendency_save(action_tendency_data: $actionTendencyData, imageFile: $imageFile) {
            success,
            error_msg,
            success_msg,
            action_tendency {
                uid,
                label
            }
        }
    }
`;

export async function actionTendencySave(actionTendency: IActionTendencyForm, imageFile?: File): Promise<{ success: boolean, error_msg?: string, success_msg?: string, uid: string, label: string }> {
    const result: FetchResult<{ action_tendency_save: { success: boolean, error_msg?: string, success_msg?: string, action_tendency: { uid: string, label: string } } }> = await graphqlClient.mutate({
        mutation: ACTION_TENDENCY_MUTATION,
        variables: { 
            actionTendencyData: {
                uid: actionTendency.uid,
                label: actionTendency.label,
            },
            imageFile
        },
        errorPolicy: 'all',
    });
  
    if (!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }
  
    return {
        success: result.data.action_tendency_save.success,
        uid: result.data.action_tendency_save.action_tendency?.uid || '',
        label: result.data.action_tendency_save.action_tendency?.label || '',
        error_msg: result.data.action_tendency_save.error_msg,
        success_msg: result.data.action_tendency_save.success_msg // Include this line
    };
}

