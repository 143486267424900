import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IListResult } from '../../../models/list-result';
import { IUser } from '../../../models/user';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

function getQuery(isForGroup: boolean) {

    return gql`
        query UsersGetPaged($page: Int, $searchText: String, $orderBy: String, $groupUid: String, $notGroupUid: String, $narrativeUid: String, $roles: [String], $pageSize: Int) {
            users_get_paged(page: $page, search_text: $searchText, order_by: $orderBy, group_uid: $groupUid, not_group_uid: $notGroupUid narrative_uid: $narrativeUid roles: $roles, page_size: $pageSize) {
                count,
                page,
                pageSize: page_size,

                data {
                    uid,
                    firstName: first_name,
                    lastName: last_name,
                    email,
                    role,
                    disabled,
                    hasCurricula: has_curricula,
                    extensionInstalled: extension_installed,
                    currentLevel: current_level,
                    lastEngagement: last_engagement,
                    lastUnit: last_unit
                    hasFullAccess: has_full_access,
                    ${isForGroup ? 'userPermissionsForGroup: user_permissions_for_group,' : ''}
                }
            }
        }
    `;
}

export async function getUsersPaged(params: {
    page: number,
    searchText?: string,
    orderBy?: string[],
    groupUid?: string,
    notGroupUid?: string,
    narrativeUid?: string,
    roles?: string[],
    pageSize?: number,
}): Promise<IListResult<IUser>> {
    const result: FetchResult<{ users_get_paged: IListResult<IUser> }> = await graphqlClient.query({
        query: getQuery(!!params.groupUid),
        variables: {
            page: params.page,
            searchText: params.searchText,
            orderBy: params.orderBy?.join(';'),
            groupUid: params.groupUid,
            notGroupUid: params.notGroupUid,
            narrativeUid: params.narrativeUid,
            roles: params.roles,
            pageSize: params.pageSize || 20,
        },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.users_get_paged, ['__typename']);
}
