import { FetchResult, gql } from 'apollo-boost';
import { IAppraisal } from '../../../models/appraisal';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query AppraisalsByParticipant($uid: String!){
        appraisals_by_participant(uid: $uid) {
            uid,
            description,
            goalCongruent: goal_congruent,
        }
    }
`;

export async function getAppraisalsByParticipant(participantUid: string): Promise<IAppraisal[]> {
    const result: FetchResult<{ appraisals_by_participant: IAppraisal[]}> = await graphqlClient.query({
        query: QUERY,
        variables: { uid: participantUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.appraisals_by_participant;
}
