import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { emotionEpisodesSearch } from '../../api/actions/emotion-episode/emotion-episode-search';
import { emotionEpisodeLink } from '../../api/actions/emotion-episode/emotion-episode-link';
import { MainLayout } from '../../components/layouts/main-layout';
import { GlobalSearchContext } from '../../components/layouts/search';
import { AddBreadcrumbsItem } from '../../components/shared/breadcrumbs/breadcrumbs';
import { LinkButton } from '../../components/shared/link-button/link-button';
import { Loader } from '../../components/shared/loader/loader';
import { compareText } from '../../helpers/array-helpers';
import { secondsToTimeString } from '../../helpers/datetime-helpers';
import { basePerformError } from '../../helpers/error-helpers';
import { joinPath } from '../../helpers/path-helpers';
import { IEmotionEpisode } from '../../models/emotion-episode';
import { SecuredPage } from '../base/secured-page';
import styles from './search-result-page.module.scss';

export function SearchResultPage(props: RouteComponentProps) {

    const globalSearchContext = React.useContext(GlobalSearchContext);

    const [loading, setLoading] = useState(false);
    const [emotionEpisodes, setEmotionEpisodes] = useState<IEmotionEpisode[]>();

    useEffect(() => {
        if(!globalSearchContext?.state) return;
        setLoading(true);

        emotionEpisodesSearch(globalSearchContext.state)
            .then((result) => {
                setEmotionEpisodes(result);
            })
            .catch(() => {/** */})
            .finally(() => setLoading(false));

    }, [globalSearchContext?.state])

    const linkToExternalVideo = async (uid: string) => {
        if(!uid) return;
        try {
            const url = await emotionEpisodeLink(uid);
            if(url) {
              //console.log("got emotion episode link: ", url);
              window.open(url);
            } else {
              console.log("failed to get emotion episode link");
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };


    return (
        <SecuredPage pageName="search" {...props} >

            <AddBreadcrumbsItem
                title="Home"
                url="/"
            />
            <AddBreadcrumbsItem
                title="Search"
                url="/search"
            />

            <MainLayout>
                <div>
                    {loading ? (<Loader />) : (<>
                        <div className="list-title">
                            <div>Emotion Episodes Result: <strong>{ (emotionEpisodes?.length) || 0 }</strong></div>
                        </div>
                        { !emotionEpisodes?.length ? null : (
                            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Absolute Start</th>
                                        <th>Start</th>
                                        <th>End</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        emotionEpisodes?.sort((ee1, ee2) => compareText(ee1, ee2, (ee) => ee.scene?.narrative?.title || ''))
                                        .map((ee: IEmotionEpisode) => (
                                            <tr key={ee.uid}>
                                                <td>
                                                    <div className={styles.markContainer}>
                                                        <div className={`${styles.mark} ${ee.available ? '' : styles.disabled}`}>&nbsp;</div>
                                                        <div className={styles.title}>
                                                            {ee.description}
                                                            <div className={styles.scene}>
                                                                <span>Scene: </span>
                                                                {ee.scene?.description}
                                                            </div>
                                                            {ee.scene?.episode && (
                                                                <div className={styles.episode}>
                                                                    <span>Episode: </span>
                                                                    {`S-${ee.scene.episode.season}: E-${ee.scene.episode.numberInSeason}: `}
                                                                    {ee.scene.episode.name}
                                                                </div>
                                                            )}
                                                            <div className={styles.narrative}>
                                                                <span>Narrative: </span>
                                                                {ee.scene?.narrative?.title}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {ee.absoluteStart}
                                                </td>
                                                <td>
                                                    {secondsToTimeString(ee.start, true)}
                                                </td>
                                                <td>
                                                    {secondsToTimeString(ee.end, true)}
                                                </td>
                                                <td>
                                                <LinkButton onClick={() => linkToExternalVideo(ee.uid)} className='green'
                                                >
                                                    <FontAwesomeIcon icon={faExternalLinkAlt} /> Go to Video Clip
                                                </LinkButton><br />

                                                    <LinkButton
                                                        to={joinPath(
                                                            '/narratives',
                                                            ee.scene?.narrative?.uid,
                                                            'scenes',
                                                            ee.scene?.uid,
                                                            'emotion-episodes',
                                                            ee.uid,
                                                        )}
                                                    >
                                                        <FontAwesomeIcon icon={faExternalLinkAlt} /> Go to Emotion Episode
                                                    </LinkButton><br />
                                                    <LinkButton
                                                        to={joinPath(
                                                            '/narratives',
                                                            ee.scene?.narrative?.uid,
                                                            'scenes',
                                                            ee.scene?.uid
                                                        )}
                                                        className="gray"
                                                    >
                                                        <FontAwesomeIcon icon={faExternalLinkAlt} /> Go to Scene
                                                    </LinkButton><br />
                                                    <LinkButton
                                                        to={joinPath(
                                                            '/narratives',
                                                            ee.scene?.narrative?.uid,
                                                        )}
                                                        className="blue"
                                                    >
                                                        <FontAwesomeIcon icon={faExternalLinkAlt} /> Go to Narrative
                                                    </LinkButton><br />

                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        )}
                    </>)}
                </div>
            </MainLayout>
        </SecuredPage>
    )
}
