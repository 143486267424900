import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserSendCertEmail($uid: String!, $userUid: String!, $toAdmin: Boolean){
        ca_send_cert_email_to_user (uid: $uid, user_uid: $userUid, to_admin: $toAdmin) {
            success
        }
    }
`;

export async function userSendCertEmail(uid: string, userUid: string, toAdmin: boolean): Promise<{ success: boolean }> {
    const result: FetchResult<{ ca_send_cert_email_to_user: { success: boolean }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { uid, userUid, toAdmin },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.ca_send_cert_email_to_user;
}