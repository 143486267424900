export enum EntityTypes {
    appraisal = 'Appraisal',
    belief = 'Belief',
    emotion = 'Emotion',
    emotionEpisode = 'EmotionEpisode',
    episode = 'Episode',
    goal = 'Goal',
    initiatingEvent = 'InitiatingEvent',
    narrative = 'Narrative',
    participant = 'Participant',
    regulationStrategy = 'RegulationStrategy',
    scene = 'Scene',
}