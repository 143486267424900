import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getAllSkills } from '../../../api/actions/skills-get-all';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { compareText, repeat } from '../../../helpers/array-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import { ISkill } from '../../../models/skill';
import styles from './skills-list.module.scss';


export function SkillsList(props: RouteComponentProps) {
    const [skills, setSkills] = useState<ISkill[]>([]);

    const loadData = React.useCallback(async () => {
        try {
            setSkills(buildTree(await getAllSkills()));
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history]);

    useEffect(() => {
        loadData();
    }, [loadData]);


    const buildTree = (elements: ISkill[]) => {
        if(!elements) return elements;

        const roots = elements.filter(ec => !ec.parent)
            .sort((el1, el2) => compareText(el1, el2, (obj) => obj && obj.name));

        roots.forEach(el => buildTreeNode(el, elements));

        return roots;
    }

    const buildTreeNode = (element: ISkill, elements: ISkill[]) => {
        element.children = elements.filter(ec => ec.parent && ec.parent.includes(element.uid))
            .sort((el1, el2) => compareText(el1, el2, (obj) => obj && obj.name));

            element.children.forEach(el => buildTreeNode(el, elements));


    }

    const printLevel = (elements?: ISkill[], level = 0) => elements
        && elements.map((el => (
            <React.Fragment key={el.uid}>
                <tr key={el.uid}>
                    <td>
                        {!!level && repeat(level, () => (
                            <span>
                                &raquo;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        ))}
                        {el.name}
                    </td>
                    <td>
                        {el.uid}
                    </td>
                    <td>
                        {el.description}
                    </td>
                </tr>
                {printLevel(el.children, level + 1)}
            </React.Fragment>
        )));


    return !skills ? null : (
        <>
            <AddBreadcrumbsItem
                title="Skills"
                url={'/libraries/skills'}
            />

            <table cellPadding="0" cellSpacing="0" className={`list`}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>ID</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    { printLevel(skills) }
                </tbody>
            </table>
        </>
    );
}
