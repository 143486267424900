import { FetchResult, gql } from 'apollo-boost';
import { IUser } from '../../../models/user';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query {
        user {
            uid,
            role,
            superAdmin: super_admin,
            email,
            firstName: first_name,
            lastName: last_name,
            configurationFinished: configuration_finished,

            userGroups: user_groups {
                uid,
                groupType: group_type,
                HIPAAEnforced: HIPAA_enforced,

                curriculums: curricula_for_editing {
                    uid,
                    name,
                    description,
                    available,
                },


                narratives {
                    uid,
                    title,
                    description,
                    available,
                },
            },

            narratives {
                uid,
            },

            characters {
                uid,
            },

            completedUnits: completed_units {
                uid,
            }
        }
    }
`;

export async function getCurrentUser(): Promise<IUser> {
    const result: FetchResult<{ user: IUser }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user;
}
