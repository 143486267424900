import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../../components/layouts/main-layout';
import { AddBreadcrumbsItem } from '../../components/shared/breadcrumbs/breadcrumbs';
import { SecuredPage } from '../base/secured-page';
import { CurriculumEditor } from './curriculum/curriculum-editor';
import { CurriculumsList } from './curriculum/curriculums-list';
import { LearningChunkEditor } from './learning-chunk/learning-chunk-editor';
import { UnitSectionEditor } from './unit-section/unit-section-editor';
import { UnitEditor } from './unit/unit-editor';


export function CurriculumsManagementPage(props: RouteComponentProps) {

    return (
        <SecuredPage pageName="curriculums" {...props} >

            <AddBreadcrumbsItem
                title="Home"
                url="/"
            />
            <AddBreadcrumbsItem
                title="Curricula"
                url="/curriculums"
            />

            <MainLayout>
                <Route exact
                    path={props.match.url} component={CurriculumsList}
                />
                <Route exact
                    path={`${props.match.url}/:uid`} component={CurriculumEditor}
                />
                <Route exact
                    path={`${props.match.url}/:curriculumUid/units/:uid`} component={UnitEditor}
                />
                <Route exact
                    path={`${props.match.url}/:curriculumUid/units/:unitUid/unit-sections/:uid`} component={UnitSectionEditor}
                />
                <Route exact
                    path={`${props.match.url}/:curriculumUid/units/:unitUid/unit-sections/:unitSectionUid/learning-chunks/:uid`}
                    component={LearningChunkEditor}
                />
            </MainLayout>
        </SecuredPage>
    );
}
