import Cookies from 'js-cookie';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppConfig } from '../../config/app-config';

interface IProps extends RouteComponentProps {
    pageName: string;
    children: string | React.ReactElement | Array<string | React.ReactElement>;
}

export function SecuredPage(props: IProps) {
    if(!Cookies.get(AppConfig.tokenName)) {
        props.history.push('/login');
    }

    return (
        <div className={`page ${props.pageName}`}>
            {props.children}
        </div>
    );
}
