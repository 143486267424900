import { FetchResult, gql } from 'apollo-boost';
import { IVideoService } from '../../../models/video-service';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query {
        video_services {
            uid,
            identifier,
            name,
            url,
            available,
            comingSoon: coming_soon,
            platforms
        }
    }
`;

export async function getAllVideoServices(): Promise<IVideoService[]> {
    const result: FetchResult<{ video_services: IVideoService[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.video_services;
}
