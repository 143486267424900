import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { FlaggedResponseViewer } from './flagged-response-viewer';
import { FlaggedResponseList } from './flagged-response-list';


export function FlagsManagementPage(props: RouteComponentProps) {

    return (<>
        <Route exact
            path={props.match.url} component={FlaggedResponseList}
        />
        <Route exact
            path={`${props.match.url}/:uid`} component={FlaggedResponseViewer}
        />
    </>);
}
