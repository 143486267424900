import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const MUTATION = gql`
    mutation AdminLogin($email: String!, $password: String!){
        admin_login (email: $email, password: $password) {
            configurationFinished: configuration_finished,
            accessToken: access_token,
        }
    }
`;

export async function login(email: string, password: string): Promise<{ configurationFinished: boolean, accessToken: string }> {
    const result: FetchResult<{ admin_login: { configurationFinished: boolean, accessToken: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { email, password },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.admin_login;
}
