import * as React from 'react';

interface IProps {
    pageName: string,
    children: string | React.ReactElement | Array<string | React.ReactElement>,
}

export function PublicPage(props: IProps) {
    return (
        <div className={`page ${props.pageName}`}>
            {props.children}
        </div>
    );
}
