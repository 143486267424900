import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { beliefDelete } from '../../../../../../api/actions/belief/belief-delete';
import { Button } from '../../../../../../components/shared/button/button';
import { LinkButton } from '../../../../../../components/shared/link-button/link-button';
import { Popup } from '../../../../../../components/shared/popup/popup';
import { cutStr } from '../../../../../../helpers/string-helpers';
import { IBelief } from '../../../../../../models/belief';
import { BeliefEditor } from './belief-editor';
import { basePerformError } from '../../../../../../helpers/error-helpers';
import { CommentButton } from '../../../../../../components/comments/comment-button';
import { EntityTypes } from '../../../../../../models/enums/entity-types';

interface IProps extends RouteComponentProps {
    narrativeUid: string,
    participantUid: string,
    title: string,
    beliefs: IBelief[],
    holds: boolean,
    reload(): void,
}

function BeliefManagement(props: IProps) {

    const [editPopupVisible, setEditPopupVisible] = useState(false);
    const [editableEntity, setEditableEntity] = useState<IBelief>();
    const [entityForDelete, setEntityForDelete] = useState<IBelief>();

    return (<>
        <div className="list-title">
            <div>{props.title}:
                <strong> { ( props.beliefs && props.beliefs.length) || 0 }</strong>
            </div>
            <div>
                <Button
                    className="orange"
                    onClick={() => {
                        setEditableEntity(undefined);
                        setEditPopupVisible(true);
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} /> <span>Add Belief</span>
                </Button>
            </div>
        </div>
        { props.beliefs && !!props.beliefs.length && (
            <table cellPadding="0" cellSpacing="0" className="list">
                <thead>
                    <tr>
                        <th>Description</th>
                        { props.holds && (<th>Appraisal</th>) }
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.beliefs
                        .sort((i1, i2) => i1.description > i2.description ? 1 : (i1.description < i2.description ? -1 : 0) )
                        .map((el: IBelief) => (
                            <tr key={el.uid}>
                                <td>
                                    {el.description}
                                </td>
                                { props.holds && (<td>
                                    {el.appraisal && el.appraisal.description}
                                </td>)}
                                <td>
                                    <LinkButton
                                        onClick={() => {
                                            setEditableEntity(el);
                                            setEditPopupVisible(true);
                                        } }
                                    >
                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                    </LinkButton><br />
                                    <LinkButton
                                        className="red"
                                        onClick={async () => {
                                            setEntityForDelete(el);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                    </LinkButton><br />
                                    <CommentButton
                                        isLink={true}
                                        entityType={EntityTypes.belief}
                                        entityUid={el.uid}
                                        taskEntityType={EntityTypes.narrative}
                                        taskEntityUid={props.narrativeUid}
                                    />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )}

        { editPopupVisible && (
            <Popup
                title={editableEntity ? 'Edit Belief' : 'Add Belief'}
                modal={true}
            >
                <BeliefEditor
                    uid={editableEntity?.uid}
                    onClose={() => setEditPopupVisible(false)}
                    holds={props.holds}
                    participantUid={props.participantUid}
                    reload={props.reload}
                />
            </Popup>
        )}

        { entityForDelete && (
            <Popup
                modal={true}
                className="red"
                title="Delete Belief"
                onClose={() => setEntityForDelete(undefined) }
                buttons={[
                    {
                        title: <><FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span></>,
                        className: 'gray',
                        onClick: () => setEntityForDelete(undefined),
                    },
                    {
                        title: <><span>Delete</span> <FontAwesomeIcon icon={faTrashAlt} /></>,
                        className: 'red',
                        onClick: async () => {
                            try {
                                const result = await beliefDelete(entityForDelete.uid);
                                if(result.success) {
                                    props.reload();
                                    toast.success('Item has been successfully delete');
                                    setEntityForDelete(undefined);
                                }
                            }
                            catch(err) {
                                basePerformError(err, props.history);
                            }
                        },
                    },
                ]}
            >
                Are you sure want to delete belief: <strong>{cutStr(entityForDelete.description)}</strong>?
            </Popup>
        )}
    </>);
}

const BeliefManagementWithRouter = withRouter(BeliefManagement);
export { BeliefManagementWithRouter as BeliefManagement };

