import { ICharacter } from './character';
import { IEpisode } from './episode';
import { IExternalReference } from './external-reference';
import { IScene } from './scene';
import { ITopic } from './topic';
import { PlatformTypes} from './enums/platform-types';

export enum NarrativeTypes {
    book = 'book',
    film = 'film',
    series = 'series',
}

export const NarrativeAvailabiltyFilter = [
 { name :'enabled',uid:'1'},
 { name :'disabled',uid:'0'},
]

export const ContentAgeRating: Record<number, string> = {
    0: 'All (0+)',
    7: 'Older Kids (7+)',
    13: 'Teens (13+)',
    16: 'Young Adults (16+)',
    18: 'Adults (18+)',
};


export interface INarrative {
    uid: string,
    title: string,
    description: string,
    mediaType: NarrativeTypes,
    platformType: PlatformTypes,
    platforms?: string[],
    fileName?: string,
    audioSignatureFileName?: string,
    audioCatalogFileName?: string,
    soundtrackFileName?: string,
    imageFile?: File,
    contentUrl?: string,
    available?: boolean,
    enabledAt?: string,
    minAgeRating: number,
    comingSoon?: boolean,
    status?: string,
    duration?: number,

    tasksAvailableForUserCount?: number,

    episodes?: IEpisode[],
    characters?: ICharacter[],
    scenes?: IScene[],
    topics?: ITopic[],

    endSurveyUid?: string,

    externalReferences?: IExternalReference[],

    selected?: boolean,
}
