import { faArrowLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { pipOptionDelete } from '../../../api/actions/pip-option/pip-option-delete';
import { getPipOption } from '../../../api/actions/pip-option/pip-option-get';
import { pipOptionsSave } from '../../../api/actions/pip-option/pip-option-save';
import { Block } from '../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { basePerformError } from '../../../helpers/error-helpers';
import { getParentPath, joinPath } from '../../../helpers/path-helpers';
import { IPipOption } from '../../../models/pip-option';
import { IDictionary } from '../../../types/dictionary';
import styles from './pip-option-editor.module.scss';


enum FormFields {
    name = 'name',
    earnOptionId = 'earnOptionId',
    amount = 'amount',
    description = 'description',
}

interface IForm {
    [FormFields.name]: string,
    [FormFields.earnOptionId]: string,
    [FormFields.amount]: number | null,
    [FormFields.description]?: string,
}

export function PipOptionsEditor(props: RouteComponentProps<{uid: string}>) {

    const [loading, setLoading] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [pipOption, setPipOption] = useState<IPipOption>();

    const loadData = useCallback(async (uid?: string) => {
        try {
            if(uid || props.match.params.uid !== '_') {
                setPipOption(await getPipOption(uid || props.match.params.uid));
            }
            else {
                setPipOption({} as IPipOption);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData().catch(() => {/** */});
    }, []);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newPipOption: Partial<IPipOption> = {
                uid: pipOption?.uid,
                name: values.name,
                earnOptionId: values.earnOptionId,
                amount: values.amount || 0,
                description: values.description,
            };
            
            const uid = await pipOptionsSave(newPipOption);
            if(!pipOption?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            }
            await loadData(uid);
            toast.success('Item has been successfully saved');
        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().label('Name').trim().required().min(2).max(50),
            earnOptionId: Yup.string().label('Earn Option ID').trim().required().min(3).max(50),
            amount: Yup.number().label('Amount').required().integer(),
            description: Yup.string().label('Description').trim().min(3).max(250),
        });
    };

    const deletePipOption = async () => {
        if(!pipOption?.uid) return;
        await pipOptionDelete(pipOption?.uid);
        setDeleteDialogVisible(false);
    };

    const renderForm = ({ errors, touched }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>

                    <div className="form-buttons">
                        <div></div>
                        { pipOption?.uid && (
                            <LinkButton onClick={() => setDeleteDialogVisible(true)} className="red"><FontAwesomeIcon icon={faTrashAlt} /> Delete</LinkButton>
                        )}
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name
                            </div>
                            <Field type="text" name={FormFields.name} />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Earn Option ID
                            </div>
                            <Field type="text" name={FormFields.earnOptionId} />
                        </label>
                        <div className="errors">{touched.earnOptionId && errors.earnOptionId}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Amount
                            </div>
                            <Field type="number" name={FormFields.amount} />
                        </label>
                        <div className="errors">{touched.amount && errors.amount}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field component="textarea" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    

                    <div className="form-buttons">
                        <Button className="gray" to={getParentPath(props.match.url, 1)}>
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>Save</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !pipOption ? null : (<>
        <Block className={styles.editor}>

            <AddBreadcrumbsItem
                title="PIP Options"
                url={getParentPath(props.match.url, 1)}
            />
            <AddBreadcrumbsItem
                key={pipOption.uid}
                title={pipOption.uid ? `Edit: ${pipOption.name}` : 'Add PIP Option'}
                url={props.match.url}
            />
            <Formik<IForm>
                initialValues={{
                    name: pipOption?.name || '',
                    earnOptionId: pipOption?.earnOptionId || '',
                    amount: pipOption?.amount || null,
                    description: pipOption?.description || '',
                }}
                validationSchema={getValidationSchema}
                onSubmit={handleSubmit}
            >
                {renderForm}
            </Formik>
        </Block>

        {deleteDialogVisible && (
            <DeleteQuestionPopup
                title="PIP Option Deletion"
                question={<>Are you sure want to delete PIP Option?</>}
                onClose={() => setDeleteDialogVisible(false)}
                onDelete={deletePipOption}
                onFinish={() => { props.history.push(getParentPath(props.match.url, 1)) }}
            />
        )}
    </>);
}
