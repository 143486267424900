import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { emotionDelete } from '../../../../../../api/actions/emotion/emotion-delete';
import { CommentButton } from '../../../../../../components/comments/comment-button';
import { Button } from '../../../../../../components/shared/button/button';
import { LinkButton } from '../../../../../../components/shared/link-button/link-button';
import { Popup } from '../../../../../../components/shared/popup/popup';
import { basePerformError } from '../../../../../../helpers/error-helpers';
import { cutStr } from '../../../../../../helpers/string-helpers';
import { IEmotion } from '../../../../../../models/emotion';
import { EntityTypes } from '../../../../../../models/enums/entity-types';
import { EmotionEditor } from './emotion-editor';

interface IProps extends RouteComponentProps {
    narrativeUid: string,
    participantUid: string,
    title: string,
    emotions: IEmotion[],
    reload(): void,
}

function EmotionManagement(props: IProps) {

    const [editPopupVisible, setEditPopupVisible] = useState(false);
    const [editableEntity, setEditableEntity] = useState<IEmotion>();
    const [entityForDelete, setEntityForDelete] = useState<IEmotion>();

    return (<>
        <div className="list-title">
            <div>{props.title}:
                <strong> { ( props.emotions && props.emotions.length) || 0 }</strong>
            </div>
            <div>
                <Button
                    className="orange"
                    onClick={() => {
                        setEditableEntity(undefined);
                        setEditPopupVisible(true);
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} /> <span>Add Emotion</span>
                </Button>
            </div>
        </div>
        { props.emotions && !!props.emotions.length && (
            <table cellPadding="0" cellSpacing="0" className="list">
                <thead>
                    <tr>
                            <th>Category</th>
                            <th>Description</th>
                            <th>Age Rating</th>
                            <th>Intensity</th>
                            <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.emotions?.sort((i1, i2) => i1.category.uid > i2?.category.uid ? 1 : (i1.category.uid < i2.category.uid ? -1 : 0) )
                        .map((el: IEmotion) => (
                            <tr key={el.uid}>
                                <td>
                                    {el.category.uid}
                                </td>
                                <td>
                                    {el.description}
                                </td> 
                                <td>
                                    {el.category.minAgeRating}
                                </td>
                                <td>
                                    {el.intensity}
                                </td>
                                <td>
                                    <LinkButton
                                        onClick={() => {
                                            setEditableEntity(el);
                                            setEditPopupVisible(true);
                                        } }
                                    >
                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                    </LinkButton><br />
                                    <LinkButton
                                        className="red"
                                        onClick={async () => {
                                            setEntityForDelete(el);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                    </LinkButton><br />
                                    <CommentButton
                                        isLink={true}
                                        entityType={EntityTypes.emotion}
                                        entityUid={el.uid}
                                        taskEntityType={EntityTypes.narrative}
                                        taskEntityUid={props.narrativeUid}
                                    />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )}

        { editPopupVisible && (
            <Popup
                title={editableEntity ? 'Edit Emotion' : 'Add Emotion'}
                modal={true}
            >
                <EmotionEditor
                    uid={editableEntity?.uid}
                    onClose={() => setEditPopupVisible(false)}
                    participantUid={props.participantUid}
                    reload={props.reload}
                />
            </Popup>
        )}

        { entityForDelete && (
            <Popup
                modal={true}
                className="red"
                title="Delete Emotion"
                onClose={() => setEntityForDelete(undefined) }
                buttons={[
                    {
                        title: <><FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span></>,
                        className: 'gray',
                        onClick: () => setEntityForDelete(undefined),
                    },
                    {
                        title: <><span>Delete</span> <FontAwesomeIcon icon={faTrashAlt} /></>,
                        className: 'red',
                        onClick: async () => {
                            try {
                                const result = await emotionDelete(entityForDelete.uid);
                                if(result.success) {
                                    props.reload();
                                    toast.success('Item has been successfully delete');
                                    setEntityForDelete(undefined);
                                }
                            }
                            catch(err) {
                                basePerformError(err, props.history);
                            }
                        },
                    },
                ]}
            >
                Are you sure want to delete emotion: <strong>{cutStr(entityForDelete.category.uid)}</strong>?
            </Popup>
        )}
    </>);
}

const EmotionManagementWithRouter = withRouter(EmotionManagement);
export { EmotionManagementWithRouter as EmotionManagement };
