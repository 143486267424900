import { FetchResult, gql } from 'apollo-boost';
import { ICharacter } from '../../../models/character';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query Character($uid: String!){
        character(uid: $uid) {
            uid,
            name,
            shortName: short_name,
            description,
            importance,
            gender,
            age,
            race,
            ethnicity,
            fileName: file_name,
        }
    }
`;

export async function getCharacter(uid: string): Promise<ICharacter> {
    const result: FetchResult<{ character: ICharacter}> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.character;
}
