import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useState } from 'react';
import {
  getBasicFileName,
  getFullFileName,
  isDataUrl,
  joinPath,
  isAbsoluteUrl,
} from '../../../helpers/path-helpers';
import { IFile } from '../../../models/file';
import { Loader } from '../loader/loader';
import styles from './uploader.module.scss';

interface IProps {
  onDelete: () => void;
  file: IFile;
  path: string;
}

export function UploaderImagePreview(props: IProps) {
  console.log('uploader image props >>> ', props);
  const [url, setUrl] = useState(props.file.url);
  const fileName = getFullFileName(
    props.file.url || props.file.file?.name || 'Unknown'
  );
  if (props.file.file) {
    new Promise((resolve) => {
      if (props.file.file) {
        const reader = new FileReader();
        reader.readAsDataURL(props.file.file);
        reader.onloadend = () => resolve(reader.result?.toString());
      }
    })
      .then((dataUrl) => setUrl((dataUrl as any).toString()))
      .catch(() => {
        /** */
      });
  }
  return (
    <div className={styles.imagePreview} title={fileName}>
      <div className={styles.imageContainer}>
        {url && (
          <img
            src={
              isDataUrl(url) || isAbsoluteUrl(url)
                ? url
                : joinPath(props.path, url)
            }
            alt={getBasicFileName(fileName)}
          />
        )}
        {!url && <Loader />}
      </div>
      <div className={styles.fileName}>
        <span>{getBasicFileName(fileName)}</span>

        {props.onDelete && (
          <div className={styles.deleteBtn} onClick={props.onDelete}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </div>
        )}
      </div>
    </div>
  );
}
