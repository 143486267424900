import { faCheck, faSquareFull } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { CheckboxGroupContext, ICheckboxGroupContextProps } from './checkbox-group';
import styles from './checkbox.module.scss';

interface IProps<T> {
    value: T,
    checked?: boolean | null,
    disabled?: boolean,
    disabledLabel?: boolean,
    className?: string,
    children?: any,
    onChange?(val?: T): void,
}


function CheckboxElement<T>(props: IProps<T> & { checkboxGroupContextValue?: ICheckboxGroupContextProps }) {

    const groupElementUid = useRef<string | undefined>(undefined);

    const [checked, setChecked] = useState<boolean | null | undefined>(props.checked)

    // subscribe to group and unsubscribe from group
    useEffect(() => {
        if(!props.disabled) {
            if(props.checkboxGroupContextValue && !groupElementUid.current) {
                groupElementUid.current = props.checkboxGroupContextValue.registerCheckbox(checked);
            }
            return () => props.checkboxGroupContextValue?.unregisterCheckbox(groupElementUid.current)
        }
    }, [props.disabled]);

    // update component state if it was changed outside
    useEffect(() => {
        setState(props.checked);
    }, [props.checked]);

    const setState = (newState?: boolean | null) => {
        if(checked !== newState) {
            setChecked(newState);

            if(props.checkboxGroupContextValue && props.checkboxGroupContextValue?.state !== newState) {
                props.checkboxGroupContextValue.onChangeCallback(groupElementUid.current, newState);
            }

            if(props.onChange && props.checked !== newState) {
                props.onChange(newState ? props.value : undefined);
            }
        }
    }

    // change checkbox state to group root state
    useEffect(() => {
        if(props.checkboxGroupContextValue?.state === false || props.checkboxGroupContextValue?.state === true) {
            setChecked(props.checkboxGroupContextValue?.state);

            if(props.onChange) {
                props.onChange(props.checkboxGroupContextValue?.state ? props.value : undefined);
            }
        }
    }, [props.checkboxGroupContextValue?.state]);

    const onChange = () => setState(!checked);

    return (
        <div className={`${styles.checkboxContainer} ${props.disabled && styles.disabled} ${checked && styles.checked} ${props.className}`}>
            <div className={styles.checkbox} onClick={props.disabled ? undefined : onChange}>
                {checked && (<FontAwesomeIcon icon={faCheck} />)}
                {checked === null && (<FontAwesomeIcon icon={faSquareFull} />)}
            </div>
            <div className={`${styles.label} ${props.disabledLabel && styles.disabledLabel}`} onClick={props.disabled || props.disabledLabel ? undefined : onChange}>{props.children}</div>
        </div>
    )
}


export function Checkbox<T>(props: IProps<T>) {
    return (
        <CheckboxGroupContext.Consumer>
            {(value) => <CheckboxElement {...props} checkboxGroupContextValue={value} />}
        </CheckboxGroupContext.Consumer>
    );
}