import { faTrashAlt, faCopy } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faArrowRight, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { getAllConcepts } from '../../../api/actions/concepts-get-all';
import { learningChunkDelete } from '../../../api/actions/learning-chunk/learning-chunk-delete';
import { learningChunkDisable } from '../../../api/actions/learning-chunk/learning-chunk-disable';
import { learningChunkEnable } from '../../../api/actions/learning-chunk/learning-chunk-enable';
import { getLearningChunk } from '../../../api/actions/learning-chunk/learning-chunk-get';
import { learningChunkSave } from '../../../api/actions/learning-chunk/learning-chunk-save';
import { getAllSkills } from '../../../api/actions/skills-get-all';
import { getUnitSection } from '../../../api/actions/unit-section/unit-section-get';
import { Block } from '../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { CKEditorField } from '../../../components/shared/ckeditor/ckeditor';
import { FileBrowserPopup } from '../../../components/shared/ckeditor/file-browser-popup';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { MultiRowEditorField } from '../../../components/shared/multirow-editor/multirow-editor';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { UploaderField } from '../../../components/shared/uploader/uploader';
import { getLearningChunkFilePath, LEARNING_CHUNK_FILE_URL_PLACEHOLDER, getExerciseFilePath } from '../../../config/app-config';
import { basePerformError } from '../../../helpers/error-helpers';
import { joinPath } from '../../../helpers/path-helpers';
import { escapeRegexpString } from '../../../helpers/regexp-helpers';
import { IConcept, buildConceptsTree } from '../../../models/concept';
import { IExercise } from '../../../models/exercise';
import { IFile } from '../../../models/file';
import { ILearningChunk } from '../../../models/learning-chunk';
import { ISkill, buildSkillsTree } from '../../../models/skill';
import { IUnitSection, UnitSectionTypes } from '../../../models/unit-section';
import { IDictionary } from '../../../types/dictionary';
import styles from './learning-chunk-editor.module.scss';
import { isNotNullOrEmpty, isNullOrEmpty } from '../../../helpers/common-helpers';
import { UploaderImagePreview } from '../../../components/shared/uploader/uploader-image-preview';
import { SelectField } from '../../../components/shared/select/select';
import { ExercisesExplorer } from '../unit-section/exercises-explorer';
import { Popup } from '../../../components/shared/popup/popup';


enum FormFields {
    type = 'type',
    index = 'index',

    content = 'content',
    files = 'files',

    heading = 'heading',
    question = 'question',
    responses = 'responses',
    correctResponse = 'correctResponse',
    exerciseFile = 'exerciseFile',
    exerciseType = 'exerciseType',
    skill = 'skill',
    concept = 'concept',
}

interface IForm {
    [FormFields.type]: string,
    [FormFields.index]?: number,

    [FormFields.content]?: string,
    [FormFields.files]?: IFile[],

    [FormFields.heading]?: string,
    [FormFields.question]?: string,
    [FormFields.responses]?: string[],
    [FormFields.correctResponse]?: string,
    [FormFields.exerciseFile]?: IFile,
    [FormFields.exerciseType]?: string,
    [FormFields.skill]?: string,
    [FormFields.concept]?: string,
}

export function LearningChunkEditor(
    props: RouteComponentProps<{uid: string, unitSectionUid: string, unitUid: string, curriculumUid: string}>
) {
    const [loading, setLoading] = useState(false);
    const [learningChunk, setLearningChunk] = useState<ILearningChunk>();
    const [concepts, setConcepts] = useState<IConcept[]>();
    const [skills, setSkills] = useState<ISkill[]>();
    const [unitSection, setUnitSection] = useState<IUnitSection>();
    const [entityForDelete, setEntityForDelete] = useState<ILearningChunk>();
    const [fileBrowserOpened, setFileBrowserOpened] = useState(false);
    const [exercisesExplorerVisible, setExercisesExplorerVisible] = useState(false);

    const form = React.useRef<FormikProps<IForm>>(null);

    const loadData = React.useCallback(async (uid?: string) => {
        try {
            const us = await getUnitSection(props.match.params.unitSectionUid);
            setUnitSection(us);
            if(uid || props.match.params.uid !== '_') {
                setLearningChunk(await getLearningChunk(uid || props.match.params.uid));
            }
            else {
                setLearningChunk({} as ILearningChunk);
            }
            setConcepts(await getAllConcepts());
            setSkills(await getAllSkills());
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.uid, props.match.params.unitSectionUid]);

    useEffect(() => {
        loadData();
        (window as any).CKEDITOR_openFileBrowser = () => setFileBrowserOpened(true);
        return () => {
            delete (window as any).CKEDITOR_openFileBrowser;
        };
    }, [loadData]);

    const onSourceExerciseSelect = (exercise: IExercise) => {
        const values = form.current?.values;
        setLearningChunk(undefined);
        setExercisesExplorerVisible(false);
        setTimeout(() => {
            setLearningChunk({
                index: values?.index,
                type: values?.type,
                exercise: {
                    heading: exercise.heading,
                    question: exercise.question,
                    responses: exercise.responses,
                    correctResponse: exercise.correctResponse,
                    type: exercise.type,
                    concept: exercise.concept,
                    skill: exercise.skill,
                }
            } as ILearningChunk);
        },
        0);
    };

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newLearningChunk: ILearningChunk = {
                uid: learningChunk?.uid,
                index: values.index,
                type: values.type,
            } as ILearningChunk;
            if(values.type === UnitSectionTypes.learning && values.content) {
                newLearningChunk.content = values.content.replace(
                    new RegExp(escapeRegexpString(getLearningChunkFilePath(learningChunk?.uid || '')), 'ig'),
                    LEARNING_CHUNK_FILE_URL_PLACEHOLDER,
                );
                newLearningChunk.fileNames = values.files?.map((file: IFile) => file.url).filter(isNotNullOrEmpty);
            }
            if(values.type === UnitSectionTypes.practice) {
                const exercise = {} as Partial<IExercise>;
                if(learningChunk?.exercise) {
                    exercise.uid = learningChunk?.exercise.uid;
                }
                exercise.type = values.exerciseType;
                exercise.heading = values.heading;
                exercise.question = values.question;
                exercise.responses = values.responses?.map(r => ({ text: r }));
                exercise.correctResponse = values.correctResponse;
                exercise.fileName = values.exerciseFile ? values.exerciseFile.url : undefined;
                exercise.skill = { uid: isNullOrEmpty(values.skill) ? undefined : values.skill } as ISkill;
                exercise.concept = { uid: isNullOrEmpty(values.concept) ? undefined : values.concept } as IConcept;

                newLearningChunk.exercise = exercise as IExercise;
            }
            if(!newLearningChunk.uid) {
                newLearningChunk.unitSection = { uid: props.match.params.unitSectionUid } as IUnitSection;
            }

            const uid = await learningChunkSave(
                newLearningChunk,
                values.files?.map((file: IFile) => file.file).filter(isNotNullOrEmpty),
                values.exerciseFile && values.exerciseFile.file,
            );
            if(!learningChunk?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            } else {
                props.history.push(props.match.url);
            }
            values.files = [];
            await loadData(uid);
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const enableLearningChunk = async () => {
        if(learningChunk?.uid) {
            try {
                await learningChunkEnable(learningChunk.uid);
                setLearningChunk({...learningChunk, available: true });
                toast.success('Item has been successfully saved');
            }
            catch(err) {
                basePerformError(err, props.history);
            }
        }
    };

    const disableLearningChunk = async () => {
        if(learningChunk?.uid) {
            try {
                await learningChunkDisable(learningChunk.uid);
                setLearningChunk({...learningChunk, available: false });
                toast.success('Item has been successfully saved');
            }
            catch(err) {
                basePerformError(err, props.history);
            }
        }
    };

    const getValidationSchema = () => {
        let correctAnswerValidator = Yup.mixed<string>().label('Correct Response');
        if(unitSection && unitSection.type === UnitSectionTypes.practice)
        {
            correctAnswerValidator = correctAnswerValidator.required();
        }
        correctAnswerValidator = correctAnswerValidator.when(
            FormFields.type,
            {
                is: UnitSectionTypes.practice,
                then: Yup.mixed().test(
                    FormFields.correctResponse,
                    'Please select one of existing responses',
                    function(val?: any) {
                        return !val || (this.parent.responses && this.parent.responses.includes(val));
                    },
                ),
            },
        );

        return Yup.object<IForm>({
            index: Yup.number().label('Index').required().min(1),
            type: Yup.string().label('Type').required(),

            content: Yup.string().label('Content').when(
                FormFields.type,
                {
                    is: UnitSectionTypes.learning,
                    then: Yup.string().trim().required(),
                },
            ),

            heading: Yup.string().label('Heading').when(
                FormFields.type,
                {
                    is: UnitSectionTypes.practice,
                    then: Yup.string().trim(),
                },
            ),
            question: Yup.string().label('Question').when(
                FormFields.type,
                {
                    is: UnitSectionTypes.practice,
                    then: Yup.string().trim().required(),
                },
            ),
            exerciseType: Yup.mixed<string>().label('Exercise Type').when(
                FormFields.type,
                {
                    is: UnitSectionTypes.practice,
                    then: Yup.string().trim().required().min(1),
                },
            ),
            responses: Yup.mixed<string[]>().label('Responses').when(
                FormFields.type,
                {
                    is: UnitSectionTypes.practice,
                    then: Yup.mixed().required(),
                },
            ),
            correctResponse: correctAnswerValidator,
        });
    };

    const getPrevUid = () => {
        if(unitSection && unitSection.learningChunks) {
            const prev = unitSection.learningChunks.findIndex(lc => learningChunk?.uid === lc.uid) - 1;
            return unitSection.learningChunks[prev]?.uid;
        }
    };
    const getNextUid = () => {
        if(unitSection && unitSection.learningChunks) {
            const next = unitSection.learningChunks.findIndex(lc => learningChunk?.uid === lc.uid) + 1;
            return unitSection.learningChunks[next] ? unitSection.learningChunks[next].uid : undefined;
        }
    };

    const renderForm = ({ errors, touched, values, setFieldValue }: FormikProps<IForm>): React.ReactElement => {

        return (
            <div className={styles.formContainer}>
                {!learningChunk?.uid
                && (unitSection?.type === UnitSectionTypes.practice || values.type === UnitSectionTypes.practice)
                && (<>
                    <Button className="small orange" onClick={() => setExercisesExplorerVisible(true)}>
                        <FontAwesomeIcon icon={faCopy} />
                        <span>Use Existing Exercise</span>
                    </Button>
                    <br />
                    <br />
                </>)}

                <Form noValidate>

                    { learningChunk?.uid && (<>
                        <div className="form-buttons">
                            {getPrevUid()
                                ? (<Button
                                    type="reset"
                                    onClick={() => {
                                        setLearningChunk(undefined);
                                        props.history.push(joinPath(
                                            '/',
                                            props.match.url.replace(/learning-chunks\/[^/]*$/ig, ''),
                                            'learning-chunks',
                                            getPrevUid(),
                                        ));
                                    }}
                                    className="small outline gray"
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} /> <span>Prev</span>
                                </Button>)
                                : (<div></div>)
                            }
                            {getNextUid()
                                ? (<Button
                                    type="reset"
                                    onClick={() => {
                                        setLearningChunk(undefined);
                                        props.history.push(joinPath(
                                            '/',
                                            props.match.url.replace(/learning-chunks\/[^/]*$/ig, ''),
                                            'learning-chunks',
                                            getNextUid(),
                                        ));
                                    }}
                                    className="small outline gray"
                                >
                                    <span>Next</span> <FontAwesomeIcon icon={faArrowRight} />
                                </Button>)
                                : (<div></div>)
                            }
                        </div>
                        <div className="form-buttons">
                            { learningChunk.uid && !learningChunk.available && (
                                <LinkButton onClick={enableLearningChunk}><FontAwesomeIcon icon={faUnlock} /> Enable</LinkButton>
                            )}
                            { learningChunk.uid && learningChunk.available && (
                                <LinkButton onClick={disableLearningChunk}><FontAwesomeIcon icon={faLock} /> Disable</LinkButton>
                            )}
                            { learningChunk.uid && learningChunk.edited && (
                                <div>Edited*</div>
                            )}
                            { learningChunk.uid && (
                                <LinkButton onClick={() => setEntityForDelete(learningChunk)} className="red">
                                    <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                </LinkButton>
                            )}
                        </div>
                    </>)}
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Index
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.index}
                                emptyTitle=""
                                data={[
                                    { uid: 1, name: "First" },
                                    ...(
                                        unitSection?.learningChunks
                                        ? unitSection.learningChunks.map((l: ILearningChunk, i: number) => ({
                                            uid: !learningChunk?.index || learningChunk?.index > i ? (i + 2) : (i + 1),
                                            name: '#' + (!learningChunk?.index || learningChunk?.index > i ? (i + 2) : (i + 1))
                                        }))
                                        : []
                                    ),
                                ]}
                            />
                        </label>
                        <div className="errors">{touched.index && errors.index}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Type
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.type}
                                disabled={(unitSection && unitSection.type === UnitSectionTypes.practice) || learningChunk?.uid}
                                emptyTitle=""
                                data={Object.keys(UnitSectionTypes).map((t: string) => ({ uid: t, name: t.toUpperCase() }))}
                            />
                        </label>
                        <div className="errors">{touched.type && errors.type}</div>
                    </div>


                    {  values.type === UnitSectionTypes.learning && (<>
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Content
                                </div>
                                <Field component={CKEditorField} name={FormFields.content} filebrowserBrowseUrl="/ckeditor/browse" />
                            </label>
                            <div className="errors">{touched.content && errors.content}</div>
                        </div>
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Files
                                </div>
                                <Field
                                    component={UploaderField}
                                    name={FormFields.files}
                                    acceptFileTypes=".avi,.mp4,.jpeg,.jpg,.png,.gif"
                                    multiple={true}
                                    path={getLearningChunkFilePath(learningChunk?.uid || '')}
                                    deleteDisabled={true}
                                />
                            </label>
                            <div className="errors">{touched.files && errors.files}</div>
                        </div>
                    </>)}


                    { values.type === UnitSectionTypes.practice && (<>

                      <div className="form-item">
                          <label>
                              <div className="form-label">
                                  Heading
                              </div>
                              <Field type="text" name={FormFields.heading} />
                          </label>
                          <div className="errors">{touched.heading && errors.heading}</div>
                      </div>
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Question
                                </div>
                                <Field type="text" name={FormFields.question} />
                            </label>
                            <div className="errors">{touched.question && errors.question}</div>
                        </div>
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Responses
                                </div>
                                <Field
                                    component={MultiRowEditorField}
                                    name={FormFields.responses}
                                    onChange={
                                        (e: React.FormEvent<HTMLSelectElement>) => {
                                            setFieldValue(FormFields.responses, e.currentTarget.value);
                                            if(
                                                values.correctResponse
                                                && (
                                                    !e.currentTarget.value
                                                    || !e.currentTarget.value.includes(values.correctResponse)
                                                )
                                            ) {
                                                setFieldValue(FormFields.correctResponse, undefined);
                                            }
                                        }
                                    }
                                />
                            </label>
                            <div className="errors">{touched.responses && errors.responses}</div>
                        </div>
                        <div className="form-item">
                            <label>
                                <div
                                    className={
                                        'form-label ' +
                                        (unitSection && unitSection.type === UnitSectionTypes.practice && 'required')
                                    }
                                >
                                    Correct Response
                                </div>
                                <Field
                                    component={SelectField}
                                    name={FormFields.correctResponse}
                                    emptyTitle=""
                                    data={values.responses && values.responses.map((t: string) => t)}
                                />
                            </label>
                            <div className="errors">{touched.correctResponse && errors.correctResponse}</div>
                        </div>
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Image
                                </div>
                                <Field
                                    component={UploaderField}
                                    name={FormFields.exerciseFile}
                                    acceptFileTypes=".png,.jpg,.jpeg"
                                    path={getExerciseFilePath(learningChunk?.exercise?.uid || '')}
                                    previewComponent={UploaderImagePreview}
                                />
                            </label>
                            <div className="errors">{touched.exerciseFile && errors.exerciseFile}</div>
                        </div>
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Exercise Type
                                </div>
                                <Field
                                    component={SelectField}
                                    name={FormFields.exerciseType}
                                    emptyTitle=""
                                    data={['mult_choice']}
                                />
                            </label>
                            <div className="errors">{touched.exerciseType && errors.exerciseType}</div>
                        </div>
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Skill
                                </div>
                                <Field
                                    component={SelectField}
                                    name={FormFields.skill}
                                    emptyTitle="None"
                                    data={skills && buildSkillsTree(skills)}
                                    isActive={(el: ISkill) => !el.children}
                                />

                            </label>
                            <div className="errors">{touched.skill && errors.skill}</div>
                        </div>
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Concept
                                </div>
                                <Field
                                    component={SelectField}
                                    name={FormFields.concept}
                                    emptyTitle="None"
                                    data={concepts && buildConceptsTree(concepts)}
                                    isActive={(el: IConcept) => !el.children}
                                />
                            </label>
                            <div className="errors">{touched.concept && errors.concept}</div>
                        </div>
                    </>)}

                    <div className="form-buttons">
                        <Button
                            onClick={() => props.history.push(joinPath(
                                '/curriculums',
                                props.match.params.curriculumUid,
                                'units',
                                props.match.params.unitUid,
                                'unit-sections',
                                props.match.params.unitSectionUid,
                            ))}
                            className="gray"
                        >
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                        ? (<Loader />)
                        : (<Button type="submit"><span>{learningChunk?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return (<>
        { learningChunk && unitSection?.unit?.curriculum?.name && (<>
            <Block key={learningChunk.uid} className={styles.editor}>
                <AddBreadcrumbsItem
                    title={unitSection && `Curriculum: ${unitSection.unit.curriculum.name}`}
                    url={joinPath('/curriculums', unitSection.unit.uid)}
                />
                <AddBreadcrumbsItem
                    title={unitSection && `Unit: ${unitSection.unit.name}`}
                    url={joinPath('/curriculums/', unitSection.unit.curriculum.uid, 'units', unitSection.unit.uid)}
                />
                <AddBreadcrumbsItem
                    title={unitSection && `Unit Section: ${unitSection.name}`}
                    url={joinPath(
                        '/curriculums/',
                        unitSection.unit.curriculum.uid,
                        'units',
                        unitSection.unit.uid,
                        'unit-sections',
                        unitSection.uid,
                    )}
                />
                <AddBreadcrumbsItem
                    title={learningChunk && learningChunk.uid ? 'Edit Learning Chunk' : 'Add Learning Chunk'}
                />
                <Formik
                    enableReinitialize
                    innerRef={form}
                    initialValues={{
                        index: learningChunk.index || undefined,
                        type: unitSection && unitSection.type === UnitSectionTypes.practice
                            ? UnitSectionTypes.practice
                            : (learningChunk && learningChunk.type) || (learningChunk.uid && UnitSectionTypes.learning) || undefined,

                        content: (learningChunk.content?.replace(
                            new RegExp(escapeRegexpString(LEARNING_CHUNK_FILE_URL_PLACEHOLDER), 'ig'),
                            getLearningChunkFilePath(learningChunk.uid),
                        )) || '',
                        files: (
                            learningChunk.fileNames
                            ? learningChunk.fileNames.map((fileName: string) => ({ url: fileName })) : undefined
                        ),

                        heading: learningChunk.exercise?.heading || '',
                        question: learningChunk.exercise?.question || '',
                        responses: learningChunk.exercise?.responses.map(r => r.text) || undefined,
                        correctResponse: learningChunk.exercise?.correctResponse || '',
                        exerciseFile: (learningChunk.exercise
                            && (
                                (learningChunk.exercise.fileName && { url: learningChunk.exercise.fileName })
                                || (learningChunk.exercise.file && { file: learningChunk.exercise.file })
                            )) || undefined,
                        exerciseType: learningChunk.exercise?.type,
                        skill: learningChunk.exercise?.skill?.uid || '',
                        concept: learningChunk.exercise?.concept?.uid || '',
                    }}
                    validationSchema={getValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {renderForm}
                </Formik>

                <div>&nbsp;</div>
            </Block>
        </>)}

        { exercisesExplorerVisible && (
            <Popup
                title="SELECT SOURCE EXERCISE"
                onClose={() => setExercisesExplorerVisible(false)}
                modal={true}
            >
                <ExercisesExplorer onSelect={onSourceExerciseSelect} />
            </Popup>
        )}


        { learningChunk && learningChunk.uid && (<>
            { entityForDelete && (
                <DeleteQuestionPopup
                    title="Delete Learning Chunk"
                    question={<>Are you sure want to delete this item?</>}
                    onClose={() => setEntityForDelete(undefined)}
                    onDelete={async () => await learningChunkDelete(entityForDelete.uid)}
                    onFinish={() => { props.history.push(joinPath(
                        '/curriculums',
                        props.match.params.curriculumUid,
                        'units',
                        props.match.params.unitUid,
                        'unit-sections',
                        props.match.params.unitSectionUid,
                    )); }}
                />
            )}
        </>)}

        { fileBrowserOpened && (
            <FileBrowserPopup
                path={getLearningChunkFilePath(learningChunk?.uid || '')}
                fileNames={learningChunk && learningChunk.fileNames ? [
                    ...learningChunk.fileNames,
                ] : []}
                onClose={() => setFileBrowserOpened(false)}
            />
        )}
    </>);
}
