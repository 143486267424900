import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { getAllConcepts } from '../../../../api/actions/concepts-get-all';
import { unitAddConcept } from '../../../../api/actions/unit/unit-add-concept';
import { unitRemoveConcept } from '../../../../api/actions/unit/unit-remove-concept';
import { Button } from '../../../../components/shared/button/button';
import { LinkButton } from '../../../../components/shared/link-button/link-button';
import { DeleteQuestionPopup } from '../../../../components/shared/popup/delete-question-popup';
import { Popup } from '../../../../components/shared/popup/popup';
import { Select } from '../../../../components/shared/select/select';
import { buildConceptsTree, IConcept } from '../../../../models/concept';
import { IUnit } from '../../../../models/unit';
import styles from './concepts-covered-list.module.scss';

interface IProps {
    unit: IUnit,
    reload(): void,
}

export function ConceptsCoveredList(props: IProps) {
    const [concepts, setConcepts] = React.useState<IConcept[]>();
    const [editorVisible, setEditorVisible] = React.useState(false);
    const [entityForDelete, setEntityForDelete] = React.useState<IConcept>();
    const [editorSelectedConceptUid, setEditorSelectedConceptUid] = React.useState<string>();

    const onChange = React.useCallback((e: any) => { setEditorSelectedConceptUid(e.target.value); }, []);
    const onClose = React.useCallback(() => setEditorVisible(false), []);
    const onSave = React.useCallback(
        async () => {
            if(editorSelectedConceptUid) {
                setEditorVisible(false);
                await unitAddConcept(props.unit.uid, editorSelectedConceptUid);
                props.reload();
            }
        },
        [props, editorSelectedConceptUid],
    );

    React.useEffect(() => {
        (async () => {
            const allConcepts = await getAllConcepts();
            setConcepts(
                allConcepts.filter(c => !props.unit.conceptsCovered?.some(cc => cc.uid === c.uid))
                    .sort((c1, c2) => c1.name > c2.name
                        ? 1
                        : (c1.name < c2.name ? -1 : 0),
                    ),
            );
        })().catch(() => {/** */});
    }, [props.unit.conceptsCovered]);

    return (<>
        <div className="list-title">
            <div>Concepts covered: <strong>{ (props.unit.conceptsCovered && props.unit.conceptsCovered.length) || 0 }</strong></div>
            <div>
                <Button className="orange" onClick={() => setEditorVisible(true)}>
                    <FontAwesomeIcon icon={faPlus} /> <span>Add Concept</span>
                </Button>
            </div>
        </div>
        { props.unit.conceptsCovered && !!props.unit.conceptsCovered.length && (
            <table cellPadding="0" cellSpacing="0" className="list">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.unit.conceptsCovered.sort((c1, c2) => c1.name > c2.name ? 1 : (c1.name < c2.name ? -1 : 0))
                        .map((c: IConcept, i: number) => (
                            <tr key={c.uid}>
                                <td>
                                    {c.name}
                                    <div className="list-item-comment">{c.description}</div>
                                </td>
                                <td>
                                    <LinkButton
                                        onClick={() => setEntityForDelete(c)}
                                        className="red"
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                    </LinkButton><br />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )}


        { editorVisible && (
            <Popup
                modal={true}
                title={'Add Concept'}
                onClose={() => {
                    props.reload();
                    setEditorVisible(false);
                }}
            >
                <div className="form-item">
                    <label>
                        <div className="form-label required">
                            Concept
                        </div>
                        <Select
                            value={editorSelectedConceptUid}
                            data={concepts && buildConceptsTree(concepts)}
                            onChange={onChange}
                            className={styles.selectField}
                        />
                    </label>
                </div>
                <div className="form-buttons">
                    <Button onClick={onClose} className="gray small">Close</Button>
                    <Button onClick={onSave} className="small">Save</Button>
                </div>
            </Popup>
        )}

        { entityForDelete && (
            <DeleteQuestionPopup
                title="Delete Unit"
                question={<>Are you sure want to delete concept?</>}
                onClose={() => setEntityForDelete(undefined)}
                onDelete={async () => await unitRemoveConcept(props.unit.uid, entityForDelete.uid)}
                onFinish={() => { props.reload(); }}
            />
        )}
    </>);
}
