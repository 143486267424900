import { IEmotionCategory } from '../../../models/emotion-category';
import { IActionTendency } from '../../../models/action-tendency';
import { getActionTendencyFilePath } from '../../../config/app-config';
import {addEmotionCategoryActionTendency } from '../../../api/actions/emotion-categories/emotion-category-add-action-tendency';
import {removeEmotionCategoryActionTendency } from '../../../api/actions/emotion-categories/emotion-category-remove-action-tendency';
import { faPlus, faTrashAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { joinPath } from '../../../helpers/path-helpers';
import { Button } from '../../../components/shared/button/button';
import { compareText } from '../../../helpers/array-helpers';
import { Popup } from '../../../components/shared/popup/popup';
import { ActionPopup } from '../../../components/shared/popup/action-popup';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import styles from './emotion-action-tendency-list.module.scss';

interface IProps {
    emotionUid: string, 
    emotionTendencies?: IActionTendency[],
    allTendencies?: IActionTendency[],
    reloadData: any,
    wrongTendencies: boolean
}

export function EmotionActionTendencyList(props: IProps) {

  const [tendencyToDelete, setTendencyToDelete] = useState<IActionTendency | undefined>(undefined);
  const [tendencySelectVisible, setTendencySelectVisible] = useState(false);
  const [newTendencyUid,setNewTendencyUid] = useState<string | undefined>(undefined);


  const handleDelete = async (uid: string) => {
    const { success } = await removeEmotionCategoryActionTendency(props.emotionUid, uid, props.wrongTendencies);
    if (success) {
      toast.success('Action tendency has been successfully removed');
      props.reloadData()
    } else {
        toast.error('Error removing action tendency')
    }
  };


return (
    <>
        <div className="list-title">
            <div>{props.wrongTendencies ? "Wrong " : ""} Action Tendencies: <strong>{ (props.emotionTendencies && props.emotionTendencies.length) || 0 }</strong></div>
            <div>
                <Button className="orange" onClick={() => { setTendencySelectVisible(true); }}>
                    <FontAwesomeIcon icon={faPlus} /> <span>Add {props.wrongTendencies ? "Wrong " : ""} Action Tendency</span>
                </Button>
            </div>
        </div>

      <table cellPadding="0" cellSpacing="0" className={`list`}>
        <thead>
          <tr>
            <th>UID</th>
            <th>Label</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.emotionTendencies?.map((tendency: IActionTendency) => (
            <tr key={tendency.uid}>
              <td>{tendency.uid}</td>
              <td>{tendency.label}</td>
              <td>
                {tendency.imgFileName ? (
                  <img
                    src={getActionTendencyFilePath(
                      tendency.uid,
                      tendency.imgFileName
                    )}
                    alt={tendency.label}
                    style={{ width: '50px', height: '50px' }}
                  />
                ) : (
                  'No Image'
                )}
              </td>
              <td>
                <span
                  className={styles.deleteIcon}
                  onClick={() => setTendencyToDelete(tendency)}
                >
                  <FontAwesomeIcon
                    className={styles.deleteIcon}
                    icon={faTrashAlt}
                  />{' '}
                  Remove
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {tendencyToDelete && (
        <ActionPopup
          title={`Delete ${tendencyToDelete.label}`}
          question={`Are you sure you want to remove ${tendencyToDelete.label}`}
          onClose={() => setTendencyToDelete(undefined)}
          onAction={() => handleDelete(tendencyToDelete.uid || '')}
        />
      )}

          { tendencySelectVisible && (
            <Popup
                className={styles.addTendencyPopup}
                title="Add Action Tendency"
                buttons={[
                    {
                        title: <><FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span></>,
                        className: 'gray',
                        onClick: () => { setTendencySelectVisible(false); },
                    },
                    {
                        title: <><span>Add</span> <FontAwesomeIcon icon={faPlus} /></>,
                        className: 'red',
                        onClick: async () => {
                            try {
                                if(!newTendencyUid) return;
                                await addEmotionCategoryActionTendency(props.emotionUid, newTendencyUid, props.wrongTendencies);
                                if (props.reloadData)
                                    props.reloadData();
                                setTendencySelectVisible(false);
                            }
                            catch(err) {
                                toast.error("Error adding certificaiton");
                            }
                        },
                        disabled: !newTendencyUid,
                    },
                ]}
            >
                Action Tendencies:
                <select
                    onChange={(event: React.FormEvent<HTMLSelectElement>) => setNewTendencyUid(event.currentTarget.value)}
                >
                    <option></option>
                    { props.allTendencies?.sort((c1, c2) => compareText(c1, c2, (c) => c.label)).map(
                        (at: IActionTendency) => (
                            <option
                                key={at.uid}
                                value={at.uid}
                                disabled={props.emotionTendencies?.some(
                                    (tendency: IActionTendency) => tendency.uid === at.uid)
                                }
                            >
                                {at.label}
                            </option>
                        ),
                    )}
                </select>
            </Popup>
        )}      
    </>
  );    
}