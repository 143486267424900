import { FetchResult, gql } from 'apollo-boost';
import { IUnitSection } from '../../../models/unit-section';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query UnitSection($unitSectionUid: String!) {
        unit_section(unitSectionUid: $unitSectionUid) {
            uid,
            name,
            index,
            description,
            type,
            episodeCount: episode_count,
            exerciseParams: exercise_params,
            available,
            multPerspectiveCompatible: mult_perspective_compatible,
            edited,

            unit {
                uid,
                name,

                curriculum {
                    uid,
                    name,
                }
            },

            learningChunks: learning_chunks {
                uid,
                index,
                type,
                content,
                available,
                edited,

                exercise {
                    uid,
                    question,
                }
            }
        }
    }
`;

export async function getUnitSection(unitSectionUid: string): Promise<IUnitSection> {
    const result: FetchResult<{ unit_section: IUnitSection }> = await graphqlClient.query({
        query: QUERY,
        variables: { unitSectionUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    if(result.data.unit_section.exerciseParams) {
        result.data.unit_section.exerciseParams = JSON.parse(result.data.unit_section.exerciseParams as any);
    }

    return result.data.unit_section;
}
