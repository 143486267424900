import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation FlaggedAnswerConfirm($uid: String!){
        answer_flag_confirm (uid: $uid) {
            success
        }
    }
`;

export async function flaggedAnswerConfirm(uid: string): Promise<{ success: boolean }> {
    const result: FetchResult<{ answer_flag_confirm: { success: boolean }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.answer_flag_confirm;
}
