import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserGroupUserPermissionsSave($userGroupUid: String!, $userUid: String!, $permissions: [String]){
        user_group_user_permissions_save (user_group_uid: $userGroupUid, user_uid: $userUid, permissions: $permissions) {
            success
        }
    }
`;

export async function userGroupUserPermissionsSave(userGroupUid: string, userUid: string, permissions: string[]): Promise<{ success: boolean }> {
    const result: FetchResult<{ user_group_user_permissions_save: { success: boolean }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { 
            userGroupUid,
            userUid,
            permissions,
        },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_group_user_permissions_save;
}
