import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { getAllEmotionCategories } from '../../../../../../api/actions/emotion-categories-get-all';
import { getEmotion } from '../../../../../../api/actions/emotion/emotion-get';
import { emotionSave } from '../../../../../../api/actions/emotion/emotion-save';
import { Button } from '../../../../../../components/shared/button/button';
import { Loader } from '../../../../../../components/shared/loader/loader';
import { SelectField } from '../../../../../../components/shared/select/select';
import { basePerformError } from '../../../../../../helpers/error-helpers';
import { EmotionActivationTypes, EmotionIntensityTypes, IEmotion } from '../../../../../../models/emotion';
import { buildEmotionCategoriesTree, IEmotionCategory } from '../../../../../../models/emotion-category';
import { IParticipant } from '../../../../../../models/participant';
import { IDictionary } from '../../../../../../types/dictionary';
import styles from './emotion-editor.module.scss';

interface IProps extends RouteComponentProps {
    uid?: string,
    participantUid: string,
    onClose: () => void,
    reload(): void,
}

enum FormFields {
    adjective = 'adjective',
    description = 'description',
    intensity = 'intensity',
    activation = 'activation',
    emotionCategoryUid = 'emotionCategoryUid',
}

interface IForm {
    [FormFields.adjective]?: string,
    [FormFields.description]?: string,
    [FormFields.intensity]?: string,
    [FormFields.activation]?: string,
    [FormFields.emotionCategoryUid]?: string,
}

function EmotionEditor(props: IProps) {

    const [loading, setLoading] = useState(false);
    const [emotion, setEmotion] = useState<IEmotion>();
    const [emotionCategories, setEmotionCategories] = useState<IEmotionCategory[]>();

    const loadData = useCallback(async (uid?: string) => {
        try {
            const ec = await getAllEmotionCategories();
            setEmotionCategories(buildEmotionCategoriesTree(ec));

            if(uid || props.uid) {
                setEmotion(await getEmotion(uid || props.uid || ''));
            }
            else {
                setEmotion({} as IEmotion);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.uid]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void; setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newEmotion: Partial<IEmotion> = {
                uid: emotion?.uid,
                adjective: values.adjective,
                description: values.description,
                intensity: values.intensity,
                activation: values.activation,
                category: {
                    uid: values.emotionCategoryUid,
                } as IEmotionCategory,
            };
            if(!newEmotion.uid) {
                newEmotion.experiencedBy = { uid: props.participantUid } as IParticipant;
            }
            const uid = await emotionSave(newEmotion);
            await loadData(uid);
            props.reload();
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            description: Yup.string().trim().min(3).max(255),
            emotionCategoryUid: Yup.string().required(),
            intensity: Yup.string().required()
        });
    };

    const renderForm = ({ errors, touched, isValid }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>
                  <div className="form-item">
                    <label>
                        <div className="form-label required">
                            Emotion Category
                        </div>
                        <Field
                            component={SelectField}
                            name={FormFields.emotionCategoryUid}
                            emptyTitle=""
                            data={emotionCategories}
                            titleExtractor={(el: IEmotionCategory) => el.label + ((el.minAgeRating !== undefined && (parseInt(el.minAgeRating) >= 0)) ? ' (' + el.minAgeRating + ')' : '')}
                            isActive={() => true}
                        />
                    </label>
                    <div className="errors">{touched.emotionCategoryUid && errors.emotionCategoryUid}</div>
                  </div>
                  <div className="form-item">
                      <label>
                          <div className="form-label required">
                              Intensity
                          </div>
                          <Field
                              component={SelectField}
                              name={FormFields.intensity}
                              emptyTitle=""
                              data={ Object.keys(EmotionIntensityTypes)
                                  .map((eit: string) => ({ uid: eit, name: eit.toUpperCase() }))
                              }
                          />
                      </label>
                      <div className="errors">{touched.intensity && errors.intensity}</div>
                  </div>
                  <div className="form-item">
                      <label>
                          <div className="form-label">
                              Activation
                          </div>
                          <Field
                              component={SelectField}
                              name={FormFields.activation}
                              emptyTitle=""
                              data={ Object.keys(EmotionActivationTypes)
                                  .map((eac: string) => ({ uid: eac, name: (EmotionActivationTypes as any)[eac].toUpperCase()}))
                              }
                          />
                      </label>
                      <div className="errors">{touched.activation && errors.activation}</div>
                  </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Adjective
                            </div>
                            <Field type="text" name={FormFields.adjective} />
                        </label>
                        <div className="errors">{touched.adjective && errors.adjective}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field type="text" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>

                    { emotion?.createdBy && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Created
                                </div>
                                <div>
                                    <strong>
                                        {emotion.createdBy?.firstName}
                                        &nbsp;
                                        {emotion.createdBy?.lastName}
                                    </strong>
                                    &nbsp; at &nbsp;
                                    {moment(emotion.createdAt).format('ddd, MM/DD/YYYY h:mm a')}
                                </div>
                            </label>
                        </div>
                    )}
                    { emotion?.updatedBy && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Updated
                                </div>
                                <div>
                                    <strong>
                                        {emotion.updatedBy?.firstName}
                                        &nbsp;
                                        {emotion.updatedBy?.lastName}
                                    </strong>
                                    &nbsp; at &nbsp;
                                    {moment(emotion.updatedAt).format('ddd, MM/DD/YYYY h:mm a')}
                                </div>
                            </label>
                        </div>
                    )}

                    <div className="form-buttons">
                        <Button onClick={props.onClose} className="gray small">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit" className="small"><span>{emotion?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !emotion ? (<Loader />) : (
        <Formik
            initialValues={{
                adjective: (emotion && emotion.adjective) || '',
                description: (emotion && emotion.description) || '',
                intensity: (emotion && emotion.intensity) || '',
                activation: (emotion && emotion.activation) || '',
                emotionCategoryUid: (emotion && emotion.category && emotion.category.uid) || '',
            }}
            validationSchema={getValidationSchema}
            onSubmit={handleSubmit}
        >
            {renderForm}
        </Formik>
    );
}

const EmotionEditorWithRouter = withRouter(EmotionEditor);
export { EmotionEditorWithRouter as EmotionEditor };
