import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { userGroupUserPermissionsSave } from '../../../../api/actions/user-group/user-group-user-permissions-save';
import { Button } from '../../../../components/shared/button/button';
import { Checkbox } from '../../../../components/shared/checkbox/checkbox';
import { CheckboxGroup } from '../../../../components/shared/checkbox/checkbox-group';
import { Loader } from '../../../../components/shared/loader/loader';
import { basePerformError } from '../../../../helpers/error-helpers';
import { UserGroupTypes } from '../../../../models/enums/user-group-types';
import { UserPermissionsForGroupsEnum } from '../../../../models/enums/user-permissions-for-group-enum';
import { IUser } from '../../../../models/user';
import { IUserGroup } from '../../../../models/user-group';
import styles from './group-permissions-editor.module.scss';

interface IProps {
    group: IUserGroup,
    user: IUser,
    permissions?: UserPermissionsForGroupsEnum[],
    reloadData(): void,
    onClose(): void,
}

export function GroupPermissionsEditor(props: IProps) {

    const [loading, setLoading] = useState<boolean>(false);
    const [updatedAt, setUpdatedAt] = useState<number>()

    const permissions = useRef<UserPermissionsForGroupsEnum[]>(props.permissions || []);

    const saveData = async () => {
        setLoading(true);
        try {
            await userGroupUserPermissionsSave(props.group.uid, props.user.uid, permissions.current);
            props.reloadData();
            props.onClose();
        }
        catch(err) {
            basePerformError(err);
            setLoading(false);
        }
    }

    const onChange = (val: UserPermissionsForGroupsEnum, add: boolean) => {
        if(add) {
            if(!permissions.current.includes(val)) {
                permissions.current.push(val);
            }
        }
        else {
            permissions.current = permissions.current.filter(p => p !== val);
        }
        setUpdatedAt((new Date()).getTime());
    }

    return (
        <div>
            <div>User: <strong>{props.user.firstName} {props.user.lastName} ({props.user.email})</strong></div>
            <div>Group: <strong>{props.group.title}</strong></div><br />

            <div>
              <Checkbox
                  value={true}
                  checked={permissions.current.includes(UserPermissionsForGroupsEnum.waitlisted)}
                  onChange={(v) => onChange(UserPermissionsForGroupsEnum.waitlisted, !!v)}
                  >Waitlisted</Checkbox>
              <br/>
            </div>

            {props.group.groupType === UserGroupTypes.user && (
                <CheckboxGroup title="Statistics" >
                    <div className={styles.groupElementsContainer}>
                        <Checkbox
                            value={true}
                            checked={permissions.current.includes(UserPermissionsForGroupsEnum.groupStats)}
                            onChange={(v) => onChange(UserPermissionsForGroupsEnum.groupStats, !!v)}
                        >Group Statistics</Checkbox>
                        <Checkbox
                            value={true}
                            checked={permissions.current.includes(UserPermissionsForGroupsEnum.usersStats)}
                            onChange={(v) => onChange(UserPermissionsForGroupsEnum.usersStats, !!v)}
                        >User Statistics</Checkbox>
                    </div>
                </CheckboxGroup>
            )}

            <CheckboxGroup title="Administrative" >
                <div className={styles.groupElementsContainer}>
                    {props.group.groupType === UserGroupTypes.organization && (
                        <Checkbox
                            value={true}
                            checked={permissions.current.includes(UserPermissionsForGroupsEnum.groupsManagement)}
                            onChange={(v) => onChange(UserPermissionsForGroupsEnum.groupsManagement, !!v)}
                        >Groups Management</Checkbox>
                    )}
                    <Checkbox
                        value={true}
                        checked={permissions.current.includes(UserPermissionsForGroupsEnum.usersManagement)}
                        onChange={(v) => onChange(UserPermissionsForGroupsEnum.usersManagement, !!v)}
                    >Users Management</Checkbox>
                </div>
            </CheckboxGroup>

            <div>
                <Button onClick={props.onClose} className="gray">
                    <FontAwesomeIcon icon={faArrowLeft} /> <span>Close</span>
                </Button>
                <Button onClick={saveData} disabled={loading}><span>Save</span></Button>

                { loading && (<Loader />)}
            </div>
        </div>
    )
}
