import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { getBasicFileName, joinPath } from '../../../helpers/path-helpers';
import { Popup } from '../popup/popup';
import styles from './file-browser-popup.module.scss';


interface IProps {
    path: string;
    fileNames: string[];
    onClose(): void;
}

export function FileBrowserPopup(props: IProps) {

    const [selected, setSelected] = React.useState<string>();

    React.useEffect(() => {

        return () => {
            delete (window as any).CKEDITOR_browseCallBack;
        };
    }, []);

    const onSelect = () => {
        (window as any).CKEDITOR_browseCallBack(joinPath(props.path, selected));
        props.onClose();
    };

    return (
        <Popup
            modal={true}
            title="Browse"
            onClose={props.onClose}
            zIndex={10100}
            buttons={[
                {
                    title: <><FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span></>,
                    className: 'gray',
                    onClick: props.onClose,
                },
                {
                    title: <><span>Select</span> <FontAwesomeIcon icon={faCheckCircle} /></>,
                    className: 'red',
                    onClick: onSelect,
                },
            ]}
        >
            <div className={styles.container}>
                { props.fileNames.map((fileName: string) => (
                    <div
                        key={fileName}
                        className={`${styles.item} ${selected === fileName ? styles.selected : ''}`}
                        onClick={() => setSelected(fileName)}
                    >
                        <img src={joinPath(props.path, fileName)} alt={getBasicFileName(fileName)} />
                        <div className={styles.filename}>
                            {getBasicFileName(fileName)}
                        </div>
                    </div>
                ))}
            </div>
        </Popup>
    );

}
