import { FetchResult, gql } from 'apollo-boost';
import { ICurriculum } from '../../../models/curriculum';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query {
        curricula_for_editing {
            uid,
            name,
            description,
            available,
            edited,
            carbonCopy: carbon_copy,
            minAgeRating: min_age_rating,
            default            
        }
    }
`;

export async function getAllCurriculaForEditing(): Promise<ICurriculum[]> {
    const result: FetchResult<{ curricula_for_editing: ICurriculum[] }> = await graphqlClient.query({
        query: QUERY,
        variables: {},
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.curricula_for_editing;
}
