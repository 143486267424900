import { IEmotionCategory } from './emotion-category';
import { IParticipant } from './participant';
import { IUser } from './user';

export enum EmotionIntensityTypes {
    high = 'high',
    medium = 'medium',
    low = 'low',
}

export enum EmotionActivationTypes {
    strong_activation = 'strong activation',
    moderate_activation = 'moderate activation',
    neutral = 'neutral',
    moderate_deactivation = 'moderate deactivation',
    strong_deactivation = 'strong deactivation'
}


export interface IEmotion {
    uid: string,
    adjective?: string,
    description?: string,
    intensity?: string,
    activation?: string,

    createdAt?: string,
    updatedAt?: string,

    createdBy?: IUser,
    updatedBy?: IUser,

    category: IEmotionCategory,
    experiencedBy?: IParticipant,
}
