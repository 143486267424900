import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { getAllConcepts } from '../../../api/actions/concepts-get-all';
import { getAllCustomExercises } from '../../../api/actions/exercise/custom-exercises-get-all';
import { getAllSkills } from '../../../api/actions/skills-get-all';
import { Button } from '../../../components/shared/button/button';
import { Loader } from '../../../components/shared/loader/loader';
import { Select } from '../../../components/shared/select/select';
import { compareText } from '../../../helpers/array-helpers';
import { buildConceptsTree, IConcept } from '../../../models/concept';
import { ICurriculum } from '../../../models/curriculum';
import { IExercise } from '../../../models/exercise';
import { buildSkillsTree, ISkill } from '../../../models/skill';
import styles from './exercises-explorer.module.scss';

interface IProps {
    onSelect(exercise: IExercise): void,
}


export function ExercisesExplorer(props: IProps) {

    const [loading, setLoading] = React.useState<boolean>(true);

    const [skills, setSkills] = React.useState<ISkill[]>();
    const [concepts, setConcepts] = React.useState<IConcept[]>();
    const [exercises, setExercises] = React.useState<IExercise[]>();
    const [curriculums, setCurriculums] = React.useState<ICurriculum[]>();
    
    const [selectedUnitUid, setSelectedUnitUid] = React.useState<string>();
    const [selectedUnitSectionUid, setSelectedUnitSectionUid] = React.useState<string>();
    const [selectedExerciseUid, setSelectedExerciseUid] = React.useState<string>();

    const [selectedSkillUid, setSelectedSkillUid] = React.useState<string>();
    const [selectedConceptUid, setSelectedConceptUid] = React.useState<string>();

    const load = async () => {
        try {
            setConcepts(await getAllConcepts());
            setSkills(await getAllSkills());
            setExercises(await getAllCustomExercises());
        }
        catch(e) {
            /** */
        }
        setLoading(false);
    }

    const buildTree = React.useCallback(() => {
        if(!exercises?.length) {
            setCurriculums(undefined);
            return;
        }

        const curriclms: ICurriculum[] = [];
        exercises
        .filter(e => e?.learningChunk?.unitSection?.unit?.curriculum?.uid
            && (!selectedSkillUid || e.skill?.uid === selectedSkillUid)
            && (!selectedConceptUid || e.concept?.uid === selectedConceptUid)
        )
        .sort((e1, e2) => (e1.learningChunk?.index || 0) - (e2.learningChunk?.index || 0))
        .sort((e1, e2) => compareText(e1, e2, (e) => e.learningChunk?.unitSection?.name))
        .sort((e1, e2) => compareText(e1, e2, (e) => e.learningChunk?.unitSection?.unit?.name))
        .sort((e1, e2) => compareText(e1, e2, (e) => e.learningChunk?.unitSection?.unit?.curriculum?.name))
        .forEach(e => {

            let curriculum = curriclms.find(c => c.uid === e.learningChunk?.unitSection?.unit?.curriculum?.uid);
            if(!curriculum && e.learningChunk?.unitSection?.unit?.curriculum) {
                curriculum = e.learningChunk.unitSection.unit.curriculum;
                curriculum.units = [];
                curriclms.push(curriculum);
            }

            if(curriculum?.units) {
                let unit = curriculum.units?.find(u => u.uid === e.learningChunk?.unitSection?.unit?.uid);
                if(!unit && e.learningChunk?.unitSection?.unit) {
                    unit = e.learningChunk.unitSection.unit;
                    unit.unitSections = [];
                    curriculum.units.push(unit);
                }

                let unitSection = unit?.unitSections?.find(u => u.uid === e.learningChunk?.unitSection?.uid);
                if(unit?.unitSections && !unitSection && e.learningChunk?.unitSection) {
                    unitSection = e.learningChunk.unitSection;
                    unitSection.learningChunks = [];
                    unit.unitSections.push(unitSection);
                }

                let learningChunk = unitSection?.learningChunks?.find(lc => lc.uid === e.learningChunk?.uid);
                if(!learningChunk && unitSection?.learningChunks && e.learningChunk) {
                    learningChunk = e.learningChunk;
                    learningChunk.exercise = e;
                    unitSection.learningChunks.push(learningChunk);
                }
            }
        });

        setCurriculums(curriclms);
    }, [exercises, selectedConceptUid, selectedSkillUid])

    React.useEffect(() => {
        load();
    }, []);

    React.useEffect(() => {
        buildTree();
    }, [buildTree]);

    return (
        <div>
            <div className={styles.filtersContainer}>
                <div className={styles.filterContainer}>
                    Skills:
                    <Select<ISkill, string>
                        value={selectedSkillUid}
                        data={skills && buildSkillsTree(skills)}
                        onChange={(e) => setSelectedSkillUid(e.target.value)}
                    />
                </div>
                <div className={styles.filterContainer}>
                    Concepts: 
                    <Select<IConcept, string>
                        value={selectedConceptUid}
                        data={concepts && buildConceptsTree(concepts)}
                        onChange={(e) => setSelectedConceptUid(e.target.value)}
                    />
                </div>
                <Button className="small" onClick={() => { setSelectedSkillUid(undefined); setSelectedConceptUid(undefined); }}><span>Clear</span></Button>
            </div>
            {loading
            ? (<Loader />)
            : (curriculums?.length
                ? (
                    <div className={styles.treeContainer}>
                        {curriculums.map(c => (
                            <div key={c.uid}>
                                <div>
                                    <span className={`${styles.type} ${styles.orange}`}>Curriculum</span>
                                    <span className={`${styles.name} ${styles.orange}`}>{c.name}</span>
                                </div>
                                <div>
                                    {c.units?.map(u => (
                                        <div key={u.uid} className={styles.subcontainer}>
                                            <div onClick={() => { setSelectedUnitUid(selectedUnitUid === u.uid ? undefined : u.uid) }}>
                                                <span className={`${styles.type} ${selectedUnitUid === u.uid ? styles.green : ''}`}>
                                                    <FontAwesomeIcon icon={selectedUnitUid === u.uid ? faChevronDown : faChevronRight} />&nbsp;
                                                    Unit
                                                </span>
                                                <span className={`${styles.name} ${selectedUnitUid === u.uid ? styles.green : ''}`}>{u.name}</span>
                                            </div>
                                            { selectedUnitUid === u.uid && (
                                                <div>
                                                    {u.unitSections?.map(us => (
                                                        <div key={us.uid} className={styles.subcontainer}>
                                                            <div onClick={() => { setSelectedUnitSectionUid(selectedUnitSectionUid === us.uid ? undefined : us.uid) }}>
                                                                <span className={`${styles.type} ${selectedUnitSectionUid === us.uid ? styles.green : ''}`}>
                                                                    <FontAwesomeIcon icon={selectedUnitSectionUid === us.uid ? faChevronDown : faChevronRight} />&nbsp;
                                                                    Section
                                                                </span>
                                                                <span className={`${styles.name} ${selectedUnitSectionUid === us.uid ? styles.green : ''}`}>{us.name}</span>
                                                            </div>
                                                            { selectedUnitSectionUid === us.uid && (
                                                                <div>
                                                                    {us.learningChunks?.map(lc => lc.exercise && (
                                                                        <div key={lc.exercise.uid} className={styles.subcontainer}>
                                                                            <div>
                                                                                <span
                                                                                    className={`${styles.type} ${selectedExerciseUid === lc.exercise.uid ? styles.green : ''}`}
                                                                                    onClick={() => { setSelectedExerciseUid(selectedExerciseUid === lc.exercise?.uid ? undefined : lc.exercise?.uid) }}
                                                                                >
                                                                                    <FontAwesomeIcon icon={selectedExerciseUid === lc.exercise.uid ? faChevronDown : faChevronRight} />&nbsp;
                                                                                    Question
                                                                                </span>
                                                                                <span
                                                                                    className={`${styles.name} ${selectedExerciseUid === lc.exercise.uid ? styles.green : ''}`}
                                                                                    onClick={() => { setSelectedExerciseUid(selectedExerciseUid === lc.exercise?.uid ? undefined : lc.exercise?.uid) }}
                                                                                >
                                                                                    {lc.exercise.question}
                                                                                </span>
                                                                                <Button className="orange small" onClick={() => props.onSelect(lc.exercise!)}>Select</Button>
                                                                            </div>
                                                                            {selectedExerciseUid === lc.exercise.uid && (
                                                                                <div>
                                                                                    <div className={styles.field}>
                                                                                        <div>
                                                                                            Heading
                                                                                        </div>
                                                                                        <div>
                                                                                            {lc.exercise.heading}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={styles.field}>
                                                                                        <div>
                                                                                            Question
                                                                                        </div>
                                                                                        <div>
                                                                                            {lc.exercise.question}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={styles.field}>
                                                                                        <div>
                                                                                            Responses
                                                                                        </div>
                                                                                        <div>
                                                                                            {lc.exercise.responses.map((r, i) => (
                                                                                                <div key={r.text} className={lc.exercise?.correctResponse === r.text ? styles.correct : undefined}>
                                                                                                    {i + 1}: {r}
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={styles.field}>
                                                                                        <div>
                                                                                            Exercise Type
                                                                                        </div>
                                                                                        <div>
                                                                                            {lc.exercise.type}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={styles.field}>
                                                                                        <div>
                                                                                            Skill
                                                                                        </div>
                                                                                        <div>
                                                                                            {lc.exercise.skill?.name}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={styles.field}>
                                                                                        <div>
                                                                                            Concept
                                                                                        </div>
                                                                                        <div>
                                                                                            {lc.exercise.concept?.name}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )
                : (
                    <div>There are nothing to show</div>
                )
            )}
        </div>
    );
}