import { FetchResult, gql } from 'apollo-boost';
import { IUser } from '../../../models/user';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserSave($user: UserSaveInput!){
        user_save (user_data: $user) {
            uid
        }
    }
`;

export async function userSave(user: Partial<IUser>): Promise<string> {
    const result: FetchResult<{ user_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { user },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_save.uid;
}
