import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UnitSetIndex($uid: String!, $index: Int!){
        unit_set_index (uid: $uid, index: $index) {
            success
        }
    }
`;

export async function unitSetIndex(uid: string, index: number): Promise<{ success: boolean }> {
    const result: FetchResult<{ unit_set_index: { success: boolean }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { uid, index },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.unit_set_index;
}
