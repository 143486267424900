export interface ISkill {
    uid: string,
    parent?: string,
    index: number,
    name: string,
    description: string,
    icon: string,

    children: ISkill[],
}

export function buildSkillsTree(skills: ISkill[]) {
    return skills.filter(s => !s.parent).map(s => ({ ...s, ...(getChildren(s, skills) ? { children: getChildren(s, skills)} : {})}));
}

function getChildren(skill: ISkill, skills: ISkill[]) {
    const children: ISkill[] = skills.filter(ss => ss.parent === skill.uid)
        .map(s => ({ ...s, ...(getChildren(s, skills) ? { children: getChildren(s, skills)} : {})} as ISkill));
    return children?.length ? children.sort((c1, c2) => c1.index - c2.index) : undefined;
}
