import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { SurveyEditor } from './survey-editor';
import { SurveyList } from './survey-list';


export function SurveyManagementPage(props: RouteComponentProps) {

    return (<>
        <Route exact
            path={props.match.url} component={SurveyList}
        />
        <Route exact
            path={`${props.match.url}/:uid`} component={SurveyEditor}
        />
    </>);
}
