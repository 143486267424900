import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faLock, faUnlock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getAllAchievements } from '../../api/actions/achievement/achievement-get-all';
import { curriculumAchievementDisable } from '../../api/actions/achievement/curriculum-achievement-disable';
import { curriculumAchievementEnable } from '../../api/actions/achievement/curriculum-achievement-enable';
import { userSentTestCertEmail } from '../../api/actions/user/user-send-test-cert-email';
import { LinkButton } from '../../components/shared/link-button/link-button';
import { Popup } from '../../components/shared/popup/popup';
import { basePerformError } from '../../helpers/error-helpers';
import { IAchievement } from '../../models/achievement';
import { ICurriculum } from '../../models/curriculum';
import { ICurriculumAchievement } from '../../models/curriculum-achievement';
import styles from './achievements-list.module.scss';
import { CurriculumAchievementEditor } from './curriculum-achievement-editor';

interface IProps {
    curriculum: ICurriculum,
    reloadData(): void,
    editable: boolean
}


export function CurriculumAchievementsList(props: IProps) {
    const [achievements, setAchievements] = useState<IAchievement[]>([]);
    const [editableEntity, setEditableEntity] = useState<ICurriculumAchievement>();

    const loadData = React.useCallback(async () => {
        try {
            setAchievements((await getAllAchievements(undefined))?.sort((ca1, ca2) => ca1.index - ca2.index))
        }
        catch(err) {
            basePerformError(err);
        }
    }, []);

    useEffect(() => {
        loadData().catch(() =>{/** */});
    }, [loadData]);

    useEffect(() => {
        if(editableEntity?.uid) {
            const curriculumAchievement = props.curriculum.curriculumAchievements?.find(ca => ca.uid === editableEntity.uid);
            if(curriculumAchievement) {
                setEditableEntity(curriculumAchievement);
            }
        }
    }, [props.curriculum]);

    const disableCurriculumAchievement = async (uid: string) => {
        try {
            await curriculumAchievementDisable(uid);
            props.reloadData();
            loadData().catch(() =>{/** */});
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err);
        }
    };

    const enableCurriculumAchievement = async (uid: string) => {
        try {
            await curriculumAchievementEnable(uid);
            props.reloadData();
            loadData().catch(() =>{/** */});
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err);
        }
    };

    const sendTestCertEmail = async (uid: string) => {
        try {
            const result = await userSentTestCertEmail(uid);
            if(result) toast.success('Test Cert Email Successfully Sent');
        } catch (err) {
            basePerformError(err);
        }
    }

    const getRelationObject = (uid: string) => {
        const curriculumAchievement = props.curriculum.curriculumAchievements?.find(ca => ca.achievement.uid === uid);
        if(!curriculumAchievement) return;
        curriculumAchievement.curriculum = props.curriculum;
        return curriculumAchievement;
    }

    const isAvailable = (achievement: IAchievement) => {
        return achievement.available && getRelationObject(achievement.uid)?.available;
    }

    return (<>

        <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Trigger</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {
                    achievements.map(((achievement, i) => (
                        <tr key={achievement.uid}>
                            <td>
                                <div className={styles.markContainer}>
                                    <div className={`${styles.mark} ${isAvailable(achievement) || i === 0 ? '' : styles.disabled}`}>&nbsp;</div>
                                    <div className={styles.title}>
                                        {achievement.index}
                                    </div>
                                </div>
                            </td>
                            <td>
                                {i === 0 && (<strong>(Default) </strong>)}
                                {achievement.name + (getRelationObject(achievement.uid)?.edited ? "*" : "")}
                            </td>
                            <td>
                                {achievement.achievementType.toUpperCase()}
                            </td>
                            <td>

                                {i === 0
                                    ? (<strong>Achieved at start </strong>)
                                    : ( getRelationObject(achievement.uid)
                                        && (<>
                                            <strong>Type:</strong> {getRelationObject(achievement.uid)?.triggerType.toUpperCase()}<br/>
                                            {getRelationObject(achievement.uid)?.triggerValue
                                                && <><strong>Value:</strong> {getRelationObject(achievement.uid)?.triggerValue}<br/></>}
                                            {getRelationObject(achievement.uid)?.requiredCompletedUnit
                                                && <><strong>Unit:</strong> {getRelationObject(achievement.uid)?.requiredCompletedUnit?.name}<br/></>
                                            }
                                            {getRelationObject(achievement.uid)?.unitTrigger
                                                && <><strong>Unit:</strong> {getRelationObject(achievement.uid)?.unitTrigger?.name}<br/></>
                                            }
                                        </>)
                                    )
                                }

                            </td>
                            <td>
                                <LinkButton disabled={!props.editable} onClick={() => {
                                    setEditableEntity(
                                        getRelationObject(achievement.uid)
                                        || {
                                            curriculum: props.curriculum,
                                            achievement,
                                        } as ICurriculumAchievement
                                    );
                                }}>
                                    <FontAwesomeIcon icon={faEdit} /> Edit
                                </LinkButton><br />

                                { achievement.available && getRelationObject(achievement.uid)
                                    ? (<>
                                        { !getRelationObject(achievement.uid)?.available && (<>
                                            <LinkButton
                                                disabled={!props.editable}
                                                className={styles.enableBtn}
                                                onClick={() =>
                                                    getRelationObject(achievement.uid) && enableCurriculumAchievement(getRelationObject(achievement.uid)!.uid)
                                                }
                                            >
                                                <FontAwesomeIcon icon={faUnlock} /> Enable
                                            </LinkButton><br />
                                        </>)}
                                        { getRelationObject(achievement.uid)?.available && i > 0 && (<>
                                            <LinkButton
                                                disabled={!props.editable}
                                                onClick={() =>
                                                    getRelationObject(achievement.uid) && disableCurriculumAchievement(getRelationObject(achievement.uid)!.uid)
                                            }
                                            >
                                                <FontAwesomeIcon icon={faLock} /> Disable
                                            </LinkButton><br />
                                        </>)}
                                        { achievement.achievementType === 'certification' && (<>
                                           <LinkButton
                                                onClick={() =>
                                                    getRelationObject(achievement.uid) && sendTestCertEmail(getRelationObject(achievement.uid)!.uid)
                                            }
                                            >
                                                <FontAwesomeIcon icon={faEnvelope} /> Send Test Email
                                            </LinkButton><br />  
                                            </>)}
                                    </>)
                                    : (getRelationObject(achievement.uid)
                                        ? (<div className="red">Achievement disabled globally</div>)
                                        : (<div className="red">Achievement is not configured</div>)
                                    )
                                }
                            </td>
                        </tr>
                    )))
                }
            </tbody>
        </table>

        {editableEntity && (
            <Popup
                title={'Edit Achievement'}
                modal={true}
            >
                <CurriculumAchievementEditor
                    curriculumAchievement={editableEntity}
                    reloadData={() => { loadData().catch(() => {/** */}); props.reloadData(); }}
                    onClose={() => { setEditableEntity(undefined); loadData().catch(() => {/** */}); }}
                />
            </Popup>
        )}
    </>);
}
