import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AchievementEditor } from './achievement-editor';
import { AchievementsList } from './achievements-list';


export function AchievementsManagementPage(props: RouteComponentProps) {

    return (<>
        <Route exact
            path={props.match.url} component={AchievementsList}
        />
        <Route exact
            path={`${props.match.url}/:uid`} component={AchievementEditor}
        />
    </>);
}
