import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faLock, faUnlock, faCopy, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { curriculumDisable } from '../../../api/actions/curriculum/curriculum-disable';
import { curriculumEnable } from '../../../api/actions/curriculum/curriculum-enable';
import { curriculumDuplicate } from '../../../api/actions/curriculum/curriculum-duplicate';
import { getAllCurriculaForEditing } from '../../../api/actions/curriculum/curriculums-get-all-editing';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { CurrentUserContext } from '../../../components/user/current-user-manager';
import { basePerformError } from '../../../helpers/error-helpers';
import { joinPath } from '../../../helpers/path-helpers';
import { ICurriculum } from '../../../models/curriculum';
import { ContentAgeRating } from '../../../models/narrative';
import { getAbsoluteClientUrl } from '../../../config/app-config';
import styles from './curriculums-list.module.scss';


export function CurriculumsList(props: RouteComponentProps) {

    const [loading, setLoading] = useState(true);
    const [curriculums, setCurriculums] = useState<ICurriculum[]>([]);

    const currentUserContext = React.useContext(CurrentUserContext);

    const loadData = React.useCallback(async () => {
        if(!currentUserContext?.currentUser) return;
        setLoading(true);
        try {
            const crs = await getAllCurriculaForEditing();
            if (currentUserContext?.isAdmin()) {
                setCurriculums( crs );
            } else {
                const filtered = crs.filter(c => currentUserContext.getCurriculums()?.some(c1 => c1.uid === c.uid))
                //console.log("filtered curriculum list; len: " + filtered.length);
                setCurriculums( filtered );
            }
            
        }
        catch(err) {
            basePerformError(err, props.history);
        }

        setLoading(false);
    }, [currentUserContext?.currentUser, props.history]);

    useEffect(() => {
        loadData();
    }, [loadData, currentUserContext?.currentUser]);

    const renderButtons = () => (
        <div className={styles.buttons}>
            <Button className="orange" to={joinPath(props.match.url, '_')}>Add curriculum</Button>
        </div>
    );

    const duplicateCurriculum = async (uid: string) => {
        setLoading(true);
        try {
            await curriculumDuplicate(uid);
            loadData();
            toast.success('Item has been successfully duplicated');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
        setLoading(false);
    };

    const disableCurriculum = async (uid: string) => {
        try {
            await curriculumDisable(uid);
            loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const enableCurriculum = async (uid: string) => {
        setLoading(true);
        try {
            await curriculumEnable(uid);
            loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
        setLoading(false);
    };

    const previewCurriculum = async (uid: string) => {
      const cpath = getAbsoluteClientUrl('curriculum', uid);
      window.open(cpath)
    };

    return loading ? (<Loader />) :(
        <>
            {renderButtons()}

            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Age Rating</th>
                        <th>Default</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        curriculums.sort((c1, c2) => c1.name > c2.name ? 1 : (c1.name < c2.name ? -1 : 0) ).map((c => (
                            <tr key={c.uid}>
                                <td>
                                    <div className={styles.markContainer}>
                                        <div className={`${styles.mark} ${c.available ? '' : styles.disabled}`}>&nbsp;</div>
                                        <div className={styles.title}>
                                            {c.name + (c.edited ? '*' : '')}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {c.description}
                                </td>
                                <td>
                                    {c.minAgeRating ? ContentAgeRating[c.minAgeRating] : ''}
                                </td>     

                                <td>
                                    {c.default ? 'Yes' : ''}
                                </td>
                                <td>
                                    <LinkButton onClick={() => previewCurriculum( (!c.carbonCopy || c.edited) ? c.uid : c.carbonCopy)}>
                                      <FontAwesomeIcon icon={faEye} /> { (!c.available || c.edited) ? 'Preview' : 'View'}
                                    </LinkButton><br />
                                    <LinkButton to={joinPath(props.match.url, c.uid)}>
                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                    </LinkButton><br />
                                    { !c.available && (<>
                                        <LinkButton className={styles.enableBtn} onClick={() => enableCurriculum(c.uid)}>
                                            <FontAwesomeIcon icon={faUnlock} /> Enable
                                        </LinkButton><br />
                                    </>)}
                                    { c.available && (<>
                                        <LinkButton onClick={() => disableCurriculum(c.uid)}>
                                            <FontAwesomeIcon icon={faLock} /> Disable
                                        </LinkButton><br />
                                    </>)}
                                    <LinkButton onClick={() => duplicateCurriculum(c.uid)}>
                                        <FontAwesomeIcon icon={faCopy} /> Duplicate
                                    </LinkButton>
                                </td>
                            </tr>
                        )))
                    }
                </tbody>
            </table>

            {renderButtons()}
        </>
    );
}
