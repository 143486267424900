import { FetchResult, gql } from 'apollo-boost';
import { INeed } from '../../../models/need';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query {
        needs {
            uid,
            name,
            description,
        }
    }
`;

export async function getAllNeeds(): Promise<INeed[]> {
    const result: FetchResult<{ needs: INeed[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.needs;
}
