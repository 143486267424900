import { faArrowLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { emotionEpisodeContentTagSave } from '../../../api/actions/emotion-episode-content-tag/emotion-episode-content-tag-save';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { SelectField } from '../../../components/shared/select/select';
import { basePerformError } from '../../../helpers/error-helpers';
import { EmotionEpisodeContentTagTypes, IEmotionEpisodeContentTag } from '../../../models/emotion-episode-content-tag';
import { IDictionary } from '../../../types/dictionary';
import { UserRoles } from '../../../models/enums/user-roles-enum';
import { useCurrentUser } from '../../../components/user/current-user-manager';
import styles from './emotion-episode-content-tag-editor.module.scss';


enum FormFields {
    name = 'name',
    tagType = 'tagType',
}

interface IForm {
    [FormFields.name]: string,
    [FormFields.tagType]?: EmotionEpisodeContentTagTypes,
}

interface IProps {
    tag: IEmotionEpisodeContentTag,
    onClose(): void,
    onSaved(uid: string): void,
    onDelete?(): void,
}

export function EmotionEpisodeContentTagEditorComponent(props: IProps) {

    const currentUser = useCurrentUser();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newTag: Partial<IEmotionEpisodeContentTag> = {
                uid: props.tag?.uid,
                name: values.name,
                tagType: values.tagType,
            };

            const uid = await emotionEpisodeContentTagSave(newTag);
            props.onSaved(uid);

            toast.success('Item has been successfully saved');
        }
        catch (err) {
            basePerformError(err);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().label('Name').trim().required().min(3).max(50),
            tagType: Yup.mixed<EmotionEpisodeContentTagTypes>().label('Tag Type'),
        });
    };

    const renderForm = ({ errors, touched }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>

                    <div className="form-buttons">
                        { props.tag?.uid && props.onDelete && (
                            <LinkButton onClick={props.onDelete} className="red"><FontAwesomeIcon icon={faTrashAlt} /> Delete</LinkButton>
                        )}
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name
                            </div>
                            <Field type="text" name={FormFields.name} />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Tag Type
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.tagType}
                                data={Object.keys(EmotionEpisodeContentTagTypes).map(el => ({
                                    uid: EmotionEpisodeContentTagTypes[el],
                                    name: EmotionEpisodeContentTagTypes[el].toUpperCase(),
                                }))}
                                disabled={currentUser?.role === UserRoles.editor}
                            />
                        </label>
                        <div className="errors">{touched.tagType && errors.tagType}</div>
                    </div>


                    <div className="form-buttons">
                        <Button className="gray" onClick={props.onClose}>
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>Save</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return (
        <Formik
            initialValues={{
                name: props.tag?.name || '',
                tagType: props.tag?.tagType,
            }}
            validationSchema={getValidationSchema}
            onSubmit={handleSubmit}
        >
            {renderForm}
        </Formik>
    );
}
