import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const DELETE_ACTION_TENDENCY_IMG_MUTATION = gql`
    mutation DeleteActionTendencyImage($uid: String!) {
      action_tendency_delete_img(uid: $uid) {
        success
        action_tendency {
          uid
          img_file_name
        }
      }
    }
`;


export async function deleteActionTendencyImage(uid: string): Promise<{ success: boolean }> {
  const result: FetchResult<{ action_tendency_delete_img: { success: boolean } }> = await graphqlClient.mutate({
      mutation: DELETE_ACTION_TENDENCY_IMG_MUTATION,
      variables: { uid },
      errorPolicy: 'all',
  });

  if (!result.data || (result.errors && result.errors.length)) {
      throw new MultiError(result.errors || ['Unknown error']);
  }

  return {
    success: result.data.action_tendency_delete_img.success,
  };
}