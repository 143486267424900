import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IEmotionEpisodeContentTag } from '../../../models/emotion-episode-content-tag';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query EmotionEpisodeContentTag($uid: String!) {
        emotion_episode_content_tag(uid: $uid) {
            uid,
            name,
            tagType: tag_type,
        }
    }
`;

export async function getEmotionEpisodeContentTag(tagUid: string): Promise<IEmotionEpisodeContentTag> {
    const result: FetchResult<{ emotion_episode_content_tag: IEmotionEpisodeContentTag }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid: tagUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.emotion_episode_content_tag, ['__typename']);
}
