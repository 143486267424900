import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faArrowRight, faLock, faPlus, faTrashAlt, faUnlock, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { getAllEmotionEpisodeContentTags } from '../../../../api/actions/emotion-episode-content-tag/emotion-episode-content-tag-get-all';
import { emotionEpisodeDelete } from '../../../../api/actions/emotion-episode/emotion-episode-delete';
import { emotionEpisodeDisable } from '../../../../api/actions/emotion-episode/emotion-episode-disable';
import { emotionEpisodeEnable } from '../../../../api/actions/emotion-episode/emotion-episode-enable';
import { emotionEpisodeSetRecordingStatus } from '../../../../api/actions/emotion-episode/emotion-episode-set-recording-status';
import { getEmotionEpisode } from '../../../../api/actions/emotion-episode/emotion-episode-get';
import { emotionEpisodeLink } from '../../../../api/actions/emotion-episode/emotion-episode-link';
import { getAdjacentEmotionEpisodes } from '../../../../api/actions/emotion-episode/emotion-episode-get-adjacent';
import { emotionEpisodeSave } from '../../../../api/actions/emotion-episode/emotion-episode-save';
import { participantAdd } from '../../../../api/actions/participant/participant-add';
import { participantDelete } from '../../../../api/actions/participant/participant-delete';
import { getScene } from '../../../../api/actions/scene/scene-get';
import { Block } from '../../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../../components/shared/button/button';
import { LinkButton } from '../../../../components/shared/link-button/link-button';
import { Loader } from '../../../../components/shared/loader/loader';
import { DeleteQuestionPopup } from '../../../../components/shared/popup/delete-question-popup';
import { Popup } from '../../../../components/shared/popup/popup';
import { SelectField } from '../../../../components/shared/select/select';
import { Video } from '../../../../components/shared/video/video';
import { useCurrentUser } from '../../../../components/user/current-user-manager';
import { UploaderField } from '../../../../components/shared/uploader/uploader';
import { getCharacterFilePath, getSceneFilePath, getEmotionEpisodeFilePath } from '../../../../config/app-config';
import { secondsToTimeString, timeToSeconds } from '../../../../helpers/datetime-helpers';
import { basePerformError } from '../../../../helpers/error-helpers';
import { getFileName, isAbsoluteUrl, joinPath } from '../../../../helpers/path-helpers';
import { cutStr } from '../../../../helpers/string-helpers';
import { ICharacter } from '../../../../models/character';
import { IEmotionEpisode } from '../../../../models/emotion-episode';
import { EmotionEpisodeStatusTypes } from '../../../../models/enums/emotion-episode-status-types';
import { EmotionEpisodeRecordingStatusTypes } from '../../../../models/enums/emotion-episode-recording-status-types';
import { IEmotionEpisodeContentTag } from '../../../../models/emotion-episode-content-tag';
import { UserRoles } from '../../../../models/enums/user-roles-enum';
import { IParticipant } from '../../../../models/participant';
import { IScene } from '../../../../models/scene';
import { IDictionary } from '../../../../types/dictionary';
import { IFile } from '../../../../models/file';
import { MultiError } from '../../../../types/multi-error';
import { EmotionEpisodeContentTagEditorComponent } from '../../../libraries/emotion-episode-content-tags/emotion-episode-content-tag-editor-component';
import styles from './emotion-episode-editor.module.scss';
import { EmotionalEpisodeExercisesTester } from './emotion-episode-exercises-tester';
import { InitiatingEventEditor } from './initiating-event-editor';

enum FormFields {
    description = 'description',
    contentTagsUids = 'contentTagsUids',
    start = 'start',
    end = 'end',
    status = 'status',
    clipStart = 'clipStart',
    clipEnd = 'clipEnd',
    interstitialURL = 'interstitialURL',
    audioFile = 'audioFile'
}

interface IForm {
    [FormFields.description]?: string,
    [FormFields.contentTagsUids]?: string[],
    [FormFields.start]?: string,
    [FormFields.end]?: string,
    [FormFields.status]?: string,
    [FormFields.clipStart]?: string,
    [FormFields.clipEnd]?: string,
    [FormFields.interstitialURL]?: string,
    [FormFields.audioFile]?: IFile;
}

export function EmotionEpisodeEditor(
        props: RouteComponentProps<{narrativeUid: string, sceneUid: string, uid: string, start: string, end: string}>,
    ) {

    const currentUser = useCurrentUser();

    const MAX_RECORD_ATTEMPTS = 5;
    const WAIT_BETWEEN_RECORD_ATTEMPTS = 1500;
    let attemptCount = 0;

    const formRef = React.useRef<FormikProps<IForm>>(null);

    const [loading, setLoading] = useState(false);
    const [emotionEpisode, setEmotionEpisode] = useState<IEmotionEpisode>();
    const [scene, setScene] = useState<IScene>();
    const [mappingGoesBeyondShallow, setMappingGoesBeyondShallow] = useState(false);
    const [allEmotionEpisodes, setAllEmotionEpisodes] = React.useState<IEmotionEpisode[]>();
    const [allEmotionEpisodeContentTags, setAllEmotionEpisodeContentTags] = React.useState<IEmotionEpisodeContentTag[]>();

    const [testExercisesPopupVisible, setTestExercisesPopupVisible] = useState(false);

    const [participantSelectorPopupVisible, setParticipantSelectorPopupVisible] = useState(false);
    const [newParticipantUid, setNewParticipantUid] = useState<string>();

    const [entityForDelete, setEntityForDelete] = useState<IEmotionEpisode>();
    const [participantEntityForDelete, setParticipantEntityForDelete] = useState<IParticipant>();


    const [addNewContentTag, setAddNewContentTag] = useState<string>();

    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [audioUrl, setAudioUrl] = useState<string>("");
    const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
    const [playing, setPlaying] = useState<boolean>(false);
    const [recordingStatus, setRecordingStatus] = useState<string>(EmotionEpisodeRecordingStatusTypes.none);
    const recordingStatusRef = useRef(recordingStatus);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const isRecordingRef = useRef(isRecording);

    
    const awaitAudioPlaybackBeforeRecord = async () => {
        if (!emotionEpisode) return;

        try {
            const status = EmotionEpisodeRecordingStatusTypes.awaiting_playback;

            setRecordingStatus(status);
            recordingStatusRef.current = status;

            const result = await emotionEpisodeSetRecordingStatus(emotionEpisode.uid, status);

            if (result.success) {
                //now wait for playback to be confirmed
                //console.log("awaiting playback; will attempt record; status: " + recordingStatus);
                attemptRecord();
            } else {
                setRecordingStatus(EmotionEpisodeRecordingStatusTypes.none);
                recordingStatusRef.current = EmotionEpisodeRecordingStatusTypes.none;
            }
        } catch (err: any) {
            toast.error(`Could not start waiting for playback: ${err.message}`);
            setRecordingStatus(EmotionEpisodeRecordingStatusTypes.none);
            recordingStatusRef.current = EmotionEpisodeRecordingStatusTypes.none;
        }
    }

    const attemptRecord = async () => {
        if (!emotionEpisode) return;

        try {
            attemptCount = attemptCount + 1;
            const status = EmotionEpisodeRecordingStatusTypes.recording;

            const result = await emotionEpisodeSetRecordingStatus(emotionEpisode.uid, status);


            if (result.success) {
                console.log("starting recording");
                setRecordingStatus(status);
                recordingStatusRef.current = status;
                startRecording();
                attemptCount = 0;
            } else {
                //here try again after a short interval
                console.log("could not set EmEp record status on backend to record; attempt count: " + attemptCount + "; state recording status: " + recordingStatusRef.current);
                if(attemptCount <= MAX_RECORD_ATTEMPTS && recordingStatusRef.current == EmotionEpisodeRecordingStatusTypes.awaiting_playback) {
                    setTimeout(() => {
                        attemptRecord();
                    }, WAIT_BETWEEN_RECORD_ATTEMPTS);
                } else {
                    console.log("max record attempts reached with recording status=" + recordingStatusRef.current);
                    stopAwaitingAudioPlayback();
                }

            }
        } catch (err: any) {
            toast.error(`Could not set EmEp status to Record: ${err.message}`);
        }
    }

    const stopAwaitingAudioPlayback = async () => {
        console.log("stop awaiting audio playback; state recording status: " + recordingStatus);
        if (!emotionEpisode) return;
        try {
            const status = EmotionEpisodeRecordingStatusTypes.none;
            setRecordingStatus(status);
            recordingStatusRef.current = status;
            const result = await emotionEpisodeSetRecordingStatus(emotionEpisode?.uid, status);
            attemptCount = 0;
        } catch (err: any) {
            toast.error(`Could not set EmEp status to None: ${err.message}`);
        }

    }

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;
            let chunks: BlobPart[] = [];

            mediaRecorder.ondataavailable = function(e) {
                chunks.push(e.data);
            };

            mediaRecorder.onstop = () => {
                const blob = new Blob(chunks, { type: 'audio/webm' });
                const audioUrl = URL.createObjectURL(blob);
                setAudioBlob(blob);
                setAudioUrl(audioUrl);
                chunks = [];
            };

            mediaRecorder.start();
            setIsRecording(true);
            isRecordingRef.current = true;

            const recordingDuration = emotionEpisode ? (emotionEpisode.end - emotionEpisode.start) * 1000 : 0;

            setTimeout(() => {
                if (isRecordingRef.current) {
                    stopRecording();
                    isRecordingRef.current = false;
                }
            }, recordingDuration);
        } catch (err: any) {
            toast.error(`Could not start audio recording: ${err.message}`);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            stopAwaitingAudioPlayback();
        }
    };

    const playAudio = () => {
        let audioPath = undefined;

        if(!audioUrl && emotionEpisode?.audioFileName) {
            audioPath = (getEmotionEpisodeFilePath(emotionEpisode?.uid) + '/' + emotionEpisode?.audioFileName);
            
        } else if(audioUrl) {
            audioPath = audioUrl;
        }

        if (audioPath) {
            setPlaying(true);

            const audio = new Audio(audioPath);
            audio.play().catch(err => { toast.error(`Playback failed: ${err.message}`); setPlaying(false); });

            
            const recordingDuration = emotionEpisode ? (emotionEpisode.end - emotionEpisode.start) * 1000 : 0;
            setTimeout(() => {
                audio.pause();
                setPlaying(false);
            }, recordingDuration);
        }
    };

    const discardAudio = () => {
        setAudioUrl("");
        setAudioBlob(null);
    };


    const loadContentTags = React.useCallback(async () => {
        setAllEmotionEpisodeContentTags(await getAllEmotionEpisodeContentTags());
    }, []);

    const loadData = React.useCallback(async (uid?: string) => {
        try {
            setScene(undefined);
            setEmotionEpisode(undefined);
            let em: IEmotionEpisode | undefined;
            if(uid || props.match.params.uid !== '_') {
                em = await getEmotionEpisode(uid || props.match.params.uid);
                setEmotionEpisode(em);
            }

            if(em?.scene?.uid && em?.scene?.uid !== props.match.params.sceneUid) {
                props.history.push(props.match.url.replace(/\/scenes\/[^/]*\//ig, `/scenes/${em?.scene?.uid}/`));
                return;
            }

            const sc = await getScene(props.match.params.sceneUid);
            setScene(sc);

            if(props.match.params.uid === '_') {
                const newEmotionEpisode = {
                    scene: sc,
                    start: props.match.params.start === '_' ? undefined : Number(props.match.params.start),
                    end: props.match.params.end === '_' ? undefined : Number(props.match.params.end),
                } as IEmotionEpisode;
                setEmotionEpisode(newEmotionEpisode);
            }

            loadContentTags().catch(() => { /** */});

            if(sc.narrative?.uid && em?.absoluteStart) {
                const start = em.absoluteStart || 0;
                const eps = await getAdjacentEmotionEpisodes(start, sc.narrative.uid);
                setAllEmotionEpisodes(eps);
            }
        }
        catch(err) {
            if([401, 403].includes((err as MultiError).code || 0)) {
                props.history.push('/login');
            }
            else {
                //console.log("error: " + err);
                props.history.push(`/narratives/${props.match.params.narrativeUid}`);
            }
        }
    },
    [
        props.match.params.uid,
        props.match.params.sceneUid,
        props.match.params.start,
        props.match.params.end,
        props.match.params.narrativeUid,
        props.match.url,
        props.history,
        loadContentTags,
    ]);

    const episodeStatusShallow = () =>
    {
      return (emotionEpisode?.status === EmotionEpisodeStatusTypes.shallow_map_reviewed || emotionEpisode?.status === EmotionEpisodeStatusTypes.shallow_mapped || emotionEpisode?.status === EmotionEpisodeStatusTypes.in_shallow_map_review);
    }

    const getSceneFileUrl = () => {
        if (scene?.fileName)
            return ((isAbsoluteUrl(scene?.fileName) ? scene?.fileName : getSceneFilePath((scene?.uid || ''), `${getFileName(scene?.fileName || '')}/video.m3u8`)) || '')
        else if(scene?.fileUrl)
            return scene.fileUrl

        return ''
    }

    const getClipStart = () => {
        if(scene?.fileName && emotionEpisode?.start) {
            const start = (emotionEpisode?.start >= scene?.start) ? (emotionEpisode?.start - scene?.start) : emotionEpisode?.start;
            return start ? start : 0;
        }
        else if(scene?.fileUrl)
            return (emotionEpisode?.clipStart ? emotionEpisode?.clipStart : 0);

        return 0;
    }

    const getClipEnd = () => 
    {
        if(scene?.fileName && emotionEpisode?.end) {
            const end = (emotionEpisode?.start >= scene?.start) ? (emotionEpisode?.end - scene?.start) : emotionEpisode?.end;
            return end ? end : 0;
        }
        else if(scene?.fileUrl)
            if (emotionEpisode?.clipStart && emotionEpisode?.clipEnd)
                return emotionEpisode.clipEnd;
            else if(emotionEpisode?.clipStart)
                return (emotionEpisode?.clipStart + (emotionEpisode?.end - emotionEpisode?.start));
        return 0;
    }

    useEffect(() => {
        loadData();
    }, [loadData, props.history, props.match.params.narrativeUid, props.match.params.uid]);

    useEffect(() => {
      const beyondShallow = emotionEpisode?.participants?.some(p => p.goals?.length);
      setMappingGoesBeyondShallow(!!beyondShallow);
    }, [emotionEpisode]);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newEmotionEpisode: Partial<IEmotionEpisode> = {
                uid: emotionEpisode?.uid,
                description: values.description,
                contentTags: values.contentTagsUids?.map(uid => ({ uid } as IEmotionEpisodeContentTag)),
                start: timeToSeconds(values.start),
                end: timeToSeconds(values.end),
                clipStart: timeToSeconds(values.clipStart),
                available: emotionEpisode?.available,
                status: values.status,
                interstitialURL: values.interstitialURL
            };

            let audioFile: File | undefined;
            if (audioBlob) {
                audioFile = new File([audioBlob], `${newEmotionEpisode.uid}.webm`, { type: 'audio/webm' });
            }

            if(!newEmotionEpisode.uid) {
                newEmotionEpisode.scene = { uid: props.match.params.sceneUid } as IScene;
            }
            const uid = await emotionEpisodeSave(newEmotionEpisode, audioFile/* values.audioFile && values.audioFile.file*/);
            if(!emotionEpisode?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_(\/.*)?$/, ''), uid));
            }
            else {
                await loadData(emotionEpisode.uid);
            }
            //get rid of the temp audio if any
            discardAudio();
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getPrevUid = () => {
        if(allEmotionEpisodes && emotionEpisode?.absoluteStart) {
            const sortedEpisodes = allEmotionEpisodes;
            const start = emotionEpisode?.absoluteStart !== undefined ? emotionEpisode?.absoluteStart : 0;
            const prev = sortedEpisodes.findIndex(e => e.absoluteStart && start > e.absoluteStart);
            return sortedEpisodes[prev]?.uid;
        }
    };

    const getNextUid = () => {
        if(allEmotionEpisodes && emotionEpisode?.absoluteStart) {
            const sortedEpisodes = allEmotionEpisodes;
            const start = emotionEpisode?.absoluteStart ? emotionEpisode?.absoluteStart : 0;
            const next = sortedEpisodes.findIndex(lc => lc.absoluteStart && start < lc.absoluteStart);
            return sortedEpisodes[next]?.uid;
        }
    };

    const enableEmotionEpisode = async () => {
        if(!emotionEpisode?.uid) return;
        try {
            await emotionEpisodeEnable(emotionEpisode.uid);
            setEmotionEpisode({...emotionEpisode, available: true });
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };
    const disableEmotionEpisode = async () => {
        if(!emotionEpisode?.uid) return;
        try {
            await emotionEpisodeDisable(emotionEpisode.uid);
            setEmotionEpisode({...emotionEpisode, available: false });
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const linkToExternalVideo = async (uid: string) => {
        if(!uid) return;
        try {
            const url = await emotionEpisodeLink(uid);
            if(url) {
              //console.log("got emotion episode link: ", url);
              window.open(url);
            } else {
              console.log("failed to get emotion episode link");
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            description: Yup.string().trim().required().min(3).max(255),
            interstitialURL: Yup.string().label('Interstitial URL').trim().url(),
            start: Yup.string().trim()
                .matches(/^([0-9]{1,2}:){0,2}[0-9]+$/, 'Start must match the following: H:M:S or M:S or S')
                .test('less', 'Start should be less than End',
                    function(this: { parent: IForm }, startTime) {
                        return !timeToSeconds(this.parent.end) || (timeToSeconds(startTime) || 0) <= (timeToSeconds(this.parent.end) || 0);
                    },
                )
                .test('greaterSceneStart', `Start should be  greater or equal Scene start: ${secondsToTimeString(scene?.start)}`,
                    function(this: { parent: IForm }, startTime) {
                        return (timeToSeconds(startTime) || 0) >= (timeToSeconds(scene?.start?.toString()) || 0);
                    },
                ),
            end: Yup.string().trim()
                .matches(/^([0-9]{1,2}:){0,2}[0-9]+$/, 'End must match the following: H:M:S or M:S or S')
                .test('greater', 'End should be greater than Start',
                    function(this: { parent: IForm }, endTime) {
                        return !timeToSeconds(this.parent.start) || (timeToSeconds(endTime) || 0) >= (timeToSeconds(this.parent.start) || 0);
                    },
                )
                .test('lessSceneEnd', `End should be less or equal Scene end: ${secondsToTimeString(scene?.end)}`,
                    function(this: { parent: IForm }, endTime) {
                        return (timeToSeconds(endTime) || 0) <= (timeToSeconds(scene?.end?.toString()) || 0);
                    },
                ),
        });
    };

    const renderForm = ({ errors, touched, isValid }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>
                <div className="form-buttons">
                            {getPrevUid()
                                ? (<Button
                                    type="reset"
                                    onClick={() => {
                                        setScene(undefined);
                                        setEmotionEpisode(undefined);
                                        props.history.push(joinPath(
                                            '/',
                                            props.match.url.replace(/emotion-episodes\/[^/]*$/ig, ''),
                                            'emotion-episodes',
                                            getPrevUid(),
                                        ));
                                    }}
                                    className="small outline gray"
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} /> <span>Prev</span>
                                </Button>)
                                : (<div></div>)
                            }
                            {getNextUid()
                                ? (<Button
                                    type="reset"
                                    onClick={() => {
                                        setScene(undefined);
                                        setEmotionEpisode(undefined);
                                        props.history.push(joinPath(
                                            '/',
                                            props.match.url.replace(/emotion-episodes\/[^/]*$/ig, ''),
                                            'emotion-episodes',
                                            getNextUid(),
                                        ));
                                    }}
                                    className="small outline gray"
                                >
                                    <span>Next</span> <FontAwesomeIcon icon={faArrowRight} />
                                </Button>)
                                : (<div></div>)
                            }
                        </div>
                    <div className="form-buttons">
                        { emotionEpisode?.uid && !emotionEpisode.available && (
                            <LinkButton onClick={enableEmotionEpisode}><FontAwesomeIcon icon={faUnlock} /> Enable</LinkButton>
                        )}
                        { emotionEpisode?.uid && emotionEpisode.available && (
                            <LinkButton onClick={disableEmotionEpisode}><FontAwesomeIcon icon={faLock} /> Disable</LinkButton>
                        )}
                        { emotionEpisode?.uid && (
                            <LinkButton onClick={() => setEntityForDelete(emotionEpisode)} className="red">
                                <FontAwesomeIcon icon={faTrashAlt} /> Delete
                            </LinkButton>
                        )}
                    </div>
                    { emotionEpisode?.uid && (
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                ID
                            </div>
                            <div>
                                {emotionEpisode?.uid}
                            </div>
                        </label>
                    </div>)}

                    { emotionEpisode?.reviewedBy && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Last reviewed by
                                </div>
                                <div>
                                    <strong>
                                        {emotionEpisode.reviewedBy?.firstName}
                                        &nbsp;
                                        {emotionEpisode.reviewedBy?.lastName}
                                    </strong>
                                    &nbsp; at &nbsp;
                                    {moment(emotionEpisode.reviewedAt).format('ddd, MM/DD/YYYY h:mm a')}
                                </div>
                            </label>
                        </div>
                    )}
                    <div className="form-item">
                      <label>
                      <div className="form-label required">
                          Status
                      </div>
                      <Field
                          component={SelectField}
                          name={FormFields.status}
                          emptyTitle=""
                          data={Object.keys(EmotionEpisodeStatusTypes).map((t: string) => ({ uid: t, name: EmotionEpisodeStatusTypes[t].toUpperCase() }))}
                      />
                      </label>
                      <div className="errors">{touched.status && errors.status}</div>
                      { episodeStatusShallow() && mappingGoesBeyondShallow && (
                        <span>
                          NOTE: Additional Mapping Exists
                        </span>
                      )}
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Description
                            </div>
                            <Field component="textarea" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Content Tags
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.contentTagsUids}
                                data={allEmotionEpisodeContentTags}
                                multiSelect={true}
                                onAddNew={currentUser?.role === UserRoles.admin || currentUser?.role === UserRoles.editor ? setAddNewContentTag : undefined}
                            />
                        </label>
                        <div className="errors">{touched.contentTagsUids && errors.contentTagsUids}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Start
                            </div>
                            <Field type="text" name={FormFields.start} />
                        </label>
                        <div className="errors">{touched.start && errors.start}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                End
                            </div>
                            <Field type="text" name={FormFields.end} />
                        </label>
                        <div className="errors">{touched.end && errors.end}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Absolute Start
                            </div>
                            <div>
                                {emotionEpisode?.absoluteStart !== undefined ? emotionEpisode.absoluteStart : '-'}
                            </div>
                        </label>
                    </div>

                    {scene?.fileUrl && (
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Video Clip Start
                            </div>
                            <Field type="text" name={FormFields.clipStart} />
                        </label>
                        <div className="errors">{touched.clipStart && errors.clipStart}</div>
                    </div>)}

                    {emotionEpisode?.uid && (<>

                        <div className="form-item">
                            <label>
                                <div className="form-label">Audio File</div>
                                <Field
                                    component={UploaderField}
                                    name="audioFile"
                                    acceptFileTypes=".mp3"
                                    path={getEmotionEpisodeFilePath(emotionEpisode?.uid || '')}
                                />
                            </label>
                            <div className="errors">
                            {touched.audioFile && errors.audioFile}
                            </div>
                        </div>
                        
                        {emotionEpisode?.audioFileUpdatedAt && (
                                <div className="form-item">
                                    <label>
                                        <div className="form-label">
                                        {'Audio last updated: ' +  moment(emotionEpisode?.audioFileUpdatedAt).format('ddd, MM/DD/YYYY h:mm a')}
                                        </div>
                                    </label>
                                </div>                            
                            )}

                        {audioBlob && (
                                <div className="form-item">
                                    <label>
                                        <div className="form-label">
                                        Temp audio recorded. Save Segment to keep.
                                        </div>
                                    </label>
                                </div>
                        )}

                        <div className="form-buttons">

                            {isRecording ? (
                                <Button onClick={stopRecording}>Stop Recording</Button>
                            ) : (<>
                                {recordingStatus === EmotionEpisodeRecordingStatusTypes.awaiting_playback ? 
                                    (<Button onClick={stopAwaitingAudioPlayback}>Stop Awaiting Playback</Button>) :
                                    (<Button onClick={awaitAudioPlaybackBeforeRecord}>Start Recording</Button>)
                                }
                            </>)}
                            {(audioBlob || emotionEpisode?.audioFileName) && !isRecording && recordingStatus === EmotionEpisodeRecordingStatusTypes.none && (
                                <div>
                                    <Button className='marginbottom gray' onClick={playAudio}>{playing ? 'Playing' : 'Play'}</Button>
                                    {audioBlob && (
                                        <Button className='gray' onClick={discardAudio}>Discard</Button>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Interstitial URL
                                </div>
                                <Field type="text" name={FormFields.interstitialURL} />
                            </label>
                            <div className="errors">{touched.interstitialURL && errors.interstitialURL}</div>
                        </div>

                      <div className="form-item">
                        <LinkButton onClick={() => linkToExternalVideo(emotionEpisode?.uid)} className='green'
                        >
                          <FontAwesomeIcon icon={faExternalLinkAlt} /> Go to External Clip
                          </LinkButton><br />
                      </div>
                    </>)}

                    { emotionEpisode?.createdBy && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Created
                                </div>
                                <div>
                                    <strong>
                                        {emotionEpisode.createdBy?.firstName}
                                        &nbsp;
                                        {emotionEpisode.createdBy?.lastName}
                                    </strong>
                                    &nbsp; at &nbsp;
                                    {moment(emotionEpisode.createdAt).format('ddd, MM/DD/YYYY h:mm a')}
                                </div>
                            </label>
                        </div>
                    )}
                    { emotionEpisode?.updatedBy && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Updated
                                </div>
                                <div>
                                    <strong>
                                        {emotionEpisode.updatedBy?.firstName}
                                        &nbsp;
                                        {emotionEpisode.updatedBy?.lastName}
                                    </strong>
                                    &nbsp; at &nbsp;
                                    {moment(emotionEpisode.updatedAt).format('ddd, MM/DD/YYYY h:mm a')}
                                </div>
                            </label>
                        </div>
                    )}

                    <div className="form-buttons">
                        <Button
                            onClick={() =>
                                props.history.push(`/narratives/${props.match.params.narrativeUid}/scenes/${props.match.params.sceneUid}`)
                            }
                            className="gray"
                        >
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>

                        { loading
                        ? (<Loader />)
                        : (<Button type="submit"><span>{emotionEpisode?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                    <Button className="orange" onClick={() => setTestExercisesPopupVisible(true)}><span>Test Exercises</span></Button>
                </Form>
            </div>
        );
    };

    return !scene || !emotionEpisode ? null : (<>
        <Block className={styles.editor}>
            { emotionEpisode?.scene?.narrative?.title && (<>
                <AddBreadcrumbsItem
                    title={`Narrative: ${cutStr(emotionEpisode.scene.narrative.title)}`}
                    url={`/narratives/${props.match.params.narrativeUid}`}
                />
                <AddBreadcrumbsItem
                    title={emotionEpisode.scene && `Scene: ${cutStr(emotionEpisode.scene.description)}`}
                    url={`/narratives/${props.match.params.narrativeUid}/scenes/${scene.uid}`}
                />
                <AddBreadcrumbsItem
                    title={
                        emotionEpisode && emotionEpisode.uid
                        ? `EmEpisode: ${cutStr(emotionEpisode.description)}`
                        : 'Add Emotion Episode'
                    }
                    url={props.match.url}
                />
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        description: emotionEpisode?.description || '',
                        contentTagsUids: emotionEpisode?.contentTags?.map(tag => tag.uid),
                        start: (emotionEpisode && secondsToTimeString(emotionEpisode.start)) || '',
                        end: (emotionEpisode && secondsToTimeString(emotionEpisode.end)) || '',
                        clipStart: (emotionEpisode && secondsToTimeString(emotionEpisode.clipStart)) || '',
                        status: emotionEpisode?.status || (emotionEpisode?.available ? EmotionEpisodeStatusTypes.reviewed : EmotionEpisodeStatusTypes.new),
                        interstitialURL: emotionEpisode?.interstitialURL || '',
                        audioFile: (emotionEpisode?.audioFileName && {url: emotionEpisode?.audioFileName}) ||
                            undefined,

                    }}
                    validationSchema={getValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {renderForm}
                </Formik>

                {(scene?.fileName || scene?.fileUrl) && (<div className={styles.videoContainer}>
                    <Video
                        url={getSceneFileUrl()}
                        controls={true}
                        //TODO: this logic will may fail for early/long scenes (because the episode start might be greater than the scene start in that case)
                        startPos={getClipStart()}
                        endPos={getClipEnd()}
                    />
                    <br/>
                </div>
                )}
            </>)}
        </Block>


        { entityForDelete && (
            <DeleteQuestionPopup
                title="Delete Emotion Episode"
                question={<>Are you sure want to delete emotion episode?</>}
                onClose={() => setEntityForDelete(undefined)}
                onDelete={async () => await emotionEpisodeDelete(entityForDelete.uid)}
                onFinish={() => { props.history.push(`/narratives/${props.match.params.narrativeUid}/scenes/${props.match.params.sceneUid}`); }}
            />
        )}

        {addNewContentTag && (
            <Popup
                onClose={() => setAddNewContentTag(undefined)}
                title="Add new Emotion Episode Content Tag"
            >
                <EmotionEpisodeContentTagEditorComponent
                    tag={{ name: addNewContentTag } as IEmotionEpisodeContentTag}
                    onClose={() => setAddNewContentTag(undefined)}
                    onSaved={(uid: string) => {
                        loadContentTags()
                        .then(() => {
                            if(formRef.current) {
                                const tags = formRef.current.values[FormFields.contentTagsUids];
                                formRef.current.setFieldValue(FormFields.contentTagsUids, [...(tags || []), uid]);
                            }
                        }).catch(() => { /** */});
                        setAddNewContentTag(undefined);
                    }}
                />
            </Popup>
        )}


        { emotionEpisode?.uid && (
            <InitiatingEventEditor
                {...props}
                emotionEpisode={emotionEpisode}
                loading={loading}
                reloadData={loadData}
                setLoading={setLoading}
            />
        )}

        { emotionEpisode?.uid && (<>
            <div className="list-title">
                <div>Participants:
                    <strong>{ (emotionEpisode.participants && emotionEpisode.participants.length) || 0 }</strong>
                </div>
                <div>
                    <Button
                        className="orange"
                        onClick={() => setParticipantSelectorPopupVisible(true) }
                    >
                        <FontAwesomeIcon icon={faPlus} /> <span>Add Participant</span>
                    </Button>
                </div>
            </div>
            { !!emotionEpisode.participants?.length && (
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <thead>
                        <tr>
                            <th>Participant</th>
                            <th>Emotional Response</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            emotionEpisode.participants
                            .sort((p1, p2) => p1.character.name > p2.character.name ? 1 : (p1.character.name < p2.character.name ? -1 : 0) )
                            .map((p: IParticipant) => (
                                <tr key={p.uid}>
                                    <td>
                                        <img
                                            className={styles.characterPhoto}
                                            src={getCharacterFilePath(p.character.uid, p.character.fileName)} alt={p.character.name}
                                        />
                                        {p.character.name}
                                    </td>
                                    <td>
                                        { (p.emotions && p.emotions.length) || (p.appraisals && p.appraisals.length) ? 'Yes' : 'No' }
                                    </td>
                                    <td>
                                        <LinkButton
                                            onClick={() => props.history.push(joinPath('/', props.match.url, 'participant', p.uid)) }
                                        >
                                            <FontAwesomeIcon icon={faEdit} /> Edit
                                        </LinkButton><br />
                                        <LinkButton
                                            className="red"
                                            onClick={() => { setParticipantEntityForDelete(p) }}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                        </LinkButton><br />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )}
        </>)}

        { participantSelectorPopupVisible && emotionEpisode?.uid && (
            <Popup
                className={styles.addParticipantPopup}
                title="Add Participant"
                buttons={[
                    {
                        title: <><FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span></>,
                        className: 'gray',
                        onClick: () => { setParticipantSelectorPopupVisible(false); },
                    },
                    {
                        title: <><span>Add</span> <FontAwesomeIcon icon={faPlus} /></>,
                        className: 'red',
                        onClick: async () => {
                            try {
                                if(!newParticipantUid) return;
                                await participantAdd(emotionEpisode.uid, newParticipantUid);
                                loadData().catch(() => {/** */});
                                setParticipantSelectorPopupVisible(false);
                            }
                            catch(err) {
                                basePerformError(err, props.history);
                            }
                        },
                        disabled: !newParticipantUid || loading,
                    },
                ]}
            >
                Character:
                <select
                    onChange={(event: React.FormEvent<HTMLSelectElement>) => setNewParticipantUid(event.currentTarget.value)}
                >
                    <option></option>
                    { emotionEpisode.scene?.narrative?.characters?.map(
                        (character: ICharacter) => (
                            <option
                                key={character.uid}
                                value={character.uid}
                                disabled={emotionEpisode.participants?.some(
                                    (participant: IParticipant) => participant.character.uid === character.uid)
                                }
                            >
                                {character.name}
                            </option>
                        ),
                    )}
                </select>
            </Popup>
        )}


        { participantEntityForDelete && (
            <DeleteQuestionPopup
                title="Delete Participant"
                question={<>Are you sure want to delete participant: {participantEntityForDelete.character.name}?</>}
                onClose={() => setParticipantEntityForDelete(undefined)}
                onDelete={async () => await participantDelete(participantEntityForDelete.uid)}
                onFinish={() => { loadData(); }}
            />
        )}



        { testExercisesPopupVisible && emotionEpisode && (
            <Popup
                title="Test Exercises"
                modal={true}
                buttons={[
                    {
                        title: <><FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span></>,
                        className: 'gray',
                        onClick: () => { setTestExercisesPopupVisible(false); },
                    },
                ]}
            >
                <EmotionalEpisodeExercisesTester emotionEpisode={emotionEpisode} />
            </Popup>
        )}

    </>);
}
