import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UnitAddConcept($uid: String!, $conceptUid: String!){
        unit_add_concept (uid: $uid, concept_uid: $conceptUid) {
            success
        }
    }
`;

export async function unitAddConcept(uid: string, conceptUid: string): Promise<{ success: boolean }> {
    const result: FetchResult<{ unit_add_concept: { success: boolean }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { uid, conceptUid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.unit_add_concept;
}
