import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { emailDisable } from '../../../api/actions/email/email-disable';
import { emailEnable } from '../../../api/actions/email/email-enable';
import { emailTest } from '../../../api/actions/email/email-test';
import { getEmail } from '../../../api/actions/email/email-get';
import { emailSave } from '../../../api/actions/email/email-save';
import { Block } from '../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { CheckBoxWrapper } from '../../../components/shared/checkbox/checkbox-wrapper';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { SelectField } from '../../../components/shared/select/select';
import { Tabs } from '../../../components/shared/tabs/tabs';
import { enumToValueArr } from '../../../helpers/enum-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import { joinPath } from '../../../helpers/path-helpers';
import { IEmail } from '../../../models/email';
import { EmailTypes } from '../../../models/enums/email-types';
import { IDictionary } from '../../../types/dictionary';
import styles from './email-editor.module.scss';

interface IForm {
    identifier: string,
    name: string,
    description?: string,
    subject?: string,
    type: string,
    template?: string,
    daysAfterSignup?: number
}

export function EmailEditor(props: RouteComponentProps<{uid: string}>) {

    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState<IEmail>();

    const loadData = React.useCallback(async (uid?: string) => {
        setLoading(true);
        try {
            if(uid || props.match.params.uid !== '_') {
                setEmail(await getEmail(uid || props.match.params.uid));
            }
            else {
                setEmail({} as IEmail);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
        setLoading(false);
    }, [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newEmail: Partial<IEmail> = {
                uid: email?.uid,
                identifier: values.identifier?.trim(),
                name: values.name?.trim(),
                subject: values.subject?.trim(),
                description: values.description,
                type: values.type as EmailTypes,
                template: values.template?.trim(),
                daysAfterSignup: values.daysAfterSignup,
            };
            const uid = await emailSave(newEmail);
            if(!email?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            }
            await loadData(uid);
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const testEmail = async () => {
        if(!email?.uid) return;
        try {
            await emailTest(email.uid);
            toast.success('Test email has been successfully sent');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const enableEmail = async () => {
        if(!email?.uid) return;
        try {
            await emailEnable(email.uid);
            setEmail(undefined);
            loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const disableEmail = async () => {
        if(!email?.uid) return;
        try {
            await emailDisable(email.uid);
            setEmail({...email, enabled: false });
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };


    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().trim().label('Name').required().min(3).max(50),
            identifier: Yup.string().trim().label('Identifier').required().min(3).max(50),
            description: Yup.string().trim().label('Description').min(3).max(255),
            subject: Yup.string().trim().label('Description').min(3).max(100),
            type: Yup.string().required('Please select Type').oneOf(enumToValueArr(EmailTypes)),
            template: Yup.string().trim().label('Template').required().min(3).max(50),
        });
    };

    const renderForm = ({ errors, touched }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>

                    <div className="form-buttons">
                        { email?.uid && !email.enabled && (
                            <LinkButton onClick={enableEmail}><FontAwesomeIcon icon={faUnlock} /> Enable</LinkButton>
                        )}
                        { email?.uid && email.enabled && (
                            <LinkButton onClick={disableEmail}><FontAwesomeIcon icon={faLock} /> Disable</LinkButton>
                        )}
                        { email?.uid && (
                            <LinkButton onClick={() => { /* */ }} className="red"><FontAwesomeIcon icon={faTrashAlt} /> Delete</LinkButton>
                        )}
                    </div>
                    <div>
                      { email?.uid && email.template && (
                        <LinkButton onClick={testEmail}>Test Email</LinkButton>
                      )}
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Identifier
                            </div>
                            <Field type="text" name="identifier" />
                        </label>
                        <div className="errors">{touched.identifier && errors.identifier}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name
                            </div>
                            <Field type="text" name="name" />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field component="textarea" name="description" />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Subject
                            </div>
                            <Field type="text" name="subject" />
                        </label>
                        <div className="errors">{touched.subject && errors.subject}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Template
                            </div>
                            <Field type="text" name="template" />
                        </label>
                        <div className="errors">{touched.template && errors.template}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Type
                            </div>
                            <Field
                                component={SelectField}
                                name="type"
                                emptyTitle=""
                                data={ Object.keys(EmailTypes).map(t => ({ uid: t, name: t.toUpperCase() })) }
                            />
                        </label>
                        <div className="errors">{touched.type && errors.type}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Send X days after signup
                            </div>
                            <Field type="number" name="daysAfterSignup" />
                        </label>
                        <div className="errors">{touched.daysAfterSignup && errors.daysAfterSignup}</div>
                    </div>

                    <div className="form-buttons">
                        <Button onClick={() => props.history.push('/emails')} className="gray">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                        ? (<Loader />)
                        : (<Button type="submit"><span>{email?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return (<>
        { email && (<>
            <Tabs
                data={[
                    {
                        title: 'Info',
                        content: (<>
                            <br />
                            <Block className={styles.editor}>
                                <AddBreadcrumbsItem
                                    title={email && email.uid ? `Edit: ${email.name}` : 'Add Email'}
                                    url={props.match.url}
                                />
                                <Formik
                                        initialValues={{
                                            identifier: email.identifier || '',
                                            name: email.name || '',
                                            description: (email && email.description) || '',
                                            subject:(email && email.subject) || '',
                                            type: (email && email.type) || '',
                                            template: (email && email.template) || '',
                                            daysAfterSignup: (email && email.daysAfterSignup) || 0
                                        }}
                                        validationSchema={getValidationSchema}
                                        onSubmit={handleSubmit}
                                >
                                    {renderForm}
                                </Formik>

                            </Block>

                            </>)}
                ]}
            />
        </>)}
    </>);
}
