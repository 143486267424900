import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './search-field.module.scss';

interface IProps {
    value?: string,
    onChange: (val?: string) => void,
    timeOutMS?: number,
    placeholder?: string,
}

export function SearchField(props: IProps) {

    const [timeOut, setTimeOut] = React.useState<NodeJS.Timeout>();
    const [value, setValue] = React.useState<string | undefined>(props.value || '');

    const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if(timeOut) clearTimeout(timeOut);
        const val = event.target.value;
        setValue(val);
        setTimeOut(
            setTimeout(
                () => props.onChange(val),
                props.timeOutMS || 700,
            )
        );
    }, [props, timeOut]);

    const onClear = React.useCallback(() => {
        if(timeOut) clearTimeout(timeOut);
        setValue('');
        props.onChange();
    }, [props, timeOut]);

    return (
        <div className={styles.searchField}>
            <input
                type="text"
                className={styles.searchInputField}
                onChange={onChange}
                value={value}
                placeholder={props.placeholder || 'Enter your search keywords'}
            />
            <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
            { value && (<FontAwesomeIcon icon={faTimes} className={styles.clearField} onClick={onClear} />) }
        </div>
    )
}