import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faArrowDown, faArrowUp, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { achievementDisable } from '../../api/actions/achievement/achievement-disable';
import { achievementEnable } from '../../api/actions/achievement/achievement-enable';
import { getAllAchievements } from '../../api/actions/achievement/achievement-get-all';
import { achievementSetIndex } from '../../api/actions/achievement/achievement-set-index';
import { AddBreadcrumbsItem } from '../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../components/shared/button/button';
import { LinkButton } from '../../components/shared/link-button/link-button';
import { getAchievementFilePath } from '../../config/app-config';
import { basePerformError } from '../../helpers/error-helpers';
import { joinPath } from '../../helpers/path-helpers';
import { IAchievement } from '../../models/achievement';
import styles from './achievements-list.module.scss';


export function AchievementsList(props: RouteComponentProps) {
    const [achievements, setAchievements] = useState<IAchievement[]>([]);

    const loadData = React.useCallback(async () => {

        try {
            setAchievements((await getAllAchievements(undefined))?.sort((a1, a2) => a1.index - a2.index))
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history]);

    useEffect(() => {
        loadData().catch(() =>{/** */});
    }, [loadData]);

    const renderButtons = () => (
        <div className={styles.buttons}>
            <Button className="orange" to={joinPath(props.match.url, '_')}>Add Achievement</Button>
        </div>
    );

    const disableAchievement = async (uid: string) => {
        try {
            await achievementDisable(uid);
            loadData().catch(() =>{/** */});
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const enableAchievement = async (uid: string) => {
        try {
            await achievementEnable(uid);
            loadData().catch(() =>{/** */});
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const setIndex = async (uid: string, index: number) => {
        try {
            await achievementSetIndex(uid, index);
            loadData().catch(() =>{/** */});
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    return (<>
        <AddBreadcrumbsItem
            title={'Achievements'}
            url={props.match.url}
        />

        {renderButtons()}

        <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Icon</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {
                    achievements.map(((achievement, i) => (
                        <tr key={achievement.uid}>
                            <td>
                                <div className={styles.markContainer}>
                                    <div className={`${styles.mark} ${achievement.available || i === 0 ? '' : styles.disabled}`}>&nbsp;</div>
                                    <div className={styles.title}>
                                        {achievement.index}
                                        <LinkButton
                                            onClick={() => setIndex(achievement.uid, i + 2)}
                                            disabled={i >= (achievements?.length || 0) - 1}
                                        >
                                            <FontAwesomeIcon icon={faArrowDown} />
                                        </LinkButton>
                                        <LinkButton
                                            onClick={() => setIndex(achievement.uid, i)}
                                            disabled={i < 1}
                                        >
                                            <FontAwesomeIcon icon={faArrowUp} />
                                        </LinkButton>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <img
                                    alt={achievement.name}
                                    className={styles.icon}
                                    src={joinPath(getAchievementFilePath(achievement.uid), achievement.iconFileName)}
                                />
                            </td>
                            <td>
                                {i === 0 && (<strong>(Default) </strong>)}
                                {achievement.name}
                            </td>
                            <td>
                                {achievement.achievementType.toUpperCase()}
                            </td>
                            <td>
                                <LinkButton to={joinPath(props.match.url, achievement.uid)}>
                                    <FontAwesomeIcon icon={faEdit} /> Edit
                                </LinkButton><br />
                                { !achievement.available && (<>
                                    <LinkButton className={styles.enableBtn} onClick={() => enableAchievement(achievement.uid)}>
                                        <FontAwesomeIcon icon={faUnlock} /> Enable
                                    </LinkButton><br />
                                </>)}
                                { achievement.available && i > 0 && (<>
                                    <LinkButton onClick={() => disableAchievement(achievement.uid)}>
                                        <FontAwesomeIcon icon={faLock} /> Disable
                                    </LinkButton><br />
                                </>)}
                            </td>
                        </tr>
                    )))
                }
            </tbody>
        </table>

        {renderButtons()}
    </>);
}
