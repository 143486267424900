import { faArrowLeft, faLock, faTrashAlt, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { achievementDelete } from '../../api/actions/achievement/achievement-delete';
import { achievementDisable } from '../../api/actions/achievement/achievement-disable';
import { achievementEnable } from '../../api/actions/achievement/achievement-enable';
import { getAllAchievements } from '../../api/actions/achievement/achievement-get-all';
import { achievementSave } from '../../api/actions/achievement/achievement-save';
import { Block } from '../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../components/shared/button/button';
import { LinkButton } from '../../components/shared/link-button/link-button';
import { Loader } from '../../components/shared/loader/loader';
import { DeleteQuestionPopup } from '../../components/shared/popup/delete-question-popup';
import { SelectField } from '../../components/shared/select/select';
import { UploaderField } from '../../components/shared/uploader/uploader';
import { UploaderImagePreview } from '../../components/shared/uploader/uploader-image-preview';
import { getAchievementFilePath } from '../../config/app-config';
import { basePerformError } from '../../helpers/error-helpers';
import { joinPath } from '../../helpers/path-helpers';
import { IAchievement } from '../../models/achievement';
import { AchievementTypes } from '../../models/enums/achievement-types';
import { IFile } from '../../models/file';
import { IDictionary } from '../../types/dictionary';
import { MultiError } from '../../types/multi-error';
import styles from './achievement-editor.module.scss';


enum FormFields {
    name = 'name',
    description = 'description',
    achievementType = 'achievementType',
    index = 'index',
    image = 'image',
}

interface IForm {
    [FormFields.name]: string,
    [FormFields.description]?: string,
    [FormFields.achievementType]?: AchievementTypes,
    [FormFields.index]?: number,

    [FormFields.image]?: IFile,
}

export function AchievementEditor(props: RouteComponentProps<{uid: string}>) {

    const [loading, setLoading] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [achievement, setAchievement] = useState<IAchievement>();
    const [allAchievements, setAllAchievements] = useState<IAchievement[]>();

    const loadData = useCallback(async (uid?: string) => {
        try {
            const all = await getAllAchievements(undefined);
            setAllAchievements(all);
            if(uid || props.match.params.uid !== '_') {
                setAchievement(all.find(a => a.uid === (uid || props.match.params.uid)));
            }
            else {
                setAchievement({} as IAchievement);
            }
        }
        catch(err) {
            basePerformError(err as MultiError, props.history);
        }
    }, [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData().catch(() => {/** */});
    }, []);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newAchievement: Partial<IAchievement> = {
                uid: achievement?.uid,
                name: values.name,
                description: values.description,
                achievementType: values.achievementType,
                index: values.index,

                iconFileName: values.image?.url,
            };

            const uid = await achievementSave(newAchievement, values.image?.file);
            if(!achievement?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            }
            await loadData(uid);
            toast.success('Item has been successfully saved');
        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().trim().required('Please enter Name').min(3).max(50),
            description: Yup.string().trim().min(3).max(255),
            achievementType: Yup.mixed<AchievementTypes>().label('Type').required(),
            index: Yup.number().label('Index').required().min(1).max((allAchievements?.length || 1) + 1),

            image: Yup.mixed().label('Icon').required(),
        });
    };

    const enableAchievement = async () => {
        if(!achievement?.uid) return;
        try {
            await achievementEnable(achievement.uid);
            setAchievement({...achievement, available: true });
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const disableAchievement = async () => {
        if(!achievement?.uid) return;
        try {
            await achievementDisable(achievement.uid);
            setAchievement({...achievement, available: false });
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const deleteAchievement = async () => {
        if(!achievement?.uid) return;
        await achievementDelete(achievement?.uid);
        setDeleteDialogVisible(false);
    };

    const renderForm = ({ errors, touched, isValid }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>

                    <div className="form-buttons">
                        { achievement?.uid && !achievement.available && (
                            <LinkButton onClick={enableAchievement}><FontAwesomeIcon icon={faUnlock} /> Enable</LinkButton>
                        )}
                        { achievement?.uid && achievement.available && (
                            <LinkButton onClick={disableAchievement}><FontAwesomeIcon icon={faLock} /> Disable</LinkButton>
                        )}
                        { achievement?.uid && (
                            <LinkButton onClick={() => setDeleteDialogVisible(true)} className="red"><FontAwesomeIcon icon={faTrashAlt} /> Delete</LinkButton>
                        )}
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name
                            </div>
                            <Field type="text" name="name" />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field component="textarea" name="description" />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Type
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.achievementType}
                                emptyTitle=""
                                data={
                                    Object.keys(AchievementTypes)
                                    .map((x: string) => ({ uid: x, name: x.toUpperCase() }))
                                }
                            />
                        </label>
                        <div className="errors">{touched.achievementType && errors.achievementType}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Index
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.index}
                                emptyTitle=""
                                data={[
                                    { uid: 1, name: "First" },
                                    ...(
                                        allAchievements?.sort((a1, a2) => a1.index - a2.index).map((a: IAchievement, i: number) => (
                                            a.uid === achievement?.uid
                                            ? null
                                            : {
                                                uid: !achievement?.index || achievement?.index > i ? (i + 2) : (i + 1),
                                                name: `#${!achievement?.index || achievement?.index > i ? (i + 2) : (i + 1)} After: ${a.name}`,
                                            }
                                        )).filter((u) => !!u)
                                        || []
                                    ),
                                ]}
                            />
                        </label>
                        <div className="errors">{touched.index && errors.index}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Icon
                            </div>
                            <Field
                                component={UploaderField}
                                name="image"
                                acceptFileTypes=".png,.jpg,.jpeg,.svg"
                                path={achievement?.uid && getAchievementFilePath(achievement.uid)}
                                previewComponent={UploaderImagePreview}
                            />
                        </label>
                        <div className="errors">{touched.image && errors.image}</div>
                    </div>

                    <div className="form-buttons">
                        <Button className="gray" to={'/libraries/achievements'}>
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>{achievement?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !achievement ? null : (<>
        <Block className={styles.editor}>

            <AddBreadcrumbsItem
                title={'Achievements'}
                url={'/libraries/achievements'}
            />
            <AddBreadcrumbsItem
                title={achievement.uid ? `Edit: ${achievement.name}` : 'Add Achievement'}
                url={props.match.url}
            />
            <Formik
                initialValues={{
                    name: achievement?.name || '',
                    description: achievement?.description || '',
                    achievementType: achievement?.achievementType || '',
                    index: achievement?.index || 0,

                    image: (achievement
                        && (
                            (achievement.iconFileName && { url: achievement.iconFileName })
                            || (achievement.iconFile && { file: achievement.iconFile })
                        )) || undefined,
                }}
                validationSchema={getValidationSchema}
                onSubmit={handleSubmit}
            >
                {renderForm}
            </Formik>
        </Block>

        {deleteDialogVisible && (
            <DeleteQuestionPopup
                title="Achievement Deletion"
                question={<>Are you sure want to delete Achievement?</>}
                onClose={() => setDeleteDialogVisible(false)}
                onDelete={deleteAchievement}
                onFinish={() => { props.history.push('/libraries/achievements') }}
            />
        )}
    </>);
}
