import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getAllPipOptions } from '../../../api/actions/pip-option/pip-option-get-all';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { Grid } from '../../../components/shared/grid/grid';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { compareText } from '../../../helpers/array-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import { joinPath } from '../../../helpers/path-helpers';
import { IPipOption } from '../../../models/pip-option';
import styles from './pip-options-list.module.scss';

export function PipOptionsList(props: RouteComponentProps) {
    const [loading, setLoading] = useState(true);
    const [pipOptions, setPipOptions] = useState<IPipOption[]>([]);

    const loadData = React.useCallback(async () => {
        setLoading(true);
        try {
            setPipOptions((await getAllPipOptions()).sort((el1, el2) => compareText(el1, el2, (el) => el.name)))
        }
        catch(err) {
            basePerformError(err, props.history);
        }
        setLoading(false);
    }, [props.history]);

    useEffect(() => {
        loadData().catch(() =>{/** */});
    }, [loadData]);

    const renderButtons = () => (
        <div className={styles.buttons}>
            <Button className="orange" to={joinPath(props.match.url, '_')}>Add PIP Option</Button>
        </div>
    );

    return loading ? (<Loader />) : (<>
        <AddBreadcrumbsItem
            title="PIP Options"
            url={props.match.url}
        />

        

        <Grid<IPipOption>
            getPageData={(page, pageSize) => {
                return Promise.resolve({
                    page,
                    count: pipOptions?.length || 0,
                    data: pipOptions?.slice(pageSize * (page - 1), pageSize * page),
                });
            }}

            containerRenderer={(content, pager) => (<>
                <div className={styles.pager}>{pager} {renderButtons()}</div>
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Earn Option ID</th>
                            <th>Amount</th>
                            <th>Actions</th>
                        </tr>
                        {content}
                    </tbody>
                </table>
                <div className={styles.pager}>{pager}</div>
            </>)}

            itemRenderer={(pipOption, i) => (
                <tr key={pipOption.uid}>
                    <td>
                        {i + 1}
                    </td>
                    <td>
                        {pipOption.name}
                    </td>
                    <td>
                        {pipOption.earnOptionId}
                    </td>
                    <td>
                        {pipOption.amount}
                    </td>
                    <td>
                        <LinkButton to={joinPath(props.match.url, pipOption.uid)}>
                            <FontAwesomeIcon icon={faEdit} /> Edit
                        </LinkButton><br />
                    </td>
                </tr>
            )}
        />

        {renderButtons()}
    </>);
}