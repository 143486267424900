import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IEmotionEpisode } from '../../../models/emotion-episode';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetEmotionEpisode($uid: String!){
        emotion_episode(uid: $uid) {
            uid,
            description,
            start,
            end,
            clipStart: clip_start,
            clipEnd: clip_end,
            absoluteStart: absolute_start,
            available,
            status,
            interstitialURL: interstitial_url,
            audioFileName: audio_file_name,
            audioFileUpdatedAt: audio_file_updated_at,
            createdAt: created_at,
            updatedAt: updated_at,
            reviewedAt: reviewed_at,

            contentTags: content_tags {
                uid,
                name,
            }

            createdBy: created_by {
                uid,
                firstName: first_name,
                lastName: last_name,
            },

            updatedBy: updated_by {
                uid,
                firstName: first_name,
                lastName: last_name,
            },

            reviewedBy: reviewed_by {
                uid,
                firstName: first_name,
                lastName: last_name,
            },

            initiatingEvent: initiating_event {
                uid,
                description,
                quote,
                type,
                participant {
                    uid
                },

                createdAt: created_at,
                updatedAt: updated_at,

                createdBy: created_by {
                    uid,
                    firstName: first_name,
                    lastName: last_name,
                },

                updatedBy: updated_by {
                    uid,
                    firstName: first_name,
                    lastName: last_name,
                },
            }

            participants {
                uid,

                character {
                    uid,
                    name,
                    fileName: file_name,
                }

                emotions {
                    uid,
                }
                appraisals {
                    uid,
                }
                goals {
                    uid
                }
            },

            scene {
                uid,
                description,
                start,
                end,
                fileName: file_name,

                narrative {
                    uid,
                    title,

                    characters {
                        uid,
                        name,
                        fileName: file_name,
                    },
                }
            }
        }
    }
`;

export async function getEmotionEpisode(uid: string): Promise<IEmotionEpisode> {
    const result: FetchResult<{ emotion_episode: IEmotionEpisode }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.emotion_episode, ['__typename']);
}
