import { FetchResult, gql } from 'apollo-boost';
import { INarrative } from '../../../models/narrative';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation NarrativeSave($narrative: NarrativeInput!, $imageFile: Upload, $signatureFile: Upload, $catalogFile: Upload, $soundtrackFile: Upload){
        narrative_save (narrative_data: $narrative, imageFile: $imageFile, signatureFile: $signatureFile, catalogFile: $catalogFile, soundtrackFile: $soundtrackFile) {
            uid
        }
    }
`;

export async function narrativeSave(narrative: Partial<INarrative>, imageFile?: File, signatureFile?: File, catalogFile?: File, soundtrackFile?: File): Promise<string> {
    const result: FetchResult<{ narrative_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { narrative: {
            uid: narrative.uid,
            title: narrative.title,
            min_age_rating: narrative.minAgeRating,
            description: narrative.description,
            content_url: narrative.contentUrl,
            media_type: narrative.mediaType,
            platform_type: narrative.platformType,
            platforms: narrative.platforms,
            file_name: narrative.fileName,
            coming_soon: narrative.comingSoon,
            status: narrative.status,
            duration: narrative.duration,
            end_survey: narrative.endSurveyUid
        }, imageFile, signatureFile, catalogFile, soundtrackFile },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.narrative_save.uid;
}
