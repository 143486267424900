import { faArrowLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { basePerformError } from '../../../helpers/error-helpers';
import { Popup } from './popup';
import { MultiError } from '../../../types/multi-error';

interface IProps extends RouteComponentProps {
    title: string | React.ReactElement,
    question: string | React.ReactElement,
    successText?: string,
    onClose(): void,
    onDelete(): any,
    onFinish(): void,
}

function DeleteQuestionPopup(props: IProps) {
    return (
        <Popup
            modal={true}
            className="red"
            title={props.title}
            onClose={props.onClose}
            buttons={[
                {
                    title: <><FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span></>,
                    className: 'gray',
                    onClick: props.onClose,
                },
                {
                    title: <><span>Delete</span> <FontAwesomeIcon icon={faTrashAlt} /></>,
                    className: 'red',
                    onClick: async () => {
                        try {
                            await props.onDelete();
                            props.onFinish();
                            toast.success(props.successText || 'Item has been successfully deleted');
                            props.onClose();
                        }
                        catch(err) {
                            basePerformError(err as MultiError, props.history);
                        }
                    },
                },
            ]}
        >
            {props.question}
        </Popup>
    );
}


const DeleteQuestionPopupWithHistory = withRouter(DeleteQuestionPopup);

export { DeleteQuestionPopupWithHistory as DeleteQuestionPopup };
