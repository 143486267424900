import { FetchResult, gql } from 'apollo-boost';
import { IEmotion } from '../../../models/emotion';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation EmotionSave($emotion: EmotionInput!){
        emotion_save (emotion_data: $emotion) {
            uid
        }
    }
`;

export async function emotionSave(emotion: Partial<IEmotion>): Promise<string> {
    const result: FetchResult<{ emotion_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { emotion },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.emotion_save.uid;
}
