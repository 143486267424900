import * as React from 'react';


export function createContext<T>(defaultState: () => Promise<T>): [
    React.Context<{
        state?: T | undefined,
        setState: React.Dispatch<React.SetStateAction<T | undefined>>,
    } | null | undefined>,
    <C>(props: {children: C}) => JSX.Element,
] {
    const NewContext = React.createContext<
        {state?: T, setState: React.Dispatch<React.SetStateAction<T | undefined>>} | null | undefined
    >(undefined);

    function ContextContainer<C>(props: { children: C }) {

        const [state, setState] = React.useState<T>()

        React.useEffect(() => {
                (async() => {
                    if(typeof defaultState === 'function') {
                        setState(await defaultState())
                    }
                })().catch(() => {/** */});
        }, [])
        
        return (
            <NewContext.Provider value={{state, setState}}>
                { props.children }
            </NewContext.Provider>
        )
    }

    return [NewContext, ContextContainer];
}