import { FetchResult, gql } from 'apollo-boost';
import { IUser } from '../../../models/user';
import { IUserUnitStats } from '../../../models/user-unit-stats';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';
import { removeFields } from '../../../helpers/common-helpers';

const QUERY = gql`
    query getUnitStatsForUser($uid: String, $profileUid: String){
        user_get_unit_stats(uid: $uid, profile_uid: $profileUid) {
                unitUid: unit_uid,
                points,
                timeSeconds: time_seconds,
                datetime,
                data,
                bestUnitScore: best_unit_score,
                unitScore: unit_score,

                unit {
                    uid,
                    name,
                    index,
                },

                curriculum {
                    uid,
                    name,
                }
        }
    }
`;

export async function getUserUnitStats(uid?: string, profileUid?: string): Promise<IUserUnitStats[]> {
    //console.log("getUserUnitStats profile uid: " +  profileUid);
    const result: FetchResult<{ user_get_unit_stats: IUserUnitStats[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid, profileUid },
        errorPolicy: 'all',
    });

    if(!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    try {
      if(result.data.user_get_unit_stats?.length) {
        result.data.user_get_unit_stats.forEach(stats => {
            if(stats.data) {
                stats.data = JSON.parse(stats.data as any);
                if (stats.data && stats.data.length) {
                  stats.data.forEach(el => { el.timeSeconds = (el as any).time_seconds; 
                    el.unitScore = (el as any).unit_score ? (el as any).unit_score : 0; })
                }
            }
        })
      }
    } catch (err) {
      //there's something wrong with the units data
      //todo: display via toast?
      console.log("Error prepping user unit data: " + (err as MultiError).message);
      result.data.user_get_unit_stats = [];
    }

    return removeFields(result.data.user_get_unit_stats, ['__typename']);
}
