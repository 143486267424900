import { FetchResult, gql } from 'apollo-boost';
import { IEndSurvey } from '../../../models/end-survey';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetSurveys($enabled_only: Boolean) {
        end_surveys(enabled_only: $enabled_only) {
            uid,
            name,
            description,
            available,
            default
        }
    }
`;

export async function getAllEndSurveys(enabled_only: boolean): Promise<IEndSurvey[]> {
    const result: FetchResult<{ end_surveys: IEndSurvey[] }> = await graphqlClient.query({
        query: QUERY,
        variables: { enabled_only: enabled_only },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.end_surveys;
}
