import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IAppraisal } from '../../../models/appraisal';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetAppraisal($uid: String!){
        appraisal(uid: $uid) {
            uid,
            description,
            goalCongruent: goal_congruent,
            createdAt: created_at,
            updatedAt: updated_at,

            createdBy: created_by {
                uid,
                firstName: first_name,
                lastName: last_name,
            },

            updatedBy: updated_by {
                uid,
                firstName: first_name,
                lastName: last_name,
            },

            performer {
                uid,
            }
        }
    }
`;

export async function getAppraisal(uid: string): Promise<IAppraisal> {
    const result: FetchResult<{ appraisal: IAppraisal }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.appraisal, ['__typename']);
}
