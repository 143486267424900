import { faArrowLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { pipOptionDelete } from '../../../api/actions/pip-option/pip-option-delete';
import { rewardConfigDelete } from '../../../api/actions/reward-config/reward-config-delete';
import { getRewardConfig } from '../../../api/actions/reward-config/reward-config-get';
import { rewardConfigSave } from '../../../api/actions/reward-config/reward-config-save';
import { Block } from '../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { Tabs } from '../../../components/shared/tabs/tabs';
import { basePerformError } from '../../../helpers/error-helpers';
import { getParentPath, joinPath } from '../../../helpers/path-helpers';
import { IPipOption } from '../../../models/pip-option';
import { IRewardConfig } from '../../../models/reward-config';
import { IDictionary } from '../../../types/dictionary';
import styles from './reward-config-editor.module.scss';
import { RewardConfigItemsList } from './reward-config-items-list';


enum FormFields {
    name = 'name',
    description = 'description',
    maxWeeklyEarn = 'maxWeeklyEarn'
}

interface IForm {
    [FormFields.name]: string,
    [FormFields.description]?: string,
    [FormFields.maxWeeklyEarn]?: number,
}

export function RewardConfigEditor(props: RouteComponentProps<{uid: string}>) {

    const [loading, setLoading] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [rewardConfig, setRewardConfig] = useState<IRewardConfig>();

    const loadData = useCallback(async (uid?: string) => {
        try {
            if(uid || props.match.params.uid !== '_') {
                setRewardConfig(await getRewardConfig(uid || props.match.params.uid));
            }
            else {
                setRewardConfig({} as IPipOption);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData().catch(() => {/** */});
    }, []);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newRewardConfig: Partial<IRewardConfig> = {
                uid: rewardConfig?.uid,
                name: values.name,
                description: values.description,
                maxWeeklyEarn: values.maxWeeklyEarn
            };

            const uid = await rewardConfigSave(newRewardConfig);
            if(!rewardConfig?.uid) {
                props.history.push(joinPath(getParentPath(props.match.url, 1), uid));
            }
            await loadData(uid);
            toast.success('Item has been successfully saved');
        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().label('Name').trim().required().min(2).max(50),
            description: Yup.string().label('Description').trim().min(3).max(250),
            maxWeeklyEarn: Yup.number().label('Maximum Weekly Amount')
        });
    };

    const deleteRewardConfig = async () => {
        if(!rewardConfig?.uid) return;
        await rewardConfigDelete(rewardConfig?.uid);
        setDeleteDialogVisible(false);
    };

    const renderForm = ({ errors, touched }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>

                    <div className="form-buttons">
                        <div></div>
                        { rewardConfig?.uid && (
                            <LinkButton onClick={() => setDeleteDialogVisible(true)} className="red"><FontAwesomeIcon icon={faTrashAlt} /> Delete</LinkButton>
                        )}
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name
                            </div>
                            <Field type="text" name={FormFields.name} />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field component="textarea" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Maximum Weekly Amount
                            </div>
                            <Field type="text" name={FormFields.maxWeeklyEarn} />
                        </label>
                        <div className="errors">{touched.maxWeeklyEarn && errors.maxWeeklyEarn}</div>
                    </div>


                    <div className="form-buttons">
                        <Button className="gray" to={getParentPath(props.match.url, /\/rewards\/?$/.test(props.match.url) ? 2 : 1)}>
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>Save</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !rewardConfig ? null : (<>
        <AddBreadcrumbsItem
            key={rewardConfig.uid}
            title={rewardConfig.uid ? `Edit: ${rewardConfig.name}` : 'Add Reward Schema'}
            url={props.match.url}
        />

        <Tabs
            activeTabUid={/\/rewards\/?$/.test(props.match.url) ? 'rewards' : undefined}
            data={[
                {
                    title: 'Reward Schema Info',
                    content: (<>
                        <br />
                        <Block className={styles.editor}>
                            <Formik
                                initialValues={{
                                    name: rewardConfig?.name || '',
                                    description: rewardConfig?.description || '',
                                    maxWeeklyEarn: rewardConfig?.maxWeeklyEarn || 0,
                                }}
                                validationSchema={getValidationSchema}
                                onSubmit={handleSubmit}
                            >
                                {renderForm}
                            </Formik>
                        </Block>
                    </>),
                },
                {
                    title: 'Rewards',
                    uid: 'rewards',
                    content: (<>
                        <br />
                        <RewardConfigItemsList {...props} rewards={rewardConfig.rewards} />
                    </>),
                },
            ]}
        />

        {deleteDialogVisible && (
            <DeleteQuestionPopup
                title="Reward Schema Deletion"
                question={<>Are you sure want to delete Reward Schema?</>}
                onClose={() => setDeleteDialogVisible(false)}
                onDelete={deleteRewardConfig}
                onFinish={() => { props.history.push(getParentPath(props.match.url, /\/rewards\/?$/.test(props.match.url) ? 2: 1)) }}
            />
        )}
    </>);
}
