import { FetchResult, gql } from 'apollo-boost';
import { IGoal } from '../../../models/goal';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation GoalSave($goal: GoalInput!, $imageFile: Upload){
        goal_save (goal_data: $goal, image_file: $imageFile) {
            uid
        }
    }
`;

export async function goalSave(goal: Partial<IGoal>, imageFile?: File): Promise<string> {
    const result: FetchResult<{ goal_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { goal, imageFile },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.goal_save.uid;
}
