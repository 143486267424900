import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { PipOptionsEditor } from './pip-option-editor';
import { PipOptionsList } from './pip-options-list';


export function PipOptionsManagementPage(props: RouteComponentProps) {

    return (<>
        <Route exact
            path={props.match.url} component={PipOptionsList}
        />
        <Route exact
            path={`${props.match.url}/:uid`} component={PipOptionsEditor}
        />
    </>);
}
