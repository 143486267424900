import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { getAppraisalsByParticipant } from '../../../../../../api/actions/appraisal/appraisals-get-by-participant';
import { getBelief } from '../../../../../../api/actions/belief/belief-get';
import { beliefSave } from '../../../../../../api/actions/belief/belief-save';
import { Button } from '../../../../../../components/shared/button/button';
import { Loader } from '../../../../../../components/shared/loader/loader';
import { basePerformError } from '../../../../../../helpers/error-helpers';
import { cutStr } from '../../../../../../helpers/string-helpers';
import { IAppraisal } from '../../../../../../models/appraisal';
import { IBelief } from '../../../../../../models/belief';
import { IParticipant } from '../../../../../../models/participant';
import { IDictionary } from '../../../../../../types/dictionary';
import styles from './belief-editor.module.scss';
import { SelectField } from '../../../../../../components/shared/select/select';
import moment from 'moment';

interface IProps extends RouteComponentProps {
    uid?: string,
    participantUid: string,
    holds: boolean,
    onClose: () => void,
    reload(): void,
}

enum FormFields {
    description = 'description',
    appraisal = 'appraisal',
}

interface IForm {
    [FormFields.description]?: string,
    [FormFields.appraisal]?: string,
}

function BeliefEditor(props: IProps) {

    const [loading, setLoading] = useState(false);
    const [belief, setBelief] = useState<IBelief>();
    const [appraisals, setAppraisals] = useState<IAppraisal[]>();

    const loadData = useCallback(async (uid?: string) => {
        try {
            if(props.holds) {
                setAppraisals(await getAppraisalsByParticipant(props.participantUid));
            }
            if(uid || props.uid) {
                setBelief(await getBelief(uid || props.uid || ''));
            }
            else {
                setBelief({} as IBelief);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.holds, props.participantUid, props.uid]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newBelief: Partial<IBelief> = {
                uid: belief?.uid,
                description: values.description,
            };
            if(props.holds) {
                newBelief.appraisal = { uid: values.appraisal } as IAppraisal;
            }
            if(!newBelief.uid) {
                if(props.holds) {
                    newBelief.owner = { uid: props.participantUid } as IParticipant;
                }
                else {
                    newBelief.nonOwner = { uid: props.participantUid } as IParticipant;
                }
            }
            const uid = await beliefSave(newBelief);
            await loadData(uid);
            props.reload();
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            description: Yup.string().trim().required().min(3).max(255),
            ...(props.holds ? { appraisal: Yup.string().required() } : {}),
        });
    };

    const renderForm = ({ errors, touched, isValid }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Description
                            </div>
                            <Field component="textarea" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    { props.holds && (
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Appraisal
                                </div>
                                <Field
                                    component={SelectField}
                                    name={FormFields.appraisal}
                                    emptyTitle=""
                                    data={
                                        appraisals?.sort((a1, a2) =>
                                            a1.description > a2.description ? 1 : (a1.description < a2.description ? -1 : 0),
                                        )
                                        .map((a: IAppraisal) => ({ uid: a.uid, name: cutStr(a.description, 40) }))
                                    }
                                />
                            </label>
                            <div className="errors">{touched.appraisal && errors.appraisal}</div>
                        </div>
                    )}
                    { belief?.createdBy && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Created
                                </div>
                                <div>
                                    <strong>
                                        {belief.createdBy?.firstName}
                                        &nbsp;
                                        {belief.createdBy?.lastName}
                                    </strong>
                                    &nbsp; at &nbsp;
                                    {moment(belief.createdAt).format('ddd, MM/DD/YYYY h:mm a')}
                                </div>
                            </label>
                        </div>
                    )}
                    { belief?.updatedBy && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Updated
                                </div>
                                <div>
                                    <strong>
                                        {belief.updatedBy?.firstName}
                                        &nbsp;
                                        {belief.updatedBy?.lastName}
                                    </strong>
                                    &nbsp; at &nbsp;
                                    {moment(belief.updatedAt).format('ddd, MM/DD/YYYY h:mm a')}
                                </div>
                            </label>
                        </div>
                    )}

                    <div className="form-buttons">
                        <Button onClick={props.onClose} className="gray small">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit" className="small"><span>{belief?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !belief ? null : (
        <Formik
            initialValues={{
                description: (belief && belief.description) || '',
                appraisal: (belief && belief.appraisal && belief.appraisal.uid) || '',
            }}
            validationSchema={getValidationSchema}
            onSubmit={handleSubmit}
        >
            {renderForm}
        </Formik>
    );
}

const BeliefEditorWithRouter = withRouter(BeliefEditor);
export { BeliefEditorWithRouter as BeliefEditor };

