import { faArrowLeft, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { resendConfirmationEmailByUserUid } from '../../../api/actions/user/resend-confirmation-email';
import { getUser } from '../../../api/actions/user/user-get';
import { userSave } from '../../../api/actions/user/user-save';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { CheckBoxWrapper } from '../../../components/shared/checkbox/checkbox-wrapper';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { SelectField } from '../../../components/shared/select/select';
import { Tabs } from '../../../components/shared/tabs/tabs';
import { isNotNullOrEmpty, isNullOrEmpty } from '../../../helpers/common-helpers';
import { getHIPAANameReplacementString, getHIPAAEmailReplacementString, getHIPAAGenderReplacementString } from '../../../helpers/hipaa-helpers';
import { getParentPath, joinPath } from '../../../helpers/path-helpers';
import { getAllUserPlans } from '../../../api/actions/user-plan/user-plan-get-all';
import { IUser } from '../../../models/user';
import { IUserPlan } from '../../../models/user-plan';
import { UserRoles } from '../../../models/enums/user-roles-enum';
import { IDictionary } from '../../../types/dictionary';
import { MultiError } from '../../../types/multi-error';
import { UserStats } from './parts/user-stats';
import { UserUserGroupsList } from './parts/user-user-groups-list';
import styles from './user-editor.module.scss';
import { UserGroupTypes } from '../../../models/enums/user-group-types';
import { UserGenderTypes } from '../../../models/enums/user-gender-types';
import { compareText } from '../../../helpers/array-helpers';

enum FormFields {
    email = 'email',
    password = 'password',
    confirmPassword = 'confirmPassword',
    firstName = 'firstName',
    lastName = 'lastName',
    age = 'age',
    gender = 'gender',
    role = 'role',
    configurationFinished = 'configurationFinished',
    disabled = 'disabled',
    userPlan = 'userPlan',
    curriculumEditPermission = 'curriculumEditPermission'
}

interface IForm extends Partial<IUser> {
    confirmPassword?: string,
}

function UserEditor(props: RouteComponentProps<{uid: string, groupUid?: string}>) {

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState<IUser>();
    const [userPlans, setUserPlans] = useState<IUserPlan[]>([]);

    const isHIPAAUser = React.useMemo(() => user?.userGroups?.some(ug => ug.HIPAAEnforced && (!ug.userPermissions || ug.userPermissions?.length === 0)), [user]);

    const loadData = useCallback(async (uid?: string) => {
        try {
            if(uid || props.match.params.uid !== '_') {
                setUser(await getUser(uid || props.match.params.uid));
            }
            else {
                setUser({} as IUser);
            }
            setUserPlans((await getAllUserPlans()).sort((el1, el2) => compareText(el1, el2, (el) => el.name)))
        }
        catch(err) {
            if([401, 403].includes((err as MultiError).code || 0)) {
                props.history.push('/login');
            }
            else {
              //todo: show only in debug mode?
              console.log("Error loading user: " + (err as MultiError).message);
              props.history.push('/users/users');
            }
        }
    }, [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData();
    }, [loadData, props.history, props.match.params.uid]);

    const getFreeTrialStatus = (user: IUser) =>
    {
        if (user.activeUserPlan?.trialActiveForUser) {
            let trialStatus = "(Free Trial ";
            if (user.activeUserPlan?.trialExpiredForUser) trialStatus = trialStatus + "Expired)";
            else if(user.activeUserPlan?.trialExpirationForUser) trialStatus = trialStatus + "Expires on " + moment.utc(user.activeUserPlan?.trialExpirationForUser).format('MMM DD, YYYY') + ")";
            return trialStatus;
        }
        else return "";
    }

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void; setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newUser: Partial<IUser> = {
                uid: user?.uid,
                email: user?.uid && isHIPAAUser ? user.email : values.email,
                password: isNullOrEmpty(values.password) ? undefined : values.password,
                firstName: user?.uid && isHIPAAUser ? user.firstName: values.firstName,
                lastName: user?.uid && isHIPAAUser ? user.lastName : values.lastName,
                age: values.age,
                gender: user?.uid && isHIPAAUser ? user.gender : values.gender,
                role: values.role,
                curriculumEditPermission: values.curriculumEditPermission === true,
                disabled: values.disabled === true,
                configurationFinished: values.configurationFinished ? undefined : false,
                userPlan: values.userPlan
            };

            const uid = await userSave(newUser);
            if(!user?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            }
            loadData(uid);
            toast.success('User has been successfully saved');

        }
        catch (err) {
            if([401, 403].includes((err as MultiError).code || 0)) {
                props.history.push('/login');
            }
            else {
                toast.error((err as MultiError).message);
            }
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            email: isHIPAAUser ? Yup.string().label('Email').trim().required().max(2000) : Yup.string().label('Email').trim().required().email().max(2000),
            password: Yup.string().label('Password').trim().min(8).max(50),
            confirmPassword: Yup.mixed<string>().label('Confirm Password').when(
                FormFields.password,
                {
                    is: (password) => password && !isNullOrEmpty(password.trim()),
                    then: Yup.string().label('Confirm Password').trim().required().min(8).max(50)
                        .test(
                            'passwordsShouldMatch',
                            'Password and Confirm Password should match',
                            function(item) {
                                return this.parent.password === item;
                            }
                        ),
                }
            ),
            firstName: Yup.string().label('First Name').trim().required().min(2).max(255),
            lastName: Yup.string().label('Last Name').trim().required().min(2).max(255),
            age: Yup.number().label('Age').required().min(2).max(100),
            gender: Yup.string().label('Gender'),
            role: Yup.mixed().label('Role').required().oneOf([UserRoles.admin, UserRoles.editor, UserRoles.user, UserRoles.tester])
            .test(
                'listGroupsContainsEditorGroups',
                "Member with role USER can't have User Groups with type EDITOR",
                function(item) {
                    return item !== UserRoles.user || !user?.userGroups?.some(g => g.groupType === UserGroupTypes.editor)
                }
            ).test(
                'listGroupsContainsEditorGroups',
                "Member with role TESTER can't have User Groups with type EDITOR",
                function(item) {
                    return item !== UserRoles.tester || !user?.userGroups?.some(g => g.groupType === UserGroupTypes.editor)
                }
            ).test(
                'listGroupsContainsAdminGroups',
                "Member with role ADMINISTRATOR can't have ANY User Groups",
                function(item) {
                    return item !== UserRoles.admin || !user?.userGroups?.length
                }
            ),
        });
    };

    const renderForm = ({ errors, touched, values }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>
                    {user && user.uid && (
                      <div className="form-item">
                          <label>
                              <span>
                                  ID: {user.uid}
                              </span>
                          </label>
                      </div>
                    )}
                    {user && user.role === UserRoles.admin && user.superAdmin && (
                        <div className="form-item">
                          <label>
                              <span>
                                  Super Admin
                              </span>
                          </label>
                        </div>                    
                      )}

                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Role
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.role}
                                emptyTitle=""
                                data={[
                                    { uid: UserRoles.admin, name: 'Administrator'},
                                    { uid: UserRoles.editor, name: 'Editor'},
                                    { uid: UserRoles.user, name: 'User'},
                                    { uid: UserRoles.tester, name: 'Tester'},
                                ]}
                            />
                        </label>
                        <div className="errors">{errors.role}</div>
                    </div>
                    {user && user.role === UserRoles.editor && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    &nbsp;
                                </div>
                                <CheckBoxWrapper label="Curriculum Edit Permission" >
                                    <Field type="checkbox" name={FormFields.curriculumEditPermission} disabled={true} />
                                </CheckBoxWrapper>
                            </label>
                            <div className="errors">{touched.curriculumEditPermission && errors.curriculumEditPermission}</div>
                        </div>

                        )}
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Email
                            </div>
                            <Field type="text" name={FormFields.email} disabled={user?.uid && isHIPAAUser}/>
                        </label>
                        <div className="errors">{touched.email && errors.email}</div>
                        <div className="green">{ isNotNullOrEmpty(user?.email) && values.email?.toLowerCase() === user?.confirmedEmail?.toLowerCase() && ('Email address confirmed')}</div>

                    </div>
                    { isNotNullOrEmpty(user?.createdAt) && (
                      <div className="form-item row ">
                          <span>Created on: {user && user.createdAt ? user.createdAt : ""}</span>
                      </div>
                    )}

                    { isNotNullOrEmpty(user?.lastActive) && (
                      <div className="form-item row ">
                          <span>Last Active: {user && user.lastActive ? user.lastActive : ""}</span>
                      </div>
                    )}

                    {isNotNullOrEmpty(user?.pipsEarned) && (
                      <div className="form-item">
                          <label>
                              <span>
                                  PIPs Earned: {user && user.pipsEarned ? user.pipsEarned : "0"}
                              </span>
                          </label>
                      </div>
                    )}
                    { isNotNullOrEmpty(user?.motivation) && (
                      <div className="form-item row ">
                          <span>Reason(s) for signup: {user && user.motivation ? user.motivation.join(', ') : ""}</span>
                      </div>
                    )}
                    { isNotNullOrEmpty(user?.pdState) && (
                      <div className="form-item row ">
                          <span>State of PD Hours: {user && user.pdState ? user.pdState : ""}</span>
                      </div>
                    )}                    
                    { user?.interests && !!user.interests.length && (
                      <div className="form-item row ">
                          <span>Interest topics: {(user && user.interests) ?
                            user.interests.map(interest => (interest.name + ", ")) : ''}
                        </span>
                      </div>
                    )}
                    { isNotNullOrEmpty(user?.email) && user?.email?.toLowerCase() !== user?.confirmedEmail?.toLowerCase() && (
                        <div className="form-item row ">
                            <span className="red">Email is not confirmed!</span>

                            <LinkButton
                                onClick={async () => {
                                    if(user?.uid && await resendConfirmationEmailByUserUid(user.uid)) {
                                        toast.success('Confirmation email has been successfully sent');
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faEnvelope} /> Resend Confirmation Email
                            </LinkButton>
                        </div>
                    )}
                    <hr />
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Password
                            </div>
                            <Field type="text" name={FormFields.password} />
                        </label>
                        <div className="errors">{touched.password && errors.password}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Confirm Password
                            </div>
                            <Field type="text" name={FormFields.confirmPassword} />
                        </label>
                        <div className="errors">{errors.confirmPassword}</div>
                    </div>
                    <hr />
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                First Name
                            </div>
                            <Field type="text" name={FormFields.firstName} disabled={user?.uid && isHIPAAUser}/>
                        </label>
                        <div className="errors">{touched.firstName && errors.firstName}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Last Name
                            </div>
                            <Field type="text" name={FormFields.lastName} disabled={user?.uid && isHIPAAUser}/>
                        </label>
                        <div className="errors">{touched.lastName && errors.lastName}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Age
                            </div>
                            <Field type="number" name="age" disabled={user?.uid && isHIPAAUser} />
                        </label>
                        <div className="errors">{touched.age && errors.age}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Gender
                            </div>
                            <Field
                                component={SelectField}
                                name="gender"
                                data={Object.keys(UserGenderTypes).map(t => ({ uid: t, name: UserGenderTypes[t]}))}
                                hideSearch={true}
                                disabled={user?.uid && isHIPAAUser}
                            />
                        </label>
                        <div className="errors">{touched.gender && errors.gender}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                User Plan
                            </div>
                            <Field
                                component={SelectField}
                                name="userPlan"
                                emptyTitle=""
                                data={ userPlans?.sort((a1, a2) =>
                                        a1.name > a2.name ? 1 : (a1.name < a2.name ? -1 : 0),
                                    )
                                    .map((a: IUserPlan) => ({ uid: a.uid, name: a.name }))
                                }
                            />
                        </label>
                        <div className="errors">{errors.userPlan}</div>
                    </div>

                    { user?.uid && (<>
                        <div className="form-item">
                          <label>
                              <span>
                                  Active User Plan (may be inherited): {user.activeUserPlan?.name + " " + getFreeTrialStatus(user)}
                              </span>
                          </label>
                      </div>

                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    &nbsp;
                                </div>
                                <CheckBoxWrapper label="Configuration Finished (Narratives and Characters selected)" >
                                    <Field type="checkbox" name={FormFields.configurationFinished} disabled={!user.configurationFinished} />
                                </CheckBoxWrapper>
                            </label>
                            <div className="errors">{touched.configurationFinished && errors.configurationFinished}</div>
                        </div>
                    </>)}
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Disabled">
                                <Field type="checkbox" name={FormFields.disabled} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.disabled && errors.disabled}</div>
                    </div>

                    <div className="form-buttons">
                        <Button to={getParentPath(props.match.url, 1)} className="gray">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>{user?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !user ? null : (<>
        {props.match.params.groupUid && (<>
            <AddBreadcrumbsItem
                title={'Groups'}
                url={'/users/groups'}
            />
            <AddBreadcrumbsItem
                title={'Group Info'}
                url={getParentPath(props.match.url, 1)}
            />
        </>)}

        <AddBreadcrumbsItem
            title={user && user.uid ? `Edit: ${user?.uid && isHIPAAUser ? getHIPAAEmailReplacementString(user.email) : user.email}` : 'Add User'}
            url={props.match.url}
        />

        <Tabs
            data={[
                {
                    title: 'User Info',
                    content: (
                        <div className={styles.formContainer}>
                            <Formik
                                initialValues={{
                                    email: user?.uid && isHIPAAUser ? getHIPAAEmailReplacementString(user.email) : (user.email || ''),
                                    password: '',
                                    confirmPassword: '',
                                    firstName: user?.uid && isHIPAAUser ? getHIPAANameReplacementString(user.firstName) : (user.firstName || ''),
                                    lastName: user?.uid && isHIPAAUser ? getHIPAANameReplacementString(user.lastName)  : (user.lastName || ''),
                                    age: user.age,
                                    gender: user?.uid && isHIPAAUser ? getHIPAAGenderReplacementString(user.gender) : (user.gender || ''),
                                    role: user.role || '',
                                    curriculumEditPermission: user.curriculumEditPermission || false,
                                    configurationFinished: user.configurationFinished || false,
                                    disabled: !user.uid || user.disabled ? true : false,
                                    userPlan: user.userPlan || ''
                                }}
                                validationSchema={getValidationSchema}
                                onSubmit={handleSubmit}
                            >
                                {renderForm}
                            </Formik>
                        </div>
                    )
                },
                ...(user.uid
                    ? [
                        {
                            title: 'User Groups',
                            content: (<UserUserGroupsList user={user} reloadUser={loadData} />)
                        },
                        {
                            title: 'User Statistics',
                            content: (!user.completedUnitsStats ? null : (<UserStats user={user} stats={user.completedUnitsStats} />)),
                        },
                    ]
                    : []
                ),

            ]}
        />
    </>);
}

const UserEditorWithRouter = withRouter(UserEditor);
export { UserEditorWithRouter as UserEditor };
