import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IRegulationStrategy } from '../../../models/regulation-strategy';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query {
        regulation_strategy_defaults {
            uid,
            description,
            activeDescription: active_description,
            layParentCategory: lay_parent_category,
            color,
            index,
            imgFileName: image_file_name,
            minAgeRating: min_age_rating,
            maxAgeRating: max_age_rating
        }
    }
`;

export async function getRegulationStrategyDefaults(): Promise<IRegulationStrategy[]> {
    const result: FetchResult<{ regulation_strategy_defaults: IRegulationStrategy[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.regulation_strategy_defaults, ['__typename']);
}
