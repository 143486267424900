import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { getUsersPaged } from '../../../../api/actions/user/users-get-paged';
import { Grid } from '../../../../components/shared/grid/grid';
import { SearchField } from '../../../../components/shared/search-field/search-field';
import { UserRoles } from '../../../../models/enums/user-roles-enum';
import { IUser } from '../../../../models/user';
import { IUserGroup } from '../../../../models/user-group';
import { MultiError } from '../../../../types/multi-error';
import styles from './users-list-component.module.scss';


interface IProps {
    roles: UserRoles[],
    narrativeUid?: string,
    isNotInUserGroup?: IUserGroup,
    value?: IUser,
    onChange(value: IUser): void,
}

export function SelectUsersList(props: IProps) {
    const [searchText, setSearchText] = useState<string | undefined>();

    const loadData = React.useCallback(async (page: number = 1) => {
        try {
            return await getUsersPaged({
                page,
                searchText,
                notGroupUid: props.isNotInUserGroup?.uid,
                narrativeUid: props.narrativeUid,
                roles: props.roles || []
            });
        }
        catch(err) {
            toast.error((err as MultiError).message);
        }
    }, [props.isNotInUserGroup?.uid, props.narrativeUid, props.roles, searchText]);


    const gridContainerRenderer = React.useCallback(
        (content, pager) => (<>
            <div className={styles.pager}>{pager}</div>
            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list} ${styles.simple}`}>
                <tbody>
                    {content}
                </tbody>
            </table>
            <div className={styles.pager}>{pager}</div>
        </>),
        [],
    );

    const gridItemRenderer = React.useCallback(
        (user: IUser, i: number) => (
            <tr key={user.uid} className={` ${props.value?.uid === user.uid ? styles.selected : ''}`}>
                <td onClick={() => props.onChange(user)}>
                    <div className={styles.titleContainer}>
                        <div className={`${styles.mark} ${user.disabled ? styles.disabled : ''}`}>&nbsp;</div>
                        <div className={styles.title}>
                            {i + 1}
                        </div>
                    </div>
                </td>
                <td onClick={() => props.onChange(user)}>
                    {user.firstName} {user.lastName}
                    <div className={styles.userInfo}>
                        <div className={styles.email}>{user.email}</div> <div className={styles.userRole}>{user.role?.toUpperCase()}</div>
                    </div>
                </td>
            </tr>
        ),
        [],
    );


    return (<>
        <SearchField onChange={setSearchText} />

        <Grid<IUser>
            getPageData={loadData}
            containerRenderer={gridContainerRenderer}
            itemRenderer={gridItemRenderer}
            updateFlag={searchText}
        />
    </>);
}
