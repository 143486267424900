import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IEmail } from '../../../models/email';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetEmail($uid: String!){
        email(uid: $uid) {
            uid,
            identifier,
            name,
            description,
            subject,
            type,
            template,
            enabled,
            daysAfterSignup: days_after_signup,
            createdAt: created_at,
            updatedAt: updated_at,

        }
    }
`;

export async function getEmail(uid: string): Promise<IEmail> {
    const result: FetchResult<{ email: IEmail }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.email, ['__typename']);
}
