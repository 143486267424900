import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../helpers/common-helpers';
import { IRegulationCategory } from '../../models/regulation-category';
import { MultiError } from '../../types/multi-error';
import { graphqlClient } from '../graphql-client';

const QUERY = gql`
{
    regulation_categories {
        uid,
        name,
        description,
        parent,
    }
}
`;

export async function getAllRegulationCategories(): Promise<IRegulationCategory[]> {
    const result: FetchResult<{ regulation_categories: IRegulationCategory[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.regulation_categories, ['__typename']);
}
