import { IEmotionEpisode } from './emotion-episode';
import { IParticipant } from './participant';
import { IUser } from './user';

export enum InitiatingEventTypes {
    action = 'action',
    speech = 'speech',
}

export interface IInitiatingEvent {
    uid: string,
    description: string,
    quote?: string,
    type: InitiatingEventTypes,

    createdAt?: string,
    updatedAt?: string,

    createdBy?: IUser,
    updatedBy?: IUser,

    participant?: IParticipant,
    emotionEpisode?: IEmotionEpisode,
}
