import { FetchResult, gql } from 'apollo-boost';
import { IComment } from '../../../models/comment';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation CommentSave($comment: CommentSaveInput!){
        comment_save (comment_data: $comment) {
            uid
        }
    }
`;

export async function commentSave(comment: IComment): Promise<string> {
    const result: FetchResult<{ comment_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: {
            comment: {
                uid: comment.uid,
                html: comment.html,
                resolved: comment.resolved,
                parent_comment_uid: comment.parentCommentUid,
                entity_uid: comment.entityUid,
                entity_type: comment.entityType,
                task: comment.task,
            }
        },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.comment_save.uid;
}
