import { Field, Form, Formik, FormikProps } from 'formik';
import Cookies from 'js-cookie';
import * as React from 'react';
import { ReactElement, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { login } from '../../api/actions/login-action';
import { MainLayout } from '../../components/layouts/main-layout';
import { Button } from '../../components/shared/button/button';
import { Loader } from '../../components/shared/loader/loader';
import { CurrentUserContext } from '../../components/user/current-user-manager';
import { AppConfig } from '../../config/app-config';
import { IDictionary } from '../../types/dictionary';
import { PublicPage } from '../base/public-page';
import styles from './login-page.module.scss';


interface IForm {
    email: string,
    password: string,
}

export function LoginPage(props: RouteComponentProps) {
    Cookies.remove(AppConfig.tokenName);

    const [loading, setLoading] = useState(false);

    const currentUserContext = React.useContext(CurrentUserContext);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const { accessToken } = await login(values.email, values.password);
            if(accessToken) {
                Cookies.set(AppConfig.tokenName, accessToken);
                currentUserContext?.reloadCurrentUser();

                props.history.replace('/');
            }
        }
        catch (err) {
            setSubmitting(false);
            setErrors({
                password: 'The password or email you entered is incorrect. Please try again.',
            });
            setLoading(false);
        }
    };

    const getValidationSchema = () => {
        return Yup.object({
            email: Yup.string()
                .required('Please enter your email address')
                .email('Please enter valid email address'),
            password: Yup.string().required('Please enter your password'),
        });
    };

    const renderLoginForm = ({ errors, touched, isValid }: FormikProps<IForm>): ReactElement => {
        return (
            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <h1>
                        Sign in<br />
                    </h1>
                    <Form noValidate>
                        <div className="form-item">
                        </div>
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Email
                                </div>
                                <Field type="email" name="email" />
                            </label>
                            <div className="errors">{touched.email && errors.email}</div>
                        </div>
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Password
                                </div>
                                <Field type="password" name="password" />
                            </label>
                            <div className="errors">{touched.password && errors.password}</div>
                        </div>

                        <div className="form-item">
                            { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>Login</span></Button>)
                            }
                        </div>
                    </Form>
                </div>
            </div>
        );
    };

    return (
        <PublicPage pageName="login">
            <MainLayout>
                <Formik
                    initialValues={{
                        email: AppConfig.isDebug ? AppConfig.testUserEmail : '',
                        password: AppConfig.isDebug ? AppConfig.testUserPsw : '',
                    }}
                    validationSchema={getValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {renderLoginForm}
                </Formik>
            </MainLayout>
        </PublicPage>
    );
}
