import { IParticipant } from "./participant";
import { IRegulationCategory } from "./regulation-category";
import { IGoal } from "./goal";
import { IUser } from "./user";

export enum RegulationAttemptTypes {
    unknown = 'Unknown',
    yes = 'Yes',
    no = 'No',
}

export enum RegulationOutcomeTypes {
    upregulate = 'Upregulate',
    downregulate = 'Downregulate',
}

export enum RegulationSuccessTypes {
    unknown = 'Unknown',
    high = 'High',
    moderate = 'Moderate',
    low = 'Low',
}

export interface IRegulationStrategyDefaultForm {
    uid: string,
    description: string,
    activeDescription?: string,
    image?: File;
    layParentCategory?: string;
    imgFileName?: string | null;
    minAgeRating?: number,
    maxAgeRating?: number,
  }


export interface IRegulationStrategy {
    uid: string,
    description: string,
    activeDescription?: string,
    attempted?: RegulationAttemptTypes,
    intendedOutcome?: RegulationOutcomeTypes,
    success?: RegulationSuccessTypes,
    imgFileName?: string,
    minAgeRating?: number,
    maxAgeRating?: number,
    isNew?: boolean,

    createdAt?: string,
    updatedAt?: string,

    createdBy?: IUser,
    updatedBy?: IUser,

    layParentCategory?: string,
    defaultOption?: boolean,
    color?: string,
    index?: number,

    doneBy?: IParticipant,
    regulationCategory?: IRegulationCategory,
    goal?: IGoal,

    children: IRegulationStrategy[],
}
