import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { videoServiceDisable } from '../../../api/actions/video-service/video-service-disable';
import { videoServiceEnable } from '../../../api/actions/video-service/video-service-enable';
import { getAllVideoServices } from '../../../api/actions/video-service/video-service-get-all';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { Grid } from '../../../components/shared/grid/grid';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { compareText } from '../../../helpers/array-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import { joinPath } from '../../../helpers/path-helpers';
import { IVideoService } from '../../../models/video-service';
import styles from './video-services-list.module.scss';

export function VideoServicesList(props: RouteComponentProps) {
    const [loading, setLoading] = useState(true);
    const [videoServices, setVideoServices] = useState<IVideoService[]>([]);

    const loadData = React.useCallback(async () => {
        setLoading(true);
        try {
            setVideoServices((await getAllVideoServices()).sort((el1, el2) => compareText(el1, el2, (el) => el.name)))
        }
        catch(err) {
            basePerformError(err, props.history);
        }
        setLoading(false);
    }, [props.history]);

    useEffect(() => {
        loadData().catch(() =>{/** */});
    }, [loadData]);

    const renderButtons = () => (
        <div className={styles.buttons}>
            <Button className="orange" to={joinPath(props.match.url, '_')}>Add Video Service</Button>
        </div>
    );

    const disableVideoService = async (uid: string) => {
        try {
            await videoServiceDisable(uid);
            loadData().catch(() =>{/** */});
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const enableVideoService = async (uid: string) => {
        try {
            await videoServiceEnable(uid);
            loadData().catch(() =>{/** */});
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    return loading ? (<Loader />) : (<>
        <AddBreadcrumbsItem
            title={'Video Services'}
            url={props.match.url}
        />

        

        <Grid<IVideoService>
            getPageData={(page, pageSize) => {
                return Promise.resolve({
                    page,
                    count: videoServices?.length || 0,
                    data: videoServices?.slice(pageSize * (page - 1), pageSize * page),
                });
            }}

            containerRenderer={(content, pager) => (<>
                <div className={styles.pager}>{pager} {renderButtons()}</div>
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>Identifier</th>
                            <th>Name</th>
                            <th>URL</th>
                            <th>Platforms</th>
                            <th>Actions</th>
                        </tr>
                        {content}
                    </tbody>
                </table>
                <div className={styles.pager}>{pager}</div>
            </>)}

            itemRenderer={(videoService, i) => (
                <tr key={videoService.uid}>
                    <td>
                        <div className={styles.markContainer}>
                            <div className={`${styles.mark} ${videoService.available ? '' : styles.disabled}`}>&nbsp;</div>
                            <div className={styles.title}>
                                {i + 1}
                            </div>
                        </div>
                    </td>
                    <td>
                        {videoService.identifier}
                    </td>
                    <td>
                        {videoService.name}
                    </td>
                    <td>
                        {videoService.url}
                    </td>
                    <td>
                        {videoService.platforms ? videoService.platforms.join(', ') : ''}
                    </td>
                    <td>
                        <LinkButton to={joinPath(props.match.url, videoService.uid)}>
                            <FontAwesomeIcon icon={faEdit} /> Edit
                        </LinkButton><br />
                        { !videoService.available && (<>
                            <LinkButton className={styles.enableBtn} onClick={() => enableVideoService(videoService.uid)}>
                                <FontAwesomeIcon icon={faUnlock} /> Enable
                            </LinkButton><br />
                        </>)}
                        { videoService.available && (<>
                            <LinkButton onClick={() => disableVideoService(videoService.uid)}>
                                <FontAwesomeIcon icon={faLock} /> Disable
                            </LinkButton><br />
                        </>)}
                    </td>
                </tr>
            )}
        />

        {renderButtons()}
    </>);
}