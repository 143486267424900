import * as React from 'react';
import { INarrative } from '../../../../../../models/narrative';
import { Button } from '../../../../../../components/shared/button/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrashAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { LinkButton } from '../../../../../../components/shared/link-button/link-button';
import { IExternalReference } from '../../../../../../models/external-reference';
import { compareText } from '../../../../../../helpers/array-helpers';
import { Popup } from '../../../../../../components/shared/popup/popup';
import { ExternalReferenceEditor } from './external-reference-editor';
import { IEpisode } from '../../../../../../models/episode';
import { externalReferenceDelete } from '../../../../../../api/actions/external-reference/external-reference-delete';
import { toast } from 'react-toastify';


interface IProps {
    narrative?: INarrative,
    episode?: IEpisode,
    reloadData(): Promise<void>,
}


export function ExternalReferencesManagement(props: IProps) {

    const [externalReferenceUid, setExternalReferenceUid] = React.useState<string>();
    const [externalReferenceEditorVisible, setExternalReferenceEditorVisible] = React.useState(false);
    const [externalReferenceForDelete, setExternalReferenceForDelete] = React.useState<IExternalReference>();

    const relatedObject = (props.narrative || props.episode);

    return (<>
        <div className="list-title">
            <div>External References: <strong>{ relatedObject?.externalReferences?.length || 0}</strong></div>
            <div>
                <Button className="orange" onClick={() => { setExternalReferenceUid(undefined); setExternalReferenceEditorVisible(true); }}>
                    <FontAwesomeIcon icon={faPlus} /> <span>Add External Reference</span>
                </Button>
            </div>
        </div>
        { !!relatedObject?.externalReferences?.length && (
            <table cellPadding="0" cellSpacing="0" className="list">
                <thead>
                    <tr>
                        <th>External Service Name</th>
                        <th>Service UID</th>
                        <th>Paid Plans Only</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        relatedObject?.externalReferences
                        .sort((e1, e2) => compareText(e1, e2, (e) => e.serviceName))
                        .map((e: IExternalReference) => (
                            <tr key={e.uid}>
                                <td>{e.serviceName}</td>
                                <td>{e.externalServiceUid}</td>
                                <td className="center">{e.paidPlansOnly ? 'YES' : ''}</td>
                                <td>
                                    <LinkButton onClick={() => { setExternalReferenceUid(e.uid); setExternalReferenceEditorVisible(true); }}>
                                        <FontAwesomeIcon icon={faEdit} /> Edit
                                    </LinkButton><br />
                                    <LinkButton
                                        className="red"
                                        onClick={() => { setExternalReferenceForDelete(e); }}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                    </LinkButton><br />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )}



        { externalReferenceEditorVisible && (
            <Popup
                modal={true}
                title={externalReferenceUid ? 'Edit External Reference' : 'Add External Reference'}
                onClose={() => {
                    props.reloadData();
                    setExternalReferenceEditorVisible(false);
                }}
            >
                <ExternalReferenceEditor
                    uid={externalReferenceUid}
                    narrativeUid={props.narrative?.uid}
                    episodeUid={props.episode?.uid}
                    onClose={() => { props.reloadData(); setExternalReferenceEditorVisible(false); }}
                    reload={props.reloadData}
                />
            </Popup>
        )}

        { externalReferenceForDelete && (
            <Popup
                modal={true}
                className="red"
                title="Delete External Reference"
                onClose={() => {
                    props.reloadData();
                    setExternalReferenceForDelete(undefined);
                }}
                buttons={[
                    {
                        title: <><FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span></>,
                        className: 'gray',
                        onClick: () => setExternalReferenceForDelete(undefined),
                    },
                    {
                        title: <><span>Delete</span> <FontAwesomeIcon icon={faTrashAlt} /></>,
                        className: 'red',
                        onClick: async () => {
                            const result = await externalReferenceDelete(externalReferenceForDelete.uid);
                            if(result.success) {
                                props.reloadData();
                                toast.success('Item has been successfully delete');
                                setExternalReferenceForDelete(undefined);
                            }
                        },
                    },
                ]}
            >
                Are you sure want to delete External Reference with name: <strong>{externalReferenceForDelete.serviceName}</strong>?<br />
            </Popup>
        )}
    </>)
}
