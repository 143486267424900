import { faComment } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { getCommentsByEntity } from '../../api/actions/comment/comments-get-by-entity';
import { IComment } from '../../models/comment';
import { EntityTypes } from '../../models/enums/entity-types';
import { Button } from '../shared/button/button';
import { LinkButton } from '../shared/link-button/link-button';
import { Popup } from '../shared/popup/popup';
import { CommentsList } from './comments-list';


interface IProps {
    entityType: EntityTypes,
    entityUid: string,
    taskEntityType?: EntityTypes,
    taskEntityUid?: string,
    className?: string,
    isLink?: boolean,
    title?: string,
}

export function CommentButton(props: IProps) {
    const [comments, setComments] = React.useState<IComment[]>();
    const [displayCommentsPopup, setDisplayCommentsPopup] = React.useState(false);

    const loadData = React.useCallback(async () => {
        if(props.entityUid) {
            setComments(await getCommentsByEntity(props.entityType, props.entityUid));
        }
    }, [props.entityType, props.entityUid]);

    React.useEffect(() => {
        loadData();
    }, [loadData]);

    const unresolvedCommentsCount = React.useMemo(() => comments?.filter(comment => !comment.resolved), [comments])?.length;

    return (<>
        {props.isLink && (
            <LinkButton className={`orange ${props.className}`} onClick={() => setDisplayCommentsPopup(true)}>
                <FontAwesomeIcon icon={faComment} />
                {comments?.length
                    ? (<>
                        Comments ({unresolvedCommentsCount ? <><span className="red">!{unresolvedCommentsCount}</span>/</> : ''}
                        {comments?.length})
                    </>)
                    : 'Add Comment'
                }
            </LinkButton>
        )}
        {!props.isLink && (
            <Button className={`orange ${props.className}`} onClick={() => setDisplayCommentsPopup(true)}>
                <FontAwesomeIcon icon={faComment} />
                {comments?.length
                    ? (<>
                        Comments ({unresolvedCommentsCount ? <><span className="red">!{unresolvedCommentsCount}</span>/</> : ''}
                        {comments?.length})
                    </>)
                    : 'Add Comment'
                }
            </Button>
        )}
        {displayCommentsPopup && props.entityUid && (
            <Popup
                title={props.title || `Comments for ${props.entityType}`}
                modal={true}
                onClose={() => { setDisplayCommentsPopup(false); loadData(); }}
                buttons={[{
                    title: 'Close',
                    onClick: () => { setDisplayCommentsPopup(false); loadData(); }
                }]}
            >
                <CommentsList
                    entityType={props.entityType}
                    entityUid={props.entityUid}
                    taskEntityType={EntityTypes.narrative}
                    taskEntityUid={props.taskEntityUid}
                />
            </Popup>
        )}
    </>);
}