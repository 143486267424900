import { FetchResult, gql } from 'apollo-boost';
import { IEmotionEpisodeContentTag } from '../../../models/emotion-episode-content-tag';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation EmotionEpisodeContentTagSave($tag: EmotionEpisodeContentTagInput!){
        emotion_episode_content_tag_save (input_data: $tag) {
            uid
        }
    }
`;

export async function emotionEpisodeContentTagSave(episode: Partial<IEmotionEpisodeContentTag>): Promise<string> {
    const result: FetchResult<{ emotion_episode_content_tag_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { tag: {
            uid: episode.uid,
            name: episode.name,
            tag_type: episode.tagType,
        } },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.emotion_episode_content_tag_save.uid;
}
