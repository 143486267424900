import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { RewardConfigEditor } from './reward-config-editor';
import { RewardConfigsList } from './reward-configs-list';
import { RewardConfigItemEditor } from './reward-config-item-editor';


export function RewardSchemasManagementPage(props: RouteComponentProps) {

    return (<>
        <AddBreadcrumbsItem
            title="Reward Schemas"
            url={props.match.url}
        />

        <Route
            exact
            path={props.match.url}
            component={RewardConfigsList}
        />
        <Route
            exact
            path={[`${props.match.url}/:uid`, `${props.match.url}/:uid/rewards`]}
            component={RewardConfigEditor}
        />
        <Route exact path={`${props.match.url}/:rewardConfigUid/rewards/:uid`} component={RewardConfigItemEditor} />
    </>);
}
