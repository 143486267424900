import { FetchResult, gql } from 'apollo-boost';
import { IAppraisal } from '../../../models/appraisal';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation AppraisalSave($appraisal: AppraisalInput!){
        appraisal_save (appraisal_data: $appraisal) {
            uid
        }
    }
`;

export async function appraisalSave(appraisal: Partial<IAppraisal>): Promise<string> {
    const result: FetchResult<{ appraisal_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: {
            appraisal: {
                uid: appraisal.uid,
                description: appraisal.description,
                goal_congruent: appraisal.goalCongruent,
                performer: appraisal.performer,
                non_performer: appraisal.nonPerformer,
            },
        },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.appraisal_save.uid;
}
