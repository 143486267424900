import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserInvitesPaged } from '../../../../api/actions/user/user-invites-get-paged';
import { Button } from '../../../../components/shared/button/button';
import { Grid } from '../../../../components/shared/grid/grid';
import { Popup } from '../../../../components/shared/popup/popup';
import { SearchField } from '../../../../components/shared/search-field/search-field';
import { basePerformError } from '../../../../helpers/error-helpers';
import { IUserInvite } from '../../../../models/user-invite';
import { IUserGroup } from '../../../../models/user-group';
import { UserGroupCreateInviteComponent } from '../../user-groups/parts/user-group-create-invite-component';
import { LinkButton } from '../../../../components/shared/link-button/link-button';
import styles from './user-group-invites-list.module.scss';

interface IProps {
    userGroup?: IUserGroup,
    reloadUserGroup(): void,
}

export function UserGroupInvitesList(props: IProps) {
    const history = useHistory();

    const [searchText, setSearchText] = useState<string | undefined>();
    const [inviteFormVisible, setInviteFormVisible] = useState(false);
    const [targetInvite, setTargetInvite] = useState <IUserInvite | undefined>();

    const loadData = React.useCallback(async (page: number = 1) => {
        //for now always order invites by most recently sent
        const orderBy = ['-last_sent_at'];
        try {
            return await getUserInvitesPaged({
                page,
                searchText,
                groupUid: props.userGroup?.uid,
                orderBy: orderBy
            });
        }
        catch(err) {
            basePerformError(err, history);
        }
    }, [history, props.userGroup?.uid, searchText]);

    const resendInvite = (invite: IUserInvite) => {
        setTargetInvite(invite);
        setInviteFormVisible(true);
    };

    const resendSucceeded = () => {
        setInviteFormVisible(false);
        loadData();
    };

    const gridContainerRenderer = React.useCallback(
        (content, pager) => (<>
            <div className={styles.pager}>
                {pager}
            </div>
            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <tbody>
                    <tr>
                        <th>#</th>
                        <th>Sender</th>
                        <th>Recipient</th>
                        <th>Recip. First</th>
                        <th>Recip. Last</th>
                        <th>Last Sent</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    {content}
                </tbody>
            </table>
            <div className={styles.pager}>{pager}</div>
        </>),
        [],
    );

    const gridItemRenderer = React.useCallback(
        (invite: IUserInvite, i: number, count: number, reloadData: () => void) => (
            <tr key={invite.uid}>
                <td>
                    <div className={styles.titleContainer}>
                        <div className={`${styles.mark} ${invite.status == 'accepted' ? '' : styles.disabled}`}>&nbsp;</div>
                        <div className={styles.title}>
                            {i + 1}
                        </div>
                    </div>
                </td>
                <td>{invite.inviterEmail}</td>
                <td>{invite.inviteeEmail}</td>
                <td>{invite.inviteeFirstName}</td>
                <td>{invite.inviteeLastName}</td>
                <td>{invite.lastSent}</td>
                <td>{invite.expired ? 'Expired' : invite.status}</td>
                <td>
                { invite.status != 'accepted' && (<>
                    <LinkButton onClick={() => resendInvite(invite)} className="orange">
                        Resend
                    </LinkButton><br />
                </>)}
                </td>
            </tr>
        ),
        [],
    );

    return (<>
        <div>Total Invites: {props.userGroup?.inviteCount}</div>

        <SearchField onChange={setSearchText} />

        <Grid<IUserInvite>
            getPageData={loadData}
            containerRenderer={gridContainerRenderer}
            itemRenderer={gridItemRenderer}
        />

        {inviteFormVisible && props.userGroup && (
            <Popup
                title="Resend Invite"
                onClose={() => setInviteFormVisible(false)}
            >
                <UserGroupCreateInviteComponent userGroup={props.userGroup} invite={targetInvite} onSuccess={() => resendSucceeded()} />
            </Popup>
        )}
    </>)
}
