import * as React from 'react';
import { useEffect, useState } from 'react';
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps } from 'react-router-dom';
import { getAllEmotionCategories } from '../../../api/actions/emotion-categories-get-all';
import { getAllActionTendencies } from '../../../api/actions/action-tendencies/action-tendency-get-all';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { repeat } from '../../../helpers/array-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import {
  buildEmotionCategoriesTree,
  IEmotionCategory,
} from '../../../models/emotion-category';
import {IActionTendency} from '../../../models/action-tendency';
import {IRegulationStrategy} from '../../../models/regulation-strategy';
import { getRegulationStrategyDefaults } from '../../../api/actions/regulation-strategy/regulation-strategy-defaults-get';
import { deleteEmotionCategory } from '../../../api/actions/emotion-categories/emotion-category-delete';
import styles from './emotion-categories-list.module.scss';
import { Button } from '../../../components/shared/button/button';
import { joinPath } from '../../../helpers/path-helpers';
import { Link } from 'react-router-dom';
import { ActionPopup } from '../../../components/shared/popup/action-popup';

export function EmotionCategoriesList(props: RouteComponentProps) {
  const [emotionCategories, setEmotionCategories] = useState<
    IEmotionCategory[]
  >([]);
  const [actionTendencies, setActionTendencies] = useState<
    IActionTendency[]
  >([]);
  const [regulationStrategies, setRegulationStrategies] = useState<
    IRegulationStrategy[]
  >([]);


  const [deletePopup, setDeletePopup] = useState(false);
  const [selectedEcToDelete, setSelectedEcToDelete] =
    useState<IEmotionCategory | null>(null);

  const handleOpenDeletePopup = (ec: IEmotionCategory) => {
    setSelectedEcToDelete(ec);
    setDeletePopup(true);
  };

  const handleDelete = async (uid: string) => {
    try {
      const response = await deleteEmotionCategory(uid);
      if (response.success) {
        void loadData();
      }
    } catch (errors) {
      console.error('Error while deleting:', errors);
    }
  };

  const loadData = React.useCallback(async () => {
    try {
      const ec = await getAllEmotionCategories();
      setEmotionCategories(buildEmotionCategoriesTree(ec));
      const ats = await getAllActionTendencies();
      setActionTendencies(ats);
    } catch (err) {
      basePerformError(err, props.history);
    }
  }, [props.history]);

  const printLevel = (categories?: IEmotionCategory[], level = 0) =>
    categories &&
    categories.map((ec) => (
      <React.Fragment key={ec.uid}>
        <tr key={ec.uid}>
          <td>{ec.imgFileName ? 'Yes' : ''}</td>
          <td>
            {!!level &&
              repeat(level, () => (
                <span>
                  &raquo; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              ))}
            {ec.label}
          </td>
          <td>{ec.definition}</td>
          <td>{ec.minAgeRating}</td>
          <td>{ec.color}</td>
          <td>{ec.adjective}</td>
          <td>{ec.valence}</td>
          <td>
            <Link
              className={styles.editLink}
              to={{
                pathname: joinPath(props.match.url, '_'),
                state: { emotionCategory: ec, parents: parents, 
                          allActionTendencies: actionTendencies, emotionTendencies: ec.actionTendencies, 
                          allRegulationStratgies: regulationStrategies, emotionStrategies: ec.regulationStrategies },
              }}
            >
              <FontAwesomeIcon icon={faEdit} /> Edit
            </Link>
            {ec.parent || (ec.parent === null && !ec.children) ? (
              <>
                <span
                  className={styles.deleteIcon}
                  onClick={() => handleOpenDeletePopup(ec)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} /> Delete
                </span>
                {deletePopup && selectedEcToDelete && (
                  <ActionPopup
                    title={`Delete ${selectedEcToDelete.label}`}
                    question={`Are you sure you want to delete ${selectedEcToDelete.label}`}
                    onClose={() => setDeletePopup(false)}
                    onAction={() => handleDelete(selectedEcToDelete.uid)}
                  />
                )}
              </>
            ) : null}
          </td>
        </tr>
        {printLevel(ec.children, level + 1)}
      </React.Fragment>
    ));

  useEffect(() => {
    loadData();
  }, [loadData]);

  const parents = emotionCategories.map((ec) => ec.label);

  return !emotionCategories ? null : (
    <>
      <AddBreadcrumbsItem
        title="Emotion Categories"
        url={'/libraries/emotion-categories'}
      />
      <Button
        // to="/libraries/emotion-categories/_"
        to={{
          pathname: '/libraries/emotion-categories/_',
          state: {
            parents: parents,
          },
        }}
        className="orange"
      >
        Add Emotion Category
      </Button>
      <table cellPadding="0" cellSpacing="0" className={`list`}>
        <thead>
          <tr>
            <th>Icon</th>
            <th>Name</th>
            <th>Definition</th>
            <th>Age Rating</th>
            <th>Color</th>
            <th>Adjective</th>
            <th>Valence</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{printLevel(emotionCategories)}</tbody>
      </table>
    </>
  );
}
