import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { getCharacter } from '../../../../api/actions/character/character-get';
import { characterSave } from '../../../../api/actions/character/character-save';
import { Button } from '../../../../components/shared/button/button';
import { Loader } from '../../../../components/shared/loader/loader';
import { UploaderField } from '../../../../components/shared/uploader/uploader';
import { UploaderImagePreview } from '../../../../components/shared/uploader/uploader-image-preview';
import { getCharacterFilePath } from '../../../../config/app-config';
import { basePerformError } from '../../../../helpers/error-helpers';
import { ICharacter, CharacterTypes } from '../../../../models/character';
import { IFile } from '../../../../models/file';
import { GenderTypes } from '../../../../models/gender';
import { INarrative } from '../../../../models/narrative';
import { IDictionary } from '../../../../types/dictionary';
import styles from './character-editor.module.scss';
import { SelectField } from '../../../../components/shared/select/select';

interface IProps extends RouteComponentProps {
    uid?: string,
    narrativeUid: string,
    onClose: () => void,
    reload(): Promise<void>,
}

interface IForm {
    name: string,
    shortName?: string,
    description?: string,
    importance?: string,
    gender?: string,
    age?: number,
    race?: string,
    ethnicity?: string,
    image?: IFile | null,
}

function CharacterEditor(props: IProps) {

    const [loading, setLoading] = useState(false);
    const [character, setCharacter] = useState<ICharacter>();

    const loadData = useCallback(async (uid?: string) => {
        try {
            if(uid || props.uid) {
                setCharacter(await getCharacter(uid || props.uid!));
            }
            else {
                setCharacter({} as ICharacter);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.uid]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newCharacter: Partial<ICharacter> = {
                uid: character?.uid,
                name: values.name,
                shortName: values.shortName,
                description: values.description,
                importance: values.importance,
                gender: values.gender,
                age: values.age,
                race: values.race,
                ethnicity: values.ethnicity,
                fileName: values.image?.url,
            };
            if(!newCharacter.uid) {
                newCharacter.narrative = { uid: props.narrativeUid } as INarrative;
            }
            const uid = await characterSave(newCharacter as ICharacter, values.image?.file);
            await loadData(uid);
            await props.reload();
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().trim().required('Please enter Name').min(3).max(50),
            shortName: Yup.string().trim().min(3).max(50),
            description: Yup.string().trim().min(3).max(255),
            importance: Yup.string().trim().min(3).max(50),
            gender: Yup.string().trim().min(3).max(50),
            age: Yup.number(),
            race: Yup.string().trim().min(3).max(50),
            ethnicity: Yup.string().trim().min(3).max(50),
            image: Yup.object().nullable().required('Please select Image'),
        });
    };

    const renderForm = ({ errors, touched, isValid }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name
                            </div>
                            <Field type="text" name="name" />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Short Name/Nickname
                            </div>
                            <Field type="text" name="shortName" />
                        </label>
                        <div className="errors">{touched.shortName && errors.shortName}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field component="textarea" name="description" />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Importance
                            </div>
                            <Field
                                component={SelectField}
                                name="importance"
                                emptyTitle=""
                                data={
                                    Object.keys(CharacterTypes)
                                    .map((x: string) => ({ uid: x, name: x.toUpperCase() }))
                                }
                            />
                        </label>
                        <div className="errors">{touched.importance && errors.importance}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Gender
                            </div>
                            <Field
                                component={SelectField}
                                name="gender"
                                emptyTitle=""
                                data={
                                    Object.keys(GenderTypes)
                                    .map((x: string) => ({ uid: x, name: x.toUpperCase() }))
                                }
                            />
                        </label>
                        <div className="errors">{touched.gender && errors.gender}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Age
                            </div>
                            <Field type="text" name="age" />
                        </label>
                        <div className="errors">{touched.age && errors.age}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Race
                            </div>
                            <Field type="text" name="race" />
                        </label>
                        <div className="errors">{touched.race && errors.race}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Ethnicity
                            </div>
                            <Field type="text" name="ethnicity" />
                        </label>
                        <div className="errors">{touched.ethnicity && errors.ethnicity}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Image
                            </div>
                            <Field
                                component={UploaderField}
                                name="image"
                                acceptFileTypes=".png,.jpg,.jpeg"
                                path={character?.uid && getCharacterFilePath(character.uid)}
                                previewComponent={UploaderImagePreview}
                            />
                        </label>
                        <div className="errors">{touched.image && errors.image}</div>
                    </div>

                    <div className="form-buttons">
                        <Button onClick={props.onClose} className="gray">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>{character?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !character ? null : (
        <Formik
            initialValues={{
                name: (character && character.name) || '',
                shortName: (character && character.shortName) || '',
                description: (character && character.description) || '',
                importance: (character && character.importance) || '',
                gender: (character && character.gender) || undefined,
                age: (character && character.age) || undefined,
                race: (character && character.race) || '',
                ethnicity: (character && character.ethnicity) || '',
                image: (character
                    && (
                        (character.fileName && { url: character.fileName })
                        || (character.imageFile && { file: character.imageFile })
                    )) || null,
            }}
            validationSchema={getValidationSchema}
            onSubmit={handleSubmit}
        >
            {renderForm}
        </Formik>
    );
}

const CharacterEditorWithRouter = withRouter(CharacterEditor);
export { CharacterEditorWithRouter as CharacterEditor };
