import { FetchResult, gql } from 'apollo-boost';
import { ICharacter } from '../../../models/character';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation CharacterSave($character: CharacterInput!, $imageFile: Upload){
        character_save (character_data: $character, imageFile: $imageFile) {
            uid
        }
    }
`;

export async function characterSave(character: ICharacter, imageFile?: File): Promise<string> {
    const result: FetchResult<{ character_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { character, imageFile },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.character_save.uid;
}
