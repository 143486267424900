import { faArrowDown, faArrowUp, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldProps } from 'formik';
import * as React from 'react';
import { LinkButton } from '../link-button/link-button';
import styles from './multirow-editor.module.scss';

interface IProps {
    name?: string;
    value?: string[];
    onChange(e: any): void;
    onBlur(e: any): void;
}

export function MultiRowEditor(props: IProps) {
    const [newVal, setNewVal] = React.useState('');

    const onChange = (val?: string[]) => {
        const target = {
            name: props.name,
            value: val && val.length ? val : (val === undefined ? undefined : null),
        };
        props.onChange({
            target,
            currentTarget: target,
        });
    };

    const onUp = (currRow: number) => {
        if(props.value) {
            onChange([
                ...props.value.slice(0, currRow - 1),
                props.value[currRow],
                props.value[currRow - 1],
                ...props.value.slice(currRow + 1, props.value.length),
            ]);
        }
    };

    const onDown = (currRow: number) => {
        if(props.value) {
            onChange([
                ...props.value.slice(0, currRow),
                props.value[currRow + 1],
                props.value[currRow],
                ...props.value.slice(currRow + 2, props.value.length),
            ]);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.inputContainer} >
                <input
                    name={props.name}
                    type="text"
                    value={newVal}
                    onBlur={props.onBlur}
                    onChange={(e: any) => setNewVal(e.target.value)}
                />
                <LinkButton onClick={() => {
                        if(newVal && newVal.trim() !== '') {
                            onChange([...(props.value || []), newVal.trim()]);
                            setNewVal('');
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} /> Add
                </LinkButton>
            </div>
            <div className={styles.values}>
                {
                    props.value && props.value.map((str, i) => (
                        <div key={str} className={styles.valueItem}>
                            <div className={styles.value}>{str}</div>
                            <div className={styles.actions}>
                                <LinkButton
                                    onClick={() => onChange(props.value?.filter((s, index) => i !== index ))}
                                    buttonType="button"
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </LinkButton>
                                <LinkButton
                                    onClick={() => onDown(i)}
                                    disabled={i >= (props.value?.length || 0) - 1}
                                    buttonType="button"
                                >
                                    <FontAwesomeIcon icon={faArrowDown} />
                                </LinkButton>
                                <LinkButton
                                    onClick={() => onUp(i)}
                                    disabled={i < 1}
                                    buttonType="button"
                                >
                                    <FontAwesomeIcon icon={faArrowUp} />
                                </LinkButton>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export function MultiRowEditorField(props: FieldProps & Partial<IProps>): React.ReactElement {
    const {field, form, ...params} = props;
    return (
        <MultiRowEditor
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            {...params}
        />
    );
}
