import { IConcept } from './concept';
import { ICurriculum } from './curriculum';
import { IUnitSection } from './unit-section';

export enum UnitTypes {
    standard = 'Standard',
    review = 'Review',
    ongoing_practice = 'Ongoing Practice',
}

export interface IUnit {
    uid: string,
    name: string,
    description: string,
    teaser: string,
    type: UnitTypes,
    index: number,
    educatorGuide?: string,
    available?: boolean,
    edited?: boolean,
    finalEmotionModelState?: string,
    iconFileName?: string,
    worksheet?: string,

    modelFile?: File,
    iconFile?: File,

    curriculum?: ICurriculum,
    unitSections?: IUnitSection[],
    conceptsCovered?: IConcept[],


    completed?: boolean,
}
