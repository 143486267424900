import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { getAllTasks } from '../../../api/actions/task/tasks-get-all';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Popup } from '../../../components/shared/popup/popup';
import { CurrentUserContext } from '../../../components/user/current-user-manager';
import { EntityTypes } from '../../../models/enums/entity-types';
import { ITask, TaskPriorities, TaskStatuses, TaskStatusesInfo } from '../../../models/task';
import { TaskEditor } from './task-editor';
import styles from './tasks-list.module.scss';


interface IProps {
    history: any,
    entity?: {
        uid: string,
        name: string,
        type: EntityTypes,
    },
    setTasksCount?(str: any): void,
}

export function EntityTasksList(props: IProps) {

    const currentUserContext = React.useContext(CurrentUserContext);

    const [tasks, setTasks] = React.useState<ITask[]>();
    const [editableTask, setEditableTask] = React.useState<ITask>();


    const getMyTasks = (tsks: ITask[] | undefined) => {
        tsks = tsks?.filter(t => {
            if([TaskStatuses.notStarted, TaskStatuses.inProgress].includes(t.status)) {
                return t.assignee?.uid === currentUserContext?.currentUser?.uid;
            }
            else {
                return t.author.uid === currentUserContext?.currentUser?.uid;
            }
        });

        return tsks;
    }

    const loadData = async () => {
        const tsks = await getAllTasks(props.entity?.type, props.entity?.uid);
        setTasks(tsks);
        if(editableTask?.uid) {
            setEditableTask(tsks.find(t => t.uid === editableTask.uid));
        }

        const count = (<><strong>{getMyTasks(tsks)?.length}</strong> / {tsks?.length}</>);

        props.setTasksCount?.(count);
    };

    React.useEffect(() => {
        loadData();
    }, []);

    const createNewTask = () => {
        if(currentUserContext?.currentUser) {
            setEditableTask({
                entityUid: props.entity?.uid,
                entityName: props.entity?.name,
                entityType: props.entity?.type,
                priority: TaskPriorities.normal,
                status: TaskStatuses.newTask,
                author: currentUserContext.currentUser,
            } as ITask);
        }
    }
    
    return (<>
        <div className="list-title">
            <div>Tasks: <strong>{ (tasks?.length) || 0}</strong></div>
            <div>
                <Button className="orange" onClick={createNewTask}>
                    <FontAwesomeIcon icon={faPlus} /> <span>Add Task</span>
                </Button>
            </div>
        </div>
        
        { !!tasks?.length && (
            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Priority</th>
                        <th>Author</th>
                        <th>Assignee</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tasks
                        .map((t: ITask) => (
                            <tr key={t.uid}>
                                <td>
                                    <div className={styles.markContainer}>
                                        <div
                                            className={
                                                `${styles.mark} ${t.priority === TaskPriorities.high ? styles.priorityHigh : (t.priority === TaskPriorities.low ? styles.priorityLow : '')}`
                                            }
                                        >
                                            &nbsp;
                                        </div>
                                        <div className={styles.title}>
                                            {t.name}
                                        </div>
                                    </div>
                                </td>
                                <td>{TaskStatusesInfo[t.status].statusText}</td>
                                <td>{t.priority?.toUpperCase()}</td>
                                <td>{`${t.author?.firstName} ${t.author?.lastName} [${t.author?.email}]`}</td>
                                <td>{t.assignee ? `${t.assignee?.firstName} ${t.assignee?.lastName} [${t.assignee?.email}]` : ''}</td>
                                <td>
                                    <LinkButton onClick={() => setEditableTask(t)}>
                                        <FontAwesomeIcon icon={faEdit} /> View
                                    </LinkButton><br />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )}

        {editableTask && (
            <Popup
                modal={true}
                title={(editableTask.uid ? 'Edit' : 'Add') + ' Task'}
                onClose={() => {
                    setEditableTask(undefined);
                }}
            >
                <TaskEditor
                    task={editableTask}
                    updateTask={setEditableTask}
                    onClose={() => setEditableTask(undefined)}
                    reload={loadData}
                    history={props.history}
                />
            </Popup>
        )}
        
    </>);
}