import { faCheckCircle, faComments, faMinusSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faArrowRight, faExclamationTriangle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { commentDelete } from '../../api/actions/comment/comment-delete';
import { commentResolve } from '../../api/actions/comment/comment-resolve';
import { commentSave } from '../../api/actions/comment/comment-save';
import { getCommentsByEntity } from '../../api/actions/comment/comments-get-by-entity';
import { getAllTasks } from '../../api/actions/task/tasks-get-all';
import { isNotNullOrEmpty } from '../../helpers/common-helpers';
import { basePerformError } from '../../helpers/error-helpers';
import { getRelativeLinkToEntity } from '../../helpers/path-helpers';
import { IComment } from '../../models/comment';
import { EntityTypes } from '../../models/enums/entity-types';
import { ITask, TaskStatuses } from '../../models/task';
import { Button } from '../shared/button/button';
import { CKEditorField } from '../shared/ckeditor/ckeditor';
import { LinkButton } from '../shared/link-button/link-button';
import { Loader } from '../shared/loader/loader';
import { SelectField } from '../shared/select/select';
import { useCurrentUser } from '../user/current-user-manager';
import { MultiError } from '../../types/multi-error';
import styles from './comments-list.module.scss';


const FormFields: Partial<Record<keyof IComment, string>> = {
    html: 'html',
    task: 'task',
}

interface IForm {
    parentCommentUid?: string,
    html: string,
    task: string,
}

interface IProps {
    entityType?: EntityTypes,
    entityUid?: string,
    taskEntityType?: EntityTypes,
    taskEntityUid?: string,
    comments?: IComment[],
    readonly?: boolean,
    displayLinkToEntity?: boolean,
}

export function CommentsList(props: IProps) {
    const [submittingComment, setSubmittingComment] = React.useState(false);
    const [mainFormVisible, setMainFormVisible] = React.useState(!props.readonly);
    const [showOldComments, setShowOldComments] = React.useState(false);

    const [comments, setComments] = React.useState<IComment[]>();
    const [tasks, setTasks] = React.useState<ITask[]>();

    const [replyForCommentUid, setReplyForCommentUid] = React.useState<string>();

    const currentUser = useCurrentUser();

    const history = useHistory();

    React.useEffect(() => {
        loadData();
    }, []);

    // if outer loading process finished after component initialization need update comments
    React.useEffect(() => {
        setComments(props.comments);
    }, [props.comments])

    const loadData = async () => {
        if(!props.entityType || !props.entityUid) return;

        const allComments = await getCommentsByEntity(props.entityType, props.entityUid);
        setComments(allComments);
        const rootActiveComments = getComments(allComments)?.filter(c => !isOldComment(c));
        if(rootActiveComments?.length === 1) {
            setReplyForCommentUid(rootActiveComments[0].uid);
            setMainFormVisible(false);
        }
        if(props.taskEntityUid) {
            setTasks((await getAllTasks())
                .filter(t => t.status !== TaskStatuses.closed && t.entityType === props.taskEntityType && t.entityUid === props.taskEntityUid));
        }
    }

    const resolveComment = async (uid: string) => {
        await commentResolve(uid);
        await loadData();
    }

    const isOldComment = (comment: IComment) => !comment.parentCommentUid && comment.resolved && moment(comment.updatedAt) < moment().add(-30, 'days');

    const isExistOldComments = React.useMemo(
        () => comments?.some(isOldComment),
        [comments]
    )


    const handleSubmit = async (
        values: IForm,
        { setSubmitting, resetForm,  }: FormikHelpers<IForm>,
    ) => {
        if(!props.entityType || !props.entityUid) return;

        setSubmittingComment(true);
        try {
            const comment = {} as IComment;
            comment.html = values.html;
            comment.parentCommentUid = values.parentCommentUid;
            comment.resolved = true;
            comment.entityType = props.entityType;
            comment.entityUid = props.entityUid;
            comment.task = isNotNullOrEmpty(values.task) ? ({ uid: values.task } as ITask) : undefined;

            await commentSave(comment);
            await loadData();
            resetForm();
        }
        catch (err) {
            basePerformError(err);
        }
        setSubmitting(false);
        setSubmittingComment(false);
    }


    const getComments = (comments?: IComment[], parentCommentUid?: string) => {
        return comments?.filter(c => parentCommentUid ? c.parentCommentUid === parentCommentUid : !c.parentCommentUid)
            .sort((c1, c2) => moment(c1.createdAt).diff(c2.createdAt));
    }

    const isCommentHasSubComments = (commentUid: string) => comments?.some(c => c.parentCommentUid === commentUid);


    const getValidationSchema = () => {
        return Yup.object<IForm>({
            html: Yup.string().label('Comment').trim().required().min(3).max(20000),
            task: Yup.mixed(),
        });
    };

    const goToEntity = async (comment: IComment) => {
        const url = await getRelativeLinkToEntity(comment.entityType, comment.entityUid);

        // replaced  history.push(url) with the below line of code to make the comments open in a new window
        window.open(url, "_blank") 
        
    }

    const renderComment = (comment: IComment) => {
        return !showOldComments && isOldComment(comment) ? null : (
            <div key={comment.uid} className={`${styles.commentItemContainer} ${comment.uid === replyForCommentUid ? styles.active : ''} `}>
                <div className={styles.commentInfo}>
                    <div className={styles.row}>
                        {!comment.resolved && (
                            <FontAwesomeIcon icon={faExclamationTriangle} className={styles.unresolvedIcon} />
                        )}
                        <div className={styles.author}>
                            {comment.author.firstName}
                            {comment.author.lastName}
                            <div className={styles.dates}>
                                <div>
                                    {moment.utc(comment.createdAt).format('h:ma MMM DD, YYYY')}
                                </div>
                                {comment.createdAt !== comment.updatedAt && (
                                    <div>
                                        Edited: {moment.utc(comment.updatedAt).format('H:ma MMM DD, YYYY')}
                                    </div>
                                )}
                            </div>
                        </div>
                        {!props.readonly && (<>
                            {!comment.resolved && (
                                <LinkButton className="green" onClick={() => { resolveComment(comment.uid); }}>
                                    <FontAwesomeIcon icon={faCheckCircle} /> Resolve
                                </LinkButton>
                            )}
                            {!comment.parentCommentUid && !isCommentHasSubComments(comment.uid) && comment.uid !== replyForCommentUid && (
                                <LinkButton onClick={() => { setMainFormVisible(false); setReplyForCommentUid(comment.uid); }} className="orange">
                                    <FontAwesomeIcon icon={faComments} /> Reply in thread
                                </LinkButton>
                            )}
                            {comment.author.uid === currentUser?.uid && (
                                <LinkButton onClick={async () => { await commentDelete(comment.uid); await loadData(); }} className="gray" >
                                    <FontAwesomeIcon icon={faTrash} /> Delete
                                </LinkButton>
                            )}
                        </>)}
                        {props.displayLinkToEntity && !comment.parentCommentUid && (
                            <LinkButton onClick={() => goToEntity(comment)} className="gray" >
                                <FontAwesomeIcon icon={faArrowRight} /> Go to Entity
                            </LinkButton>
                        )}
                    </div>
                    {comment.task && (
                        <div className={styles.taskInfo}>
                            Task: {comment.task.name}
                        </div>
                    )}
                </div>
                <div className={styles.commentContent} dangerouslySetInnerHTML={{__html: comment.html}}>
                </div>
                {isCommentHasSubComments(comment.uid) && (<>
                    <div className={styles.subComments}>
                        <ExpandCollapse expanded={!props.readonly && !comment.resolved} count={getComments(comments, comment?.uid)?.length || 0}>
                            {getComments(comments, comment?.uid)?.map(renderComment)}
                            {!props.readonly && !comment.parentCommentUid && comment.uid !== replyForCommentUid && (
                                <LinkButton onClick={() => setReplyForCommentUid(comment.uid)} className="orange">
                                    <FontAwesomeIcon icon={faComments} /> Reply in thread
                                </LinkButton>
                            )}
                        </ExpandCollapse>
                    </div>
                    <div className={styles.commentItemButtons}>
                    </div>
                </>)}
                {comment.uid === replyForCommentUid && (
                    <div className={styles.replyInThreadFormContainer}>
                        {renderReplyForm(comment.uid)}
                    </div>
                )}
            </div>
        )
    }

    const renderForm = ({ errors, touched, }: FormikProps<IForm>, parentCommentUid?: string): React.ReactElement => {
        return (
            <Form noValidate>
                <div className="form-item">
                    <label>
                        <div className="form-label required">
                            Comment
                        </div>
                        <Field
                            component={CKEditorField}
                            name={FormFields.html}
                            toolbar={[
                                { name: 'clipboard', items: [ 'Undo', 'Redo' ] },
                                { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] }, { name: 'tools', items: [ 'ShowBlocks' ] },
                                { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                                { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', ] },
                                { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', ] },
                                { name: 'insert', items: [ 'HorizontalRule', 'SpecialChar' ] },
                            ]}
                            height={100}
                        />
                    </label>
                    <div className="errors">{errors.html}</div>
                </div>
                {!replyForCommentUid && !!tasks?.length && (
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Task
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.task}
                                data={tasks}
                            />
                        </label>
                        <div className="errors">{touched.task && errors.task}</div>
                    </div>
                )}

                <div className="form-buttons">
                    {!parentCommentUid
                    ? (<div></div>)
                    : (
                        <LinkButton onClick={() => { setMainFormVisible(true); setReplyForCommentUid(undefined); }}>
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Cancel</span>
                        </LinkButton>
                    )}

                    <Button type="submit" className="orange small">
                        <span>Submit</span>
                    </Button>
                </div>
            </Form>
        )
    }

    const renderReplyForm = (parentCommentUid?: string) => {
        return (
            <div className={styles.replyForm}>
                {submittingComment && (<Loader />)}
                {!submittingComment && (
                    <Formik<IForm>
                        initialValues={{
                            parentCommentUid: parentCommentUid,
                            html: '',
                            task: '',
                        }}
                        validationSchema={getValidationSchema}
                        onSubmit={handleSubmit}
                    >
                        {(formProps) => renderForm(formProps, parentCommentUid)}
                    </Formik>
                )}
            </div>
        )
    }

    return (<>
        {isExistOldComments && !showOldComments && (
            <div className={styles.oldComments}>
                Resolved comments have been hidden. <LinkButton onClick={() => setShowOldComments(true)}>Show All</LinkButton>
            </div>
        )}

        <div className={styles.commentsList}>
            {getComments(comments)?.map(renderComment)}
        </div>
        {mainFormVisible && renderReplyForm()}
    </>)
}


function ExpandCollapse(props: { children: any[], expanded: boolean, count: number }) {

    const [expanded, setExpanded] = React.useState(props.expanded);

    return (<>
        {expanded && (
            <div className={styles.subCommentsContainer}>
                {props.children}
            </div>
        )}
        <div className={styles.row}>
            {!expanded && (
                <LinkButton onClick={() => setExpanded(true)} className={styles.gray}>
                    <FontAwesomeIcon icon={faPlusSquare} /> &nbsp; Show {props.count.toString()} comment(s)
                </LinkButton>
            )}
            {expanded && (
                <LinkButton onClick={() => setExpanded(false)} className={styles.gray}>
                    <FontAwesomeIcon icon={faMinusSquare} /> &nbsp; Hide {props.count.toString()} comment(s)
                </LinkButton>
            )}
            <div className={styles.line}></div>
        </div>
    </>)
}
