import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation EmotionEpisodeDisable($uid: String!){
        emotion_episode_disable (uid: $uid) {
            success
        }
    }
`;

export async function emotionEpisodeDisable(uid: string): Promise<{ success: boolean }> {
    const result: FetchResult<{ emotion_episode_disable: { success: boolean }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.emotion_episode_disable;
}
