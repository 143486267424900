import * as React from 'react';
import ReactPlayer from 'react-player';

interface IProps {
    url: string,
    playing?: boolean,
    startPos?: number,
    endPos?: number,
    controls?: boolean,
    onSeek?(seconds: number): void,
    onProgress?(state: { played: number, playedSeconds: number, loaded: number, loadedSeconds: number }): void,
}

interface IState {
    playing: boolean,
    shouldStop?: boolean,
}

export class Video extends React.Component<IProps, IState> {

    player = React.createRef<ReactPlayer>();

    constructor(props: IProps) {
        super(props);
        this.state = { playing: !!props.playing };
    }

    render() {
        const url = this.props.url;

        return (
            <div style={{ width: '100%', height: '100%'}} onClick={this.onClick}>
                <ReactPlayer
                    ref={this.player}
                    width="100%"
                    height="100%"
                    playing={this.state.playing}
                    controls={this.props.controls}
                    url={this.isYoutubeLink() ? `${url}&t=${this.props.startPos}s` : url}
                    onReady={this.onReady}
                    onProgress={this.onProgress}
                    onSeek={this.props.onSeek}
                    onEnded={this.onEnded}
                    muted={this.state.shouldStop}
                />
            </div>
        );
    }

    private onClick = () => {
        if(this.props.controls) return;

        if(this.state.playing) {
            this.pause();
        }
        else {
            this.play();
        }
    }

    private isYoutubeLink() {
        return this.props.url.includes('https://www.youtube.com/watch?');
    }

    private onReady = () => {
        // if(this.player.current && this.props.startPos && !this.isYoutubeLink(this.props.url)) {
        //     this.player.current.seekTo(this.props.startPos, 'seconds');
        //     if(this.player.current) (this.player.current.getInternalPlayer() as any).pause();
        //     // this.setState({ playing: true, shouldStop: true });
        // }
    }

    private onProgress = (info: { played: number, playedSeconds: number, loaded: number, loadedSeconds: number }) => {
        if(this.props.onProgress) {
            this.props.onProgress(info);
        }

        if(this.props.startPos && this.props.startPos > info.playedSeconds) {
            this.player.current?.seekTo(this.props.startPos, 'seconds');
        }
        if(this.props.endPos && info.playedSeconds >= this.props.endPos) {
            this.player.current?.seekTo(this.props.startPos || 0, 'seconds');
            this.pause();
            this.setState({ playing: false });
        }
    }

    private pause() {
        if(!this.player.current) return;
        const player: any = this.player.current.getInternalPlayer();
        if(!player || !player.pause) return;
        player.pause();
        this.setState({ playing: false });
    }

    private play() {
        this.setState({ playing: true });
    }

    private onEnded = () => {
        if(this.props.startPos) {
            this.player.current?.seekTo(this.props.startPos, 'seconds');
        }
        this.pause();
    }
}
