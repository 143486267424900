import { FetchResult, gql } from 'apollo-boost';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';
import { IEmotionCategoryForm } from '../../../models/emotion-category';

const EMOTION_CATEGORY_MUTATION = gql`
    mutation SaveEmotionCategory($emotionCategoryData: EmotionCategoryInput!, $imageFile: Upload) {
        emotion_category_save(emotion_category_data: $emotionCategoryData, imageFile: $imageFile) {
            success,
            error_msg,
            emotion_category {
                uid,
                label
            }
        }
    }
`;

// eslint-disable-next-line max-len
export async function emotionCategorySave(emotionCategory: IEmotionCategoryForm, imageFile?: File): Promise<{ success: boolean, error_msg?: string, uid: string, label: string }> {
    // eslint-disable-next-line max-len
    const result: FetchResult<{ emotion_category_save: { success: boolean, error_msg?: string, emotion_category: { uid: string, label: string } } }> = await graphqlClient.mutate({
        mutation: EMOTION_CATEGORY_MUTATION,
        variables: { 
            emotionCategoryData: {
                uid: emotionCategory.uid,
                parent: emotionCategory.parent,
                label: emotionCategory.label,
                definition: emotionCategory.definition,
                valence: emotionCategory.valence,
                color: emotionCategory.color,
                adjective: emotionCategory.adjective,
                min_age_rating: emotionCategory.minAgeRating,
            },
            imageFile 
        },
        errorPolicy: 'all',
    });
  
    if (!result.data || (result.errors && result.errors.length)) {
        throw new MultiError(result.errors || ['Unknown error']);
    }
  
    return {
        success: result.data.emotion_category_save.success,
        uid: result.data.emotion_category_save.emotion_category?.uid || '',
        label: result.data.emotion_category_save.emotion_category?.label || '',
        error_msg: result.data.emotion_category_save.error_msg
    };
  }
  