import * as React from 'react';
import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { isNullOrEmpty } from '../../../helpers/common-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import { map } from '../../../helpers/object-helpers';
import { IConcept } from '../../../models/concept';
import styles from './scheme.module.scss';

export class SchemeInfo {
    width: number;
    height: number;
    title?: string;
    description?: string;
    cssClass?: string;
    elements: Array<SchemeElement | SchemeConnection>;

    static parse = (data: any) => {
        const scheme: SchemeInfo = map(SchemeInfo, data);
        scheme.elements = scheme.elements.map(
            (el: any) => {
                switch(el.type) {
                    case SchemeElementTypes.connection:
                        return map(SchemeConnection, el);
                    case SchemeElementTypes.element:
                        return map(SchemeElement, el);

                    default: throw new Error('Unknown scheme Type');
                }
            }
        );

        return scheme;
    }
}

enum SchemeElementTypes {
    connection = 'connection',
    element = 'element',
}

export class SchemeRenderers {
    static default = (scheme: SchemeInfo) => {
        return (
            <svg width="100%" viewBox={`0 0 ${scheme.width} ${scheme.height}`} xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <marker id="marker-arrow-gray-end" className="markerArrowGray" markerWidth="4" markerHeight="4" fill="currentColor" refX="3" refY="2" orient="auto" markerUnits="strokeWidth">
                        <path d="M0,0 L0,4 L4,2 z" />
                    </marker>
                    <marker id="marker-arrow-gray-start" className="markerArrowGray" markerWidth="4" markerHeight="4" fill="currentColor" refX="1" refY="2" orient="auto" markerUnits="strokeWidth">
                        <path d="M0,2 L4,0 L4,4 z" />
                    </marker>
                    <marker id="marker-arrow-green-end" className="markerArrowGreen" markerWidth="4" markerHeight="4" fill="currentColor" refX="3" refY="2" orient="auto" markerUnits="strokeWidth">
                        <path d="M0,0 L0,4 L4,2 z" />
                    </marker>
                    <marker id="marker-arrow-green-start" className="markerArrowGreen" markerWidth="4" markerHeight="4" fill="currentColor" refX="1" refY="2" orient="auto" markerUnits="strokeWidth">
                        <path d="M0,2 L4,0 L4,4 z" />
                    </marker>
                    <marker id="marker-arrow-blue-end" className="markerArrowBlue" markerWidth="4" markerHeight="4" fill="currentColor" refX="3" refY="2" orient="auto" markerUnits="strokeWidth">
                        <path d="M0,0 L0,4 L4,2 z" />
                    </marker>
                    <marker id="marker-arrow-blue-start" className="markerArrowBlue" markerWidth="4" markerHeight="4" fill="currentColor" refX="1" refY="2" orient="auto" markerUnits="strokeWidth">
                        <path d="M0,2 L4,0 L4,4 z" />
                    </marker>
                    <marker id="marker-arrow-red-end" className="markerArrowRed" markerWidth="4" markerHeight="4" fill="currentColor" refX="3" refY="2" orient="auto" markerUnits="strokeWidth">
                        <path d="M0,0 L0,4 L4,2 z" />
                    </marker>
                    <marker id="marker-arrow-red-start" className="markerArrowRed" markerWidth="4" markerHeight="4" fill="currentColor" refX="2" refY="2" orient="auto" markerUnits="strokeWidth">
                        <path d="M0,2 L4,0 L4,4 z" />
                    </marker>
                </defs>
                { scheme.elements.map((el) => {
                    if(el instanceof SchemeElement) {
                        return SchemeElementRenderers.default(el);
                    }
                    else if(el instanceof SchemeConnection) {
                        return SchemeConnectionRenderers.default(el);
                    }
                    return null;
                })}
            </svg>
        );
    }

}

export class SchemeElement {
    title: string;
    content?: string;
    imageUrl?: string;
    cssClass?: string;
    type: any;
    x: number;
    y: number;
    width?: number;
    height?: number;
}

export class SchemeElementRenderers {
    static default = (el: SchemeElement) => {
        return (
            <g key={`el${el.x}:${el.y}`} transform={`translate(${el.x}0, ${el.y}0)`} className={`element ${el.cssClass}`}>
                <rect rx="10" ry="10" width={(el.width || 10) * 10} height={(el.height || 10) * 10} />
                { el.imageUrl && (<image xlinkHref={el.imageUrl} className={ !el.title ? styles.onlyImage : undefined} />)}
                { el.title && (
                    <text x="4" y={el.imageUrl ? 50 : 0} fontSize="10">
                        {SchemeElementRenderers.text(el)}
                    </text>
                )}
            </g>
        );
    }

    static text = (el: SchemeElement) => {
        const width = (el.width || 10) * 10;
        const letterSize = 5.5;
        const rowSize = width / letterSize;
        const result = [];
        let str = el.title;
        while(str !== '') {
            const lastSpace = str.length <= rowSize ? str.length : str.lastIndexOf(' ', rowSize);
            const lastBrake = str.indexOf('\n');
            const strPart = str.substr(0, (lastBrake === -1 || (lastSpace != -1 && lastSpace < lastBrake) ? lastSpace : lastBrake));
            result.push(<tspan key={`el${el.x}:${el.y}:${result.length}:${strPart}`} x="5" dy="12">{strPart}&nbsp;</tspan>);
            str = str.substr(strPart.length + 1);
        }
        return result;
    }
}

export class SchemeConnection {
    title?: string;
    cssClass?: string;
    points: {x: number, y: number}[];
}

export class SchemeConnectionRenderers {
    static default = (el: SchemeConnection) => {
        const rad = 2;
        const result: string[] = [];
        const length = el.points.length;
        let prev: {x: number, y: number};
        el.points.forEach((p, i: number) => {
            if(i === 0 || !prev) result.push(`M${p.x * 10},${p.y * 10}`);
            else if(prev.x < p.x && prev.y === p.y) {
                if(i > 1) result.push(`Q${prev.x * 10},${prev.y * 10} ${(prev.x + rad) * 10},${prev.y * 10}`);
                if(i === length - 1) result.push(`L${p.x * 10},${p.y * 10}`);
                else result.push(`L${(p.x - rad) * 10},${p.y * 10}`);
            }
            else if(prev.x > p.x && prev.y === p.y) {
                if(i > 1) result.push(`Q${prev.x * 10},${prev.y * 10} ${(prev.x - rad) * 10},${prev.y * 10}`);
                if(i === length - 1) result.push(`L${p.x * 10},${p.y * 10}`);
                else result.push(`L${(p.x + rad) * 10},${p.y * 10}`);
            }
            else if(prev.x === p.x && prev.y < p.y) {
                if(i > 1) result.push(`Q${prev.x * 10},${prev.y * 10} ${prev.x * 10},${(prev.y + rad) * 10}`);
                if(i === length - 1) result.push(`L${p.x * 10},${p.y * 10}`);
                else result.push(`L${p.x * 10},${(p.y - rad) * 10}`);
            }
            else if(prev.x === p.x && prev.y > p.y) {
                if(i > 1) result.push(`Q${prev.x * 10},${prev.y * 10} ${prev.x * 10},${(prev.y - rad) * 10}`);
                if(i === length - 1) result.push(`L${p.x * 10},${p.y * 10}`);
                else result.push(`L${p.x * 10},${(p.y + rad) * 10}`);
            }

            prev = p;
        })
        
        return (
            <g key={result.join(' ')} className={`connection ${el.cssClass}`}>
                <path  
                    id={result.join(' ').toLowerCase().replace(/[^a-zA-Z0-9]/ig, '-')}
                    d={result.join(' ')}
                    className={el.cssClass}
                />
                {!isNullOrEmpty(el.title) && (
                    <text fontSize="12">
                        <textPath
                            xlinkHref={'#' + result.join(' ').toLowerCase().replace(/[^a-zA-Z0-9]/ig, '-')}
                            startOffset="40%"
                            textAnchor="middle"
                        >
                            {el.title}
                        </textPath>
                    </text>
                )}
            </g>
        );
    }
}

const scheme: SchemeInfo = SchemeInfo.parse({
    width: 800,
    height: 800,
    elements: [
        {
            title: `Vivamus quis mi.`,
            type: 'element',
            cssClass: 'bordered dashed',
            x: 18,
            y: 8,
            width: 35,
            height: 13,
        },
        {
            title: 'Some element title with long size Some element title with long size Some element title with long size',
            type: 'element',
            cssClass: 'green',
            x: 0,
            y: 30,
        },
        {
            title: 'Some element title with long size Some element title with long size Some element title with long size',
            type: 'element',
            cssClass: 'gray',
            x: 20,
            y: 10,
        },
        {
            title: 'Some element title title with long size',
            imageUrl: 'https://www.lunapic.com/editor/premade/transparent.gif',
            cssClass: `bordered blue`,
            type: 'element',
            x: 40,
            y: 10,
        },
        {
            title: 'Connection text',
            cssClass: `dashed red endArrow startArrow`,
            type: 'connection',
            points: [
                { x: 10, y: 31 },
                { x: 15, y: 31},
                { x: 15, y: 15},
                { x: 20, y: 15},
            ],
        },

        {
            title: 'Some element title with long size Some element title with long size Some element title with long size',
            type: 'element',
            cssClass: 'blue',
            x: 40,
            y: 27,
        },
        {
            title: 'Link 2',
            cssClass: `dotted green endArrow`,
            type: 'connection',
            points: [
                { x: 10, y: 33 },
                { x: 40, y: 33},
            ],
        },

        {
            title: 'Some element title with long size Some element title with long size Some element title with long size',
            type: 'element',
            cssClass: 'red',
            x: 20,
            y: 35,
        },
        {
            title: 'Belongs',
            cssClass: `dashed endArrow`,
            type: 'connection',
            points: [
                { x: 10, y: 37},
                { x: 17, y: 37},
                { x: 17, y: 40},
                { x: 20, y: 40},
            ],
        },

        {
            title: 'Some element title with long size Some element title with long size Some element title with long size',
            type: 'element',
            cssClass: `red bordered`,
            x: 35,
            y: 45,
        },
        {
            title: 'Connection text',
            cssClass: `blue endArrow startArrow`,
            type: 'connection',
            points: [
                { x: 5, y: 40},
                { x: 5, y: 50},
                { x: 35, y: 50},
            ],
        },


        {
            title: 'Some element title with long size Some element title with long size Some element title with long size',
            type: 'element',
            x: 55,
            y: 45,
        },
        {
            title: 'Next',
            cssClass: `blue endArrow`,
            type: 'connection',
            points: [
                { x: 45, y: 50},
                { x: 55, y: 50},
            ],
        },



        {
            type: 'element',
            imageUrl: 'https://st2.depositphotos.com/1853861/7028/v/950/depositphotos_70280097-stock-illustration-end-button-icon.jpg',
            x: 60,
            y: 25,
        },

        {
            cssClass: `blue`,
            type: 'connection',
            points: [
                { x: 50, y: 15},
                { x: 65, y: 15},
                { x: 65, y: 25},
            ],
        },

        {
            cssClass: `red dashed bold endArrow`,
            type: 'connection',
            points: [
                { x: 50, y: 30},
                { x: 60, y: 30},
            ],
        },

        {
            cssClass: `green thin endArrow`,
            type: 'connection',
            points: [
                { x: 30, y: 40},
                { x: 65, y: 40},
                { x: 65, y: 35},
            ],
        },

        {
            cssClass: `gray dotted endArrow`,
            type: 'connection',
            points: [
                { x: 65, y: 50},
                { x: 75, y: 50},
                { x: 75, y: 30},
                { x: 70, y: 30},
            ],
        },
    ]
});


export function Scheme(props: RouteComponentProps) {
    const [concepts, setConcepts] = useState<IConcept[]>([]);

    const loadData = React.useCallback(async () => {
        try {
            // setConcepts( buildTree(await getAllConcepts()));
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history]);

    // useEffect(() => {
    //     loadData();
    // }, [loadData]);


    return !concepts ? null : (
        <>
            <AddBreadcrumbsItem
                title="Scheme"
                url={'/libraries/scheme'}
            />


            <div style={{width: '100%'}}>
                {SchemeRenderers.default(scheme)}
            </div>
        </>
    );
}
