import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../../components/layouts/main-layout';
import { AddBreadcrumbsItem } from '../../components/shared/breadcrumbs/breadcrumbs';
import { LinkButton } from '../../components/shared/link-button/link-button';
import { FlagsManagementPage } from './flags/flags-management-page';
import { ResponseManagementPage } from './responses/response-management-page';
import { SecuredPage } from '../base/secured-page';
import styles from './crowd-management-page.module.scss';


export function CrowdManagementPage(props: RouteComponentProps) {

    const isActive = (path: string) => new RegExp(`^${path}`, 'ig').exec(document.location.pathname);

    return (
        <SecuredPage pageName="crowd" {...props} >

            <AddBreadcrumbsItem
                title="Home"
                url="/"
            />
            <AddBreadcrumbsItem
                title="Crowd"
                url="/crowd"
            />

            <MainLayout
                submenu={
                    <div className="mainsubmenu">
                        <ul>
                            <li>
                                <LinkButton
                                    to={`${props.match.url}/flags`}
                                    className={isActive(`${props.match.url}/flags`) && 'active'}
                                >Flags</LinkButton>
                            </li>
                            <li>
                                <LinkButton
                                    to={`${props.match.url}/responses`}
                                    className={isActive(`${props.match.url}/flags`) && 'active'}
                                >Responses</LinkButton>
                            </li>                            
                        </ul>
                    </div>
                }
            >

                <Route
                    path={`${props.match.url}/flags`} component={FlagsManagementPage}
                />
                <Route
                    path={`${props.match.url}/responses`} component={ResponseManagementPage}
                />

                <Route exact path={props.match.url} component={() => <div className={styles.pleaseChoose}>Please choose one of items from submenu</div>} />
            </MainLayout>
        </SecuredPage>
    );
}
