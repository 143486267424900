import { FetchResult, gql } from 'apollo-boost';
import { IUser } from '../../../models/user';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetUser($uid: String!) {
        user(uid: $uid) {
            uid,
            email,
            confirmedEmail: confirmed_email,
            firstName: first_name,
            lastName: last_name,
            age,
            gender,
            role,
            interests {
                uid,
                name,
                description,
                motivations: associated_motivations
            },
            curriculumEditPermission: curriculum_edit_permission,
            superAdmin: super_admin,
            motivation,
            pdState: pd_state,
            configurationFinished: configuration_finished,
            extensionInstalled: extension_installed,
            disabled,
            lastActive: last_active,
            createdAt: created_at,
            userPlan: user_plan_ref,
            pipsEarned: pips_earned,
            activeUserPlan: user_plan {
                uid,
                name,
                trialActiveForUser: trial_active_for_user,
                trialExpiredForUser: trial_expired_for_user,
                trialExpirationForUser: trial_expiration_date_for_user,
            },

            usedNarratives: used_narratives {
                uid,
                title
            },

            viewedNarratives: viewed_narratives {
                uid,
                title
            },

            viewedEpisodes: viewed_episodes {
                uid,
                title
                narrative {
                    title
                }
            },

            userGroups: user_groups {
                uid,
                title,
                description,
                groupType: group_type,
                userPermissions: user_permissions,
                HIPAAEnforced: HIPAA_enforced,
                enabled,

                curriculums {
                    uid,
                    name,
                    description,
                    available,
                }
            },

            completedUnitsStats: completed_units_stats {
                unitUid: unit_uid,
                points,
                timeSeconds: time_seconds,
                datetime,
                data,
                unitScore: unit_score,
                bestUnitScore: best_unit_score,

                unit {
                    uid,
                    name,
                    index,
                },

                curriculum {
                    uid,
                    name,
                }
            },
        }
    }
`;

export async function getUser(uid: string): Promise<IUser> {
    const result: FetchResult<{ user: IUser }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }


    try {
      if(result.data.user.completedUnitsStats?.length) {
        result.data.user.completedUnitsStats.forEach(stats => {
            if(stats.data) {
                stats.data = JSON.parse(stats.data as any);
                if (stats.data && stats.data.length) {
                  stats.data.forEach(el => { el.timeSeconds = (el as any).time_seconds; el.unitScore = (el as any).unit_score ? (el as any).unit_score : 0; })
                }
            }
        })
      }
    } catch (err) {
      //there's something wrong with the units data
      //todo: display via toast?
      console.log("Error prepping user unit data: " + (err as MultiError).message);
      result.data.user.completedUnitsStats = [];
    }

    return result.data.user;
}
