import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { emotionEpisodeContentTagDelete } from '../../../api/actions/emotion-episode-content-tag/emotion-episode-content-tag-delete';
import { getEmotionEpisodeContentTag } from '../../../api/actions/emotion-episode-content-tag/emotion-episode-content-tag-get';
import { Block } from '../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Loader } from '../../../components/shared/loader/loader';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { basePerformError } from '../../../helpers/error-helpers';
import { getParentPath, joinPath } from '../../../helpers/path-helpers';
import { IEmotionEpisodeContentTag } from '../../../models/emotion-episode-content-tag';
import { IVideoService } from '../../../models/video-service';
import { EmotionEpisodeContentTagEditorComponent } from './emotion-episode-content-tag-editor-component';
import styles from './emotion-episode-content-tag-editor.module.scss';



export function EmotionEpisodeContentTagEditor(props: RouteComponentProps<{uid: string}>) {

    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [tag, setTag] = useState<IEmotionEpisodeContentTag>();

    const loadData = useCallback(async (uid?: string) => {
        try {
            if(uid || props.match.params.uid !== '_') {
                setTag(await getEmotionEpisodeContentTag(uid || props.match.params.uid));
            }
            else {
                setTag({} as IVideoService);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData().catch(() => {/** */});
    }, []);


    const deleteTag = async () => {
        if(!tag?.uid) return;
        await emotionEpisodeContentTagDelete(tag?.uid);
        setDeleteDialogVisible(false);
    };

    const onSaved = (uid: string) => {
        if(!tag?.uid) {
            props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
        }
    }

    const onDelete = () => setDeleteDialogVisible(true);
    

    return !tag ? (<Loader />) : (<>
        <Block className={styles.editor}>

            <AddBreadcrumbsItem
                title="Emotion Episode Content Tags"
                url={'/libraries/emotion-episode-tags'}
            />
            <AddBreadcrumbsItem
                title={tag.uid ? `Edit: ${tag.name}` : 'Add Tag'}
                url={props.match.url}
            />

            <EmotionEpisodeContentTagEditorComponent
                tag={tag}
                onSaved={onSaved}
                onDelete={onDelete}
                onClose={() => props.history.push(getParentPath(props.match.url, 1))}
            />
            
        </Block>

        {deleteDialogVisible && (
            <DeleteQuestionPopup
                title="Emotion Episode Content Tag Deletion"
                question={<>Are you sure want to delete Tag with name: {tag.name}?</>}
                onClose={() => setDeleteDialogVisible(false)}
                onDelete={deleteTag}
                onFinish={() => { props.history.push(getParentPath(props.match.url, 1)) }}
            />
        )}
    </>);
}
