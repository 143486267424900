import { faArrowLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { getAllAchievements } from '../../../api/actions/achievement/achievement-get-all';
import { pipOptionDelete } from '../../../api/actions/pip-option/pip-option-delete';
import { getAllPipOptions } from '../../../api/actions/pip-option/pip-option-get-all';
import { rewardConfigItemDelete } from '../../../api/actions/reward-config-item/reward-config-item-delete';
import { getRewardConfigItem } from '../../../api/actions/reward-config-item/reward-config-item-get';
import { rewardConfigItemSave } from '../../../api/actions/reward-config-item/reward-config-item-save';
import { getRewardConfig } from '../../../api/actions/reward-config/reward-config-get';
import { rewardConfigSave } from '../../../api/actions/reward-config/reward-config-save';
import { Block } from '../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { SelectField } from '../../../components/shared/select/select';
import { compareText } from '../../../helpers/array-helpers';
import { basePerformError } from '../../../helpers/error-helpers';
import { getParentPath, joinPath } from '../../../helpers/path-helpers';
import { IAchievement } from '../../../models/achievement';
import { IPipOption } from '../../../models/pip-option';
import { IRewardConfig } from '../../../models/reward-config';
import { IRewardConfigItem } from '../../../models/reward-config-item';
import { IDictionary } from '../../../types/dictionary';
import styles from './reward-config-editor.module.scss';


enum FormFields {
    name = 'name',
    description = 'description',
    achievement = 'achievement',
    pipOption = 'pipOption',
}

interface IForm {
    [FormFields.name]: string,
    [FormFields.description]?: string,
    [FormFields.achievement]: string,
    [FormFields.pipOption]: string,
}

export function RewardConfigItemEditor(props: RouteComponentProps<{uid: string, rewardConfigUid: string}>) {

    const [loading, setLoading] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [rewardConfigItem, setRewardConfigItem] = useState<IRewardConfigItem>();
    const [pipOptions, setPipOptions] = useState<IPipOption[]>();
    const [achievements, setAchievements] = useState<IAchievement[]>();

    const loadData = useCallback(async (uid?: string) => {
        try {
            const [achievements, pipOptions] = await Promise.all([
                getAllAchievements(undefined),
                getAllPipOptions()
            ]);
            setAchievements(achievements?.sort((el1, el2) => el1.index - el2.index));
            setPipOptions(pipOptions.sort((el1, el2) => compareText(el1, el2, el => el.name)));
            
            if(uid || props.match.params.uid !== '_') {
                setRewardConfigItem(await getRewardConfigItem(uid || props.match.params.uid));
            }
            else {
                setRewardConfigItem({} as IPipOption);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData().catch(() => {/** */});
    }, []);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newRewardConfigItem: Partial<IRewardConfigItem> = {
                uid: rewardConfigItem?.uid,
                name: values.name,
                description: values.description,
                pipOption: { uid: values.pipOption } as IPipOption,
                achievement: { uid: values.achievement } as IAchievement,
                rewardConfig: { uid: props.match.params.rewardConfigUid } as IRewardConfig,
            };
            
            const uid = await rewardConfigItemSave(newRewardConfigItem);
            if(!rewardConfigItem?.uid) {
                props.history.push(joinPath(getParentPath(props.match.url, 1), uid));
            }
            await loadData(uid);
            toast.success('Item has been successfully saved');
        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().label('Name').trim().required().min(2).max(50),
            description: Yup.string().label('Description').trim().min(3).max(250),
            achievement: Yup.string().label('Achievement').required(),
            pipOption: Yup.string().label('PIP Option').required(),
        });
    };

    const deleteRewardConfigItem = async () => {
        if(!rewardConfigItem?.uid) return;
        await rewardConfigItemDelete(rewardConfigItem?.uid);
        setDeleteDialogVisible(false);
    };

    const renderForm = ({ errors, touched }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>

                    <div className="form-buttons">
                        <div></div>
                        { rewardConfigItem?.uid && (
                            <LinkButton onClick={() => setDeleteDialogVisible(true)} className="red"><FontAwesomeIcon icon={faTrashAlt} /> Delete</LinkButton>
                        )}
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name
                            </div>
                            <Field type="text" name={FormFields.name} />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field component="textarea" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Achievement
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.achievement}
                                data={achievements}
                            />
                        </label>
                        <div className="errors">{touched.achievement && errors.achievement}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                PIP Option
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.pipOption}
                                data={pipOptions}
                            />
                        </label>
                        <div className="errors">{touched.pipOption && errors.pipOption}</div>
                    </div>
                    

                    <div className="form-buttons">
                        <Button className="gray" to={getParentPath(props.match.url, 1)}>
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>Save</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !rewardConfigItem ? null : (<>
        <AddBreadcrumbsItem
            title="Rewards"
            url={getParentPath(props.match.url, 1)}
        />
        <AddBreadcrumbsItem
            title={rewardConfigItem.uid ? `Edit: ${rewardConfigItem.name}` : 'Add Reward'}
            url={props.match.url}
        />

            
        <Block className={styles.editor}>
            <Formik
                initialValues={{
                    name: rewardConfigItem?.name || '',
                    description: rewardConfigItem?.description || '',
                    achievement: rewardConfigItem?.achievement?.uid || '',
                    pipOption: rewardConfigItem?.pipOption?.uid || '',
                }}
                validationSchema={getValidationSchema}
                onSubmit={handleSubmit}
            >
                {renderForm}
            </Formik>
        </Block>

        {deleteDialogVisible && (
            <DeleteQuestionPopup
                title="Reward Schema Deletion"
                question={<>Are you sure want to delete Reward?</>}
                onClose={() => setDeleteDialogVisible(false)}
                onDelete={deleteRewardConfigItem}
                onFinish={() => { props.history.push(getParentPath(props.match.url, 1)) }}
            />
        )}
    </>);
}
