
import { faArrowLeft, faTrashAlt, faUnlock, faLock, faPlus, faEdit, faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { Block } from '../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { basePerformError } from '../../../helpers/error-helpers';
import { getParentPath, joinPath } from '../../../helpers/path-helpers';
import { compareText } from '../../../helpers/array-helpers';
import { IAnswer } from '../../../models/answer';
import { IEpisode } from '../../../models/episode';
import { IEmotionEpisode } from '../../../models/emotion-episode';
import { getAnswer } from '../../../api/actions/answers/answer-get';
import { flaggedAnswerResolve } from '../../../api/actions/answers/flagged-answer-resolve';
import { flaggedAnswerConfirm } from '../../../api/actions/answers/flagged-answer-confirm';
import { EntityTypes } from '../../../models/enums/entity-types';
import { getRelativeLinkToEntity } from '../../../helpers/path-helpers';
import moment from 'moment';
import styles from './response-viewer.module.scss';



export function ResponseViewer (props: RouteComponentProps<{uid: string}>) {

    const [answer, setAnswer] = useState<IAnswer>();


    const loadData = useCallback(async (uid?: string) => {
        try {
            if(uid || props.match.params.uid !== '_') {
                setAnswer(await getAnswer(uid || props.match.params.uid));
            }
            else {
                setAnswer({} as IAnswer);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData().catch(() => {/** */});
    }, []);

    const getEpisodeTitle = (episode: IEpisode) => {
      return (`S${episode.season} : E${episode.numberInSeason} ${episode.name}`);
    }

    const goToUser = async (userUid: string | undefined, isNewWindow = false) => {
        if(!userUid) return;

        const url = '/users/users/' + userUid;
        if(isNewWindow) {
            window.open(url);
        }
        else {
            props.history.push(url);
        }
    }

    const goToEmotionEpisode = async (emep: IEmotionEpisode | undefined, isNewWindow = false) => {
        if (!emep) return;

        const url = await getRelativeLinkToEntity(EntityTypes.emotionEpisode, emep.uid);
        if(isNewWindow) {
            window.open(url);
        }
        else {
            props.history.push(url);
        }
    }

    const resolveAnswer = async (uid: string) => {

        if(!uid) return;
        try {
            const result = await flaggedAnswerResolve(uid);
            
            if (result) {
                await loadData();
                toast.success('Flagged answer has been successfully resolved.');
            } else {
                toast.error('Oops. There was a problem.');
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }

    const confirmAnswer = async (uid: string) => {

        if(!uid) return;
        try {
            const result = await flaggedAnswerConfirm(uid);
            
            if (result) {
                await loadData();
                toast.success('Answer has been successfully confirmed as officially flagged.');
            } else {
                toast.error('Oops. There was a problem.');
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }


	return (<>
        <Block className={styles.viewer}>

            <AddBreadcrumbsItem
                title="User Responses"
                url={getParentPath(props.match.url, 1)}
            />
            <AddBreadcrumbsItem
                key={answer?.uid}
                title={'Response'}
                url={props.match.url}
            />

            {answer?.uid && (<>

            <div className={styles.dataContainer}>
                <div className={styles.basicInfo}>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Submitted At:</div>
                        <div className={styles.basicItemValue}>{moment.utc(answer.createdAt).format('MMM DD, YYYY, h:mm a')}</div>
                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Author ID:</div>
                        <div className={ styles.basicItemValue }>{answer.user?.uid}</div>
                        <LinkButton
                            onClick={() => goToUser(answer.user?.uid, true)}
                        >
                            <FontAwesomeIcon icon={faExternalLinkAlt} /> View
                        </LinkButton>

                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>User Role:</div>
                        <div className={styles.basicItemValue}>{answer.user?.role}</div>
                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Answer:</div>
                        <div className={styles.basicItemValue}>{'"' + answer.answer + '"'}</div>
                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Skill:</div>
                        <div className={styles.basicItemValue}>{answer.skill?.name}</div>
                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Emotion Episode:</div>
                        <div className={styles.basicItemValue}>{'"' + answer.participant?.emotionEpisode?.description + '"'}</div>
                        <LinkButton
                            onClick={() => goToEmotionEpisode(answer.participant?.emotionEpisode, true)}
                        >
                        <FontAwesomeIcon icon={faExternalLinkAlt} /> View EmEp
                        </LinkButton>

                    </div>
                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Character Focus:</div>
                        <div className={styles.basicItemValue}>{answer.participant?.character?.name}</div>
                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Source:</div>
                        <div className={styles.basicItemValue}>{answer.participant?.emotionEpisode?.scene?.narrative?.title + (answer.participant?.emotionEpisode?.scene?.episode ? ', ' + getEpisodeTitle(answer.participant?.emotionEpisode?.scene?.episode) : '')}</div>
                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Upvotes:</div>
                        <div className={styles.basicItemValue}>{answer.upvoteCount}</div>
                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Downvotes:</div>
                        <div className={styles.basicItemValue}>{answer.downvoteCount}</div>
                    </div>

                    <div className={styles.basicItem}>
                        <div className={styles.basicItemName}>Flag Status:</div>
                        <div className={ styles.basicItemValue }>{answer.flagStatus}</div>
                        
                        {(answer.flagStatus?.toLowerCase() === 'open' || answer.flagStatus?.toLowerCase() === 'confirmed') &&(
                        <div className={styles.itemActions}>
                            <LinkButton
                                onClick={() => resolveAnswer(answer.uid)}
                                className="green"
                            >
                                Resolve
                            </LinkButton>
                        </div>)}


                        {(answer.flagStatus?.toLowerCase() !== 'confirmed') && (
                        <div className={styles.itemActions}>
                            <LinkButton
                                onClick={() => confirmAnswer(answer.uid)}
                                className="orange"
                            >
                                Confirm
                            </LinkButton>
                        </div>)}

                    </div>
                </div>         

            { !!answer?.flags?.length && (<>
            <div>
                {'Flags: ' + answer?.flags.length}

            <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Created</th>
                        <th>Updated</th>
                        <th>Creator ID</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        answer?.flags?.sort((c1, c2) => moment(c1.createdAt).diff(c2.createdAt)).map(((flag, i) => (
                            <tr key={flag.uid}>
                                <td>
                                    <div className={styles.markContainer}>
                                        <div className={`${styles.mark} ${flag.status?.toLowerCase() !== 'pending' ? '' : styles.disabled}`}>&nbsp;</div>
                                        <div className={styles.title}>
                                            {i + 1}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {flag.status}
                                </td>
                                <td>
                                    {flag.type}
                                </td>
                                <td>
                                    {moment.utc(flag.createdAt).format('h:ma MMM DD, YYYY')}
                                </td>

                                <td>
                                    {moment.utc(flag.updatedAt).format('h:ma MMM DD, YYYY')}
                                </td>

                                <td>
                                    {flag.creator?.uid}
                                    <LinkButton
                                        onClick={() => goToUser(flag.creator?.uid, true)}
                                    >
                                        <FontAwesomeIcon icon={faExternalLinkAlt} /> View
                                    </LinkButton>

                                </td>
                            </tr>
                        )))
                    }
                </tbody>
            </table>
            </div>
            </>)}
        </div>

        </>)}
            
        </Block>

    </>);
}