import { FieldProps } from 'formik';
import loadScript from 'load-script';
import * as React from 'react';

declare global {
    interface Window { CKEDITOR: any }
}

const defaultScriptUrl = '/scripts/ckeditor/ckeditor.js'; // 'https://cdn.ckeditor.com/4.6.2/standard/ckeditor.js';

interface IProps {
    content: string,
    config: any,
    isScriptLoaded?: boolean,
    scriptUrl: string,
    bodyClass?: string,
    activeClass?: string,
    onInit?(ckeditor: any): void,
    onChange(event: any): void,
    onFocus?(event: any): void,
    onBlur?(event: any): void,
}

interface IState {
    isScriptLoaded: boolean,
}

export class CKEditor extends React.Component<IProps, IState> {

    static defaultProps = {
        content: '',
        config: {},
        isScriptLoaded: false,
        scriptUrl: defaultScriptUrl,
        bodyClass: 'cke-content',
        activeClass: 'active',
    };

    element?: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    editor?: CKEDITOR.editor;
    unmounting: boolean;

    constructor(props: IProps) {
        super(props);

        // State initialization
        this.state = {
            isScriptLoaded: !!props.isScriptLoaded,
        };
    }

    // load ckeditor script as soon as component mounts if not already loaded
    componentDidMount() {
        if (!this.state.isScriptLoaded) {
            loadScript(this.props.scriptUrl, this.onLoad);
        } else {
            this.onLoad();
        }
    }

    componentDidUpdate() {
        const editor = this.editor;
        if (editor && editor.getData() !== this.props.content) {
            editor.setData(this.props.content);
        }
    }

    componentWillUnmount() {
        this.unmounting = true;
        this.destroyEditor();
    }

    onLoad = () => {
        if (this.unmounting) return;

        this.setState({
            isScriptLoaded: true,
        });

        if (!window.CKEDITOR) {
            console.error('CKEditor not found');
            return;
        }

        this.editor = window.CKEDITOR.appendTo(
            this.element?.current, // ReactDOM.findDOMNode(this),
            {
                bodyClass: this.props.bodyClass,
                ...this.props.config,
            },
            this.props.content,
        );

        if(this.props.onInit) {
            this.props.onInit(window.CKEDITOR);
        }

        // Register listener for custom events if any
        // Object.keys(this.props.events).forEach((event: string) => {
        //     const eventHandler = (this.props.events as any)[event];
        //     this.editorInstance.on(event, eventHandler);
        // });
        this.editor?.on('change', this.props.onChange);
        if(this.props.onFocus) this.editor?.on('focus', this.props.onFocus);
        if(this.props.onBlur) this.editor?.on('blur', this.props.onBlur);
    }

    render() {
        return <div ref={this.element} className={this.props.bodyClass} />;
    }

    private destroyEditor() {
        if ( this.editor ) {
            this.editor.destroy();
        }

        this.editor = undefined;
        this.element = undefined;
    }
}

interface IFieldProps extends FieldProps {
    filebrowserBrowseUrl?: string,
    filebrowserUploadUrl?: string,
    toolbar?: Array<{ name: string, items: string[]} | string>,
    height?: number,
    disabled?: boolean,
}


export function CKEditorField(props: IFieldProps): React.ReactElement {
    const {field} = props;
    return (
        <CKEditor
            content={field.value}
            onChange={( event: any ) => {
                const data = event.editor.getData();
                event = {
                    ...event,
                    target: {
                        name: field.name,
                        value: data,
                    },
                };
                field.onChange(event);
            }}
            onInit={(ckeditor: any) => {
                if(!ckeditor.stylesSet.registered.custom) {
                    ckeditor.stylesSet.add( 'custom', [
                        // Block-level styles.
                        { name: 'Header 1', element: 'h1' },
                        { name: 'Header 2', element: 'h2' },
                        { name: 'Header 3',  element: 'h3' },

                        // Inline styles.
                        { name: 'Success', element: 'div', attributes: { class: 'cke-success' } },
                        { name: 'Attention', element: 'div', attributes: { class: 'cke-attention' } },
                        { name: 'Warning', element: 'div', attributes: { class: 'cke-warning' } },
                    ]);
                }
            }}
            config={{
                // allowedContent: 'div(*); span; p; h1; h2; h3; img[*](*){*}; a; strong; i; u',
                height: props.height || 800,
                readOnly: props.disabled,
                stylesSet: 'custom',
                contentsCss: '/styles/ckeditor.css',
                toolbar: props.toolbar || [
                    { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                    { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] }, { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
                    { name: 'document', items: [ 'Preview', 'Print', '-', 'Source' ] },
                    '/',
                    { name: 'styles', items: [ 'Styles' ] },
                    { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
                    { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                    { name: 'fonts', items: [ 'Font', 'FontSize', 'TextColor' ]},
                    { name: 'insert', items: [ 'Image', 'Video', 'Table', 'HorizontalRule', 'SpecialChar' ] },
                ],
                filebrowserBrowseUrl: props.filebrowserBrowseUrl,
                filebrowserUploadUrl: props.filebrowserUploadUrl,
                filebrowserWindowWidth: '100',
                filebrowserWindowHeight: '100',
            }}
        />
    );
}

