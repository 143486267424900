import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { getExternalReference } from '../../../../../../api/actions/external-reference/external-reference-get';
import { externalReferenceSave } from '../../../../../../api/actions/external-reference/external-reference-save';
import { Button } from '../../../../../../components/shared/button/button';
import { Loader } from '../../../../../../components/shared/loader/loader';
import { basePerformError } from '../../../../../../helpers/error-helpers';
import { IEpisode } from '../../../../../../models/episode';
import { IExternalReference } from '../../../../../../models/external-reference';
import { INarrative } from '../../../../../../models/narrative';
import { IDictionary } from '../../../../../../types/dictionary';
import { CheckBoxWrapper } from '../../../../../../components/shared/checkbox/checkbox-wrapper';
import { isNotNullOrEmpty, isNullOrEmpty } from '../../../../../../helpers/common-helpers';
import { SelectField } from '../../../../../../components/shared/select/select';
import { IVideoService } from '../../../../../../models/video-service';
import { getAllVideoServices } from '../../../../../../api/actions/video-service/video-service-get-all';
import { compareText } from '../../../../../../helpers/array-helpers';
import styles from './external-reference-editor.module.scss';

interface IProps extends RouteComponentProps {
    uid?: string,
    narrativeUid?: string,
    episodeUid?: string,
    onClose: () => void,
    reload: () => void,
}


enum FormFields {
    serviceName = 'serviceName',
    externalServiceUid = 'externalServiceUid',
    externalServiceUrl = 'externalServiceUrl',
    infoPageUid = 'infoPageUid',
    timeOffset = 'timeOffset',
    preferredSource = 'preferredSource',
    paidPlansOnly = 'paidPlansOnly'
}

interface IForm extends Partial<IExternalReference> {
}


function ExternalReferenceEditor(props: IProps) {

    const [loading, setLoading] = useState(false);
    const [externalReference, setExternalReference] = useState<IExternalReference>();
    const [videoServices, setVideoServices] = useState<IVideoService[]>();

    const [useVideoUid, setUseVideoUid] = useState(true);

    const loadData = useCallback(async (uid?: string) => {
        try {
            setVideoServices((await getAllVideoServices()).sort((el1, el2) => compareText(el1, el2, (el) => el.name)));
            if(uid || props.uid) {
                const exr = await getExternalReference(uid || props.uid || '');
                setUseVideoUid(!exr.infoPageUid);
                setExternalReference(exr);
            }
            else {
                setExternalReference({} as IExternalReference);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.uid, props.history]);

    useEffect(() => {
        loadData();
    }, [loadData, props.history, props.uid]);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newExternalReference: Partial<IExternalReference> = {
                uid: externalReference?.uid,
                serviceName: values.serviceName,
                externalServiceUid: values.externalServiceUid,
                infoPageUid: values.infoPageUid,
                externalServiceUrl: isNotNullOrEmpty(values.externalServiceUrl) ? values.externalServiceUrl : undefined,
                timeOffset: !values.timeOffset || isNullOrEmpty(values.timeOffset) ? undefined : values.timeOffset,
                preferredSource: values.preferredSource,
                paidPlansOnly: values.paidPlansOnly === true
            };
            if(!newExternalReference.uid) {
                if(props.narrativeUid) {
                    newExternalReference.narrative = { uid: props.narrativeUid } as INarrative;
                }
                else if(props.episodeUid) {
                    newExternalReference.episode = { uid: props.episodeUid } as IEpisode;
                }
            }

            const uid = await externalReferenceSave(newExternalReference);
            loadData(uid);
            props.reload();
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            serviceName: Yup.string().trim().label('Service Name').required().min(3).max(50),
            externalServiceUid: Yup.string().label('External Service UID').trim().required().min(3).max(100),
            externalServiceUrl: Yup.string().label('External Service URL').trim().url(),
            infoPageUid: Yup.string().label('Info Page UID').trim().min(3).max(200),

        });
    };

    const renderForm = ({ errors, touched, values, setFieldValue }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div>
                <Form noValidate>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Service Name
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.serviceName}
                                data={videoServices}
                                valueExtractor={(item: IVideoService) => item.identifier}
                            />
                        </label>
                        <div className="errors">{touched.serviceName && errors.serviceName}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Video UID
                            </div>
                            <Field type="text" name={FormFields.externalServiceUid} />
                        </label>
                        <div className="errors">{touched.externalServiceUid && errors.externalServiceUid}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Info Page UID
                            </div>
                            <Field type="text" name={FormFields.infoPageUid} disabled={useVideoUid} />

                            <CheckBoxWrapper label="Use Video UID as Info Page UID">
                                <input
                                    type="checkbox"
                                    checked={useVideoUid}
                                    onChange={()=> {
                                        setUseVideoUid(!useVideoUid);
                                        if(!useVideoUid) {
                                            setFieldValue(FormFields.infoPageUid, '');
                                        }
                                    }}
                                />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.infoPageUid && errors.infoPageUid}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Service URL
                            </div>
                            <Field type="text" name={FormFields.externalServiceUrl} />
                        </label>
                        <div className="errors">{touched.externalServiceUrl && errors.externalServiceUrl}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Available with Paid Plans Only">
                                <Field type="checkbox" name={FormFields.paidPlansOnly} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.paidPlansOnly && errors.paidPlansOnly}</div>
                    </div>
                    
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Time Offset (seconds)
                            </div>
                            <Field type="text" name={FormFields.timeOffset} />
                        </label>
                        <div className="errors">{touched.timeOffset && errors.timeOffset}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Preferred Source">
                                <Field type="checkbox" name={FormFields.preferredSource} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.preferredSource && errors.preferredSource}</div>
                    </div>

                    <div className="form-buttons">
                        <Button onClick={props.onClose} className="gray">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>{externalReference?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !externalReference ? null : (
        <Formik
            initialValues={{
                serviceName: externalReference.serviceName || '',
                externalServiceUid: externalReference.externalServiceUid || '',
                infoPageUid: useVideoUid ? '': (externalReference.infoPageUid || ''),
                externalServiceUrl: externalReference.externalServiceUrl || '',
                timeOffset: externalReference.timeOffset || 0,
                preferredSource: externalReference.preferredSource || false,
                paidPlansOnly: externalReference.paidPlansOnly || false,
            }}
            validationSchema={getValidationSchema}
            onSubmit={handleSubmit}
        >
            {renderForm}
        </Formik>
    );
}

const ExternalReferenceEditorWithRouter = withRouter(ExternalReferenceEditor);
export { ExternalReferenceEditorWithRouter as ExternalReferenceEditor };
