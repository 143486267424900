import { FetchResult, gql } from 'apollo-boost';
import { ITask } from '../../../models/task';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation TaskSave($task: TaskSaveInput!){
        task_save (task_data: $task) {
            uid
        }
    }
`;

export async function taskSave(task: Partial<ITask>): Promise<string> {
    const result: FetchResult<{ task_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: {
            task: {
                uid: task.uid,
                name: task.name,
                description: task.description,
                status: task.status,
                priority: task.priority,
                deadline_at: task.deadlineAt,
                task_type: task.taskType,
                entity_uid: task.entityUid,
                entity_name: task.entityName,
                entity_type: task.entityType,

                author: task.author,
                assignee: task.assignee,
            }
        },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.task_save.uid;
}
