import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { UserGroupsListComponent } from './parts/user-groups-list-component';


export function UserGroupsList(props: RouteComponentProps) {

    

    return (
        <>
            <AddBreadcrumbsItem
                title="Groups"
                url={props.match.url}
            />

            <UserGroupsListComponent pagePath={props.match.url} />
        </>
    );
}
