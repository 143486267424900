import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowDown, faArrowLeft, faArrowUp, faLock, faPlus, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { getCurriculum } from '../../../api/actions/curriculum/curriculum-get';
import { unitSectionDisable } from '../../../api/actions/unit-section/unit-section-disable';
import { unitSectionEnable } from '../../../api/actions/unit-section/unit-section-enable';
import { unitSectionSetIndex } from '../../../api/actions/unit-section/unit-section-set-index';
import { unitDelete } from '../../../api/actions/unit/unit-delete';
import { unitDisable } from '../../../api/actions/unit/unit-disable';
import { unitEnable } from '../../../api/actions/unit/unit-enable';
import { getUnit } from '../../../api/actions/unit/unit-get';
import { unitSave } from '../../../api/actions/unit/unit-save';
import { Block } from '../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { SelectField } from '../../../components/shared/select/select';
import { UploaderField } from '../../../components/shared/uploader/uploader';
import { UploaderImagePreview } from '../../../components/shared/uploader/uploader-image-preview';
import { getUnitFilePath } from '../../../config/app-config';
import { basePerformError } from '../../../helpers/error-helpers';
import { joinPath } from '../../../helpers/path-helpers';
import { ICurriculum } from '../../../models/curriculum';
import { IFile } from '../../../models/file';
import { IUnit, UnitTypes } from '../../../models/unit';
import { IUnitSection } from '../../../models/unit-section';
import { IDictionary } from '../../../types/dictionary';
import { ConceptsCoveredList } from './concept-covered/concepts-covered-list';
import { CKEditorField } from '../../../components/shared/ckeditor/ckeditor';
import styles from './unit-editor.module.scss';


enum FormFields {
    name = 'name',
    description = 'description',
    teaser = 'teaser',
    type = 'type',
    index = 'index',
    guide = 'guide',
    modelFile = 'modelFile',
    iconFile = 'iconFile',
    worksheet = 'worksheet'
}

interface IForm {
    [FormFields.name]: string,
    [FormFields.description]?: string,
    [FormFields.teaser]?: string,
    [FormFields.type]?: UnitTypes,
    [FormFields.index]?: number,
    [FormFields.guide]?: string,
    [FormFields.worksheet]?: string,
    [FormFields.modelFile]?: IFile,
    [FormFields.iconFile]?: IFile,
}

export function UnitEditor(props: RouteComponentProps<{uid: string, curriculumUid: string}>) {

    const [loading, setLoading] = useState(false);
    const [unit, setUnit] = useState<IUnit>();
    const [curriculum, setCurriculum] = useState<ICurriculum>();
    const [entityForDelete, setEntityForDelete] = useState<IUnit>();

    const loadData = React.useCallback(async (uid?: string) => {
        try {
            setCurriculum(await getCurriculum(props.match.params.curriculumUid));
            if(uid || props.match.params.uid !== '_') {
                setUnit(await getUnit(uid || props.match.params.uid));
            }
            else {
                setUnit({} as IUnit);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.curriculumUid, props.match.params.uid]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newUnit: Partial<IUnit> = {
                uid: unit?.uid,
                name: values.name,
                description: values.description,
                teaser: values.teaser,
                type: values.type,
                index: values.index,
                educatorGuide: values.guide,
                worksheet: values.worksheet,
                iconFileName: values.iconFile && values.iconFile.url,
            };
            if(!newUnit.uid) {
                newUnit.curriculum = { uid: props.match.params.curriculumUid } as ICurriculum;
            }
            const uid = await unitSave(newUnit, values.modelFile && values.modelFile.file, values.iconFile && values.iconFile.file);
            if(!unit?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            }
            await loadData(uid);
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const enableUnit = async () => {
        if(!unit?.uid) return;
        try {
            await unitEnable(unit.uid);
            setUnit({...unit, available: true });
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const disableUnit = async () => {
        if(!unit?.uid) return;
        try {
            await unitDisable(unit.uid);
            setUnit({...unit, available: false });
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const enableUnitSection = async (uid: string) => {
        try {
            await unitSectionEnable(uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const disableUnitSection = async (uid: string) => {
        try {
            await unitSectionDisable(uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const setIndex = async (uid: string, index: number) => {
        try {
            await unitSectionSetIndex(uid, index);
            loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().trim().required('Please enter Name').min(3).max(50),
            description: Yup.string().trim().min(3).max(255),
            teaser: Yup.string().trim().min(3).max(255),
            index: Yup.number().required('Please select Index').min(1),
        });
    };

    const renderForm = ({ errors, touched, isValid }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>

                    { unit && unit.uid && (
                        <div className="form-buttons">
                            { unit.uid && !unit.available && (
                                <LinkButton onClick={enableUnit}><FontAwesomeIcon icon={faUnlock} /> Enable</LinkButton>
                            )}
                            { unit.uid && unit.available && (
                                <LinkButton onClick={disableUnit}><FontAwesomeIcon icon={faLock} /> Disable</LinkButton>
                            )}
                            { unit.uid && unit.edited && (
                                <div>Edited*</div>
                            )}
                            { unit.uid && (
                                <LinkButton onClick={() => setEntityForDelete(unit)} className="red">
                                    <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                </LinkButton>
                            )}
                        </div>
                    )}
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name
                            </div>
                            <Field type="text" name={FormFields.name} />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field component="textarea" name={FormFields.description} style={{ height: '10rem' }} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Teaser
                            </div>
                            <Field type="text" name={FormFields.teaser} />
                        </label>
                        <div className="errors">{touched.teaser && errors.teaser}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Type
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.type}
                                emptyTitle=""
                                data={ Object.keys(UnitTypes)
                                    .map((el: string) => ({ uid: (UnitTypes as any)[el], name: (UnitTypes as any)[el] }))
                                }
                            />
                        </label>
                        <div className="errors">{touched.type && errors.type}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Index
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.index}
                                emptyTitle=""
                                data={[
                                    { uid: 1, name: "First" },
                                    ...(
                                        curriculum?.units
                                        ? curriculum.units.map((u: IUnit, i: number) => (
                                            u.uid === unit?.uid
                                            ? null
                                            : {
                                                uid: !unit?.index || unit?.index > i ? (i + 2) : (i + 1),
                                                name: `#${!unit?.index || unit?.index > i ? (i + 2) : (i + 1)} After: ${u.name}`,
                                            }
                                        )).filter((u) => !!u)
                                        : []
                                    ),
                                ]}
                            />
                        </label>
                        <div className="errors">{touched.index && errors.index}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Practitioner Guide
                            </div>
                            <Field component={CKEditorField} name={FormFields.guide} height={200}/>
                        </label>
                        <div className="errors">{touched.guide && errors.guide}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Worksheet
                            </div>
                            <Field type="text" name={FormFields.worksheet} />
                        </label>
                        <div className="errors">{touched.worksheet && errors.worksheet}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Final Emotion Model State
                            </div>
                            <Field
                                component={UploaderField}
                                name={FormFields.modelFile}
                                acceptFileTypes=".png,.jpg,.jpeg"
                                path={getUnitFilePath(unit?.uid || '')}
                                previewComponent={UploaderImagePreview}
                            />
                        </label>
                        <div className="errors">{touched.modelFile && errors.modelFile}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Icon
                            </div>
                            <Field
                                component={UploaderField}
                                name={FormFields.iconFile}
                                acceptFileTypes=".png,.jpg,.jpeg"
                                path={getUnitFilePath(unit?.uid || '')}
                                previewComponent={UploaderImagePreview}
                            />
                        </label>
                        <div className="errors">{touched.modelFile && errors.modelFile}</div>
                    </div>

                    <div className="form-buttons">
                        <Button onClick={() => props.history.push(joinPath('/curriculums', props.match.params.curriculumUid))} className="gray">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                        ? (<Loader />)
                        : (<Button type="submit"><span>{unit?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return (<>
        <Block className={styles.editor}>
            { unit && curriculum?.uid && (<>
                <AddBreadcrumbsItem
                    title={curriculum && `Curriculum: ${curriculum.name}`}
                    url={joinPath('/curriculums', curriculum.uid)}
                />
                <AddBreadcrumbsItem
                    title={unit && unit.uid ? `Edit Unit: ${unit.name}` : 'Add Unit'}
                />
                <Formik
                        initialValues={{
                            name: unit.name || '',
                            description: (unit && unit.description) || '',
                            teaser: (unit && unit.teaser) || '',
                            type: (unit && unit.type),
                            index: (unit && unit.index) || undefined,
                            guide: (unit && unit.educatorGuide) || '',
                            worksheet: (unit && unit.worksheet) || '',
                            modelFile: (unit
                                && (
                                    (unit.finalEmotionModelState && { url: unit.finalEmotionModelState })
                                    || (unit.modelFile && { file: unit.modelFile })
                                )) || undefined,
                            iconFile: (unit
                                && (
                                    (unit.iconFileName && { url: unit.iconFileName })
                                    || (unit.iconFile && { file: unit.iconFile })
                                )) || undefined,
                        }}
                        validationSchema={getValidationSchema}
                        onSubmit={handleSubmit}
                >
                    {renderForm}
                </Formik>

                <div>

                </div>
            </>)}
        </Block>





        { unit && unit.uid && (<>
            <ConceptsCoveredList unit={unit} reload={loadData} />

            <div className="list-title">
                <div>Unit Sections: <strong>{ (unit.unitSections && unit.unitSections.length) || 0 }</strong></div>
                <div>
                    <Button className="orange" onClick={() => { props.history.push(joinPath('/', props.match.url, 'unit-sections/_')); }}>
                        <FontAwesomeIcon icon={faPlus} /> <span>Add Unit Section</span>
                    </Button>
                </div>
            </div>
            { unit.unitSections && !!unit.unitSections.length && (
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Unit Section</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            unit.unitSections.sort((u1, u2) => u1.index - u2.index)
                            .map((u: IUnitSection, i: number) => (
                                <tr key={u.uid}>
                                    <td>
                                        <div className={styles.markContainer}>
                                            <div className={`${styles.mark} ${u.available ? '' : styles.disabled}`}>&nbsp;</div>
                                            <div className={styles.title}>
                                                {u.index}
                                                <LinkButton
                                                    onClick={() => setIndex(u.uid, i + 2)}
                                                    disabled={i >= (unit.unitSections?.length || 0) - 1}
                                                >
                                                    <FontAwesomeIcon icon={faArrowDown} />
                                                </LinkButton>
                                                <LinkButton
                                                    onClick={() => setIndex(u.uid, i)}
                                                    disabled={i < 1}
                                                >
                                                    <FontAwesomeIcon icon={faArrowUp} />
                                                </LinkButton>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {u.name + (u.edited ? '*' : '')}
                                        <div className="list-item-comment">{u.description}</div>
                                    </td>
                                    <td>
                                        {u.type?.toUpperCase()}
                                    </td>
                                    <td>
                                        <LinkButton
                                            onClick={() => props.history.push(joinPath('/', props.match.url, 'unit-sections', u.uid)) }
                                        >
                                            <FontAwesomeIcon icon={faEdit} /> Edit
                                        </LinkButton><br />
                                        { !u.available && (<>
                                            <LinkButton onClick={() => enableUnitSection(u.uid)}>
                                                <FontAwesomeIcon icon={faUnlock} /> Enable
                                            </LinkButton><br />
                                        </>)}
                                        { u.available && (<>
                                            <LinkButton onClick={() => disableUnitSection(u.uid)}>
                                                <FontAwesomeIcon icon={faLock} /> Disable
                                            </LinkButton><br />
                                        </>)}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )}

            { entityForDelete && (
                <DeleteQuestionPopup
                    title="Delete Unit"
                    question={<>Are you sure want to delete unit: <strong>{entityForDelete.name}</strong>?</>}
                    onClose={() => setEntityForDelete(undefined)}
                    onDelete={async () => await unitDelete(entityForDelete.uid)}
                    onFinish={() => { props.history.push(joinPath('/curriculums', props.match.params.curriculumUid)); }}
                />
            )}
        </>)}
    </>);
}
