import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import styles from './button.module.scss';

interface IProps extends RouteComponentProps {
  children: string | React.ReactElement | Array<string | React.ReactElement>;
  onClick?: () => void;
  to?: string | { pathname: string; state: any };
  disabled?: boolean;
  type?: 'submit' | 'reset';
  className?: string;
}

function Button(props: IProps) {
  const { to: toUrl, onClick } = props;
  return (
    <button
      className={`button ${styles.button} ${props.className || ''}`}
      disabled={props.disabled}
      type={props.type || 'button'}
      onClick={
        props.disabled
          ? undefined
          : onClick
          ? onClick
          : toUrl
          ? () => props.history.push(toUrl)
          : undefined
      }
    >
      {props.children}
    </button>
  );
}

const ButtonWithHistory = withRouter(Button);

export { ButtonWithHistory as Button };
