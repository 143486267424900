import { faFile, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { getBasicFileName, getFullFileName, joinPath } from '../../../helpers/path-helpers';
import { IFile } from '../../../models/file';
import styles from './uploader.module.scss';

interface IProps {
    name?: string,
    value?: IFile | IFile[],
    path?: string,
    onChange: (event: React.ChangeEvent<any>) => void,
    onBlur: (event: FocusEvent) => void,
    disabled?: boolean,
    multiple?: boolean,
    acceptFileTypes?: string,
    form: FormikProps<any>,
    previewComponent?: React.ComponentClass<any> | React.FC<any>,
    deleteDisabled?: boolean,
}

export function Uploader(props: IProps) {

    const getValue = (): IFile[] => (props.value ? (Array.isArray(props.value) ? props.value : [props.value]) : []);

    const onDragOver = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
    };

    const onChange = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        appendFiles(event);
    };

    const onDrop = (event: any) => {
        onChange(event);
    };

    const appendFiles = (event: any) => {
        const fls: FileList = (event.dataTransfer && event.dataTransfer.files) || (event.target && event.target.files) || null;
        if(!fls) return;

        const selectedFiles = Array.from<File>(fls).map<IFile>(f => ({ file: f }));

        event = { ...event, target: {} };

        event.target.name = props.name;

        if(!props.multiple) {
            event.target.value = selectedFiles[0];
        }
        else {
            event.target.value = [...getValue(), ...selectedFiles];
        }
        props.onChange(event);
    };

    const removeFile = (event: any, i: number) => props.onChange({
        ...event,
        target: {
            name: props.name,
            value: getValue().filter((f: IFile, index: number) => i !== index),
        },
    });

    const stopEvent = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const openPreview = (f: IFile) => {
        // if(f.url) {
        //     window.open(joinPath(props.path, f.url));
        // }
    };

    return (
        <div className={styles.uploader}>
            <label className={styles.dropZone} onDragOver={onDragOver} onDrop={onDrop}>
                <div className={styles.inactiveText} >
                    Drag and drop files here<br />
                    or<br />
                    Click here to browse
                </div>
                <input type="file" accept={props.acceptFileTypes || '*'} onChange={onChange} multiple={!!props.multiple} />
            </label>
            <div className={styles.filesList} onClick={stopEvent}>
                {
                    getValue().map((f: IFile, i: number) => {
                        return props.previewComponent
                            ? (<props.previewComponent
                                key={i}
                                file={f}
                                path={props.path}
                                onDelete={props.deleteDisabled ? undefined : (evt: any) => removeFile(evt, i)}
                            />)
                            : (
                                <div key={i} className={styles.filePreview}>
                                    <a
                                        href={f.url && joinPath(props.path, f.url)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() => openPreview(f)}
                                    >
                                        <FontAwesomeIcon icon={faFile} />
                                        {getBasicFileName(getFullFileName((f.file && f.file.name) || f.url || ''))}
                                    </a>
                                    { !props.deleteDisabled && (
                                        <div className={styles.deleteBtn} onClick={(evt: any) => removeFile(evt, i)}>
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </div>
                                    )}
                                </div>
                            );
                    })
                }
            </div>
        </div>
    );
}

export function UploaderField(props: FieldProps & Partial<IProps>): React.ReactElement {
    const {field, form, ...params} = props;
    return (
        <Uploader
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            form={form}
            {...params}
        />
    );
}
